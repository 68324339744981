<template>
    <div class="upload-box">
        <el-drawer
        :visible.sync="drawerShow"
        size="100%"
        v-watchWidth="handleWatch"
        custom-class="UploadfileClass"
        id="UploadfileId"
        direction="btt"
        :modal-append-to-body="false"
        :destroy-on-close="false"
        ref=""
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :show-close="!is_after_uploading"
        >
            <div slot="title" class="title">
                证据中心上传工作台
            </div>
            <div class="upload" v-if="!is_after_uploading">
                <div class="el-upload-box">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    drag
                    :style="upLoadStyle"
                    action="none"
                    :auto-upload="false"
                    :on-change="handlenUpLoadChangeDebounced"
                    :accept="getAccept"
                    multiple>
                    <div>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text"><p>将文件拖到此处</p><p> 或</p><p><em>点击上传</em></p></div>
                    </div>
                    <div class="upload-footer-class">
                    <p>支持格式：pdf，jpg，png，bmp，zip，rar，doc，docx，xls，xlsx，mp4，avi，mov，mkv，wmv，flv，webm，mp3，wav，aac，ogg，wma，flac，mid，midi</p>
                    <p>单次上传限制：文件数量不超过1000个，文件总大小不超过1GB（压缩包不超过1GB，单个文件不超过500MB）</p>
                    </div>
                    </el-upload>
                </div>
                <div class="upload-list-box">
                    <div class="upload-list" :style="uploadListStyle">
                        <div v-for="fileType in uploadFileTypes" :key="fileType.value">
                            <div v-show="getFiles(fileType.value).length > 0">
                                <el-row class="fileTitle">
                                    <el-col :span="20">{{ fileType.label }}</el-col>
                                    <el-col :span="3">文件大小</el-col>
                                    <el-col :span="1"></el-col>
                                </el-row>
                                <div v-for="item in getFiles(fileType.value)" :key="item.key" @mouseout="OneFileOut(item)" @mouseover="OneFileOver(item)" class="fileContent">
                                    <el-col :title="item.fileName" class="fileColumn1" :span="20">
                                        <el-image :src="getFileTypeImg(item.fileName)"></el-image>
                                        <p> {{ item.fileName }}</p>
                                    </el-col>
                                    <el-col :span="3">
                                        <span>{{ item.fileSizeStr}}</span>
                                    </el-col>
                                    <el-col v-show="item.is_hover" :span="1">
                                        <span @click="handleRemoveFile(fileType.value, item.key)" class="delICon">删除</span>
                                    </el-col>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="upload-list-footer">
                        <div>
                            <el-checkbox v-model="is_decompression_file">zip, rar文件仅保存，不解压缩</el-checkbox>
                        </div>
                        <div>
                            <span style="margin-right:10px;">共{{ fileList.length }}个文件</span>
                            <el-button size="small " type="primary" v-preventReClick  @click="handlenSubmitFile">上&nbsp;&nbsp;传</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else :style="UploadSuccessStyle" class="UploadSuccessClass">
                <div class="fileTitle">
                    <el-col :span="upload_after_file_list_width.fileName">文件名称</el-col>
                    <el-col :span="upload_after_file_list_width.fileSize">文件大小</el-col>
                    <el-col :span="upload_after_file_list_width.fileStatus" class="fileColumn3">
                        <span>上传状态</span>
                        <span class="caret">
                            <i class="el-icon-caret-top iconCaret" :class="sortProps =='top' ? 'iconCaretActive' :''" @click="handleSetsortProps('top')"></i>
                            <i class="el-icon-caret-bottom iconCaret" :class="sortProps =='bottom' ? 'iconCaretActive' :''" @click="handleSetsortProps('bottom')"></i>
                    </span>
                    </el-col>
                </div>
                <div v-for=" file in upload_file_list" :key="file.key">
                    <FileItem :file="file" :widths="upload_after_file_list_width" @oneFileSubmit="handleOneFileSubmit"/>
                </div>
            </div>
            <el-progress v-show="is_after_uploading" :percentage="uploadPercentage" :show-text="false" class="progressClass"></el-progress>
            <div class="footer-box"  v-show="is_after_uploading"></div>
            <div class="drawer-footer-class" v-show="is_after_uploading">
                <div v-show="!is_complete" style="display:flex;justify-content: space-between;align-items:center;">
                    <div class="text2 animationBox" ref="animationRef" style="display:flex;">
                        <p  v-watchWidth="handleSpanWidth">共
                            <span class="colorSpan">{{ upload_file_list.length }}</span>
                            个文件，已完成</p>&nbsp;
                            <span class="numberScroll colorSpan" :style="`left:${SpanWidth + 4 }px;`">0</span>
                            <span style="opacity: 0;" class="colorSpan">{{completeNum}}</span>
                            &nbsp;个文件
                    </div>
                    <el-button type="primary" size="small" v-preventReClick @click="handleHideUpload">后台运行</el-button>
                </div>
                <div  v-show="is_complete" style="display:flex;justify-content: flex-end;align-items:center;">
                    <p class="text2">共上传
                    <span class="colorSpan"> {{ upload_file_list.length }} </span>
                    个文件，成功
                    <span class="colorSpan"> {{ succeededNum }} </span>
                    个文件，失败 
                    <span class="colorSpan"> {{ upload_file_list.length - succeededNum }} </span>
                    个文件</p>
                    <el-button type="primary" size="small" v-preventReClick @click="handleCloseUpload">完成</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapState,mapGetters,mapMutations,mapActions } from 'vuex';
import { getfileSuffixAndImg,get_size_str,delEvidenceSeq} from '@/utils/index.js';
import FileItem from '@/components/evidence/FileItem.vue';
import { fileLimits } from "@/utils/data";

export default {
    name:'evidence_upload',
    data(){
        return{
            is_after_uploading:false,
            upLoadStyle:{
                width:'100%',
                height:''  //抽屉的高
            },
            uploadListStyle:{
                height:'' // 抽屉的高
            },
            UploadSuccessStyle:{
                height:'',
            },
            // accept:'.pdf,.jpg,.png,.bmp,.zip,.rar,.doc,.docx,.xls,.xlsx,.mp4,.avi,.mov,.mkv,.wmv,.flv,.webm,.mp3,.wav,.aac,.ogg,.wma,.flac,.mid,.midi',
            fileList:[],   // 总文件
            fileAllSize:0,
            textFiles:[],   // 文档文件  
            imgFiles:[],    // 图片文件
            zipFiles:[],    // 压缩包文件
            mediaPlayerFiles:[],   // 多媒体文件  （视频音频）
            fileSort:fileLimits,
            is_decompression_file:true,
            uploadFileTypes:[
                {label:'文档',value:'textFiles'},
                {label:'图片',value:'imgFiles'},
                {label:'压缩包',value:'zipFiles'},
                {label:'多媒体',value:'mediaPlayerFiles'},
            ],
            upload_after_file_list_width:{
                fileName:16,
                fileSize:4,
                fileStatus:4
            },
            upload_file_list:[],
            // fileStatus:{
            //     succeeded:[// 上传成功
                    
            //     ],
            //     failed_store:[// 上传失败
            //         {key:4,text:'重新发送',api:'handleSubmit'},
            //     ],
            //     repeat:[// 文件存在

            //     ],
            //     failed_unzip:[// failed_unzip

            //     ],
            //     wait:[// 上传中

            //     ]
            // },
            is_complete:false,      // 是否上传后端
            uploadPercentageServer:0,    // 后端服务进度条
            succeededList:['succeeded','repeat'],   // 成功的数组
            succeededNum:0,     // 成功的数量
            completeNum:0,     // 完成的数量
            // 计算页面更新-------
            completeNumCopy:0,   
            numberScrollLoading:false,
            SpanWidth:0,
            // 限制条件
            allFilesSize:1024 *1024 *1024 * 1,
            allFilesNum:1000,
            // 
            sortProps:null, // top bottom 
        }
    },
    components: {
        FileItem,
    },
    watch:{
        numberScrollLoading(newVal){
            if (newVal) return
            if (this.completeNum === this.completeNumCopy) return
            this.handleWatchCompleteNum(this.completeNum)
        },
        completeNum:{
            handler:'handleWatchCompleteNum',
            deep: true,
        },
    },
    methods:{
        // 修改一些样式
        handleWatch(e){
            let upLoadStyleHeight =  e.height;
            let delHeight = 52 + 20 +20 +10;
            upLoadStyleHeight = upLoadStyleHeight - delHeight;
            upLoadStyleHeight = upLoadStyleHeight >500?upLoadStyleHeight:500;
            this.upLoadStyle.height = upLoadStyleHeight  + 'px';
            this.uploadListStyle.height = upLoadStyleHeight - 40 +'px' ;
            this.UploadSuccessStyle.height = upLoadStyleHeight -40  + 'px';
        },
        // 防抖函数
        handlenUpLoadChangeDebounced: _.debounce(function (file, fileList) {
            this.handleBatchUpload(fileList)
        }, 500),
        // 限制函数
        async handleBatchUpload(fileList){
            const batchSize = 200;  // 多少个文件停止一次

            let currentBatchSize = 0; // 现在是第几个文件

            for (const file of fileList) {

                if (this.fileAllSize > this.allFilesSize  || this.fileList.length > this.allFilesNum) continue;

                if (currentBatchSize >= batchSize) {

                    await new Promise((resolve, reject)=>{
                        setTimeout(resolve, 100)
                    });

                    currentBatchSize = 0;
                }

                this.handlenUpLoadChange(file);

                currentBatchSize++;
            }
            fileList.length = 0;

        },
        // 选中文件触发
        handlenUpLoadChange(file){
            let fileType = file.raw.type;
            let fileName = file.name;
            let fileSize = file.size;
             // 判断文件是否符合------
            let is_fileType = false;
            let addFileField = null;
            let fileLimitSize = 1024 * 1024 * 500;
            let suffix = fileName.split('.').at(-1);
            suffix = '.' + suffix;
             // 判断文件格式是否正确 并记录需要分类数组
            for (const key in this.fileSort) {
                if (this.fileSort[key] && this.fileSort[key].fileType && this.fileSort[key].fileType.length > 0) {
                    is_fileType =  this.fileSort[key].fileType.includes(fileType)
                     if (!is_fileType) {
                        is_fileType = this.fileSort[key].fileSuffix.includes(suffix);
                    }
                    if (is_fileType){
                        addFileField = this.fileSort[key].addFileField
                        fileLimitSize = this.fileSort[key].singleFileSize
                        break;
                    } 
                }
            }
            if (!is_fileType || !addFileField) {
                this.$message({
                    message: `上传的文件${fileName}格式错误，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
             // 判断文件大小 1491016   15M
            if (fileSize > fileLimitSize) {
                this.$message({
                    message: `上传的文件${fileName}大小超过${get_size_str(fileLimitSize)}，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            // 判断文件大小 1491016   15M
            if (fileSize === 0) {
            this.$message({
                message: `文件内容异常，请重试！`,
                type: 'error',
                duration:3000
            })
            return 
            }
            //根据文件名和文件大小判断是否重复 fileList
            if (this.fileList.find(item=>item.name ===fileName && item.size ===fileSize)) {
                this.$message({
                    message: `您已选择过${fileName}文件，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            this.fileAllSize += fileSize
            // 文件总大小 不能超过1G
            if (this.fileAllSize >  this.allFilesSize) {
                this.$message({
                    message: `所有上传的文件大小不能超过${get_size_str(this.allFilesSize)}，请调整后分次上传.`,
                    type: 'error',
                    duration:3000
                })
                this.fileAllSize -= fileSize
                return 
            }
             // 文件数量不能超过1000
            if (this.fileList.length > this.allFilesNum) {
                this.$message({
                    message: `上传的文件不能超过${this.allFilesNum}个，请调整后分次上传.`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            let key = Math.random()
            let fileSizeStr = get_size_str(fileSize);
            this[addFileField].push({
                fileName:fileName,
                key,
                is_hover:false,
                fileSize,
                fileSizeStr,
            })
            file.key = key
            file.fileSizeStr = fileSizeStr
            this.fileList.push(file)
        },
        OneFileOut(item){
            item.is_hover = false
        },
        OneFileOver(item){
            item.is_hover = true
        },
        // 点击上传按钮
        async handlenSubmitFile(){
            if (!this.evidenceInfo?.selectFullPath){
                return this.$message.warning('不允许上传')
            }
            this.addAlertListener({
                target: "upload",
                opr: "annex-total",
                listener_name:"evidence-annex-total",
                once:true,
                callback_fun:(data) => this.onCompleteUploadFileTotal(data)
            })
            this.addAlertListener({
                target: "upload",
                opr: "total",
                listener_name:"evidence-total",
                once:true,
                callback_fun:(data) => this.onUploadFileTotal(data)
            })
            this.addAlertListener({
                target: "upload",
                opr: "received",
                listener_name:"evidence-upload",
                once:false,
                callback_fun:(data) => this.onUploadSingleFile(data)
            })
            this.upload_evidence({files:this.fileList,logic_path:this.evidenceInfo.selectFullPath,needUnzip:!this.is_decompression_file})
            .catch(err=>{
                console.log(err);
                this.$message.error(`上传失败，原因：${err.data.message}`)
            })
                       
            this.upload_file_list = this.fileList.map(file=>{
                return{
                    fileSizeStr:file.fileSizeStr,
                    key:file.key,
                    filename:file.name,
                    size:file.size,
                    uid:file.uid,
                    status:'wait',
                    is_hover:false,
                    children:[],
                    level:0,
                }
            });
            this.upload_file_list = _.sortBy(this.upload_file_list,['filename']);
            this.is_after_uploading = true;
        },
        // 删除文件
        handleRemoveFile(ArrayName,key){
            this[ArrayName].forEach((item,index)=>{
                if (item.key === key) {
                    this[ArrayName].splice(index,1)
                    return
                }
            })
            this.fileList.forEach((item,index)=>{
                if (item.key === key) {
                    this.fileList.splice(index,1)
                    return
                }
            })
        },
        // 文件上传成功返回的文件列表
        onUploadFileTotal(data){

            const client_file = (f)=>{
                let file = _.cloneDeep(f);
                file.fileSizeStr = get_size_str(file.size);
                file.key = Math.random();
                file.status = 'wait';
                file.is_hover = false;
                file.children = [];
                file.level = 1;
                return file
            };
            if (data.files && data.files.annex_files && data.files.annex_files.length > 0) {
                for (let file of data.files.annex_files) {
                    if (file.rootZip) {
                        let newFile = client_file(file);
                        let findZipIndex = this.upload_file_list.findIndex(i=>i.uname == file.rootZip.uname);
                        if (findZipIndex === -1) {
                            findZipIndex = this.upload_file_list.findIndex(i=>i.filename == file.rootZip.filename && i.size == file.rootZip.size);
                        }
                        if (findZipIndex === -1) continue;
                        let zipFile = this.upload_file_list[findZipIndex];
                        zipFile.uname =  file.rootZip.uname;
                        zipFile.children.push(newFile);
                        this.$set(this.upload_file_list,findZipIndex,zipFile);
                    }else{
                        let findIndex = this.upload_file_list.findIndex(i=>i.filename == file.filename && i.size == file.size);
                        if (findIndex === -1) continue;
                        let oneFile = this.upload_file_list[findIndex];
                        oneFile.uname = file.uname;
                        this.$set(this.upload_file_list,findIndex,oneFile);
                        // 如果文件是单个文件   往fileList注入uname
                        let findClientFind = this.fileList.findIndex(i=>i.name == file.filename && i.size == file.size);
                        if (findClientFind === -1) continue;
                        let ClientFind = this.fileList[findClientFind];
                        ClientFind.uname = file.uname;
                        this.$set(this.fileList,findClientFind,ClientFind);
                    }
                    
                }
            }

            this.upload_file_list = this.recursiveSort(this.upload_file_list,1);
        },
        recursiveSort(array,sortBy){
            let sortArry = ["succeeded","repeat","failed_store","failed_unzip","wait"];
            array.forEach(item=>{
                if (item.children && item.children.length > 0){
                    item.children = this.recursiveSort(item.children);
                }
            })
            let cb = null;
            switch (sortBy) {
                case 1:
                    cb  = ['filename'];
                    break;
                case 2:
                    cb = (o)=>{
                        return sortArry.indexOf(o['status']);
                    }
                    break;
                case 3:
                    cb = (o)=>{
                        return -(sortArry.indexOf(o['status']));
                    }
                    break;
                
                default:
                     cb  = ['filename'];
                    break;
            }
            return _.sortBy(array,cb)
        },
        // 全部文件上传成功的通知
        onCompleteUploadFileTotal(data){
            this.$message.success('上传成功');
            this.$EventBus.$emit('evidenceUploadSuccess',data);
        },
        // 单个文件上传成功通知
        onUploadSingleFile(data){
            //  !data.file.filename || !data.file.size  压缩包解压出错 可能文件名或大小为空
            if (!data || !data.file  || !data.file.status || !data.file.uname) return;
            if (data.file.rootZip && data.file.rootZip.filename && data.file.rootZip.size) {
                let findIndex = this.upload_file_list.findIndex(file=>file.uname == data.file.rootZip.uname);
                if (findIndex === -1) {
                    data.file.rootZip.filename = delEvidenceSeq(data.file.rootZip.filename);
                    findIndex = this.upload_file_list.findIndex(file=>file.uname == data.file.rootZip.uname && file.filename == data.file.rootZip.filename);
                }
                if (findIndex === -1) return;
                let ZipFile = this.upload_file_list[findIndex];
                ZipFile.status = "succeeded";
                let findIndex2 = ZipFile.children.findIndex(file=>file.uname == data.file.uname && file.status == 'wait');
                if (findIndex2 === -1) {
                    data.file.filename = delEvidenceSeq(data.file.filename);
                    findIndex2 = ZipFile.children.findIndex(file=>file.uname == data.file.uname && file.filename == data.file.filename && file.status == 'wait');
                }
                if (findIndex2 === -1) return;
                let file = ZipFile.children[findIndex2];
                _.assign(file,data.file);
                ZipFile.children[findIndex2] = file;
                this.$set(this.upload_file_list,findIndex,ZipFile);
            }else{
                let findIndex = this.upload_file_list.findIndex(file=>file.uname == data.file.uname && file.status == 'wait');
                if (findIndex === -1){
                    data.file.filename = delEvidenceSeq(data.file.filename);
                    findIndex = this.upload_file_list.findIndex(file=>file.uname == data.file.uname && file.filename == data.file.filename && file.status == 'wait');
                }
                if (findIndex === -1) return;
                let file = this.upload_file_list[findIndex];

                _.assign(file,data.file);
                this.$set(this.upload_file_list,findIndex,file);
            }
            this.watchAlertPercentage();

        },
        // 监听收到的alert通知的进度
        watchAlertPercentage(){
            if(!this.is_after_uploading)  return;

            let waitNum = 0;
            let succeededNum = 0;

            this.upload_file_list.forEach(item=>{
                if (item.status ==='wait') {
                    waitNum++;
                }
                if (this.succeededList.indexOf(item.status) !=-1) {
                    succeededNum ++;
                }
            })

            if (waitNum == 0) {
                this.is_complete = true;
            }else{
                this.is_complete = false;
            }

            let FileAllNum = this.upload_file_list.length;

            this.succeededNum = succeededNum;
            this.completeNum =  FileAllNum -  waitNum;
            this.uploadPercentageServer = Math.round(this.completeNum / FileAllNum *  100);
            this.uploadPercentageServer > 100 ? 100 : this.uploadPercentageServer;
        },
        // 监听滚动数字的样式
        handleSpanWidth(e){
            this.SpanWidth = e.width;
        },
        //  监听滚动数字
        handleWatchCompleteNum(newVal){
            if (this.$refs.animationRef) {
                if(this.numberScrollLoading) return;
                let newCurrentNumber = newVal;
                let numberElement = this.$refs.animationRef.children[1];
                const currentNumber = parseInt(numberElement.innerText);
                if (newCurrentNumber == currentNumber) return;
                const newNumberElement = document.createElement('span');
                newNumberElement.classList.add('numberScroll');
                newNumberElement.classList.add('colorSpan');
                newNumberElement.style.left = this.SpanWidth + 4 + 'px';
                // newNumberElement.style.top = 23 + 'px';
                newNumberElement.innerText = newCurrentNumber.toString();
                let className = '';

                if (currentNumber < newCurrentNumber) {
                    numberElement.classList.add('scroll-down');
                    newNumberElement.classList.add('scroll-down2');
                    className = 'scroll-down2'
                }else{
                    numberElement.classList.add('scroll-up');
                    newNumberElement.classList.add('scroll-up2');
                    className = 'scroll-up2'
                }
                this.completeNumCopy = newVal;
                numberElement.parentElement.insertBefore(newNumberElement, numberElement.nextSibling);
                this.numberScrollLoading = true
                // 移除原始数字元素
                numberElement.addEventListener('animationend', () => {
                    this.numberScrollLoading = false
                    numberElement.remove();
                    newNumberElement.classList.remove(className);

                });
            }
        },
        // 关闭上传
        handleCloseUpload(){
            this.removeAlertListener({
                target: "upload",
                opr: "received",
                listener_name:"evidence-upload",
            });
            this.closeEvidenceUpload();
        },
        // 后台运行
        handleHideUpload(){
            this.hideEvidenceUpload();
        },
        // 重新上传文件
        handleOneFileSubmit(file){
            console.log(file);
            if (file.rootZip) return this.$message.error('压缩包内的文件暂不支持重新上传！');
            let seqFilename = delEvidenceSeq(file.filename);
            let findIndex = this.fileList.findIndex(f=>f.uname == file.uname && f.name == file.filename);
            if (findIndex === -1) {
                findIndex = this.fileList.findIndex(f=>f.uname == file.uname && f.name == seqFilename);
            }
            if (findIndex === -1) return this.$message.error('文件数据丢失，请重新上传！');
            let uploadFile = this.fileList[findIndex];

            this.upload_evidence({files:[uploadFile],logic_path:this.evidenceInfo.selectFullPath,needUnzip:!this.is_decompression_file})
            .catch(err=>{
                console.log(err);
                this.$message.error(`上传失败，原因：${err.data.message}`)
            })

            let findIndex2 =  this.upload_file_list.findIndex(f=>f.uname == file.uname  && f.filename == file.filename);
            if (findIndex2 === -1) {
                findIndex2 =  this.upload_file_list.findIndex(f=>f.uname == file.uname && f.filename == seqFilename);
            }
            if (findIndex2 > -1) {
                let findFile = this.upload_file_list[findIndex2];
                findFile.status = 'wait';
                this.$set(this.upload_file_list,findIndex2,findFile);
            }
            this.watchAlertPercentage();
        },
        // 排序
        handleSetsortProps(props){
            if (this.sortProps == props) {
                this.sortProps = '';
            }else{
                this.sortProps = props;
            }
            let sortBy = 1;
            switch (this.sortProps) {
                case 'top':
                    sortBy = 2;
                    break;
                case 'bottom':
                    sortBy = 3;
                    break;
                default:
                    sortBy = 1;
                    break;
            }
            this.upload_file_list = this.recursiveSort(this.upload_file_list,sortBy);
        },
        ...mapMutations(['addAlertListener','hideEvidenceUpload','closeEvidenceUpload','removeAlertListener']),
        ...mapActions(['upload_evidence',]),
    },
    computed:{
        ...mapState({
            evidenceUploadShow:state => state.evidenceUpload.show,
            uploadPercentageClient:state => state.evidenceUpload.percentage,
            evidenceUploading:state => state.evidenceUpload.Uploading,
            evidenceInfo:state => state.evidenceUpload.info,
        }),
        ...mapGetters({
            
        }),
        drawerShow:{
            get() {
                return this.evidenceUploadShow;
            },
            set() {
                this.$store.commit('closeEvidenceUpload');
            }
        },
        getFiles() {
            return (fileType) => this[fileType] || [];
        },
        getFileTypeImg(){
            return (fileName) =>{
                let { suffix, iconUrl } = getfileSuffixAndImg(fileName);
                return iconUrl
            }
        },    
        uploadPercentage(){
            return (this.uploadPercentageClient / 2) + (this.uploadPercentageServer / 2);
        },
        getAccept(){
            let accept = ''
            for (const key in this.fileSort) {
                if (this.fileSort[key] && this.fileSort[key].fileSuffix && this.fileSort[key].fileSuffix.length > 0) {
                    accept += (this.fileSort[key].fileSuffix.join(',') +',');
                }
            }
            accept = accept.slice(0,-1);
            return accept
        },    
    },

}
</script>

<style lang="less" scoped>
@import "~@/assets/css/upload.less";
@import "~@/assets/css/theme.less";
.upload{
    display: flex;
    height: 100%;
    .el-upload-box{
        width: 20%;
        min-width: 250px;
    }
    .upload-list-box{
        flex: 1;
        margin-left: 10px;
        .upload-list{
            width: 100%;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            overflow-y:auto ;  
        }
        .upload-list-footer{
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
        }
    } 
}
.UploadSuccessClass{
    position: relative; 
    overflow:auto;
    width: 100%;
    border:1px dashed #d9d9d9;
    border-bottom: 0px;
    min-width:400px;
    // -ms-overflow-style: none; /* IE 和 Edge */
    // scrollbar-width: none; /* Firefox */
}
.progressClass{
    // position: absolute;
    // bottom: 0;
    width: 100%;
    border:1px dashed #d9d9d9;
    border-top: 0px;
}

@TableLeft:14px;
.fileTitle{
    background: #f0f2f5;
    color: #606266;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    font-weight: 800;
    padding-left: @TableLeft;
    .fileColumn3{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .caret{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left:7px;
            .iconCaret{
                color:#c0c4cc;
                font-size: 12px;
                cursor: pointer;
                line-height: 8px;
            }
            .iconCaret:hover{
                color:#409eff;
            }
            .iconCaretActive{
                color:#409eff;
            }
        }
    }
}
.fileTitle{
  position: sticky;
  top: 0;
  z-index: 9000;
}

.fileContent{
    height: 48px;
    line-height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .delICon{
      cursor: pointer;
      color: @color-danger;
    }
    .fileColumn1{
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-image{
            width: 16px;
            height: 16px;
            margin:0 @TableLeft;
        }
        p{
            flex: 1;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
        }
    }
}
.fileContent:hover{
    background-color: #F2F6FC;
}
.text2{
    text-align: left;
    line-height: 14px;;
    height:14px;
    margin: 23px 0;
    color:rgba(161,161,161);
    .colorSpan{
        color:#29c5ee;
    }
}

@footerHeight:60px;      // 定位盒子的高度 = 占位盒子的高度 + 抽屉下padding的距离
@footerBoxHeight:40px;   //占位盒子  因为drawer的下padding占了20
::v-deep .el-drawer__body{
        // position:relative; 
        .footer-box{
            height: @footerBoxHeight;
        }
        .drawer-footer-class{
            background: #ffffff;
            position:absolute ;
            bottom: 10px;
            left:0;
            width:100%;
            height:@footerHeight;
            padding: 10px 40px 10px 40px;
            z-index: 10000;
        }
    }
</style>