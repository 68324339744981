
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import Vue from 'vue'
import { Message, Loading, Notification } from "element-ui"
import { getBlob } from '../api/request'
import { models } from './models'
import cfg from '@/config'
import _, { forEach } from 'lodash'
import { toYMDTime, get_size_str,streamSaverJS,loginOvertimeBox,toBeijingTime,getTimeDifference,logoutLogin,isUnauthorizedRouter, getCaseFolderPath } from '../utils'
import router from '../router'
import {trademarkEdit} from '@/utils/data.js'
import {Brand,getFileTotal,Getofficial, } from '@/api/axios.js';
import axios from "axios";
import moment from 'moment'
const client = feathers()
const socket = io(cfg.baseURL)

socket.on('connect',()=>{
    console.log('连接成功!');
})

// 监听断开连接事件
socket.on('disconnect', () => {
    console.log('与服务器断开连接');
})

// 监听重新连接事件
socket.on('reconnect', (attemptNumber) => {
    console.log('成功重新连接，尝试次数:', attemptNumber);
});
// 监听登录事件
client.on('login', () => {
    console.log('用户已登录');
});

// 监听注销事件
client.on('logout', () => { //不知道为什么监听不到
    console.log('用户已注销');
});

client.hooks({
    error: {
        all: [
            (context) => {
                console.error('client请求发生错误:', context.error);
                // 在这里添加通用的错误处理逻辑
                let { code,message,className} = context.error;
                if (code == 401 && message == "Not authenticated" && className == "not-authenticated") {
                    console.log('jwt过期 ，请重新登录！');
                    loginOvertimeBox();
                }
                if (code == 408 && className == "timeout") {
                    console.log('请求超时！');
                    Message.warning('当前网络慢，请稍后再试。');
                }
                return context;
            },
        ],
    },
});

client.configure(socketio(socket))

client.configure(auth({
    storage: window.localStorage
}))

const listenOnEvents = function ({ commit,dispatch }) {
    client.service('alert').removeListener('created')
    client.service('alert').on('created', data => {
        commit('newAlert', {commit, dispatch,data})
    })

    // TODO: 前端对于genericdata服务，只查看自己数据的列表  user_id === user._id
    client.service('genericdata').on('created', data => commit('new_or_update_generic_data', data))
    client.service('genericdata').on('updated', data => commit('new_or_update_generic_data', data))
    client.service('genericdata').on('patched', data => commit('new_or_update_generic_data', data))
    client.service('genericdata').on('removed', data => commit('remove_generic_data', data))

}

//记录被监听的service，避免重复监听
const listened_services = []

function add_listen_service_name(service_name) {
    if (listened_services.includes(service_name)) {
        console.error(`service ${service_name} already been listened.`)
        return false
    }
    listened_services.push(service_name)

    return true
}

const ACTIONS = { created: 0, updated: 1, patched: 2, removed: 3 }

const alert_listeners = []

function call_alert_listener(alert) {
    const index = alert_listeners.findIndex(al => al.target == alert.target && al.opr == alert.opr)

    if (index < 0) {
        return
    }

    const listener = alert_listeners[index]

    listener.callback_fun(alert)

    if (listener.once) {
        alert_listeners.splice(index, 1)
    }
}

const g_cached_tms = new Map()


let alert_index = 0
const wsModule = {
    state: () => ({
        user: null,
        clt_sess_id: null, //session id of this user. A user may have multiple sessions if she/he logins from multiple browsers/machines.
        alerts: [],
        ws_token: null,
        generic_data: [],
        user_trademark_selected: [],
        authorized_paths: [],
        trademark_complex: [],
        passwordCollapse:false,
        allMenus: new trademarkEdit().allMenus,
        route:null,
        loginTimer:null,
        authorization_object_config:{},   // 权限functions actions modules
        brand:[],
        brandKey:10,
        docTotalSize: 0,
        copyrightTotalSize: 0,
        evidenceTotalSize: 0,
        dirHandle:null,
        odFileList:{},
        Fileparameter:{},
        case_constants_config:{},
        subDirHandle:null,
        uploadFileData:{},
        translations_config:{},
    }),

    getters: {
        all_alerts: (state) => state.alerts,
        save_passwordCollapse:(state) => state.passwordCollapse,
        unread_alerts: (state) => state.alerts.filter(al => !al.read),
        trademark_selected: (state) => {
            var obj = {};
            state.user_trademark_selected = state.user_trademark_selected.reduce(function (item, next) {
                obj[next._id] ? '' : obj[next._id] = true && item.push(next);
                return item;
            }, []);
            return state.user_trademark_selected
        },
        getodFileList:(state)=>state.odFileList,
        getUploadFileData:(state)=>state.uploadFileData,
        list_config: (state) => (list_name) => state.generic_data.filter(gd =>
            gd.type == 'list_config' && gd.key == list_name),
        
        getTranslationCfg: (state) => (key) =>{
            return state.translations_config[key];
        },
        common_service: (state) => (field_name) => state[field_name],

        //检查是否是授权页面。page_开始的路径是为页面设置的。如果有'*'或''的路径表示有全部权限，就保留，这样会匹配所有路径。
        authorized_page_paths: (state) => {
            const page_paths = state.authorized_paths.map(ap => ap.path.replaceAll('*', ''))
                .filter(ap => ap == '' || ap.startsWith('page_') || ap.startsWith('action_')) //只留下前端的路径。''表示超级用户对所有对象有权限。

            // console.log(`authorized_page_paths:`)
            // console.table(page_paths)

            return page_paths
        },

        is_authorized_path: (state, getters) => (path) => {
            const found = getters.authorized_page_paths.some(ap => path.includes(ap))
            //如果authorized_page_paths是空的found就是false，返回false表示该路径未必授权，符合预期结果。
             console.log(`is_authorized_path:path:${path} => ${found}`)
            return found
        },

        authorized_menus: (state, getters) => (menus) => {
            console.log(`authorized_menus:input:${menus.length}`)

            return menus.filter(item => getters.is_authorized_path(item.auth_path))
        },

        enabled_menus (state, getters) {
            return state.allMenus.filter(item => getters.is_authorized_path(item.auth_path))
        },

        // 过滤左边菜单栏的权限   全部权限为 *
        // 2次过滤
        // 第一次为权限过滤  auth_path
        // 第二次为付费过滤  is_optional_feature为true的进行检查 (name和optional_features的每一项name 是否一致)  剩下的默认通过
        enabled_menus2(state, getters){
            // 第一次过滤
            let filterAllMenus = getters.filterallMenu(state.allMenus)
            // 第二次过滤
            return   getters.filterOptionalFeatures(filterAllMenus)
        },
        filterallMenu:(state,getters)=>(allMenus)=>{
            let a = allMenus.filter(item=>{
                if (!item.auth_path) {
                    return true
                }
                return getters.authorized_page_paths2.some(ap=>{
                    if (ap === '') {
                       return true
                    }
                    return ap.includes(item.auth_path)
                })
            })
            a.forEach(item=>{
                if (item.children && item.children.length > 0) {
                    item.children = getters.filterallMenu(item.children)
                }
            })
            return a
        },
        authorized_page_paths2:(state)=>{
            const page_paths = state.authorized_paths.map(ap => ap.path.replaceAll('*', ''))
            if (!page_paths ||  page_paths.length == 0) { // 数据没回来 会返回undfind     权限为*会被判断为false
                return []
            }
            return page_paths
        },
        filterOptionalFeatures:(state,getters)=>(filterAllMenus)=>{
            let a = filterAllMenus.filter(item=>{
                if (!item.name) {
                    return false
                }
                if(!item.is_optional_feature  || item.name == 'authorized'){
                    return true
                }
                return getters.authorized_router_name.some(ap=>{
                    return ap.name == item.name
                })
            })
            a.forEach(item=>{{
                if (item.children && item.children.length > 0) {
                    item.children = getters.filterOptionalFeatures(item.children)
                }
            }})
            return a
        },
        authorized_router_name:(state)=>{
            if (!state.optional_features) return []
            return state.optional_features.filter(item=>item.enable)
        },
        // 给router路由返回判断条件的name
        enabled_menus_name(state,getters){
            let data = _.cloneDeep(getters.enabled_menus2)
            let flattenData = []
            enabled_menus_flatten(flattenData,data)
            return flattenData.map(item=>item.name)
        },
        get_bus_lines: (state) => {
            if (!state['config']) return []

            const busi_line_config = state['config'].find(c => c.catagory == 'bus-line')
            return _.get(busi_line_config, ['config', 'bus-line'], [])
        },

        get_docs_tags: (state) => {
            if (!state['config']) return []

            const cfg = state['config'].find(cfg => cfg.catagory == 'tm_attachment_tag')

            return _.get(cfg, ['config', 'tag'])
        },

        get_config: (state) => ({catagory}) => {
            if (!state['config']) return {}

            const cfg = state['config'].find(cfg => cfg.catagory == catagory)
            let cfgCopy = _.cloneDeep(cfg);
            if (!cfg) return {};

            if (catagory === 'authorization_object_names' && state.authorization_object_config) {
                _.assign(cfgCopy.config,state.authorization_object_config);
            }
            return cfgCopy.config;
        },

        /**
         * type: 权限对象的类别：roles(角色),functions(功能),actions(动作)
         * obj: 对象的名称, 例如如果是roles，就是sa, supervisor；如果是functions,就是brand, applicant...
         * @param {*} state
         * @returns
         */
        get_authorization_object_name: (state) => ({type, obj}) => {
            const authorization_info = state.config.find(gd => gd.catagory == 'authorization_object_names')

            if (!authorization_info) {
                console.warn('cannot found authorization info from genericdata, configuration data is missing.')
                return
            }
            if (obj) {
                return _.get(authorization_info, ['config', type, obj])
            }else{
                return _.get(authorization_info, ['config', type])
            }
        },

        get_op_tree: (state) => (level) => {
            if (!state['odprocess_tree'] || level <= 0) return []

            const filter = (data, level) => {
                if (level == 1) return data.map(en => _.omit(en, 'children')).filter(en => en.label != '其他')

                return data.map(en => ({
                    ..._.omit(en, 'children'),
                    children: en.children ? filter(en.children, level - 1) : undefined
                })).filter(en => en.label != '其他')
            }

            return filter(state['odprocess_tree'], level)
        },

        get_trademark_complex: (state) => (tm_id) => {
            return state.trademark_complex.find(tmc => tmc._id == tm_id)
        },

        get_applicants: (state) => (query) => {
            return client.service('applicant').find(query)
        },
        get_Branddata:(state) => (query) =>{
            return client.service('brand').find(query)
        },
        async get_max_brand_level(state, getters) {
            const {data:the_brand} = await getters.get_Branddata({query:{$sort:{level:-1}, $limit:1}})
            if (!the_brand || the_brand.length == 0 ) return undefined

            return the_brand[0]?.level
        },
        get_reactive_entity:(state) => ({service, id}) => {
            let service_info = state[service]
            let entity = service_info.entities.find(en => en._id == id)

            return entity
        },
        get_optional_features:(state) =>(key) =>{
            if (state.optional_features && state.optional_features.length > 0) {
                return state.optional_features.findIndex(item=>item.name == key && item.enable) > -1
            }else{
                return false
            }
        },
        getEvidenceTags(state){
            const evidence_tags = state.config.find(gd => gd.catagory == 'evidence_tag')
            if (evidence_tags) {
                return evidence_tags.config
            }else{
                return []
            }
        },
        // 和著作权的config相关的查询  这些配置不会更改  写个快速查询的方法
        /**
         * 
         * @param { String} key   status  type  od_type
         * @param { String || null} registry_type   works  software  null
         * @returns []
         */
        get_copyright_config:(state) =>({key,registry_type})=>{
            if (!state.config) return [];
            const copyrightConfig = state.config.find(gb => gb.catagory == 'copyright')?.config;

            if (!copyrightConfig) return []

            if (key === 'status') {
                return copyrightConfig.status
            }else if (key === 'type') {
                let type_arr = [];
                let works_type = copyrightConfig.works_type || [];
                let software_type = copyrightConfig.software_type || [];
                switch (registry_type) {
                    case 'works':
                        type_arr = works_type;
                        break;
                    case 'software':
                        type_arr = software_type;
                        break;
                    default:
                        type_arr = _.concat(works_type,software_type)
                        break;
                }
                return type_arr;
            }else if(key === 'od_type'){
              let od_type_arr = [];
              let od_works_type = copyrightConfig.works_od_type || [];
              let od_software_type = copyrightConfig.software_od_type || [];
              switch (registry_type) {
                case 'works':
                    od_type_arr = od_works_type;
                    break;
                case 'software':
                    od_type_arr = od_software_type;
                    break;
                default:
                    od_type_arr = _.concat(od_works_type,od_software_type)
                    break;
              }
              return od_type_arr;
            }
            
        },
        get_copyright_od_code:(state,getters) =>(type)=>{
            let od_copytighe = getters.get_copyright_config({
                key:'od_type',
                registry_type:null,
            })
            if (od_copytighe.length == 0) return null;
            return  od_copytighe.find(odType=>odType.type == type)?.type_code
        },
        // 获取品牌树的偏平化
        get_all_Brand_flat:(state) =>{
            if (!state.brand || state.length === 0) {
                return [];
            }
            const  cb = (items) =>{
                return _.flatMapDeep(items, (item) => 
                    _.concat(item, cb(item.children || []))
                );
            }
            const sortBrandData = (arr) =>{
                arr.forEach(item=>{
                    if (item.children && item.children.length > 0) {
                    item.children = sortBrandData(item.children)
                    }
                })
                return _.sortBy(arr,'seqNum')
            }
            let sortBrand = sortBrandData(state.brand);
            let flatBrand = cb(sortBrand);
            return flatBrand;
        },

        get_all_applicants:(state) =>{
            if (state.allApplicants  && state.allApplicants.length > 0) {
                return state.allApplicants;
            }
            return [];
        },
        get_brand_key:(state) =>{
            return state.brandKey;
        },
        get_doc_total_size:(state) => {
            return get_size_str(state.docTotalSize);
        },
        get_copyright_total_size:(state) => {
            return get_size_str(state.copyrightTotalSize);
        },
        get_evidence_total_size:(state) => {
            return get_size_str(state.evidenceTotalSize);
        },
        get_all_total_size:(state) => {
            const size = state.docTotalSize + state.copyrightTotalSize + state.evidenceTotalSize;
            return get_size_str(size);
            // return '1023.99MB'
        },
        get_case_constants_config:(state) =>{
            return state.case_constants_config;
        }
    },
    mutations: {
        setUserToken(state, { user, token, cpl }) {
            state.user = user
            state.ws_token = token
            sessionStorage.setItem("token", token)
            sessionStorage.setItem("user", JSON.stringify(user));

            try {
                const cpl_o = JSON.parse(cpl)
                state.clt_sess_id = cpl_o.clt_sess_id
            } catch (error) {
                console.log(`invalid cpl: ${cpl}`)
            }
        },
        setOptional_features(state, optional_features) {
            state.optional_features = optional_features
        },
        newAlert: (state, {commit, dispatch, data}) => {
            _.assign(data, { id: ++alert_index, read: false, receivedAt: Date.now(), message: format_message(data) })
            console.log(`new alert:${JSON.stringify(data, null, 2)}`)

            if (data.opr == 'zip-files') {//下载文件的消息
                return handleZipFileDownload2(state,commit,data)
            }

            if (data.target === 'brand' && data.opr === 'linkTradeMarks') {
                // Notification({
                //     title:'品牌匹配商标',
                //     message: "品牌匹配商标完成，等待数据加载",
                //     type: "info",
                // })
                
                dispatch('getBrandData'); 
            }

            if (data.message) {
                if (data.showType == 'message') {
                    Message.info(data.message)
                } else if (data.showType == 'notification') {
                    // _.delay(()=>{
                    Notification({
                        title:'提示',
                        message:data.message,
                        type: data.success? 'success' : 'info'
                    })
                    // },100)
                } else if (data.showType == 'alert') {
                    state.alerts.push(data)
                }
            }

            call_alert_listener(data)
        },
        clearAlerts: (state) => {
            state.alerts.splice(0)
        },
        markReadAlerts: (state, ids) => {
            state.alerts = state.alerts.filter(a1 => !ids.find(id1 => a1.id == id1))
        },
        updateDirHandle:(state,params)=>{
            state.dirHandle = params.dirHandle;
            state.Fileparameter = params.params;
        },
        uploadOdFile:(state,params)=>{
            state.uploadFileData = params;
        },
        updateShowExoprt(state,n){
            state.odFileList.ShowExoprt = n;
        },
        emptyReadAlerts(state, ids) {
            state.alerts = []
        },
        addAlertListener: (state, { target, opr, listener_name, once = false, callback_fun}) => {
            const index = alert_listeners.findIndex(al => al.target == target && al.opr == opr && al.listener_name == listener_name)
            if (index >= 0) alert_listeners[index] = { target, opr, listener_name, once, callback_fun }
            else alert_listeners.push({ target, opr, listener_name, once, callback_fun })
        },

        removeAlertListener:(state,{target,opr,listener_name})=>{
            const index = alert_listeners.findIndex(al => al.target == target && al.opr == opr && al.listener_name == listener_name)
            if (index >= 0) alert_listeners.splice(index,1)
        },

        refresh_generic_data(state, data) {
            state.generic_data = data
            // console.log(`refresh generic_data(${state.generic_data.length}):${JSON.stringify(state.generic_data, null ,2)},
            // data:${JSON.stringify(data)}`)

        },
        new_or_update_generic_data(state, data) {
            const user_id = _.get(state.user, ['_id'])
            if (data.user_id !== user_id) return;

            const index = state.generic_data.findIndex(gd => gd._id == data._id)
            if (index >= 0) Vue.set(state.generic_data,index,data);
            else state.generic_data.push(data)
            console.log(`new_or_update generic_data(${state.generic_data.length}):${JSON.stringify(state.generic_data, null, 2)},
            data:${JSON.stringify(data)}`)
        },
        remove_generic_data(state, data) {
            const index = state.generic_data.findIndex(gd => gd._id == data._id)
            if (index >= 0) state.generic_data.splice(index, 1)
            console.log(`remove generic_data(${state.generic_data.length}):${JSON.stringify(state.generic_data, null, 2)},
            data:${JSON.stringify(data)}`)
        },
        common_data_update(state, { array_name, data, action }) {
            const index = state[array_name].findIndex(sd => sd._id == data._id)
            if (action == ACTIONS.created || action == ACTIONS.updated || action == ACTIONS.patched) {
                if (index >= 0) Vue.set(state[array_name],index,data)
                else state[array_name].push(data)
            } else if (action == ACTIONS.removed) {
                if (index >= 0) state[array_name].splice(index, 1)
            }
        },
        //递归查询品牌tree
        /**
         * 属于reactive_entity功能.
         * 更新vuex中的entity数据
         * @param {object} state 
         * @param {object} param1 
         * @returns 
         */
        update_entities(state, {service, index, entity}) {
            let service_info = state[service]
            let new_entity
            if (index >= 0) {
                Vue.set(service_info.entities, index, entity)
                new_entity = service_info.entities[index]
            } else if (index < 0) {
                service_info.entities.push(entity)
                new_entity = service_info.entities[0]
            }

            console.log(`update_entities(${service}), index:${index}`)

            return new_entity
        },
        common_data_load(state, { array_name, data }) {
            state[array_name] = data;
        },

        track_tm_complex(state, tmc) {
            const index = state.trademark_complex.findIndex(tc => tc._id == tmc._id)
            if (index >= 0) {
                Vue.set(state.trademark_complex, index, tmc)
            } else {
                state.trademark_complex.push(tmc)
            }
        },
        SET_ROUTE(state, route) {
            state.route = route; // Set the route in the state
        },
        clearLoginTimeout(state){
            clearTimeout(state.loginTimer);
            state.loginTimer = null;
        },
        setLoginTimeout(state,timerMilliSecondTime){
            if (state.loginTimer) clearTimeout(state.loginTimer);
            state.loginTimer = setTimeout(()=>{
                loginOvertimeBox();
            },timerMilliSecondTime)
        },
        common_brandData_update(state,brand_data){
            state.brand = brand_data;
            state.brandKey++;
        },
        setAuthorization_object_config(state,authorization_object_config){
            state.authorization_object_config = authorization_object_config;
        },
        setServersTotalSize(state, { docTotalSize, copyrightTotalSize, evidenceTotalSize }) {
            docTotalSize = docTotalSize ? docTotalSize : 0;
            copyrightTotalSize = copyrightTotalSize ? copyrightTotalSize : 0;
            evidenceTotalSize = evidenceTotalSize ? evidenceTotalSize : 0;

            state.docTotalSize = docTotalSize;
            state.copyrightTotalSize = copyrightTotalSize;
            state.evidenceTotalSize = evidenceTotalSize;
        },
        async commonOdFile(state,{data}){

            state.odFileList.source = axios.CancelToken.source();
            Vue.set(state.odFileList,'list',data);
            Vue.set(state.odFileList,'NotcompletedTotal',data.length);
            Vue.set(state.odFileList,'total',0);
            state.odFileList.downloadState = 'progress';
            state.odFileList.sep_folder = state.Fileparameter.sep_folder
            
            const dataCopy = _.cloneDeep(data);
            var i = 0;
            state.odFileList.total = 0;
            async function fetchDataFromIds(data, speed_callback) {
                let stop = false;
                while (data.length > 0 && !stop) {
                    const item = data.shift()
                    // 首先，通过ID请求获取URL
                    if (!item) {
                        return
                    }
                    const urlResponse = await GetodAxios(item);
                    const url = urlResponse.filelink; // 假设响应数据中包含一个url属性
                    // 使用获取到的URL发送请求或获取Blob数据
                    try {
                        const dataResponse = await getBlob('' + url, {}, urlResponse._id, speed_callback, state.odFileList.source).then(res => {
                            const DirHandle = state.subDirHandle?state.subDirHandle:state.dirHandle
                            handleFileDownload(DirHandle, state.Fileparameter, res, item,state).then(data=>{
                                const index = state.odFileList.list.findIndex(v => v.new_id === item.new_id);
                                if (data =='QuotaExceededError') {
                                    Vue.set(state.odFileList.list[index], 'status','下载失败');                      
                                }else{
                                    if (state.odFileList.list[index]) {
                                        Vue.set(state.odFileList.list[index], 'status','已完成');
                                        i++
                                        Vue.set(state.odFileList,'completedTotal',i);
                                        state.odFileList.total++;
                                        state.odFileList.NotcompletedTotal--;
                                    }                                
                                }
                            });
                        }).catch(error => {
                            console.log(error, item.reg_num);
                            var status = '';
                            const index = state.odFileList.list.findIndex(v => v.id === item.id);
                            if (error.name == 'CanceledError') {
                                status = '已取消'; 
                                state.odFileList.downloadState = 'canceled'
                                if (document.getElementsByClassName('el-message').length == 0) {
                                    Message.warning(error.message);
                                }
                                stop = true
                             }else{
                                status = '下载失败';
                                Message.warning(`${item.fname}下载失败!`);
                             }
                            Vue.set(state.odFileList.list[index], 'status', status)
                            state.odFileList.total++;
                            state.odFileList.NotcompletedTotal--;
                        });

                    } catch (error) {
                        // 处理请求错误
                        console.error(`Error fetching data  ${item}:`, error);
                        throw error;
                    }
                }
            }
            
            const batchSize = 3;
            var jobs = [], jobs_speed = []
            
            for (let i = 0; i < batchSize; ++i) {
                jobs_speed[i] = 0
                jobs.push(fetchDataFromIds(dataCopy, ({downloadSpeed,doc_id,percentCompleted}) => {
                    //计算总的下载速度
                    jobs_speed[i] = downloadSpeed
                    let total_speed = 0
                    for (let j = 0; j < batchSize; ++j) {
                        total_speed += jobs_speed[j]
                    }
                    this.commit("setDwnloadSpeed",{downloadSpeed,doc_id, total_speed,percentCompleted});
                }))
            }
            return Promise.allSettled(jobs)
        },
        setDwnloadSpeed(state,{downloadSpeed,doc_id,total_speed,percentCompleted}){
            Vue.set(state.odFileList,'total_speed',total_speed)
            state.odFileList.list?.forEach((item,index)=>{
                if (item.id==doc_id) {
                    item.downloadSpeed = downloadSpeed;
                    item.percentCompleted =percentCompleted;
                    Vue.set(state.odFileList.list,index,item)
                }
            })
        },
        saveDownloadState(state,{status}){
            if (status=='suspend'&&state.odFileList.source) {
                state.odFileList.source.cancel('已取消下载任务!');
                state.odFileList.suspendTaskDisabled = true;
            }
        },
        saveUploadState(state,{status}){
            if (status=='suspend') {
                state.uploadFileData.source.cancel('已取消上传任务!');
                state.uploadFileData.suspendTaskDisabled = true;
            }
        },
        set_case_constants_config(state,case_constants_config){
            state.case_constants_config = case_constants_config;
        },
        set_translations_config(state,translations_config){
            state.translations_config = translations_config;
        },
    },
    actions: {
        async STORE_INIT({ state,getters, dispatch, commit }) {
            console.log(`STORE_INIT called.`)

            try {
                const auth = await client.reAuthenticate()
                verifyToken(auth,commit)
                const user = _.cloneDeep(_.get(auth, 'user'))
                const token = await client.authentication.getAccessToken()
                const cpl = _.get(auth, "authentication.payload.cpl")
                commit('setUserToken', { user, token, cpl })
                console.log(`reauth :${_.get(state.user, ['user'])}`)

                 store_init_function({ state, getters, dispatch, commit })

                console.log(`reAuthenticate succeed.`)
            } catch (err) {
                // 如果当前页面是无权限页面，不需要它去跳转
                if (isUnauthorizedRouter()) return
                console.log(`reAuthenticate failed:${err.message}`)
                // router.push({name:'Mylogin'})
                logoutLogin();
            }
        },
        async login({ state, getters,dispatch, commit }, credentials) {
            console.log(`ws login`)
            const auth = await client.authenticate({ strategy: 'local', ...credentials ,origin:window.location.origin})
            verifyToken(auth,commit)
            const user = _.cloneDeep(_.get(auth, 'user'))
            const token = await client.authentication.getAccessToken()
            const cpl = _.get(auth, "authentication.payload.cpl")
            commit('setUserToken', { user, token, cpl })
            console.log(`login:${_.get(state.user, ['user'])}`)

            store_init_function({ state, getters, dispatch, commit })
        },
        /**
         * 属于reactive_entity功能.
         * 收到server下发的数据更新消息后自动重新加载数据
         */
        async reactive_update({state, commit}, {service, data, action }) {
            const service_info = state[service]
            if (!service_info) {
                console.warn(`reactive_update unknown service: ${service}`)
                return
            }

            console.log(`reactive_update(${service}) fired, id:${data._id}`)

            if (action == ACTIONS.created || action == ACTIONS.updated || action == ACTIONS.patched) {
                let index = service_info.entities.findIndex(en => en._id == data._id)
                if (index != -1) {
                    const entity = await client.service(service).get(data._id)
                    commit('update_entities', {service, index, entity})
                }
            }
        },
        async Changepassword({ state,dispatch },data) {
           try {
             await client.service('users').patch(data._id, data)
             state.passwordCollapse = true
           } catch (error) {
            Message.error(error.message)
            state.passwordCollapse = false
           }
        },

        async load_generic_data({ state,commit }) {
            const user_id = _.get(state.user, ['_id'])
            // 只查看自己的，按理说后端应该返回userId为请求用户的
            const { data } = await client.service('genericdata').find(
                {query:{
                    user_id,
                }}
            )
            commit('refresh_generic_data', data)
        },

        async save_generic_data({ dispatch }, data) {
            let newData = null;
            if (data._id){
                newData = await client.service('genericdata').update(data._id, data)
            } else {
                newData = await client.service('genericdata').create(data)
            }
            
            return newData?._id;
        },

        async remove_generic_data({ commit }, data) {
            client.service('genericdata').remove(data._id, data)
        },

        async save_list_config({ dispatch }, data) {
            return await dispatch('save_generic_data', _.assign(data, { type: 'list_config' }))
        },

        async set_authorization_object_name({state, dispatch}, {type, obj, name}){
            const authorization_info = state.config.find(gd => gd.catagory == 'authorization_object_names')

            if (!authorization_info) {
                console.warn('cannot found authorization info from genericdata, configuration data is missing.')
                return
            }

            _.set(authorization_info, ['config', type, obj], name)

            client.service('config').update(authorization_info._id, authorization_info)
        },

        async delete_authorization_object_roles({state, dispatch}, {obj}){
            const authorization_info = state.config.find(gd => gd.catagory == 'authorization_object_names')

            if (!authorization_info) {
                console.warn('cannot found authorization info from genericdata, configuration data is missing.')
                return
            }

            let deleteData = _.omit(authorization_info.config.roles, [obj])
            authorization_info.config.roles = deleteData
            client.service('config').update(authorization_info._id, authorization_info)
        },

        /**
         * 属于reactive_entity功能.
         * 页面代码调用这个方法获取reactive的数据实例(entity)
         * @param {*} param0 
         * @param {*} param1 
         * @returns 
         */
        async load_reactive_entity({getters, commit}, {service, id}) {
            let entity = getters.get_reactive_entity({service, id})
            if (entity) {
                console.log(`load_reactive_entity(${service}) id:${id}, get from cache.`)
                return entity
            }
            entity = await client.service(service).get(id)
            if (entity) {
                commit(`update_entities`, {service, index:-1, entity})
                entity = getters.get_reactive_entity({service, id})
            }

            console.log(`load_reactive_entity(${service}) entity._id:${entity?._id}, get from server.`)
            return entity
        },
        async load_trademark_complex({ commit, getters }, { tm_id }) {
            console.log(`load_trademark_complex begins.`)
            const tmc = await generate_trademark_complex(tm_id, getters)

            if (tmc) commit('track_tm_complex', tmc)

            console.log(`load_trademark_complex done.`)
            return getters.get_trademark_complex(tm_id)
        },

        async save_trademark_complex({ commit, dispatch }, tmc) {
            console.log(`save_trademark_complex begins.`)
            await do_save_trademark_complex(tmc)

            // _.delay(() => dispatch("load_trademark_complex", { tm_id: tmc._id }), 5e2)
            console.log(`save_trademark_complex done.`)
        },

        async try_decorate_doc({commit}, {doc, tm_id}) {
            if (!tm_id) return

            Vue.set(doc, "trademark", {})//先把trademark设为一个空的对象

            let tm = await call_in_queue(async () => {
                let tmo = g_cached_tms.get(tm_id)
                if (!tmo) {
                    tmo = await client.service('trademark').get(tm_id)
                    if (!tmo) {
                        console.log(`try_decorate_doc can't find tm by id:${tm_id}.`)
                        return
                    }

                    if (tmo.case_list && tmo.case_list.length > 0) {
                        const { data } = await client.service('case').find({ query: { _id: { $in: tmo.case_list }, $limit: 1000 } })
                        tmo.cases = _.cloneDeep(data)
                    } else {
                        tmo.cases = []
                    }

                    console.log(`get remote tm:${tm_id}`)
                    g_cached_tms.set(tm_id, tmo)
                } else {
//                    console.log(`get cached tm:${tm_id}`)
                }

                return tmo
            })

            if (!tm) return

            do_decorate_doc(doc, tm)

            return doc
        },

        async wait_alert({commit}, {target, opr, timeout=1e4}) {

            const p = new Promise((resolve)=>{
                commit("addAlertListener", {
                    target,
                    opr,
                    listener_name: `${Math.random()*1e4}`,
                    once:true,
                    callback_fun: (alert) => resolve(alert),
                  })

                setTimeout(()=>{
                    console.warn(`wait_alert (${target},${opr}) timeout after ${timeout/1e3} seconds.`)
                    resolve()
                }, timeout)
            })

            return p
        },
        /**
         * 这个函数的主要目的是等待特定条件下的事件或警报，并在条件满足或超时时返回结果。
         * 并且可以通过resetTimeout函数来控制定时器，以延迟警报事件的事件。
         * 可以通过等待返回值promise的resolve的结果来判断是否正确。
         * 只能先放在actions里，才能获取到mutations里的函数（外部调用必须使用await等待）
         */
        async custom_wait_alert({commit}, {target, opr, timeout=1e4}) {
            let timerId = null;
            let resolveCopy = null;
            const p = new Promise((resolve)=>{
                resolveCopy = resolve;
                commit("addAlertListener", {
                    target,
                    opr,
                    listener_name: `${Math.random()*1e4}`,
                    once:true,
                    callback_fun: (alert) =>{
                        clearTimeout(timerId); // 取消定时器
                        resolve(alert);
                    },
                });

                timerId = setTimeout(()=>{
                    console.warn(`custom_wait_alert (${target},${opr}) timeout after ${timeout/1e3} seconds.`);
                    resolve();
                }, timeout)
            })
            return {
                promise:p,
                resetTimeout: () => {
                    console.log(`custom_wait_alert (${target},${opr}) triggers again ${timeout/1e3} seconds`);
                    if (timerId) {
                        clearTimeout(timerId);
                        timerId = setTimeout(()=>{
                            console.warn(`custom_wait_alert (${target},${opr}) timeout after ${timeout/1e3} seconds.`);
                            resolveCopy();
                        }, timeout)
                    }
                },
            }
        },

        //更该案件管理下的 bind数据库里的关系表  只需要官文id 和他的patch
        async Patch_bind({commit},{caseId,tm_id,data}){
            for (let item  of data) {
                await client.service('case').patch(caseId, {doc_id:item.doc_id,tm_id, clf_case_paths:item.clf_case_paths}, {query:{$modify_clf_path:true}})
            }
        },
        // 删除文件在bind里的关系表 需要案件id caseid  商标id tmID  官文id docId
        async Remove_bind({commit},{caseId,tm_id,docIds}){
            for (let item  of docIds) {
                await client.service('case').patch(caseId, { tm_id, doc_id:item }, { query: { $remove_bind: 1 } })
            }
        },
        async set_evidence_tags({state},tags){
            const evidence_tags = state.config.find(gd => gd.catagory == 'evidence_tag')
            if (evidence_tags) {
                let evidence_tags_config = _.cloneDeep(evidence_tags.config)
                for (let tag of tags) {
                    tag = tag.trim()
                    if (evidence_tags_config.some(i=>i == tag))
                    continue;
                    evidence_tags_config.push(tag)
                }
                await client.service('config').update(evidence_tags._id,{
                    catagory:'evidence_tag',
                    config:evidence_tags_config
                })
            }else{
                let data = tags
                await client.service('config').create({
                    catagory:'evidence_tag',
                    config:data
                })
            }
        },
        async patchTrademarkStatus({commit},{trademarkId,data}){
            console.log(trademarkId,data);
            await client.service('trademark').patch(trademarkId, data, {query:{$extension:{skip_status_check:true}}})
        },
        // 增加 busi_lines（品牌业务线）
        async upDataBusiLines({state,commit},{key}){
            // 判断key是不是存在  因为它的触发条件不单是创建
            let bus_line_cfg = _.cloneDeep(state['config'].find(cfg => cfg.catagory == 'bus-line'))
            let bus_lines = bus_line_cfg.config['bus-line']
            let findIndex = bus_lines.findIndex(bus_line=>bus_line == key)
            if (findIndex > -1) return
            bus_lines.push(key)
            // 有监听  无需在自己去调用commit
            await client.service('config').update(bus_line_cfg._id, { 
                catagory:'bus-line',
                config:{
                    "bus-line":bus_lines
                }
            })
        },
        // 删除 busi_lines（品牌业务线）
        async removeBusiLines({state,commit},{key}){
            // 判断要删除的key是不是存在
            let bus_line_cfg = _.cloneDeep(state['config'].find(cfg => cfg.catagory == 'bus-line'))
            let bus_lines = bus_line_cfg.config['bus-line']
            let findIndex = bus_lines.findIndex(bus_line=>bus_line == key)

            if (findIndex > -1) {
                bus_lines.splice(findIndex,1)
                 // 有监听  无需在自己去调用commit
                await client.service('config').update(bus_line_cfg._id, { 
                    catagory:'bus-line',
                    config:{
                        "bus-line":bus_lines
                    }
                })
            }
        },

        async findMaxTradmarkList(){
            const maxList = await client.service('constlist').find({query:{const_type:'tm-limit'}})
            return maxList
        },

        async findGoodsGroups(){
            return await client.service('constlist').find({query:{const_type:'goods_groups'}});
        },

        async findGoodsClasses(){
            return await client.service('constlist').find({query:{const_type:'goods_classes'}});
        },

        async findEvidenceSocket({commit},params){
            return await client.service('evidence').find({query:params});
        },

        // 查询系统里的申请人
        async findALLApplicant({commit}){
            let {data } = await client.service('applicant').find();
            return data.map(item=>{
                return {
                    _id:item._id,
                    name_cn:item.name_cn
                }
            })
        },
        // 根据申请人查询品牌   需要传一个申请人中文名称 可以是数组
        /**
         * 
         * @param {String || Array} applicantNameCn 
         * @returns { Array} 
         */
        async getBrandCountForApplicant({commit},applicantNameCn){
            let query = {
                $countBrandsByApplicant:1,
                applicantNameCn,
            }
            return await client.service('trademark').find({query});
        },
        async getBrandData({commit}){ 
            let brandData = await getBrandAxios();
            commit('common_brandData_update',brandData);
        }
    }
}

function enabled_menus_flatten(flattenData,data){
    data.forEach(item=>{
        flattenData.push(item)
        if (item.children && item.children.length > 0 ) {
            enabled_menus_flatten(flattenData,item.children)
        }
    })
}

async function store_init_function({ state, getters, dispatch, commit }) {
    const optional_features = await client.service('constlist').find({query:{const_type:'optional_features'}});
    commit('setOptional_features', optional_features);
    const authorization_object_config = await client.service('constlist').find({query:{const_type:'authorization_object_config'}});
    commit('setAuthorization_object_config', authorization_object_config);
    const case_constants_config = await client.service('constlist').find({query:{const_type:'case_constants_config'}});
    commit('set_case_constants_config',case_constants_config);
    const translations_config = await client.service('constlist').find({query:{const_type:'translations_config'}});
    commit('set_translations_config',translations_config);

    dispatch('load_generic_data')
    listenOnEvents({ dispatch, commit })

    install_odprocess_data({ state, commit })
    install_common_service({ state, commit, service_name: 'config', array_name: 'config' })
    install_common_service({ state, commit, service_name: 'applicant', array_name: 'allApplicants' });
    install_reactive_service({state, dispatch, service:'case-view', listening_service_name:'case'})
    install_reactive_service({state, dispatch, service:'case'})
    prepare_trademark_tracking({ state, dispatch, commit })
    dispatch('getBrandData');
    await load_authorized_paths({ state, roles: state.user.role })

    sessionStorage.setItem('routerName',_.join(getters.enabled_menus_name,','))

    setServersTotalSizeTime({commit});
}

/**
 * 后端在官文识别等情况下，会对同一个商标多次更新操作，会触发多个updated event.
 * 为了避免连续加载同一个商标数据，为每一个被监听的商标声明一个延迟的加载操作.
 */
let loaders = {}
async function debounced_tm_reload({state, dispatch, tm_id}) {

    if (!state.trademark_complex.some(tmc => tmc._id == tm_id)) {
        //we are not monitoring this tm.
        return
    }

    console.log(`debounced_tm_reload called for ${tm_id}, time:${(new Date().getTime())}`)

    if (!loaders[tm_id]) {
        loaders[tm_id] = _.debounce(() => dispatch("load_trademark_complex", { tm_id }), 7e2)
    }

    loaders[tm_id]()
}

async function prepare_trademark_tracking({ state, dispatch }) {
    const tm_service_name = 'trademark'

    if (!add_listen_service_name(tm_service_name)) return

    client.service(tm_service_name).on('updated', ({_id}) => debounced_tm_reload({state, dispatch, tm_id:_id}))
    client.service(tm_service_name).on('patched', ({_id}) => debounced_tm_reload({state, dispatch, tm_id:_id}))

    client.service('bind').on('created', ({tm_id}) => debounced_tm_reload({state, dispatch, tm_id}))
    client.service('bind').on('updated', ({tm_id}) => debounced_tm_reload({state, dispatch, tm_id}))
    client.service('bind').on('patched', ({tm_id}) => debounced_tm_reload({state, dispatch, tm_id}))
    client.service('bind').on('removed', ({tm_id}) => debounced_tm_reload({state, dispatch, tm_id}))

    console.log(`prepare_trademark_tracking end.`)
}

async function generate_trademark_complex(tm_id, getters) {
    console.log(`generate_trademark_complex starts:${tm_id}`)

    const tm_data = await client.service('trademark').get(tm_id)

    if (!tm_data) {
        console.error(`generate_trademark_complex can't find tm by ${tm_id}.`)
        return
    }

    tm_data.original = { tm: _.cloneDeep(tm_data) } //保存原始数据

    //load the cases and the docs, and link them
    await load_cases_and_docs(tm_data, tm_id, getters)

    //load 关联商标
    const { trademarks: linked_tms } = await client.service('trademark').get(tm_id, { query: { $protect_tm_id: true } })
    tm_data.original.linked_tms = _.cloneDeep(linked_tms)
    tm_data.tc_linked_tms = linked_tms

    const tmc_app_date = Date.parse(tm_data.app_date)
    tm_data.tc_linked_tms.forEach(ltm => {
        ltm.relationship =
            tmc_app_date < Date.parse(ltm.app_date)
                ? "补位申请(后)"
                : "补位申请(前)"
        ltm.app_date_str = toYMDTime(ltm.app_date)
    })

    //load 关联商标图
    let flow_details = await client.service('trademark').get(tm_id, { query: { $flow_detail: true } })
    tm_data.tc_flow_details = flow_details //不用监控这个值的变化
    // 更新记录
    let OprAuditData = await client.service('opr-audit').find({query:{entity:'trademark',record_id:tm_id}})
    tm_data.OprAuditData = OprAuditData
    return tm_data
}

async function load_cases_and_docs(tm_data, tm_id, getters) {
    if (tm_data.case_list && tm_data.case_list.length > 0) {
        const { data } = await client.service('case').find({ query: { _id: { $in: tm_data.case_list }, $limit: 1000, tm_id, } })
        data.sort((c1, c2) => Date.parse(c1?.create_time) - Date.parse(c2?.create_time))
        tm_data.original.cases = _.cloneDeep(data)
        tm_data.tc_cases = data

    } else {
        tm_data.tc_cases = []
        tm_data.original.cases = []
    }

    console.log(`load_cases_and_docs cases len:${tm_data.tc_cases.length}`)

    //load the docs 过滤掉引证商标和我方官文的关联，一般是驳回通知书
    const { data: docs_data } = await client.service('trademark').get(tm_id, { query: { $attachments: true, $limit: 1000 } })

    console.log(`load_cases_and_docs docs len:${docs_data.length}`)

    docs_data.forEach(doc => {
        if (!doc.cases) doc.cases = []
        doc.size_str = get_size_str(doc.size)
    })
    tm_data.original.docs = _.cloneDeep(docs_data)

    tm_data.tc_docs = docs_data.sort((a, b) => Date.parse(a.receive_date) - Date.parse(b.receive_date))
    tm_data.tc_docs_od = docs_data.filter(doc => doc.doc_type == 'od')
    tm_data.tc_docs_others = docs_data.filter(doc => doc.doc_type != 'od')

    //检查是否是重复官文
    let barcode_set = new Set()
    tm_data.tc_docs_od.forEach(od => {
        if (!!od.barcode && !!od.type_code) {
            const od_key = `${od.type_code}_${od.barcode}`
            od.duplicated = barcode_set.has(od_key)
            barcode_set.add(od_key)
        }
    })

    //为了文件抽屉读取商标数据，为其他文件都添加商标信息.
    const trademark_partial = _.pick(tm_data, models['trademarks'].writable_fields)
    tm_data.tc_docs.forEach(doc => {
        if (!doc.trademark)
            doc.trademark = _.cloneDeep(trademark_partial)
    })

    tm_data.tc_docs_od.forEach(doc => doc.show_name = doc.type? doc.type : doc.originalname)

    tm_data.docs_unbind = [] //从这个商标删除的文档
    tm_data.docs_remove = [] //删除文档本身

    //link the cases and the docs
    tm_data.tc_cases.forEach((ca) => {
        ca.docs = []
        ca.binds.forEach(bind => {
            if (!bind.doc || _.get(bind, ['tm', '_id']) != tm_id)
                return
            const doc2 = docs_data.find(doc => doc._id == bind.doc._id)
            link_single_case_doc(ca, doc2)
        })
    })

    //do the same thing on original
    tm_data.original.cases.forEach((ca) => {
        ca.docs = []
        ca.binds.forEach(bind => {
            if (!bind.doc || _.get(bind, ['tm', '_id']) != tm_id)
                return
            const doc2 = tm_data.original.docs.find(doc => doc._id == bind.doc._id)
            link_single_case_doc(ca, doc2)
        })
    })
}

//绑定一个案件和文档。
//把文档放在ca.docs里, 把ca的一个部分属性副本(为了避免循环引用)放在doc.cases里。
function link_single_case_doc(ca, doc) {
    if (!ca || !doc) return

     const case_copy = _.pick(ca, ['_id', 'internal_case_seq', 'app_num', 'type', 'status', 'create_time','verdict_result','case_applicant','our_part'])
    if (!doc.cases.find(ca2 => ca2._id == case_copy._id))
        doc.cases.push(case_copy)

    ca.docs.push(doc)
}

async function do_save_trademark_complex(tmc) {

    if (tmc.updating) {
        console.error(`do_save_trademark_complex return since tmc:(${tmc._id}) is being updated`)
        return
    }

    try {
        tmc.updating = true
        await check_and_patch_object(tmc, tmc.original.tm, 'trademarks', 'trademark')

        await check_and_patch_cases_and_docs(tmc)

        await check_and_patch_docs_list(tmc)

        await check_and_patch_linkd_tms(tmc)

    } catch (error) {
        console.error(`do_save_trademark_complex: ${error.message}`)
    } finally {
        tmc.updating = false
        console.log(`do_save_trademark_complex done.`)
    }
}

/**
 * 检查一个object的哪些fields有变化，如果有就用patch提交
 * @param {*} changed
 * @param {*} original
 * @param {*} model_name
 * @param {*} service_name
 */
async function check_and_patch_object(changed, original, model_name, service_name) {
    const changed_fields = check_changed_fields(changed, original, model_name)
    // console.log(`check_and_patch_object: ${changed.comments}~${original.comments},changed_fields:${JSON.stringify(changed_fields)}`)
    if (changed_fields.length) {
        const patch_data = _.pick(changed, changed_fields)
        console.log(`check_and_patch_object, service:${service_name}, _id:${changed._id}, patch_data:${JSON.stringify(patch_data, null, 2)}`)
        await client.service(service_name).patch(changed._id, patch_data, service_name=='case'?{}:{query:{$extension:{skip_status_check:true}}})
    }
}

/**
 * 检查doc列表的改动，如果有就提交
 * @param {*} tmc
 */
async function check_and_patch_docs_list(tmc) {
    const changed_docs = tmc.tc_docs, original_docs = tmc.original.docs

    changed_docs.forEach(cd => {
        const od = original_docs.find(doc => doc._id == cd._id)
        if (od) {
            check_and_patch_doc(cd, od, tmc)
        } else {
            // //不应该发生，新的文档仅应该通过上传完成，上传后商标里的文件列表应该已经同步了
            // console.error(`find new doc ${cd._id}, this should not happen.`)
            client.service('case').patch(null, { tm_id:tmc._id, doc_id:cd._id }, { query: { $manual_bind: 1 } })
        }
    })

    const tm_id = tmc._id
    //删除文档关联
    tmc.docs_unbind.forEach(doc => {
        const doc_id = doc._id

        //解绑每个案件
        doc.cases.forEach(ca => {
            console.log(`$remove_bind case:${ca._id}, tm:${tm_id}, doc:${doc_id}`)
            client.service('case').patch(ca._id, { tm_id, doc_id }, { query: { $remove_bind: 1 } })
        })

        //解绑商标
        client.service('case').patch(null, { tm_id, doc_id }, { query: { $remove_bind: 1 } })
        console.log(`docs_unbind from tm:${tm_id}, doc:${doc_id}`)
    })

    //删除文档本身
    tmc.docs_remove.forEach(doc => {
        client.service('document').remove(doc._id)
        console.log(`remove doc:${doc._id}`)
    })
}

/**
 * 检查和更新关联商标。
 * 如果数组中有新的就调用api增加，如果有删除的就调用api删除。
 * @param {*} tms
 * @returns
 */
async function check_and_patch_linkd_tms(tmc) {
    const changed_tmid = tmc.tc_linked_tms.map(tm => tm._id), original_tmid = tmc.original.linked_tms.map(tm => tm._id)

    const jobs = []

    changed_tmid.forEach(new_tmid => {
        const index = original_tmid.findIndex(o_tmid => o_tmid == new_tmid)
        if (index < 0) {//new tm
            console.log(`link tm:(${tmc._id}) to new tm:(${new_tmid})`)
            jobs.push(client.service('trademark').patch(tmc._id, {}, {query:{$add_protect_tm_id: new_tmid}}))
        } else {
            original_tmid.splice(index, 1)
        }
    })

    original_tmid.forEach(o_tmid => {//removed ones
        console.log(`unlink tm:(${tmc._id}) from old tm:(${o_tmid})`)
        jobs.push(client.service('trademark').patch(tmc._id, {}, { query: { $rm_protect_tm_id: o_tmid } }))
    })

    return Promise.allSettled(jobs)
}

/**
 * 检查一个doc的改动，和doc上关联case绑定的改动。如果有改动就提交
 * @param {*} changed_doc
 * @param {*} original_doc
 * @param {*} tmc
 */
async function check_and_patch_doc(changed_doc, original_doc, tmc) {
    check_and_patch_object(changed_doc, original_doc, 'documents', 'document')

    changed_doc.cases.forEach(new_case => {
        if (!new_case._id) {
            console.error(`find case ${new_case._id} without id on the doc ${changed_doc._id}`)
            return
        }

        const old_case = original_doc.cases.find(od => od._id == new_case._id)
        if (!old_case) {//new bind
            const tm_id = tmc._id, doc_id = changed_doc._id
            client.service('case').patch(new_case._id, { tm_id, doc_id }, { query: { $manual_bind: 1 } })
            console.log(`bind case:${new_case._id} with tm:${tm_id}, doc:${doc_id}`)
        }
    })

    //对原doc上的每一个案件检查新案件的对应案件，如果没找到就是这个文档增加了案件关联
    original_doc.cases.forEach((old_case) => {
        const new_case = changed_doc.cases.find(new_case => new_case._id == old_case._id)
        if (!new_case) {
            const tm_id = tmc._id, doc_id = changed_doc._id
            console.log(`$remove_bind case:${old_case._id}, tm:${tm_id}, doc:${doc_id}`)
            // client.service('case').patch(old_case._id, { tm_id, doc_id }, { query: { $remove_bind: 1 } })
        }
    })
}

/**
 * 检查case列表的改动
 * 1. 检查每一个case的改动，每个case绑定的doc的改动。如果有改动就提交。
 * 2. 如果有新的case就创建
 * 3. 入股有删除的case就删除，同时删除case绑定的doc
 * @param {} tmc
 */
async function check_and_patch_cases_and_docs(tmc) {

    const changed_cases = tmc.tc_cases, original_cases = tmc.original.cases

    changed_cases.forEach(async (new_o) => {
        const old_o = original_cases.find(old_o => old_o._id == new_o._id)
        if (old_o) {//existing case, check the fields and binded docs
            check_and_patch_case(new_o, old_o, tmc)
        } else {//new object
            create_case(new_o, tmc)
        }
    })

    //deleted cases
    const deleted_ones = _.differenceWith(original_cases, changed_cases, (a, b) => a._id == b._id)
    deleted_ones.forEach(ca => delete_case(ca, tmc._id))
}

/**
 * 检查并提交一个case的改动。包括对case里绑定doc的检查
 * 1. case本身的改动
 * 2. 如果case有新的doc就新建绑定
 * 3. 如果case少了doc就删除相应的绑定
 * @param {*} changed_case
 * @param {*} original_case
 * @param {*} tmc
 */
async function check_and_patch_case(changed_case, original_case, tmc) {

    fix_clf_tree(changed_case, original_case,tmc._id)

    check_and_patch_object(changed_case, original_case, 'cases', 'case')

    //for the new added doc to this case, bind them
    changed_case.docs.forEach(new_doc => {
        if (!new_doc._id) {
            console.error(`find doc without id on the case ${new_case._id}, only doc from server (with id) allowed.`)
            return
        }
        const ori_doc = original_case.docs.find(od => od._id == new_doc._id)
        if (!ori_doc) {//new bind
            client.service('case').patch(changed_case._id, {
                tm_id: tmc._id, doc_id: new_doc._id,
                ..._.pick(new_doc, ['tag', 'ref_tm_bind', 'clf_case_paths'])
            }, { query: { $manual_bind: 1 } })
            console.log(`bind case:${changed_case._id} with tm:${tmc._id} and doc:${new_doc._id}`)
        }
    })

    //check deleted docs from this case
    original_case.docs.forEach(od => {
        if (!changed_case.docs.find(doc => doc._id == od._id)) {
            const tm_id = tmc._id, doc_id = od._id
            client.service('case').patch(original_case._id, { tm_id, doc_id }, { query: { $remove_bind: 1 } })
            console.log(`$remove_bind case:${original_case._id}, tm:${tm_id}, doc:${doc_id}`)
        }
    })
}

/**
 * 修正case上的clf_path_tree属性。页面会把混合了目录和文件的tree一起提交。
 * 过滤掉其中的文件，并把每个文件所在的目录的修改同步到对应的doc实体的属性clf_case_path上。
 * @param {*} the_case
 */
function fix_clf_tree(the_case, original_case,tmcID)
{
    // console.log(`fix_clf_tree starts: case:(${the_case._id}), tree:${JSON.stringify(the_case.clf_path_tree, null, 2)}`)

    if (_.isEqual(the_case.clf_path_tree, original_case.clf_path_tree)) {
        console.log(`fix_clf_tree: case(${the_case._id}) was skipped since the clf_path_tree doesn't change.`)
        return
    }

    const docs = _.cloneDeep(the_case.docs)
    let notRemoveCaseIds = []   //记录一下案件目录存在的文件（官文）
    const travel = (node, parent_path) => {
        if (!node.name || node.node_type != 'folder' || !node.children) return

        const cur_path = getCaseFolderPath(node.name,parent_path);
        // node.path = cur_path

        node.children.forEach((child) => {
            if (child.node_type == 'file') {
                const doc = docs.find(doc => doc._id == child._id)
                doc && notRemoveCaseIds.push(doc._id)
                if (doc && doc.clf_case_path != cur_path) {
                    doc.clf_case_path = cur_path
                    client.service('case').patch(the_case._id, {doc_id:doc._id, tm_id:tmcID, clf_case_paths:[cur_path]}, {query:{$modify_clf_path:true}})
                } else {
                    console.log(`the file (${child._id}) can't be found in the docs list.`)
                }
            } else {//folder node
                travel(child, cur_path)
            }
        })
        node.children = node.children.filter(child => child.node_type == 'folder')
    }

    // the_case.clf_path_tree = the_case.clf_path_tree.filter(child => child.node_type == 'folder')
    // the_case.clf_path_tree.forEach(item=>travel(item,null))
    for (const child of the_case.clf_path_tree) {
        if (child.node_type == 'folder') {
            travel(child,null)
        }else{
            notRemoveCaseIds.push(child._id)
        }
    }
    // 把根目录下的文件也存到notRemoveCaseIds里面

    //  把案件目录下存在的官文  在新的docs里删掉
    console.log(notRemoveCaseIds);
    if (notRemoveCaseIds.length > 0) {
        _.remove(docs,item=>{
            return notRemoveCaseIds.some(item2=>item._id == item2)
        })
    }
    console.log(docs,'RemoveCaseIds');
    // 去后端删除
    for (let item of docs) {
        console.log(item._id);
        client.service('case').patch(the_case._id, { tm_id: tmcID, doc_id:item._id }, { query: { $remove_bind: 1 } })
    }

    // console.log(`fix_clf_tree: case:(${the_case._id}), tree:${JSON.stringify(the_case.clf_path_tree, null, 2)}`)
}

/**
 * 创建一个新的案件
 * 如果这个案件已经绑定了docs，就创建相应的绑定
 * @param {} new_o
 */
async function create_case(new_o, tmc) {
    const new_case = await client.service('case').create(new_o)
    console.log(`created new case:${new_case._id}`)

    //link to the tm
    client.service('case').patch(new_case._id, { tm_id: tmc._id }, { query: { $manual_bind: 1 } })

    if (new_o.docs) {
        new_o.docs.forEach(doc => {
            if (!doc._id) {
                console.error(`find doc without id on the case ${new_case._id}, only doc from server (with id) allowed.`)
                return
            }

            client.service('case').patch(new_case._id, { tm_id: tmc._id, doc_id: doc._id }, { query: { $manual_bind: 1 } })
            console.log(`bind case:${new_case._id} with tm:${tmc._id} and doc:${doc._id}`)
        })
    }
}

/**
 * 删除一个案件
 * 删除案件和其中doc的绑定
 * @param {}} del_case
 */
async function delete_case(del_case, tm_id) {
    del_case.docs.forEach(doc => {
        const doc_id = doc._id
        client.service('case').patch(del_case._id, { tm_id, doc_id }, { query: { $remove_bind: 1 } })
        console.log(`$remove_bind case:${del_case._id}, tm:${tm_id}, doc:${doc_id}`)
    })

    client.service('case').remove(del_case._id)
    console.log(`delete case:${del_case._id}`)
}

//找到那些变动了的field name
function check_changed_fields(changed, original, model_name) {
    return models[model_name].writable_fields.filter(wf => !_.isEqual(changed[wf], original[wf]))
}

/**
 * 根据指定的service_name和query从服务器加载数据，并安装到vuex的array_name属性上，并且监听数据的后续变化。
 */
async function install_common_service({ state, commit, service_name, array_name }) {
    console.log(`install_common_service:${service_name} begins.`)

    if (!add_listen_service_name(service_name)) return

    Vue.set(state, array_name, [])

    client.service(service_name).on('created', data =>
        commit('common_data_update', { array_name, action: ACTIONS.created, data }))
    client.service(service_name).on('updated', data =>
        commit('common_data_update', { array_name, action: ACTIONS.updated, data }))
    client.service(service_name).on('patched', data =>
        commit('common_data_update', { array_name, action: ACTIONS.patched, data }))
    client.service(service_name).on('removed', data =>
        commit('common_data_update', { array_name, action: ACTIONS.removed, data }))
    let { data } = await client.service(service_name).find();
    commit('common_data_load', { array_name, data })

    console.log(`install_common_service:${service_name} end.`)
}
/**
 * 属于reactive_entity功能.
 * 为一个服务安装一个reactive功能
 * service: 加载数据的service名称，也会用来在state中作为名称保存实体数据
 * listening_service_name: 监听数据变化的service，缺省和service一样。例如如果从case-view加载数据，应该监听cases
 * @param {*} param0 
 * @returns 
 */
async function install_reactive_service({state, dispatch, service, listening_service_name = service}) {

    if (!add_listen_service_name(service)) return

    Vue.set(state, service, {entities:[]})

    client.service(listening_service_name).on('created', data =>
        dispatch('reactive_update', { service, action: ACTIONS.created, data }))
    client.service(listening_service_name).on('updated', data =>
        dispatch('reactive_update', { service, action: ACTIONS.updated, data }))
    client.service(listening_service_name).on('patched', data =>
        dispatch('reactive_update', { service, action: ACTIONS.patched, data }))
    client.service(listening_service_name).on('removed', data =>
        dispatch('reactive_update', { service, action: ACTIONS.removed, data }))

    console.log(`install_reactive_service:${service} ends.`)

}

/**
 * 根据指定的service_name和query从服务器加载数据，并安装到vuex的array_name属性上。不会监听数据的变化。
 */
async function install_common_readonly_service({ state, commit, service_name, array_name, query }) {
    Vue.set(state, array_name, [])
    const { data } = await client.service(service_name).find({ query })
    commit('common_data_load', { array_name, data })

    return data
}

async function install_odprocess_data({ state, commit }) {
    const opdata = await install_common_readonly_service({ state, commit, service_name: 'odprocess', array_name: 'odprocess', query: { $source: "mixed" } })

    const odprocess_tree = []

    //生成树状数据，第一级是base_type, 第二级是case_type, 第三级是type和type_code
    _.uniqBy(opdata, (op) => op.type_code).forEach(op => {
        const { base_type, case_type, type, type_code } = _.pick(op, ['base_type', 'case_type', 'type', 'type_code'])

        let base_entry = odprocess_tree.find(en => en.value == base_type)
        if (!base_entry) {
            odprocess_tree.push({ label: base_type, value: base_type, base_type, children: [] })
            base_entry = odprocess_tree[odprocess_tree.length - 1]
        }

        let case_entry = base_entry.children.find(en => en.value == case_type)
        if (!case_entry) {
            base_entry.children.push({ label: case_type, value: case_type, base_type, case_type, children: [] })
            case_entry = base_entry.children[base_entry.children.length - 1]
        }

        case_entry.children.push({ base_type, case_type, type, type_code, label: type, value: type_code })
    })

    commit('common_data_load', { array_name: 'odprocess_tree', data: odprocess_tree })

    // console.log(`vuex odprocess_tree:${JSON.stringify(odprocess_tree, null, 2)}`)
}

async function load_authorized_paths({ state, roles }) {
    const data = await client.service('roles').find({ query: { getPath: 1, roles, $limit: 1000 } })
    Vue.set(state, 'authorized_paths', _.cloneDeep(data)) //必须用Vue.set 否则authorized_paths不是reactive的
    console.log(`load_authorized_paths:`)
    console.table(data)
}

const format_message = function (alert) {

    if (alert.target == 'od') {
        return `完成${_.get(alert, ['names', 0])}等${_.get(alert, ['names', 'length'])}个官文识别。`
    } else if (alert.target == 'brand' && alert.opr == 'linkTradeMarks') {
        let brand_num = _.get(alert, ['counts', 'brand'])
        let tr_num = _.get(alert, ['counts', 'linked_trademarks'])

        if (brand_num === undefined || tr_num === undefined)
            return undefined

        return `匹配了${brand_num}个品牌，共匹配${tr_num}个商标。`
    } else if (alert.target == 'trademark' && alert.opr == 'import' && !alert.err_text) {
        if (alert.success) {
            if (alert.names?.length && alert.total) {
                return `成功完成 ${alert.names[0]}等${alert.total}个商标导入.`
            }
            return `成功完成商标导入.`
        } else {
            if (alert.names?.length && alert.total) {
                return ` ${alert.names[0]}等${alert.total}个商标导入失败，请稍后再试.`
            }
            return `商标导入失败，请稍后再试.`
        }
    } else if (alert.err_text) {
        return alert.err_text
    } else {
        return `完成${_.get(alert, ['names', 'length'])}个后台任务(类型:${alert.target}).`
    }
}

const handleFileDownloadByWebSocket = async function (alert) {
    const { name, id, params } = _.get(alert, 'service')

    let loadingInstance = Loading.service({ text: '下载中...' })
    setTimeout(() => loadingInstance.close(), 5e3)

    try {
        const { type, buffer } = await client.service(name).get(id, params)

        const blob = new Blob([new Uint8Array(buffer).buffer], { type })

        const filename = `${id}.zip`
        saveFile(blob, filename)
        console.log(`handleFileDownload: type:${type}, len:${buffer.byteLength}, fname:${filename}`)
    } catch (error) {
        console.error(error)
    } finally {
        loadingInstance.close()
    }
}

const handleZipFileDownload2 = async function (state,commit,alert) {
    // let loadingInstance = Loading.service({ text: '下载中...' })
    // setTimeout(() => loadingInstance.close(), 3e4)

    try {
        const id = _.get(alert, ['service', 'id'])
        const filename = alert.filename || `${id}.zip`
        const link = alert.fileLink
        console.log(`handleZipFileDownload2: link:${link}, filename:${filename}`)
        if ('showDirectoryPicker' in window && state.dirHandle && !state.Fileparameter.sep_zip) {
            //判断是否浏览器支持选择文件夹
            if (state.Fileparameter.export_excel) {
                const Blob = await getBlob('' + alert.service.name + '/' + alert.service.id + '?$excel_only=1');
                var index = 0;
                state.subDirHandle = await getDirectoryHandle(state.dirHandle,id,index);
                if (state.subDirHandle) {
                    var fileHandle = await state.subDirHandle.getFileHandle(`${alert.service.id}.xlsx`, { create: true });   
                } 
                if (!fileHandle) {
                    return
                }
                Vue.set(state.odFileList,'ShowExoprt',true);
                //创建一个可以写入的流
                const writableStream = await fileHandle.createWritable();
                await writableStream.write(Blob);
                await writableStream.close();
            }
            if (!state.Fileparameter.export_file) {
                return
            }
            const oldOdFile = _.cloneDeep(state.odFileList);
            Vue.set(state,'odFileList',{})
            var data = await getFileTotal('' + alert.service.name + '/' + alert.service.id + '?$doc_list=1');
            if (typeof data == 'string') {
                data = JSON.parse(data);
            } else {
                data = _.castArray(data);
            }
            const sizes = _.map(data, 'size');
            Vue.set(state.odFileList,'taskDrawer',true);
            const totalBytes = sizes.reduce((accumulator, currentValue) => {
                if (currentValue) {
                    return accumulator + currentValue;   
                }
            }, 0);                
            const fileName = alert.service.id;
            const fileObj = {};
            fileObj.size = totalBytes?totalBytes:0;
            fileObj.taskName = fileName;
            fileObj.fileTotal = data.length;
            fileObj.status = 'progress'
            fileObj.result = '';
            fileObj.time = moment().format('YYYY-MM-DD HH:mm:ss');
            fileObj.position = state.dirHandle.name;
            var new_id = `${fileName}${Math.floor(Math.random() * 10000).toString()}`
            fileObj.new_id = new_id;
            let fileData = [];
            let localfileData = localStorage.getItem('myFileData');
            if (localfileData) {
                fileData = JSON.parse(localfileData);
            };
            if (oldOdFile.list?.length) {
                const file = fileData.find(item=>item.new_id==oldOdFile.new_id);
                if (file) {
                    if (file.status!='suspend'){
                        if (oldOdFile.total == oldOdFile.list.length && oldOdFile.completedTotal==0) {
                                file.result ='未导出'
                        }else if(oldOdFile.completedTotal!=oldOdFile.list.length){
                                file.result ='部分导出'
                        }
                        localStorage.setItem('myFileData', JSON.stringify(fileData));
                    }                    
                }
            }
            fileData.push(fileObj);
            if (fileData.length > 10) {
                fileData.shift();
            }
            Vue.set(state.odFileList,'fileName',fileName);
            Vue.set(state.odFileList,'new_id',new_id);
            Vue.set(state.odFileList,'suspendTaskDisabled',false);
            Vue.set(state.odFileList,'totalBytes',totalBytes?totalBytes:0);
            Vue.set(state.odFileList,'completedTotal',0);
            data = data.map(item=>{
                const newObj = { ...item };
                newObj.new_id = `${item.id}${Math.floor(Math.random() * 10000).toString()}`;
                newObj.percentCompleted = '';
                newObj.downloadSpeed = '';
                newObj.status = '';
                return newObj
            })
            localStorage.setItem('myFileData', JSON.stringify(fileData));
            await commit('commonOdFile', { data });                                 

        } else if (navigator.serviceWorker) {
            // console.log('开始流式下载');          
            streamSaverJS(link,filename);
        }else{
            console.log('开始BLOB下载');
            const response = await getBlob(link)
            saveFile(response, filename)
        }
    } catch (error) {
        console.log(error,'error');
        if (error.name == 'QuotaExceededError') {
            Message.error(`磁盘空间不足，无法下载文件`);
        }else if(error.name === 'SecurityError' || error.name === 'NotAllowedError'){
            Message.error(`浏览器提示的对本地文件的查看和修改，请同意。否则无法保存下载文件。`);
        }
    } finally {
        // loadingInstance.close()
    }
}
const handleFileDownload =async function (dirHandle,params,blob,item,state){
    const regex = /[<>:"\/\\\|?]/g;
     //是否需要单独创建文件夹
     if (params.sep_folder) {
        var data = await createFoldersAndWriteFile(item.fname,item.folder.replace(regex, '.'),blob,dirHandle,state);
        return data
     }else{
        try {
        //直接在选中的文件夹下写入文件
        const fileHandle = await dirHandle.getFileHandle(item.fname, { create: true });
        //创建一个可以写入的流
        const writableStream = await fileHandle.createWritable();
        //将文件写入进去
        await writableStream.write(blob);
        await writableStream.close();            
        } catch (error) {
            console.log(error,'error');
            if (error.name == 'QuotaExceededError') {
                Message.error(`磁盘空间不足，无法下载文件`);
                if (state.odFileList.source) {
                    state.odFileList.source.cancel('已取消下载任务!');
                    return error.name
                }
            }
        }
     }
}
const GetodAxios = async function(item){
    const  od = await Getofficial({
        _id:item.id,
    })
    return od
}

function saveFile(response, filename) {
    const href = URL.createObjectURL(response)

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    
    // 创建 MouseEvent 对象
    const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
    });
    
    // 模拟鼠标点击事件
    link.dispatchEvent(event);

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
}

function do_decorate_doc(doc, tm) {
    Vue.set(doc, "reg_num", tm.reg_num)
    Vue.set(doc, "tm_name", tm.name)
    // Vue.set(doc, "first_annc_date", toYMDTime(tm.first_annc_date))

    Vue.set(doc, "trademark", tm)

    let cases = [], tm_id = tm._id
    tm.cases.forEach((ca) => {
        ca.binds.forEach(bind => {
            if (!bind.doc || _.get(bind, ['tm', '_id']) != tm_id)
                return
            if (doc._id == bind.doc._id)
                cases.push(_.cloneDeep(ca))
        })
    })
    Vue.set(doc, "cases", cases)

    console.log(`decorated doc(${doc._id}) with tm(${tm._id}), cases:(${doc.cases.length})`)
}

async function getBrandAxios(){
    return await Brand();
}

const funs = []
let calling = false

/**
 * 把多个async function放入一个队列，顺序调用。
 * @param {} fun
 * @returns 返回一个promise对象，这个对象解析(resolve)为传入的fun的返回值。也就是说await call_in_queue结果等同于await fun,除了fun的多次调用会被串行执行.
 */
async function call_in_queue(fun) {

    const p = new Promise((resolve, reject) => {
        funs.push({fun, resolve, reject})
    })

    const caller = async () => {
        while(funs.length) {
            let funo = funs.shift()
            try {
                funo.resolve(await funo.fun())
            } catch(error) {
                console.log(`call_in_queue exception:${error.message} `)
                funo.reject(error)
            }
        }
        calling = false
    }

    if (!calling) {
        calling = true
        caller()
    }

    return p
}

/**
 * 根据client返回的token信息，来做一些事情
 * @param {*} auth 
 */
function verifyToken(auth,commit){
    const expires = auth.authentication.payload.exp;
    // console.log('token过期时间-------->');
    // console.log('秒数 :>>', expires,toBeijingTime(new Date(expires)) );
    // console.log('毫秒数 :>>', expires * 1000,toBeijingTime(new Date(expires * 1000)) );
    // console.log('剩余时间 :>>', getTimeDifference(new Date(expires * 1000),new Date(),true));

    // 设置定时器  多减15分钟
    let timerMilliSecondTime =  (expires  * 1000) - Date.now() - (1000 * 60 * 5);

    let maxTimerTime =  1000 * 60 * 60 * 24 * 10; //10天

    timerMilliSecondTime = timerMilliSecondTime > maxTimerTime ? maxTimerTime :  timerMilliSecondTime;

    if (timerMilliSecondTime <= 5000) {
        loginOvertimeBox();
    } else {
        let date = new Date()
        date.setTime(date.getTime() + timerMilliSecondTime)
        console.log('本次登录超时时间:', toBeijingTime(date));
        // 定时器的最大延迟时间为 2的31次幂（Math.pow(2,31)）
        commit('setLoginTimeout',timerMilliSecondTime)
    }
}

function setServersTotalSizeTime({commit}) {
    getServersTotalSize({commit});
    setInterval(() => {
        getServersTotalSize({commit});
    },1000 * 60 * 62);
}

async function getServersTotalSize({ commit }) {
    const params = {
        query: {
            $total_size: true,
        }
    }
    let docTotalSize = await client.service('document').find(params).catch(err => {
        console.log('err :>>', err);
    })

    let copyrightTotalSize = await client.service('file').find(params).catch(err => {
        console.log('err :>>', err);
    })

    let evidenceTotalSize = await client.service('evidence').find(params).catch(err => {
        console.log('err :>>', err);
    })

    commit('setServersTotalSize', {
        docTotalSize,
        copyrightTotalSize,
        evidenceTotalSize,
    })
}
// Function to create a subdirectory within a given directory handle
async function createSubdirectory(dirHandle, subdirectoryName) {
    try {
        // Create a new subdirectory or get an existing one; set create: true
        const subDirHandle = await dirHandle.getDirectoryHandle(subdirectoryName, { create: true });
        return subDirHandle;
    } catch (error) {
        console.log(error,'error');
        // console.error(`Could not create or access subdirectory (${subdirectoryName}):`, error);
        Message.error(`无法创建文件夹${subdirectoryName}`)
    }
}

// Function to write a file to a given directory handle
async function writeFile(dirHandle, fileName, blob,state) {
    // Create a new file or get an existing one; set create: true
    const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
    var data =await fileHandle.createWritable().then(function(writable) {
        return writable.write(blob).then(function() {
            return writable.close();
        })
    }).catch(function(error) {
        console.log(error,'error');
        if (error.name === 'QuotaExceededError') {
            // 磁盘空间不足错误
            Message.error(`磁盘空间不足，无法下载文件`);
            if (state.odFileList.source) {
                state.odFileList.source.cancel('已取消下载任务!');
            }
            return 'QuotaExceededError'
        } else {
            // 其他错误
            Message.error(`写入文件失败${fileName}`);
        }
    });
    return data
}

// Main function to create nested directories and write a file
async function createFoldersAndWriteFile(fName,folder,blob,dirHandle,state) {
    try {
        if (!folder||folder=='') {
            folder = '未归档'
        }
        // Example: Creating a nested folder structure like /folder1/folder2
        const folder1Handle = await createSubdirectory(dirHandle, folder);
        // Write a file in /folder1/folder2
        var data = await writeFile(folder1Handle, fName, blob,state);

        console.log('File written successfully!');
        
        return data
    } catch (error) {
        console.error('Failed to create folders and write file:', error);
    }
}
async function getDirectoryHandle(Handle,fName,i){
    if (i == 999) {
        Message.error('当前目录文件过多，请重新选择目录保存!')
    }else{
      try {
        var fileName = i?`${fName}(${i})`:fName
        var dirHandle = await Handle.getDirectoryHandle(fileName,{create:false});
        if (dirHandle.name) {
            return await getDirectoryHandle(Handle,fName,i+1);
        }
      } catch (error) {
        if (error.name == 'NotFoundError') {
            if (i) {
                return await Handle.getDirectoryHandle(`${fName}(${i})`, { create: true });

            }else{
                return await Handle.getDirectoryHandle(fName, { create: true });
            }
        }
      }
    }
}
export { wsModule }
