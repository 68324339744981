import Vue from "vue";
import axios from 'axios'

// 配置请求的基准URL地址
//axios.defaults.baseURL = 'http://lu:3031/'

axios.interceptors.request.use(function(config ){
const token = JSON.parse(sessionStorage.getItem('token'))
if(token){
    config.headers.common['Authentication-Token']=token 
}
return config

})
//把axios注册到Vue
Vue.prototype.$http = axios;