import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home2 from "../views/Home2.vue";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes = [
  {
    path: "/platform/login",
    name: "Mylogin",
    meta: {
      title: "登录"
    },
    component: () => import("../views/Login/index.vue")
  },
  {
    path: "/platform/download",
    name: "Mydownload",
    meta: {
      title: "下载页面"
    },
    component: () => import("../views/Download/index.vue")
  },
  {
    path: '/',
    redirect: "/platform/login",
  },
  {
    path: "/home",
    name: "MyHome",
    component: Home2,
    redirect: "/home/HomePage",
    children: [
      {
        path: "HomePage",
        name: "MyHomePage",
        meta: {
          title: "首页"
        },
        component: () => import("../views/HomePage2/index.vue")
      },
      // {
      //   path: "HomePage2",
      //   name: "MyHomePage",
      //   meta: {
      //     title: "首页"
      //   },
      //   component: () => import("../views/HomePage2/index.vue")
      // },
      {
        path: "user",
        name: "MyUser",
        meta: {
          title: "用户管理"
        },
        component: () => import("../views/User/index.vue")
      },
      {
        path: "role",
        name: "MyRole",
        meta: {
          title: "角色和授权管理"
        },
        component: () => import("../views/Role/index.vue")
      },
      {
        path: "postbox",
        name: "MyPostbox",
        meta: {
          title: "监控邮箱配置"
        },
        component: () => import("../views/Postbox/index.vue")
      },
      // {
      //   path: "documents",
      //   name: "MyDocuments",
      //   meta: {
      //     title: "官文配置"
      //   },
      //   component: () => import("../views/MyDocuments/index.vue")
      // },
      // {
      //   path: "configuration",
      //   name: "MyConfiguration",
      //   meta: {
      //     title: "测试页面"
      //   },
      //   component: () => import("../views/configuration/index.vue")
      // },
      {
        path: "brand",
        name: "MyBrand",
        meta: {
          title: "品牌"
        },
        component: () => import("../views/Brand/index3.vue")
      },
      {
        path: "apply",
        name: "MyApply",
        meta: {
          title: "申请人"
        },
        component: () => import("../views/Apply/index.vue")
      },
      {
        path: 'document',
        name: 'MyOfficial',
        meta: {
          title: "所有档案",
        },
        component: () => import("../views/Official/index.vue")
      },
      {
        path: 'beprocessedOfficial',
        name: 'MybeprocessedOfficial',
        meta: {
          title: "未读档案",
          has_read:false
        },
        component: () => import("../views/Official/beprocessedOfficial.vue")
      },
      {
        path: 'lawsuitcase',
        name: 'Mylawsuitcase',
        meta: {
          title: "行政诉讼案件",
          page:'行政诉讼',
          base_type:'行政诉讼',
          case_type:'law_suit_case'
        },
        component: () => import("../views/Cases/lawsuitcase.vue")
      },
      {
        path: 'tmcase',
        name: 'Mytmcase',
        meta: {
          title: "商标基础案件",
          page:'商标基础',
          base_type:'商标基础',
          case_type:'tm_case'
        },
        component: () => import("../views/Cases/tmcase.vue")
      },
      {
        path: 'lawcase',
        name: 'Mylawcase',
        meta: {
          title: "非诉法律案件",
          page:'法律案件',
          base_type:'法律案件',
          case_type:'law_case'
        },
        component: () => import("../views/Cases/lawcase.vue")
      },
      {
        path: 'trademark',
        name: 'MyTrademark',
        meta: {
          title: '我方商标'
        },
        component: () => import('../views/Trademark/index.vue')
      },
      {
        path: 'pieVisualAnalysis',
        name: 'pieVisualAnalysis',
        meta: {
          title: "商标饼图分析"
        },
        component: () => import("../views/PieVisualAnalysis/index.vue")
      },
      {
        path: 'treemapVisualAnalysis',
        name: 'treemapVisualAnalysis',
        meta: {
          title: "商标矩阵分析"
        },
        component: () => import("../views/TreemapVisualAnalysis/index.vue")
      },
      {
        path: 'timershaft',
        name: 'Mytimershaft',
        meta: {
          title: "商标时间轴分析"
        },
        component: () => import("../views/TimerShaft/index.vue")
      },
      {
        path: 'caseshaft',
        name: 'Mycaseshaft',
        meta: {
          title: "案件可视化分析",
          page:'案件可视化分析',
        },
        component: () => import("../views/caseVisualAnalysis/index.vue")
      },
      {
        path: 'revetm',
        name: 'MyReveTM',
        meta: {
          title: '涉他商标'
        },
        component: () => import('../views/ReveTM/index.vue')
      },
      {
        path: 'timelimit',
        name: 'MyTimeLimit',
        meta: {
          title: '时限管理'
        },
        component: () => import('../views/TimeLimit/index.vue')
      },
      {
        path: 'tmpage/:id',
        name: 'MyTMpage',
        meta: {
          title: '商标详情页'
        },
        component: () => import('../views/TMpage/index.vue')
      },
      {
        path: 'flowalert',
        name: 'Myflowalert',
        meta: {
          title: '商标预警'
        },
        component: () => import('../views/flowalert/index.vue')
      },
      {
        path: 'workCopyright',
        name: 'MyWorkCopyright',
        meta: {
          title: '作品著作权'
        },
        component: () => import('../views/copyRight/workCopyright/index.vue')
      },
      {
        path: 'softwareCopyright',
        name: 'MySoftwareCopyright',
        meta: {
          title: '软件著作权'
        },
        component: () => import('../views/copyRight/softwareCopyright/index.vue')
      },
      {
        path: 'copyrightFile',
        name: 'MyCopyrightFile',
        meta: {
          title: '著作权档案'
        },
        component: () => import('../views/copyRight/copyrightFile/index.vue')
      },{
        path: 'domainname',
        name: 'Mydomainname',
        meta: {
          title: '域名管理'
        },
        component: () => import('../views/domainname/index.vue')
      },
      {
        path: 'commodityQuery',
        name: 'MyCommodityQuery',
        meta: {
          title: '商品查询'
        },
        component: () => import('../views/commodityQuery/index2.vue')
      },
      {
        path: 'evidenceList',
        name: 'MyEvidenceList',
        meta: {
          title: '证据列表'
        },
        component: () => import('../views/evidenceList/index2.vue')
      },
      {
        path: 'evidenceRecyclingBin',
        name: 'MyEvidenceRecyclingBin',
        meta: {
          title: '证据回收站'
        },
        component: () => import('../views/evidenceRecyclingBin/index.vue')
      },
      {
        path:'audit',
        name:'MyAuditList',
        meta:{
          title:'数据核查'
        },
        component:()=> import('../views/Audit/index.vue')
      },
      {
        path:'helpDocs',
        name:'MyHelpDocs',
        meta:{
          title:'操作指南'
        },
        component:()=> import('../views/helpDocumentation/index.vue')
      },
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

//解决连续点击重复页签报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 不在左边菜单栏上，且想有权限的路由
const addRoute = ['MyAuditList','MyHelpDocs'];

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  // 在vuex中保存现在的route
  router.app.$options.store.commit('SET_ROUTE',to)
  if (to.path == "/platform/download") {
    next();
    return
  }
  if (to.path == "/platform/login") {
    next();
  } else {
    var token = sessionStorage.getItem('token');
    if (token) {
       var case_arr = sessionStorage.getItem('routerName')
       if (!case_arr) {
        next();
        return
       }
       case_arr =  case_arr.split(',')
       case_arr = case_arr.concat(addRoute);
        if (_.indexOf(case_arr, to.name)!=-1) {
          next();
        }else{
          if (from.path == '/platform/login') {
            next({
              path: "/home"
            });
            return
          }
          document.title =from.meta.title
          next({
            path: from.path
          });
          Message.warning('您对当前的操作没有权限，如果需要请联系管理员。')
        }
    } else {
      router.app.$options.store.commit('patchToPath',to.path)
      next({
        path: "/platform/login"
      });
    }
  }
});
export default router;
