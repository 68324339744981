<template>
  <div class="HeaderBox">
    <div class="headerLeft">
      <img src="@/assets/img/logo.png" alt="" />
      <p>巧鲸云IP副驾驶</p>
    </div>
    <ul class="headerCenter" ref="tabAllsRef">
      <li
        v-for="item in editableTabs"
        :key="item.title"
        @click="handleClickRoute(item)"
        @contextmenu="handleContextmenuRoute($event, item)"
        class="tabRow"
        :title="item.title"
        :class="item.isCheck ? 'checkTabRow' : 'noCheckTabRow'"
      >
        <p v-show="removeIconShow ? true : !item.isCheck">{{ item.title }}</p>
        <i
          class="el-icon-close"
          @click.stop="handleRemoveTab(item)"
          v-show="removeIconShow ? true : item.isCheck"
        ></i>
      </li>
    </ul>
    <div class="headerRight">
      <div @click="openClick">
        <el-badge :value="NotcompletedTotal" class="item" style="cursor: pointer;" :hidden="!NotcompletedTotal">
          <!-- <el-avatar icon="el-icon-sort"></el-avatar> -->
          <img src="../../assets/img/download.png" alt="" >
        </el-badge>
      </div>      
      <div>
        <el-popover
          placement="bottom"
          width="190"
          trigger="hover"
          popper-class="user_popper"
          v-model="UserPopoverVisible"
        >
          <div class="text_center">
            <i class="el-icon-s-custom"></i>
            <p v-if="$store.state.ws_state.user">{{
              $store.state.ws_state.user.user
            }}</p>
          </div>
          <div class="text_center" @click="handlecheckPass">
            <i class="el-icon-lock"></i>
            <p>修改密码</p>
          </div>
          <div class="text_center" @click="logout">
            <i class="el-icon-back"></i>
            <p>退出登录</p>
          </div>
          <div class="text_center" >
            <i class="el-icon-pie-chart"></i>
            <p :title="`档案总量${get_all_total_size}`">
              档案总量{{ get_all_total_size }}
            </p>
          </div>
          <div class="loginout" slot="reference">
            <p v-if="$store.state.ws_state.user" class="user">
              <img src="@/assets/img/user.png" alt=""/>
            </p>
          </div>
        </el-popover>
      </div>
    </div>
    <RightClick
      v-show="rightClickShow"
      :show="rightClickShow"
      :route="rightClickRow"
      :event="rightClickEvent"
      @close="handleCloseRightClick"
    >
    </RightClick>
    <!-- 修改密码的弹框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="PutPassDialog"
      width="24%"
      modal-append-to-body
      append-to-body
      :close-on-press-escape="false"
    >
      <el-form
        :model="ruleForm"
        label-width="80px"
        :rules="rules"
        ref="passWordForm"
      >
        <el-form-item label="新密码" prop="pass" class="formLeftIcon">
          <el-input
            size="small"
            type="password"
            v-model.trim="ruleForm.pass"
            placeholder="6位及以上大写字母,小写字母,数字,特殊符号3种以上组合"
            autocomplete="new-password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass" class="formLeftIcon">
          <el-input
            size="small"
            type="password"
            v-model.trim="ruleForm.checkPass"
            placeholder="请确认密码"
            autocomplete="new-password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="PutPassDialog = false">取 消</el-button>
        <el-button type="primary" @click="changePass">确 定</el-button>
      </span>
    </el-dialog>
    <task v-if="taskDrawer"
      @modifyTaskDrawer="modifyTaskDrawer">
    </task>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RightClick from "@/components/layout/customRightClick.vue";
import task from "@/components/task.vue";
import {logoutLogin} from '@/utils/index.js'
import Vue from 'vue';
import lodash from 'lodash';
export default {
  components: {
    RightClick,
    task,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs["passWordForm"].validateField("checkPass");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const reg =
      /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/;
    return {
      // header中间的缓存路由
      editableTabs: [],
      taskDrawer:false,
      removeIconShow: true, // 每个li标签的icon图标是否显示  默认显示 只有单个li的宽度不足40px的时候不显示
      // header右边的工具
      UserPopoverVisible: false, // 控制用户工具
      visible2: false, // 控制列表消息的清单
      // 自定义右键
      rightClickShow: false,
      rightClickEvent: null,
      rightClickRow: null,
      defaultRoute: "/home/HomePage",
      // 修改密码的弹框
      PutPassDialog: false,
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [
          {
            pattern: reg,
            message: "大写字母,小写字母,数字,特殊符号3种以上组合",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "密码要在 6 到 15 个字符",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          {
            pattern: reg,
            message: "大写字母,小写字母,数字,特殊符号3种以上组合",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "密码要在 6 到 15 个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      NotcompletedTotal:0,
    };
  },
  created() {},
  mounted() {
    this.watchLisWidth();
    window.addEventListener("resize", () => {
      this.watchLisWidth();
    });
  },
  methods: {
    // 打开修改密码
    handlecheckPass() {
      this.PutPassDialog = true;
      if (this.$refs["passWordForm"]) {
        this.$refs["passWordForm"].resetFields();
      }
    },
    // 修改密码
    changePass() {
      this.$refs["passWordForm"].validate(async (valid) => {
        if (valid) {
          var data = {
            _id: this.$store.state.ws_state.user._id,
            password: this.ruleForm.pass,
          };
          await this.$store.dispatch("Changepassword", data);
          if (this.save_passwordCollapse) {
            this.$message({
              type: "success",
              message: `密码修改成功`,
              duration: 2000,
            });
            this.PutPassDialog = false;
            setTimeout(() => {
              this.logout();
            }, 2000);
          } else {
            this.$message({
              type: "error",
              message: `密码修改失败`,
              duration: 2000,
            });
          }
        }
      });
    },
    // 退出登录
    logout() {
      this.UserPopoverVisible = false;
      this.$store.commit('clearLoginTimeout')
      logoutLogin()
    },
    read() {},
    mouseEnter() {},
    handleMouseLeave() {},
    emptyList() {},
    // 点击跳转
    handleClickRoute(route) {
      if (route.isCheck) return;
      this.$router.push(route.path);
    },
    // 样式布局
    watchLisWidth() {
      this.removeIconShow = true;
      this.$nextTick(() => {
        let AllTabs = this.$refs.tabAllsRef;
        if (!AllTabs) return;
        let children = AllTabs.children;
        // 计算所有li标签自身的宽度
        let lisWidth = Array.from(children).reduce((total, box) => {
          box.style.width = "";
          let boxWidth = Math.round(
            parseFloat(getComputedStyle(box).getPropertyValue("width"))
          );
          return total + boxWidth;
        }, 0);

        // console.log(lisWidth, AllTabs.offsetWidth);
        if (lisWidth >= AllTabs.offsetWidth) {
          let equalWidth = Math.floor(AllTabs.offsetWidth / children.length);
          // console.log(" :>>", AllTabs.offsetWidth, children.length);

          // console.log(
          //   "equalWidth :>>",
          //   equalWidth,
          //   equalWidth > 16 + 7 + 7 + 14
          // );
          this.removeIconShow = equalWidth > 16 + 7 + 7 + 14;
          equalWidth = equalWidth > 28 ? equalWidth : 28;
          Array.from(children).forEach((li) => {
            li.style.width = equalWidth + "px";
          });
        }
      });
    },
    // 根据title 查找序号
    findEditableTabsIndex(route) {
      let findIndex = this.editableTabs.findIndex(
        (tab) => tab.title === route.title
      );
      return findIndex;
    },
    // 跳转到默认的路由（只有在editableTabs.length === 0）在执行
    jumpDefaultRoute(){
      if (this.editableTabs.length != 0) return;
      localStorage.removeItem("Tabs");
      this.$router.push(this.defaultRoute);
    },
    // 检查要删掉的最后一个路由是否为默认的路由(这种情况应该默认editableTabs的长度大于0)
    checkRouteIsDefalultRoute(){
      if (this.editableTabs.length > 1) return false;
      let route = this.editableTabs[0];
      if (route.path == this.defaultRoute) {
        this.$message.warning('最后一个默认的路由不允许删除');
        return true;
      }else{
        return false;
      }
    },
    // 删掉单个路由
    handleRemoveTab(route) {
      if (this.checkRouteIsDefalultRoute()) return;
      let findIndex = this.findEditableTabsIndex(route);
      if (findIndex === -1) return;
      this.editableTabs.splice(findIndex, 1);

      if (this.editableTabs.length === 0) return this.jumpDefaultRoute();
      // 关闭的路由是否为现在的路由
      let currentRoute = this.$route;
      let { name, path, title } = this.getCurrentRoute(currentRoute);
      if (title !== route.title) return;
      // 默认是去关闭的路由前面那个
      findIndex = findIndex === 0 ? 0 : findIndex - 1;
      this.$router.push(this.editableTabs[findIndex].path);
    },
    // 自定义右键事件
    handleContextmenuRoute(event, route) {
      event.cancelBubble = true;
      // 阻止右键事件冒泡
      event.preventDefault();
      this.rightClickShow = true;
      this.rightClickEvent = event;
      this.rightClickRow = route;
    },
    // 删除其他路由
    handleRemoveOtherTabs(route) {
      if (this.checkRouteIsDefalultRoute()) return;
      let findIndex = this.findEditableTabsIndex(route);
      if (findIndex === -1) return;
      let deleteCount = this.editableTabs.length - 1 - findIndex;
      this.editableTabs.splice(findIndex + 1, deleteCount);
      this.editableTabs.splice(0, findIndex);
      this.JumpRouteRules(route);
    },
    // 删除左侧路由
    handleRemoveLeftTabs(route) {
      if (this.checkRouteIsDefalultRoute()) return;
      let findIndex = this.findEditableTabsIndex(route);
      if (findIndex === -1) return;
      if (findIndex === 0) return;
      this.editableTabs.splice(0, findIndex);
      this.JumpRouteRules(route);
    },
    // 删除右侧路由
    handleRemoveRightTabs(route) {
      if (this.checkRouteIsDefalultRoute()) return;
      let findIndex = this.findEditableTabsIndex(route);
      if (findIndex === -1) return;
      let deleteCount = this.editableTabs.length - 1 - findIndex;
      this.editableTabs.splice(findIndex + 1, deleteCount);
      this.JumpRouteRules(route);
    },
    // 只用于删除非选中的路由逻辑（除了删除当前打开的路由页面)
    JumpRouteRules(selectRoute) {
      if (this.editableTabs.length === 0) return this.jumpDefaultRoute(); // 没有缓存路由了
      let currentRoute = this.$route;
      let { name, path, title } = this.getCurrentRoute(currentRoute);
      let findindex = this.findEditableTabsIndex({ title, name, path });
      if (findindex !== -1) return; //找到的当前路由存在
      if (selectRoute && selectRoute.path)
        return this.$router.push(selectRoute.path);
      this.$router.push(this.editableTabs.at(-1).path);
    },
    getCurrentRoute(n) {
      let { name, path, meta } = n;
      let title = meta.title;
      if (path !== n.fullPath) {
        title = n.query.title || title;  //  n.query.title 可能没有
        path = n.fullPath;
      }
      return {
        name,
        path,
        title,
      };
    },
    // 右键事件的回调函数
    handleCloseRightClick(key, route) {
      switch (key) {
        case "closeTab":
          this.handleRemoveTab(route);
          break;
        case "closeOtherTabs":
          this.handleRemoveOtherTabs(route);
          break;
        case "closeLeftTabs":
          this.handleRemoveLeftTabs(route);
          break;
        case "closeRightTabs":
          this.handleRemoveRightTabs(route);
          break;

        default:
          break;
      }
      this.rightClickShow = false;
    },
    openClick(){
      if (this.getodFileList.list?.length) {
        this.taskDrawer = true; 
      }else if(this.getUploadFileData.rowFileList?.length){
        this.getUploadFileData.addDrawer =true;
      }
    },
    modifyTaskDrawer(n){
      this.taskDrawer = n;
      this.getodFileList.taskDrawer = n;
      this.getUploadFileData.addDrawer =n;
    },
  },
  computed: {
    ...mapGetters([
      "all_alerts",
      "unread_alerts",
      "enabled_menus2",
      "getisCollapse",
      "save_passwordCollapse",
      "get_all_total_size",
      "getodFileList",
      "getUploadFileData",
    ]),
    ...mapState({
      routerDisabled: (state) => state.routerDisabled, // 将 state.count 映射到 this.count
    }),
  },
  watch: {
    $route: {
      handler: function (n) {
        // 应付页面刷新
        if (this.editableTabs.length === 0) {
          let tabs = JSON.parse(localStorage.getItem("Tabs"));
          this.editableTabs = tabs && tabs.length > 0 ? tabs : [];
        }
        // 获取一些route的配置
        let { name, path, title } = this.getCurrentRoute(n);
        // 查找跳转的路由在不在缓存里面
        let findIndex = this.findEditableTabsIndex({ name, path, title });
        // 更改所有路由的isCheck
        this.editableTabs.forEach((route, index) => {
          route.isCheck = index === findIndex;
        });
        // 在进入新的路由前，判断他是否存在，存在就更新时间，不存在判断超出10个MyTMpage商标详情页就删除更新时间最小的那个
        if (name === 'MyTMpage') {
          if (findIndex === -1) {
            let MyTMpagePageUpdates = this.editableTabs.filter(i => i.name === 'MyTMpage').map(i => {
              return i.updatedAt ? i.updatedAt : 0 ;
            });
            const MyTMpagePageNum = MyTMpagePageUpdates.length;
            const minUpDate = Math.min(...MyTMpagePageUpdates);
            if (MyTMpagePageNum > 9) {
              // console.log('需要删除一个路由');
              lodash.remove(this.editableTabs,i => i.updatedAt == minUpDate);
            }
          }else{
            let route = this.editableTabs[findIndex];
            route.updatedAt = Date.now();
            Vue.set(this.editableTabs,findIndex,route);
            // console.log('更新MyTMpage路由的时间');
          }
        }

        if (findIndex === -1) 
        this.editableTabs.push({
          name,
          title,
          path,
          isCheck: true,
          updatedAt: Date.now(),
        });
        
      },
      deep: true,
      immediate: true,
    },
    // 不开启深度监听，只有数组的长度发生变化才可以更换样式，（现在选中没有样式）
    editableTabs: {
      handler: function (newVal, oldVal) {
        localStorage.setItem("Tabs", JSON.stringify(newVal));
        this.$EventBus.$emit("patchEditableTabs", newVal);
        this.watchLisWidth();
      },
      // deep: true,
    },
    'getodFileList.taskDrawer'(n){
        if (n) {
            this.taskDrawer = true;
        }
    },
    'getodFileList.NotcompletedTotal'(n){
      this.NotcompletedTotal = n;
    },
    'getUploadFileData.addDrawer'(n){
      if (n) {
        this.taskDrawer = n;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";

@li_border_left:rgba(109, 215, 243);   // li标签左侧自带1px的颜色
@li_i_hover_bg_color:rgba(165, 230, 248);   // li标签下的ihover的背景颜色
// 未选中的样式
@li_no_check_bg_color:rgba(84, 209, 241, 0.5);
@li_no_check_i_hover_bg_color:rgba(127, 220, 245);
@li_no_check_i_hover_color:rgba(234, 249, 253);
.HeaderBox {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.headerLeft {
  height: 100%;
  width: @asideMaxWidth !important;
  display: flex;
  align-items: center;
  background-color: @color-primary;
  img {
    width: 56px;
    height: 100%;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin-left: 8px;
  }
}
.headerRight {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .unread_alerts_class {
    height: auto;
    overflow: auto;
    max-height: 700px;
    font-weight: bold;
    .alertRow {
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .mar28 {
    margin-right: @spacing-large;
  }
  .loginout {
    height: 100%;
    // margin-left: calc(@spacing-xxlarge + @spacing-xxlarge);
    margin-left: 14px;
    cursor: pointer;
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.text_center {
  cursor: pointer;
  padding: 11px 14px;
  border-bottom: 1px solid @border-color-extra-light;
  display: flex;
  flex-direction: row;
  align-items: center;
  p{
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 溢出隐藏 */
    text-overflow: ellipsis; /* 超出显示省略号 */
    flex: 1;
  }
  i {
    margin: 0 7px 0 0;
  }
}
.text_center:last-child{
  border: 0;
}
.headerCenter {
  flex: 1;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  .tabRow {
    align-self: flex-end;
    cursor: pointer;
    padding-left: @spacing-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(@elementSmall - 2px);
    line-height: calc(@elementSmall - 2px);
    border-left: 1px solid @li_border_left;
    p {
      flex: 1;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 隐藏溢出的内容 */
      // text-overflow: ellipsis;
      // -o-text-overflow: ellipsis;
      margin-right: @spacing-small;
    }
    i {
      margin-right: @spacing-small;
      font-size: 12px;
      transition: background-color 0.3s; /* 添加过渡效果 */
      padding: 2px; /* 可选，用于增加图标周围的填充 */
      border-radius: 2px; /* 创建圆形背景 */
    }
    i:hover {
      background-color: @li_i_hover_bg_color; /* 鼠标悬停时添加蓝色背景色 */
    }
  }
  .tabRow:first-child {
    border-left: none !important;
  }
  // 选中的样式
  .checkTabRow {
    background-color: @color-white;
    color: @color-text-primary;
    font-weight: 800;
    height: @elementSmall;
    line-height: @elementSmall;
    border-top-right-radius: @border-radius;
    border-top-left-radius: @border-radius;
    border-left: 1px solid @color-white !important;
    i {
      color: @color-text-primary;
      font-weight: 800;
    }
    i:hover {
      background-color: @color-white; /* 鼠标悬停时添加蓝色背景色 */
    }
  }
  .checkTabRow + li {
    border-left: 1px solid @li_no_check_bg_color;
  }
  // 未选中的样式
  .noCheckTabRow {
    background-color: @li_no_check_bg_color;
    color: @color-white;
  }
  .noCheckTabRow:hover {
    background-color: @li_no_check_i_hover_bg_color;
    height: @elementSmall;
    color: @li_no_check_i_hover_color;
    line-height: @elementSmall;
    border-top-right-radius: @border-radius;
    border-top-left-radius: @border-radius;
    border-left: 1px solid @li_no_check_i_hover_bg_color;
  }
  .noCheckTabRow:hover + li {
    border-left: 1px solid @li_no_check_bg_color;
  }
}


::v-deep .el-form>.formLeftIcon>.el-form-item__label:before{
  content:'*';
  color: #F56C6C;
  margin-right:4px;
}
::v-deep .el-avatar{
  width: 25px;
  height: 25px;
  line-height: 25px;
}
::v-deep .el-badge__content.is-fixed{
  top: 2px !important;
  right: 12px !important;
}
::v-deep .el-badge__content{
  font-size: 9px !important;
  border: 0px !important;
  height: 14px !important;
  line-height: 14px !important;
  padding: 0 4px !important;
}
</style>