<template>
  <div  class="viewItemFile" v-watchWidth="watchViewItemFile">
    <!-- error -->
    <div class="errorBox" v-if="fileShow == -1">
      <el-empty :image-size="32" :image="errImage" description="暂无数据..."></el-empty>
    </div>
    <!-- pdf -->
    <div style="height:100%;" v-if="fileShow == 0" id="page-container">
      <div :style="PageShow?'height:calc(100% - 56px)':'height:100%;'" ref="pdfContainer" v-loading="PDFloading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <iframe
          id="myFrame"
          ref="pdfIframe"
          :src="iframeFilelink"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          width="100%"
          height="100%"
          @load="handleIframeLoad"
        >
        </iframe>
        <iframe
        style="margin-top:-22px"
          id="myFrame"
          ref="pdfIframes"
          :src="iframeFilelinks"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          width="100%"
          height="0%"
          @load="handleIframeLoad2"
        >
        </iframe>
      </div>
      <div class="more" v-if="PageShow ">
        <el-button  @click="See_more" v-loading="loading"
        class="Next_section"
        type="text"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#909399"
        v-if="Next_section"
        :disabled="loading"
        >加载下一部分 <i class="el-icon-arrow-down"></i></el-button >
        <p v-else>已全部加载完成</p>
      </div>
    </div>
    <!-- excel -->
    <div class="excelBox"  v-if="fileShow == 1">
      <div class="occupyingBox"></div>
      <div class="sheetNames">
        <p v-for="item in excels" :key="item.sheetname" @click="handleClickExcel(item.sheetname)" :class="item.show?'excelfooter':''">{{  item.sheetname }}</p>
      </div>
      <div  class="excel-view-container" >
        <div  id="excelView"  v-html="excelHtml"></div>
      </div>
    </div>
    <!-- docx -->
    <div class="docWrap" v-if="fileShow == 2">
      <div ref="file"></div>
    </div>
    <!-- text -->
    <div class="text-file" v-if="fileShow == 3">
      <VueDocPreview :value="docValue" :type="docType" :code="docCode" :md-style="mdStyle"  :text-style="textStyle"/>
    </div>
    <!-- img -->
    <div class="image" v-if="fileShow == 4" :class="imageClass">
      <el-image :src="imgUrl" :preview-src-list="srcList" v-watchWidth="watchImageClass"> </el-image>
    </div>
    <!-- video -->
    <div class="videoBox" v-if="fileShow == 5">
      <video width="360" height="360" controls>
        <source :src="videoSrc" :type="videoType">
      </video>
    </div>
    <!-- audio -->
    <div  class="audioBox" v-if="fileShow == 6">
      <audio controls>
        <source :src="audioSrc" :type="audioType">
      </audio>
    </div>
  </div>
  
  
</template>

<script>
import { PDFDocument, StandardFonts } from 'pdf-lib';
import * as XLSX from 'xlsx'
import * as DOCX from 'docx-preview'
import VueDocPreview from 'vue-doc-preview'
import {getBlobFile,getFileTotal} from '@/api/axios'
var pdfDoc = ''
export default { 
  components: {
    VueDocPreview
  },
  props: {
    filelink: {   //url地址
      type: String,
      required: true,
    },
    fileName:{    //文件名
      type: String,
      required: true,
    },
    file_size:null,
  },
  data() {
    return {
      fileShow: 0,
      iframeArr:['pdf'],
      XLSXArr:['xlsx','xlsm','xlsb','xls'],
      VueDocPreviewCode:[ 'js','html','css','java','json','ts','cpp','xml','bash','less','nginx','php','powershell','python','scss','shell','sql','yaml','ini'],
      imgArr:['jpg','png','bmp','webp','jpeg','gif','tiff'],
      videoArr:["mpeg", "mpg", "avi", "mp4", "mov", "wmv"],
      audioArr:["mp3", "wav", "aac", "flac", "ogg"],
      //iframe -------------
      iframeFilelink:'',
      // 文本路径   VueDocPreview---------
      docValue:'',
      docType:'',
      docCode:'',
      mdStyle:{},
      textStyle:{},
      //excel-----------
      excelHtml:'',
      excels:[],
      //img--------------
      imgUrl:'',
      srcList:[],
      viewItemFileHeight:0,
      imageClass:'',
      //video------------
      videoSrc:'',
      videoType:'',
      videoMimeTypes:new Map([
        ['mpeg', 'video/mpeg'],
        ['mpg', 'video/mpeg'],
        ['avi', 'video/x-msvideo'],
        ['mp4', 'video/mp4'],
        ['mov', 'video/quicktime'],
        ['wmv', 'video/x-ms-wmv'],
      ]),
      //audio------------
      audioSrc:'',
      audioType:'',
      audioMimeTypes:new Map([
        ['mp3', 'audio/mpeg'],
        ['wav', 'audio/wav'],
        ['aac', 'audio/aac'],
        ['flac', 'audio/flac'],
        ['ogg', 'audio/ogg'],
      ]),
      pdfDoc:null,
      copiedPages:[],
      filePage:1,
      Size:null,
      PageShow:false,
      FlieTotal:0,
      loading:false,
      Next_section:true,
      pageCount:0,
      showIframe:false,
      iframeFilelinks:'',
      limitPage:0,
      PDFloading:false,
      errImage:require('../../public/jingshi.png'),
    };
  },
  async mounted(){
     if (_.isInteger(this.file_size) || this.file_size > 0){
      this.Size = this.file_size/(1024*1024);
     }else{
      this.Size = 0
     }
     console.log(this.Size);
    this.pdfDoc = await PDFDocument.create();
    this.patchView();
  },
  watch:{
    filelink(val){
      if (val) {
        this.patchView()
      }
    },
  },
  methods: {
    watchImageClass(e){
     this.imageClass = e.height <= this.viewItemFileHeight ? 'imageFlexClass':''
    },
    watchViewItemFile(e){
      this.viewItemFileHeight = e.height;
    },
    patchView(){
      //获取文件后缀
      let fileNameArr = this.fileName.split('.')
      let suffix = fileNameArr[fileNameArr.length - 1]
      //判断文件后缀
      if(this.iframeArr.includes(_.toLower(suffix))){
        this.PreviewIframeFile(this.filelink)
      }else if (suffix === 'docx') {
        this.PreviewDocxFile()
      }else if (this.XLSXArr.includes(suffix)) {
        this.PreviewXlsxFile()
      }else if(suffix === 'txt'){
        this.PreviewVueDocFile('text',null)
      }else if (suffix === 'md') {
        this.PreviewVueDocFile('markdown',null)
      }else if (this.VueDocPreviewCode.includes(suffix)) {
        this.PreviewVueDocFile('code',suffix)
      }else if (this.imgArr.includes(suffix)) {
        this.PreviewImgFile(this.filelink)
      }else if(this.videoArr.includes(suffix)){
        this.PreviewVideoFile(this.filelink,suffix)
      }else if (this.audioArr.includes(suffix)) {
        this.PreviewAudioFile(this.filelink,suffix)
      }else{
        this.PreviewError()
      }
    },
    PreviewError(){
      this.fileShow = -1
      this.$message({
          showClose: true,
          message: '暂不支持该文件类型的查看！',
          type: 'error',
          duration:2000,
          customClass:'messageClass'
      })
    },
    //0
    async PreviewIframeFile(url){
      this.fileShow = 0

      this.$nextTick( async ()=>{
        this.PDFloading = true;
        let fileData =  await getFileTotal(`${url}?type=split`).catch(err=>{
          this.fileShow = -1;
          this.$message({
              showClose: true,
              message: '文件读取失败，请联系管理员',
              type: 'error',
              duration:2000,
              customClass:'messageClass'
          })
        }); 
        let data = fileData ?.data;
        
        if (!data?.totalPart) return;
        
        this.FlieTotal = data?.totalPart;
        this.limitPage = data?.limitPage
        if (this.FlieTotal > 1) {
          try {
          url = `${url}?type=part&part=0&limit=${this.limitPage}`;
          const pdfBytes = await fetch(url).then(res => res.arrayBuffer());
          const tempDoc = await PDFDocument.load(pdfBytes);
          this.copiedPages = await this.pdfDoc.copyPages(tempDoc, tempDoc.getPageIndices());
          this.copiedPages.forEach(page => this.pdfDoc.addPage(page));
          const mergedPdfBytes = await this.pdfDoc.save();
          const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
          const urls = URL.createObjectURL(blob);
          this.iframeFilelink = urls + '#toolbar=1'; // 将合并后的PDF文件URL保存到data中
          this.PDFloading = false;
          this.PageShow = true;
          } catch (error) {
            console.log(error);
          }
        }else{
          this.PDFloading = false;
          this.PageShow = false;
          this.iframeFilelink = url+ '#toolbar=1';
        }
        // this.iframeFilelink = url;
      })
    },
    //1
    PreviewXlsxFile(){
      let that = this;
      console.log(this.filelink);
      getBlobFile(`${this.filelink}`).then(dataBLob=>{
          // 解析Excel文件
          let blob = dataBLob;
          let reader = new FileReader();
          reader.readAsBinaryString(blob);
          reader.onload = function(event) {
          let data = event.target.result;
          let workbook = XLSX.read(data, {type: 'binary'});  //cellStyles:true
          that.excels =  workbook.SheetNames.map((item,index)=>{
            let worksheet = workbook.Sheets[workbook.SheetNames[index]];
            let html_table = XLSX.utils.sheet_to_html(worksheet);
            //生产序号
            let key =-1
            let new_html_table = html_table.replace(/<tr>/g,()=>{
              key++
              if (key==0) {
                return '<tr><td id="qjy-1">' + '序号' + '</td>'
              }
              return '<tr><td>' + key + '</td>'
            })
            return {
              new_html_table,
              sheetname:item,
              show:index == 0
            }
          })
          that.fileShow = 1
          that.excelHtml = that.excels[0].new_html_table
          that.$nextTick( ()=> {
            // DOM加载完毕后执行，解决HTMLConnection有内容但是length为0问题。
            that.setStyle4ExcelHtml();
          });
          };
          reader.onerror =function(err){
            console.log(err);
            this.$message({
              showClose: true,
              message: '文件读取失败，请联系管理员',
              type: 'error',
              duration:2000,
              customClass:'messageClass'
          })
          }
      }).catch(err=>{
        console.log(err);
        this.$message({
            showClose: true,
            message: '文件请求失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
      })
    },
    //2
    PreviewDocxFile(){
      let that = this;
      getBlobFile(this.filelink).then(dataBLob=>{
        this.fileShow = 2
        this.$nextTick(()=>{
          DOCX.renderAsync(dataBLob, this.$refs.file,null,{
            className: "docx", //class name/prefix for default and document style classes
            inWrapper: true, //enables rendering of wrapper around document content
            ignoreWidth: false, //disables rendering width of page
            ignoreHeight: false, //disables rendering height of page
            ignoreFonts: false, //disables fonts rendering
            breakPages:true, //enables page breaking on page breaks
            ignoreLastRenderedPageBreak:true, //disables page breaking on lastRenderedPageBreak elements
            experimental:false, //enables experimental features (tab stops calculation)
            trimXmlDeclaration: true, //if true, xml declaration will be removed from xml documents before parsing
            useBase64URL:false, //if true, images, fonts, etc. will be converted to base 64 URL, otherwise URL.createObjectURL is used
            useMathMLPolyfill: false, //@deprecated includes MathML polyfills for chrome, edge, etc.
            renderChanges: false, //enables experimental rendering of document changes (inserions/deletions)
            renderHeaders: true, //enables headers rendering
            renderFooters: true, //enables footers rendering
            renderFootnotes: true, //enables footnotes rendering
            renderEndnotes: true, //enables endnotes rendering
            debug: false, //enables additional logging
          });
        })
      }).catch(err=>{
        this.$message({
            showClose: true,
            message: '文件请求失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
      })
    }, 
    // 设置Excel转成HTML后的样式
    setStyle4ExcelHtml() {
      const excelViewDOM = document.getElementById("excelView");
      if (excelViewDOM) {
        const excelViewTDNodes = excelViewDOM.getElementsByTagName("td");
        if (excelViewTDNodes) {
          const excelViewTDArr = Array.prototype.slice.call(excelViewTDNodes);
          for (const i in excelViewTDArr) {
            const id = excelViewTDArr[i].id; // 默认生成的id格式为sjs-A1、sjs-A2......
            if (id) {
              const idNum = id.replace(/[^0-9]/gi, ""); // 提取id中的数字，即行号
              if (idNum && (idNum === "1" || idNum === 1)) {
                excelViewTDArr[i].classList.add("class4Title");
              }
            }
          }
        }
      }
    },
    handleClickExcel(sheetname){
      this.excels.forEach(excel=>{
        if (excel.sheetname == sheetname){
          this.excelHtml = excel.new_html_table
        } 
        excel.show = excel.sheetname == sheetname
      })
      this.$nextTick( ()=> {
        // DOM加载完毕后执行，解决HTMLConnection有内容但是length为0问题。
        this.setStyle4ExcelHtml();
      });
    },
    //3
    PreviewVueDocFile(type,code){
      let that = this
      getBlobFile(this.filelink).then(dataBLob=>{
        let blob = dataBLob;
        let reader = new FileReader();
        reader.readAsText(blob, 'utf8');
        reader.onload = function(event) {
          let data = event.target.result;
          that.fileShow = 3
          that.$nextTick(()=>{
            that.docType = type
            that.docValue = data
            that.docCode = code
          })
        }
        reader.onerror = function(err){
          console.log(err);
          this.$message({
            showClose: true,
            message: '文件读取失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
        }
      }).catch(err=>{
        this.$message({
            showClose: true,
            message: '文件请求失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
      })
    },
    // 4
    PreviewImgFile(url){
      this.fileShow = 4
      this.$nextTick(()=>{
        this.imgUrl = url
        this.srcList = [url]
      })
    },
    // 5
    PreviewVideoFile(url,suffix){
      if (!this.videoMimeTypes.has(suffix)) {
        return this.$message({
            showClose: true,
            message: '文件类型获取失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
      }
      this.fileShow = 5
      this.$nextTick(()=>{
        this.videoSrc = url
        this.videoType = this.videoMimeTypes.get(suffix)
        console.log(this.videoSrc,this.videoType);
      })
    },
    // 6
    PreviewAudioFile(url,suffix){
      if (!this.audioMimeTypes.has(suffix)) {
        return this.$messagee({
            showClose: true,
            message: '文件类型获取失败，请联系管理员',
            type: 'error',
            duration:2000,
            customClass:'messageClass'
        })
      }
      this.fileShow = 6
      this.$nextTick(()=>{
        this.audioSrc = url
        this.audioType = this.audioMimeTypes.get(suffix)
      })
    },
    async See_more(){
      try {
          this.loading = true;
          const pdfBytes = await fetch(`${this.filelink}?type=part&part=${this.filePage}&limit=${this.limitPage}`).then(res => res.arrayBuffer());
          const tempDoc = await PDFDocument.load(pdfBytes);
          this.copiedPages = await this.pdfDoc.copyPages(tempDoc, tempDoc.getPageIndices());
          this.copiedPages.forEach(page => this.pdfDoc.addPage(page));
            const mergedPdfBytes = await this.pdfDoc.save();
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            var url = URL.createObjectURL(blob);
            this.pageCount+= this.limitPage
            url = `${url}#page=${this.pageCount}&toolbar=1`
            this.filePage +=1
            if (!this.showIframe) {
              // console.log('加载第二个iframe',url);
              this.iframeFilelinks = url;
            }else{
              // console.log('加载第一个iframe',url);
              this.iframeFilelink = url;;
            }
            // this.iframeFilelinks = url; // 将合并后的PDF文件URL保存到data中  
            this.loading = false;      
      } catch (error) {
        this.loading = false;  
        console.log(error);
      }
      if (this.FlieTotal==this.filePage) {
        this.Next_section = false;
      }
    },
    handleIframeLoad2(){
      if (this.iframeFilelinks) {
        _.delay(()=>{
          if (this.$refs.pdfIframes) {
            this.$refs.pdfIframes.height="100%"
          }
          if (this.$refs.pdfIframe) {
            this.$refs.pdfIframe.height="0"
          }
          this.showIframe =true;
        },3000)        
      }
    },
    handleIframeLoad(){
      if (this.iframeFilelink) {
        _.delay(() =>{
          if (this.$refs.pdfIframes) {
            this.$refs.pdfIframes.height="0"
          }
          if (this.$refs.pdfIframe) {
            this.$refs.pdfIframe.height="100%"
          }
          this.showIframe =false;
        },3000)       
      }
    },
    updateProgress(progress){
      console.log('下载进度：', progress + '%');
    }
  },
};
</script>

<style lang="less" scoped>
//excel样式
.viewItemFile {
  width: 100%;
  height: 100%;
  .excelBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  /*  excel 的样式   */
  .excel-view-container{
    // background-color: red;
    overflow: auto !important;
    width: 100% !important;
    flex:1;
    /deep/ table {
      width: 100% !important;
      border-collapse: collapse !important;
      border-spacing: 0 !important;
      text-align: center !important;
      border: 0px !important;
    }
   
    /deep/ table tr  {
      td{
        border: 1px solid #000000 !important;
        // max-width: 150px !important;
        min-width: 70px !important;
        height: 50px !important;
        white-space:nowrap !important;
        line-height: 50px !important;
        font-size: 14px !important;
      } 
     
    }
    /**标题样式 */
    /deep/ .class4Title {
      font-size: 14px !important;
      font-weight: bold !important;
      padding: 2px !important;
    }
    /deep/tr:nth-child(2n-1) td{
        background-color:rgb(242,242,242) !important;
    }
  }
  @sheetHeight:60px;
  .occupyingBox{
    height: @sheetHeight;
  }
  .sheetNames{
    position: absolute;
    top: 0;
    width: 100%;
    height: @sheetHeight;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      // padding-right:10px;
      flex: 1;
      text-align: center;
      background-color: rgb(230,230,230);
      cursor: pointer;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
    p:first-child{
      border: 1px solid black;
    }
  }
  //图片的样式
  .image {
    width: 100%;
    height: 100%;
    background: #ccc;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    border: 1px solid #000000;
    .el-image{
      width: 100%;
    }
  }
  .imageFlexClass{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // 视频样式
  .videoBox{
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .audioBox{
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .errorBox{
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.excelfooter{
  background-color: #fff !important;
}
.more{
  padding: 14px 0;
  width: 100%;
  background: #606266;
  height: 56px;
  display: flex;
  justify-content: center;
   align-items:center;
  p{
    height: 28px;
    width: 160px;
    line-height: 28px;
    text-align: center;
    color: #bec2ca;
    justify-content:center;
    align-items:center
  }
  .Next_section{
    background: #909399;
    border-radius: 4px;
    cursor: pointer;
    // height: 28px;
    width: 160px;
    // line-height: 28px;
    text-align: center;
    color: #bec2ca;
    justify-content:center;
    align-items:center
  }
  /deep/ .el-loading-spinner{
    top: 65% !important;
  }
}
</style>
