<template>
  <div>
    <el-dialog
        :visible.sync="delDialogVisible"
        append-to-body
        width="20%">
        <span slot="title" style="font-size:14px;"> <i class="el-icon-warning" style="color:#f56c6c;font-size:16px;margin-right:7px;"></i>
        确定将选中文件从商标中<span v-if="reg_num">({{reg_num}})</span>删除？</span>
        <span><el-checkbox v-model="OdCheckedDel">彻底删除(不可恢复)</el-checkbox></span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="cancellationDel" size="mini" plain class="info_button">取 消</el-button>
        <el-button type="primary" @click="remove_od" size="mini" style="margin-left:7px;">确 定</el-button>
        </span>
    </el-dialog> 
  </div>
</template>

<script>
export default {
  name: 'RemoveodIndex',
  props: {
    reg_num:'',
  },
  data() {
    return {
      OdCheckedDel:false,
      delDialogVisible:true,
    };
  },

  mounted() {

  },
  methods: {
    remove_od(){
      this.$emit('remove_od',this.OdCheckedDel)
    },
    cancellationDel(){
      this.delDialogVisible = false;
      this.$message({
        type: "info",
        message: "已取消删除",
      });
    }, 
  },
  watch:{
    delDialogVisible(n){
        if (!n) {
            this.$emit('changeDelDialogVisible',n)
        }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-dialog__body{
  padding: 14px 20px;
}
::v-deep .el-dialog{
  min-width: 400px;
}
</style>