import { mapGetters,} from "vuex";
export default {
    methods:{
        handleJumpHelp(AnchorId){
            this.$router.push({
                name: "MyHelpDocs",
                query: {
                    AnchorId,
                },
            })
        },
    },
    computed:{
        ...mapGetters({
            getSubsectionTitle:'getSubsectionTitle',
        })
    }
}