<template>
    <el-drawer
    ref="refTMdrawer"
    title="引证商标分析"
    id="drawer"
    :visible.sync="refTMdetails_drawer"
    direction="btt">
        <div slot="title">
            <div class="drawer_title" >
            <p style="width:100px">引证商标分析</p>
            <p>
            <el-tooltip class="item" effect="dark" content="下载引证商标分析表" placement="bottom-end">
                <el-link :underline="false" @click="exportExcel" style="color:#C0C4CC;margin-right:20px;margin-bottom:2px">
                    <i class="el-icon-download" style="font-size:18px"></i>
                </el-link>
            </el-tooltip>
            </p>                
            </div>
        </div>
        <div class="element-main" v-if="getHeaders.length">
            <el-table
            :data="getValues"
            v-loading="loading"
            height="100%"
            class="trademarkTable min_table"
            border
            :width="tableWidth"
            ref="tableRef"
            id="tableId"
            :cell-style="selectedstyle"
            :cell-class-name="cellClassName"
            :span-method="arraySpanMethod"
            row-key="title"
            :key="tableKey"
            @cell-mouse-enter="mouseoverCell"
            @cell-mouse-leave="mouseoutHight"
            >
                    <!-- :fixed="item=='title'||item==0?true:false" -->
            <template  v-for="(item, index) in getHeaders">
            <el-table-column
                :key="index"
                :prop="item.index"
                :fixed="item=='title'||item.index==0?true:false"
                min-width="200px"
            >
                <template #header>
                    <span v-if="item.index==0">我方商标</span>
                    <span v-else-if="item !='title'">引证商标【{{item.index}}】
                        <!-- <span v-if="item.marked">
                            <el-popover
                                placement="top-start"
                                width="160"
                                trigger="hover">
                                <p>重点商标 <span style="color:#2ac3e9;margin-left:14px;cursor: pointer;" @click.stop="change_tm(item)">取消标记</span></p>
                                <span slot="reference" style="margin-left:14px"><img src="../assets/img/diamond1.png" alt=""></span>
                            </el-popover>        
                        </span>
                        <span v-else>
                            <el-popover
                                placement="top-start"
                                v-if="item.show"
                                width="80"
                                trigger="hover"
                                content="标记为重点商标">
                                <span slot="reference" @click.stop="change_tm(item)" 
                                style="margin-left:14px" >
                                <span slot="reference" v-if="item.show"><img src="../assets/img/diamond.png" alt="" ></span>
                                </span>
                            </el-popover>                    
                        </span> -->
                    </span>
            </template>
            <template slot-scope="scope" >
                <el-popover
                    placement="bottom"
                    v-if="scope.row.title=='商标图样'&&item!='title'"
                    width="200"
                    trigger="manual"
                    v-model="visible">
                <el-image v-if="scope.row.title=='商标图样'&&item!='title'" :src="get_img_url(ref_OD_info[item.index])" style="width:140px;height:200px;" v-model="visible"></el-image>
                            <div slot="reference" class="name-wrapper" @mouseover="visible_ImgShow" @mouseout="visible =false;">
                                <el-image  :src="get_img_url(ref_OD_info[item.index])"  style="height: 40px; width: 40px" ></el-image>
                            </div>
                </el-popover>
                <div v-else>
                    <p v-if="scope.row.goods && item=='title'">
                        {{scope.row[item]|filterOD_info(scope.row,item)}}
                        <span v-if="scope.row.status" style="color:#909399;">({{scope.row.status}})</span>
                        <span>
                            <span v-if="!scope.row.marked_brandName"  style="float:right;padding-right:14px">
                                <!-- <span v-if="scope.row.show" @click.stop> -->
                            <el-popover
                                placement="top-start"
                                v-if="scope.row.show"
                                ref="popoverRef"
                                width="280"
                                title="标记为品牌重点群组"
                                @show="group_all=false"
                                trigger="hover"
                                v-model="scope.row.visible">
                                <!-- @mouseleave="scope.row.show=false;goods_visible=false;" -->
                                <p  class="goods">
                                    <span style="display:flex;margin-top:8px">
                                        <el-radio v-model="group_all" :label="false">为该品牌标记</el-radio>
                                        <el-radio v-model="group_all" :label="true">为所有品牌标记</el-radio>   
                                    </span>
                                    <span style="margin-left:85px">
                                        <el-button size="mini" style="width:80px" @click.stop="scope.row.visible=false">取消</el-button>
                                        <el-button size="mini" style="margin-left:8px;width:80px" type="primary" @click.stop="change_goods(scope.row,scope)">标记</el-button>                                
                                    </span>
                                    <!-- <span style="color:#2ac3e9;cursor: pointer;" @click.stop="change_goods(scope.row,scope)">标记为重点群组</span> -->
                                    <!-- <el-checkbox v-model="group_all" style="margin-left:14px;" >所有品牌重点群组</el-checkbox> -->
                                </p>
                                <span slot="reference" 
                                style="cursor: pointer;"  v-if="scope.row.show" @mouseover="scope.row.visible=true">
                                <span ><img src="../assets/img/diamond.png" alt="" ></span>
                                </span>
                            </el-popover>
                            </span>
                            <span v-else  style="float:right;padding-right:14px">
                                <el-popover
                                placement="top-start"
                                width="280"
                                trigger="hover"
                                title="删除重点群组标记"
                                v-model="scope.row.visible"
                                v-if="scope.row.show"
                                >
                                <p  class="goods">
                                    <span style="margin-top:8px;display:flex;color:#909399">
                                        删除后仍可重新标记
                                    </span>
                                    <!-- <span style="color:#2ac3e9;margin-left:14px;cursor: pointer;" @click.stop="change_goods(scope.row,scope)">取消标记</span> -->
                                    <span style="margin-left:85px">
                                        <el-button size="mini" style="width:80px" @click.stop="scope.row.visible=false">取消</el-button>
                                        <el-button size="mini" style="margin-left:8px;width:80px" type="primary" @click.stop="change_goods(scope.row,scope)">删除</el-button>                                
                                    </span>                        
                                </p>
                                <span slot="reference" style="cursor: pointer;" @mouseover="scope.row.visible=true;"><img src="../assets/img/diamond1.png" alt=""></span>
                                </el-popover>
                                <span slot="reference" style="cursor: pointer;" @mouseover="scope.row.visible=true;" v-if="!scope.row.show"><img src="../assets/img/diamond1.png" alt=""></span>
                            </span>
                        </span>
                    </p>
                    <p v-else 
                    :style="item=='title'&&!scope.row.ref_goods?'color:#303133;font-weight: bold;':scope.row.title=='申请号'||scope.row.title=='商标名称'?'color:#29C5EE;font-weight: bold;':''">
                    <span v-if="scope.row.title=='申请号'||scope.row.title=='商标名称'" @click.stop="gotoTM(item._id)" style="cursor: pointer;">
                        {{scope.row[item.index?item.index:item]|filterOD_info(scope.row,item.index?item.index:item)}}
                            
                        <i class="el-icon-copy-document " 
                        @click.stop="copy_info(scope.row[item.index])" v-if="item!='title'&&scope.row[item.index] && scope.row.copyRight && cellIndex-1==item.index"></i>
                        <span v-if="item.marked&& scope.row.title=='商标名称'" style="float:right;padding-right:14px">
                            <el-popover
                                placement="top-start"
                                width="280"
                                trigger="hover"
                                title="删除重点引证商标标记"
                                v-if="item.show"
                                v-model="item.visible">
                                <!-- <p>重点商标 <span style="color:#2ac3e9;margin-left:14px;cursor: pointer;" @click.stop="change_tm(item)">取消标记</span></p> -->
                                <p  class="goods">
                                    <span style="margin-top:8px;display:flex;color:#909399">
                                        删除后仍可重新标记
                                    </span>

                                    <span style="margin-left:85px">
                                        <el-button size="mini" style="width:80px" @click.stop="item.visible=false">取消</el-button>
                                        <el-button size="mini" style="margin-left:8px;width:80px" type="primary" @click.stop="change_tm(item)">删除</el-button>                                
                                    </span>                        
                                </p>
                                <span slot="reference" style="margin-left:14px" @mouseover.stop="item.visible=true;"><img src="../assets/img/diamond1.png" alt=""></span>
                            </el-popover> 
                            <span slot="reference" style="margin-left:14px" @mouseover.stop="item.visible=true;" v-if="!item.show"><img src="../assets/img/diamond1.png" alt=""></span>
                        </span>
                        <span v-else style="float:right;padding-right:14px">
                            <el-popover
                                placement="top-start"
                                v-if="item.show && scope.row.title=='商标名称'"
                                width="280"
                                trigger="hover"
                                title="标记为重点引证商标"
                                v-model="item.visible">
                                <p  class="goods" >
                                    <span style="margin-left:85px;margin-top:45px">
                                        <el-button size="mini" style="width:80px" @click.stop="item.visible=false;item.show=false;">取消</el-button>
                                        <el-button size="mini" style="margin-left:8px;width:80px" type="primary" @click.stop="change_tm(item)">标记</el-button>                                
                                    </span>                            
                                </p>
                                <span slot="reference" @mouseover.stop="item.visible=true;" 
                                style="margin-left:14px" >
                                <span slot="reference" v-if="item.show && scope.row.title=='商标名称'"><img src="../assets/img/diamond.png" alt="" ></span>
                                </span>
                            </el-popover>                    
                        </span>
                        </span>
                    <span v-else >
                        <div v-if="scope.row[item.index?item.index:item]&&scope.row.status&&scope.row.goods&& item.index!='0'">
                            <template v-for="v in scope.row.ref_goodsData" >
                                <!-- {{v[item.index]}} -->
                                <div v-for="n in v[item.index]" :key="n._id">
                                    <span :style="n.status=='已驳回'||n.status=='已销亡'||n.status=='其他'||n.status=='删减'?'text-decoration:line-through;color:#909399':'color:#303133'">
                                        {{n.cloneName?n.cloneName:n.name}}
                                    <i class="el-icon-copy-document copyRight" v-if="item!='title'&&scope.row[item.index] && scope.row.copyRight && cellIndex-1==item.index"
                                        style="cursor: pointer;"
                                        @click.stop="copy_info(n.cloneName?n.cloneName:n.name)">
                                    </i>
                                    </span>
                                        
                                    <p style="font-size:12px;">
                                        <span> 【{{n.status?n.status:'无'}}】</span> 
                                        <span @click="patch_ref_goods(scope.row.ref_goods,item.index,item._id)" slot="reference" ><i class="el-icon-edit-outline" ></i></span>
                                    </p>
                                </div>
                                
                            </template>
                        </div>
                        <p v-else>
                            <span>{{scope.row[item.index?item.index:item]|filterOD_info(scope.row,item.index?item.index:item)}}</span>
                            <i class="el-icon-copy-document copyRight" v-if="item!='title'&&scope.row[item.index] && scope.row.copyRight && cellIndex-1==item.index"
                                style="cursor: pointer;"
                                @click.stop="copy_info(scope.row[item.index])">
                            </i>
                        </p>
                    </span>
                    <span></span>
                    </p>
                </div>
            </template>
            </el-table-column>
            </template>
            </el-table>
            <el-dialog
                title="修改状态"
                :closeOnClickModal="false"
                :closeOnPressEscape="false"
                :visible.sync="editStatusAllVisible"
                :modal="false"
                width="50%"
            >
                <el-table
                :data="selectGoodsData"
                :header-cell-style="{ background: '#f9f9f9', color: '#606266' }"
                border
                >
                <el-table-column
                    width="150"
                    label="状态"
                >
                    <template slot-scope="scope">
                    <el-select v-model="scope.row['status']" placeholder="请选择状态" size="mini">
                        <el-option
                        v-for="item in goodsStartList"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    label="群组"
                >
                    <template slot-scope="scope">
                    <span>
                        {{
                        scope.row.group
                            ? scope.row.group
                            : "" + "-" + scope.row.group_title
                            ? scope.row.group_title
                            : ""
                        }}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="商品/服务名称">
                    <template slot-scope="scope">
                    <span>
                        {{ scope.row.name + (scope.row.code ?`(${scope.row.code})`:'') }}
                    </span>
                    </template>
                </el-table-column>
                </el-table>
                <div
                slot="footer"
                class="dialog-footer"
                >
                <el-row>
                <el-col :span="18">
                    <div class="batch-area">
                    <span>批量修改以上状态：</span>
                    <el-select v-model="batchValue" placeholder="请选择状态" size="mini">
                        <el-option
                        v-for="item in goodsStartList"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-button @click="editStatusAllCal" size="mini">取 消</el-button>
                    <el-button
                        type="primary"
                        @click="editStatusAllSuc"
                        size="mini">
                        确 定
                    </el-button>
                </el-col>
                </el-row>
                </div>
            </el-dialog>
        </div>
    </el-drawer>
</template>

<script>
import { toYMDTime,copyreg_num} from "../utils/index";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { mapGetters } from "vuex";
import {
goods_groups,
get_goods_groups,
PATCHtrademark,
patch_goods_groups,
GetBrand,
} from "../api/axios";
export default {
    name: 'refTMdetails',
    props:{
        ref_OD_info:[],
    },
    data() {
        return {
            refTMdetails_drawer:true,
            headers: [
            {
                prop: 'class_name',
                label: '类别',
            },
            {
                prop: 'name',
                label: '商标名称',
            },
            {
                prop: 'reg_num',
                label: '申请号',
            },
            {
                prop: 'pic_url',
                label: '商标图样',
            },
            {
                prop: 'applicant',
                label: '申请人',
            },
            {
                prop: 'app_date',
                label: '申请日期',
            },
            {
                prop: 'first_annc_date',
                label: '初审公告日期',
            },
            {
                prop: 'reg_annc_date',
                label: '注册公告日期',
            },
            {
                prop: 'legal_status',
                label: '法律状态',
            },
            {
                prop: '',
                label: '类似群组',
            },
            {
                prop: '',
                label: '非类似群组',
            },
            ],
            refClass:'',
            highlightedRow:null,
            rowIndex:null,
            keys:[],
            ref_id:[],
            tableWidth:'100%',
            getHeaders:[],
            tableKey:0,
            visible:false,
            group_all:false,
            cellIndex:'',
            ref_highlightedRow:'',
            goodsStartList:[],
            goodsStart:'',
            editStatusAllVisible:false,
            selectGoodsData:[],
            batchValue:'',
            ref_tm_id:'',
            indexArr:[],
            unStatusArr:['已驳回','已销亡','其他','删减'],
            cellRowIndex:[],
            rowIndex:[],
            loading:true,
        };
    },
    mounted() {
        //查询引证商标
        // this.getRefTM();
        // 获取引证商标商品状态
        this.goodsStartList = this.get_config({catagory:"goods_status"});
        this.getHeaders = this.ref_OD_info.reduce((pre, cur, index) => pre.concat({index:`${index}`,marked:cur.marked,_id:cur._id,visible:false,}), ['title']);
        this.getrefTMData();
        this.handleWatchWindowResize();
        window.addEventListener("resize", this.handleWatchWindowResize);
        document.addEventListener("click",this.handleWatchDocumentClick);
    },
    activated(){
        this.handleWatchWindowResize();
        this.handleWatchDocumentClick();
        window.addEventListener("resize", this.handleWatchWindowResize);
        document.addEventListener("click",this.handleWatchDocumentClick);
    },
    beforeDestroy(){
        window.removeEventListener("resize", this.handleWatchWindowResize);
        document.removeEventListener("click",this.handleWatchDocumentClick);
    },
    deactivated(){
        window.removeEventListener("resize", this.handleWatchWindowResize);
        document.removeEventListener("click",this.handleWatchDocumentClick);
    },
    methods: {
        handleWatchWindowResize(){
            let drawer = document.getElementById('drawer'); 
            if (!drawer) return;
            if (this.ref_OD_info.length < 8) {
                var num =  (this.ref_OD_info.length+1)*200;
                num += 100;
                var margin  = (document.body.clientWidth-num)/2
                margin<60?margin = 60:margin
                drawer.style.marginLeft =  `${margin}px`
                drawer.style.marginRight  = `${margin}px`
                margin = (document.body.clientWidth-num)/2
                margin<60?margin = 60:margin
                drawer.style.marginLeft =  `${margin}px`
                drawer.style.marginRight  = `${margin}px`
            }else{
                drawer.style.marginLeft ="3%"
                drawer.style.marginRight ="3%"
            }
        },
        handleWatchDocumentClick(e){
            if (this.$refs.refTMdrawer) {
                this.$nextTick(() => {
                    if (e.target.className == "v-modal") {
                        this.refTMdetails_drawer = false;
                    }
                });
            }
        },
        async getrefTMData(){
            this.loading = true;
            this.getValues.forEach(item=>{
                if (item.ref_goodsData) {
                    delete item.ref_goodsData
                }
                if (item.ref_goods) {
                    delete item.ref_goods
                }
            })
        let goods = {};
        this.refClass = this.ref_OD_info[0].class;
        var {data} = await get_goods_groups({
            class:this.refClass
        })
        var codeData =  data;
         var tm_id = this.ref_OD_info[0]._id
        this.ref_OD_info[0].goods?.forEach((item) => {
           var group =  Object.keys(goods).find((item2)=>item2==item.group);
            if (item.group == group) {
                goods[item.group][0].name = `${goods[item.group][0].name},${item.name}`
            }else{
                goods[item.group] =[];
                var marked_brandName = false;
               var obj =  codeData.find(item2=>item2.code==item.group);
               if (_.includes(obj?.marked,this.ref_OD_info[0].brand_name)|| _.includes(obj?.marked,'all')) {
                    marked_brandName = true;
               }
                goods[item.group].push({
                    group:item.group,
                    name:item.name,
                    index:0,
                    status:item.status,
                    code:item.group,
                    group_title:item.group_title,
                    marked:obj?.marked,
                    marked_brandName:marked_brandName,
                    _id:tm_id,
                    brand_name:this.ref_OD_info[0]?.brand_name,
                    visible:false,
                })
            }
        });
        this.ref_id = _.map(this.ref_OD_info,'_id');
        this.ref_OD_info.forEach((item,index)=>{
            if (index!==0) {
                for (const value of item.goods) {
                    for(let key in goods){
                        if (value.group == key) {
                           var k = goods[key].find(v=>v.index ==index);
                            if (k) {
                                // k.name = `${k.name},${value.name}`
                                k.ref_goods.push(value);
                              var n =   k.ref_goodsData.find(v=>v.status ==value.status);
                                if (n) {
                                    n.cloneName = n.cloneName?`${n.cloneName},${value.name}`:`${n.name},${value.name}`
                                    n.cloneName = _.uniq(n.cloneName.split(',')).join(',');
                                }else{
                                    k.ref_goodsData.push(value)
                                }
                            }else{
                               var data =  _.cloneDeep(value);
                               data.cloneName = ''
                               goods[key].ref_goods = [];
                               goods[key].ref_goodsData = [];
                                goods[key].push({
                                    group:value.group,
                                    name:value.name,
                                    index,
                                    status:value.status,
                                    code:value.group,
                                    group_title:value.group_title,
                                    brand_name:item.brand_name,
                                    visible:false,
                                    ref_goods:[_.cloneDeep(data)],
                                    ref_goodsData:[_.cloneDeep(data)],
                                })
                            }
                        }
                    }
                }
                if (item.class!=this.ref_OD_info[0].class) {
                    this.groupedData(item.goods,index,goods);
                        //  双循环添加非类别相同的群组
                }
            }
        })
        this.ref_OD_info.forEach((item,index)=>{
            if (index!=0) {
                for (const value of item.goods) {  
                    var group =  Object.keys(goods);
                    if (_.includes(group,value.group)) {
                        // if (goods[value.group].length==1) {
                        // if (value.group=='1109') {
                        //  console.log(goods[value.group][0],item.reg_num);   
                        // }
                        //   goods[value.group][0].name = `${goods[value.group][0].name},${value.name}`
                        // }
                            var v1 =  goods[value.group].find(n=>n.index==index);
                            if (v1) {
                                v1.name = `${v1.name},${value.name}`
                            }else{
                                goods[value.group].push({
                                    group:value.group,
                                    name:value.name,
                                    index,
                                    status:value.status,
                                    code:value.group,
                                    group_title:value.group_title,
                                    brand_name:item.brand_name,
                                    visible:false,    
                                })
                            }
                    }else{
                        if (value.group) {
                         if (goods[value.group]) {
                         }else{
                            goods[value.group] = [];
                            goods[value.group].push({
                                group:value.group,
                                name:value.name,
                                index,
                                status:value.status,
                                code:value.group,
                                group_title:value.group_title,
                                brand_name:item.brand_name,
                                visible:false,    
                            })
                         }
                        }
                    }
                }
            }
        })
       var index =  this.getValues.findIndex((item)=>{
            return item.title == "类似群组"
        });
        // var ref_index = this.getValues.findIndex(n=> n.title=='非类似群组');
        //_.toPairs将对象转换为[key, value]数组，_.sortBy对该数组进行排序，然后_.fromPairs将其转换回对象。
        goods = _.fromPairs(_.sortBy(_.toPairs(goods)));
        //  如果数组数据大于1条为我方商标的类似群组否则为非类似群组;
          for(let key in goods){
            if (goods[key][0] && goods[key][0].index ==0) {
                index++
                var obj = {
                    title:key,
                    goods:true,
                    code:goods[key][0].code,
                    group_title:goods[key][0].group_title,
                    marked:goods[key][0].marked,
                    status:goods[key][0].status,
                    marked_brandName:goods[key][0].marked_brandName,
                    _id:goods[key][0]._id,
                    brand_name:goods[key][0].brand_name,
                    visible:false,
                    
                }
                var n = this.getValues.find(item=>item.code ==obj.code);
                if (!n) {
                    this.getValues.splice(index,0,obj);
                }
                goods[key].forEach(item => {
                    var n =  this.getValues.find(v=> v.title==item.group||v.title==item.tm_group);
                    if (n) {
                        n[item.index] = item.name;
                        if (n.ref_goods) {
                            n.ref_goods.push({
                                [item.index]:item.ref_goods
                            })
                            n.ref_goodsData.push({
                                [item.index]:item.ref_goodsData
                            })
                            return 
                        }else{
                            n.ref_goods =[];
                            n.ref_goodsData = [];
                        }               
                    }                    
                });
            }else{
                var obj = {
                    title:key,
                    ref_goods:true,
                    code:goods[key][0].code,
                    group_title:goods[key][0].group_title,
                    status:goods[key][0].status,
                    _id:goods[key][0]._id,
                    show:false,
                    marked_brandName:goods[key][0].marked_brandName,
                    brand_name:goods[key][0].brand_name,
                    visible:false,
                }
                var n1 = this.getValues.find(v=>v.code==obj.code);
                if (!n1) {
                    this.getValues.push(obj);   
                }
                goods[key].forEach(item=>{
                   var n =  this.getValues.find(v=> v.title==item.group);
                   if (n) {
                    n[item.index] = item.name;
                   }
                })       
            }
          }
          this.tableKey++;
          this.loading = false;
        },
        get_img_url(ref_tm){
            if (ref_tm) {
            const url = `/tmimage?$imgdata&reg_num=${_.get(ref_tm, [
                "reg_num",
            ])}`;
            return url;            
            }
        },
        selectedstyle(row){
            if (this.ref_highlightedRow && row.row.goods&& row.row[this.ref_highlightedRow] ||this.ref_highlightedRow && row.columnIndex==_.toNumber(this.ref_highlightedRow)+1) {
                    if (_.includes(this.indexArr,_.toNumber(this.ref_highlightedRow))) {
                        var n =''
                        if (row.row.ref_goodsData) {
                            for (const key of this.indexArr) {
                                const result = _.filter(row.row.ref_goodsData, obj => _.has(obj, key));
                                if (result[0]&&result[0][key]) {
                                    var data = result[0][key];
                                    var arr = ["待审中","已初审","已注册"]
                                    n =  data.find(item=>_.includes(arr,item.status));
                                }                                
                            }
                        }
                        if (n||row.columnIndex==_.toNumber(this.ref_highlightedRow)+1) {
                            return {
                                "background-color": "#fdf6ec",
                            }                                         
                        }                      
                    }
            }
            if (row.columnIndex) {
                row.row.right = true;
            }
            if (this.highlightedRow) {
                if (row.columnIndex&&this.keys.length) {
                var num =  this.keys.find(item=>item==row.columnIndex-1);
                    num = _.toNumber(num)
                if ( num+1 ==row.columnIndex && row.columnIndex!=1 && row.column.property!='title') {
                    if (_.includes(this.indexArr,row.columnIndex-1)) {
                        return {
                            "background-color": "#fdf6ec",
                        }                         
                    }  
                }
                }
                if (row.rowIndex==this.highlightedRow) {
                    return {
                    "background-color": "#fdf6ec",
                    };            
                }            
            }
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex } ){
            // if (row.title === '类似群组'||row.title === '非类似群组') {
            //   if (columnIndex === 0) {
            //     return [1, this.getHeaders.length];
            //   }
            // }
        },
        mouseoverCell(row, column, cell, event){ 
            if (row.goods && row[column.property] && column.property!=0) {
                this.ref_highlightedRow = column.property;
            }
            this.cellIndex = event.target?.cellIndex
            if (row.right) {
                row.copyRight = true;
            }
            var index = this.getValues.indexOf(row);
            this.keys = _.keys(this.getValues[index]);
            if (row.goods&&!column.property||row.goods && column.property==0) {
                row.show = true;
                this.highlightedRow = index            
            }else{
                // this.keys = [];
                this.highlightedRow = null;
                // row.show = false
            }
            if (row.title=='商标名称') {
                var obj = _.nth(this.getHeaders, event.target?.cellIndex);
                if (obj.index && obj.index!=0) {
                    obj.show = true;
                }
            }
                var nums = []
                  this.keys.forEach(item=>{
                    if (_.toNumber(item)) {
                        nums.push(_.toNumber(item))
                    }
                });
                this.indexArr = [];
                for (const key of nums) {
                   const result = _.filter(row.ref_goodsData, obj => _.has(obj, key));
                    if (result[0]&&result[0][key]) {
                        var data = result[0][key];
                        var arr = ["待审中","已初审","已注册"]
                        var n =  data.find(item=>_.includes(arr,item.status));
                        if (n) {
                            this.indexArr.push(key);
                        }
                    }
                }
                    // var indexArr = [];                    
        },
        mouseoutHight(row,column,cell, event){
            //el-popover     el-popper
            if (row.goods && row[column.property]&&column.property!=0) {
                this.ref_highlightedRow ='';
            }
            var class_name = (event.toElement?.classList[0]);
            // setTimeout(()=>{
            //     if (row.visible && class_name !="popper__arrow" &&class_name!='el-popover') {
            //         row.visible = false;
            //     }
            // },300)
            if (row.title=='商标名称') {
                var obj = _.nth(this.getHeaders, event.target?.cellIndex);
                if (obj.index && obj.index!=0 && class_name !='popper__arrow' && class_name !='el-popover'&& !obj.visible) {
                    obj.show = false;
                }
            }
            if (class_name !='popper__arrow' && class_name !='el-popover'&& !row.visible) {
                row.show = false;
            }
                this.keys = [];
                this.highlightedRow = null;
                // row.show = false; 
            if (row.right) {
                row.copyRight = false;
            }
        },
        visible_ImgShow(){
            this.visible = true;
        },
        async change_goods(row,scope){
            try {
                var {data} = await get_goods_groups({
                        code:row.code
                });
                if (!data[0]?._id) {
                    var obj ={
                        class:this.refClass,
                        code:row.code,                    
                    }
                    if (this.group_all) {
                        obj.marked =['all'];
                    }else{
                        obj.marked = [row.brand_name]
                    }
                    await goods_groups(obj);
                }else{
                    if (this.group_all) {
                        await patch_goods_groups(data[0]._id,{
                            marked:['all']
                        });                 
                    }else{
                        if (_.includes(data[0].marked,'all')) {
                        var {data:brand_data} =  await GetBrand({
                                $select: ['name'],
                        });
                        var brand_name = _.filter(brand_data, (item) => item.name !== row.brand_name);  
                        brand_name = _.map(brand_name,'name')
                        await patch_goods_groups(data[0]._id,{
                            marked:brand_name
                        })         
                        }else if(_.includes(data[0].marked,row.brand_name)){
                            var Newmarked = _.filter(data[0].marked,(item)=>item!==row.brand_name);
                            await patch_goods_groups(data[0]._id,{
                            marked:Newmarked
                            })
                        }else{
                            console.log(data[0].marked);
                            data[0].marked.push(row.brand_name);
                            await patch_goods_groups(data[0]._id,{
                            marked:data[0].marked
                            })                    
                        }
                    }
                }
                row.marked_brandName =!row.marked_brandName;
                var keys = _.keys(row);
                var ids = [];
                var obj  = {}
                obj.tm_id = this.ref_OD_info[0]._id;
                obj.ref_tm = [];
                this.ref_id.filter((item,index)=>{
                    keys.forEach(v=>{
                        if (index == v) {
                            this.getHeaders.find(value=>{
                                if (value._id==item) {
                                    if (!value.marked &&value.index!=0 && row.marked_brandName ) {
                                        obj.ref_tm.push({
                                        ref_tm_id:item,
                                            marked:true
                                        })
                                        value.marked =true
                                    }
                                }
                            })
                        ids.push(item) 
                        }
                    })
                })
                if (ids.length) {
                    ids.forEach(async (item,index)=>{
                        if (index!=0 && row.marked_brandName) {
                        var data =  await PATCHtrademark(item,{
                                marked:true
                            },{$extension: {skip_status_check: true}})
                        }
                        if (data) {
                        this.$emit('changerefTMData', data);   
                        }
                    })
                }
                this.$EventBus.$emit('changeRefTM_marked',obj);
                row.visible = false;
            } catch (error) {
                console.log(error);
            }
        },
        cellClassName({ row, column, rowIndex, columnIndex }) {
                if (columnIndex&&this.keys.length) {
                var num =  this.keys.find(item=>item==columnIndex-1);
                    num = _.toNumber(num)
                if (num+1 ==columnIndex) {
                        return 'bgClass'
                }
                }
        },
        async change_tm(row){
            try {
            var data =  await PATCHtrademark(row._id,{
                    marked:!row.marked
                },{$extension: {skip_status_check: true}})
            var index =  this.getHeaders.findIndex(item=>item._id==row._id);
                this.$set(this.getHeaders,index,row);
                this.$emit('changerefTMData', data);
                var obj = {}
                obj.tm_id = this.ref_OD_info[0]._id
                obj.ref_tm = [{
                    ref_tm_id:row._id,
                    marked:!row.marked
                }];
                this.$EventBus.$emit('changeRefTM_marked',obj)
                row.marked = !row.marked;
                row.visible = false;
            } catch (error) {
                console.log(error);
            }
        },
        //跳转商标详情页
        gotoTM(id){
        var row =  this.ref_OD_info.find(item=>item._id==id);
        if (row._id && row.is_case_main) {
                this.refTMdetails_drawer = false;
                this.$router.push({
                    name: "MyTMpage",
                    params: { id: row._id },
                    query: { title: `${row.name?row.name:''}(${row.reg_num}-${row.class})`,OurTM:row.applicant.id?true:false },
                });        
        }
        },
        groupedData(groupData,index,goods){
                    goods = _.fromPairs(_.sortBy(_.toPairs(goods)));
                    const groupedData = _.map(_.groupBy(groupData, 'group'), (group) => {
                        return {
                            code:group[0].group,
                            group: group[0].group,
                            name: `(${group[0].group})` + group.map(item => item.name).join(','),
                            index:index,
                            group_title:group[0].group_title,
                            status:group[0].status,
                        };
                    });
                    var keysGoods = Object.keys(goods)
                for (const key in goods) {
                var i =  keysGoods.findIndex(item=> item==key);
                groupedData.forEach((v,i2)=>{
                    if (i==i2) {
                        v.tm_group = goods[key][0].group
                        goods[key].push(v);
                    }
                })
                }
        },
        copy_info(row){
            copyreg_num(row);
        },
        //下载表格
        async workbookData(urls,sheetFilter,sheetData,workName){
            try {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('引证商标分析表');
                // 设置表头
                worksheet.columns = sheetFilter
                var headersData =  _.map(sheetFilter,'headers')
                sheetData.unshift(headersData)
                worksheet.addRows(sheetData);
                var imageId = null
                var index = 0
                for(let item of urls){
                    index++
                    imageId = workbook.addImage({
                        base64: item ,
                        extension: 'png',
                    });
                    
                    worksheet.addImage(imageId, {
                        tl: { col: index+0.03, row: 4.1 },
                        ext: { width: 60, height: 35 },
                        editAs: 'oneCell'
                    });            
                }
                // worksheet.properties.defaultRowHeight = 30;
                worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
                    if (rowNumber<=11||row.values[1]=='非类似群组') {
                        worksheet.getRow(rowNumber).height = 25;
                    }
                    row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
                        if (cell.value.match(/<s>.*?<\/s>/g)) {
                            const resultArray = cell.value.split('\n')
                            // 创建一个空的 richText 数组
                            const richTextArray = [];

                            // 循环遍历文本数组并将每个文本添加到 richText 数组
                            for (let text of resultArray) {
                                if (text.match(/<s>.*?<\/s>/g)) {
                                    const resultString = text.replace(/<\/?s>/g, '');
                                 richTextArray.push({ text: resultString,font: { strike: true,color: { argb: '909399' } }});
                                 richTextArray.push({text: "\n"})
                                }else{
                                richTextArray.push({ text: text});
                                richTextArray.push({text: "\n"})
                                }
                            }
                            cell.value = { richText: richTextArray };
                        }
                        cell.alignment = { vertical: 'middle', horizontal: 'left',wrapText: true};
                        //设置单元格边框颜色
                        cell.border = {
                            top: {style:'thin', color: {argb:'C0C4CC'}}, 
                            left: {style:'thin', color: {argb:'C0C4CC'}}, 
                            bottom: {style:'thin', color: {argb:'C0C4CC'}}, 
                            right: {style:'thin', color: {argb:'C0C4CC'}} 
                        };
                    });
                });
                //前两列固定
                if (worksheet.getRow(5)) {
                worksheet.getRow(5).height = 35;   
                }
                worksheet.views = [
                    { state: 'frozen', xSplit: 2, ySplit: 1, topLeftCell: 'C2' }
                ];
                //第一行和第一列字体加粗
                worksheet.getRow(1).eachCell({ includeEmpty: true }, function(cell) {
                    
                    cell.font = { bold: true }; // 设置第一列的字体为加粗
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DCDFE6' } // 
                    };
                        cell.border = {
                            top: {style:'thin', color: {argb:'C0C4CC'}}, 
                            left: {style:'thin', color: {argb:'C0C4CC'}}, 
                            bottom: {style:'thin', color: {argb:'C0C4CC'}}, 
                            right: {style:'thin', color: {argb:'C0C4CC'}} 
                        };
                });
                worksheet.getColumn(1).eachCell({ includeEmpty: true }, function(cell) {
                    cell.font = { bold: true }; // 设置第一列的字体为加粗
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: cell.value=='类似群组'||cell.value=='非类似群组'?{}:{ argb: 'DCDFE6' } // 
                    };
                });
                var base64Url = await this.convertImageToBase64(require('@/assets//img/diamond1.png'));
                    var imageId = workbook.addImage({
                        base64: base64Url ,
                        extension: 'png',
                    });
                    if (this.cellRowIndex.length) {
                        this.cellRowIndex.forEach(item=>{
                            worksheet.addImage(imageId, {
                                tl: { col: _.toNumber(item)+1.999, row: 2.6 },
                                 ext: { width: 14, height: 14 },
                                editAs: 'oneCell',
                            });                        
                        })                        
                    }
                    // if (this.rowIndex.length) {
                    //     this.rowIndex.forEach(item=>{
                    //         worksheet.addImage(imageId, {
                    //             tl: { col: 0.9, row: item +1.5 },
                    //             ext: { width: 14, height: 14 },
                    //             editAs: 'oneCell'
                    //         });                            
                    //     })                        
                    // }
                workbook.xlsx?.writeBuffer().then((data => {
                    const blob = new Blob([data], {type: 'xlsx'});
                    saveAs(blob, workName);
                }))        
            } catch (error) {
                console.log(error);
            }
        },
        async exportExcel(){
            try {
                this.$notify({
                    title: '正在导出',
                    message: '导出引证商标对比表已经开始下载,请在右上角浏览器菜单中查看下载进度.',
                    type: 'success'
                });
                var urls = []
                var getValues = _.cloneDeep(this.getValues);
                var rowIndex = getValues.filter((item,index)=>{
                    if (item.marked_brandName) {
                        item.index = index
                        return item.marked_brandName
                    }
                });
                this.rowIndex = rowIndex.map(item=>item.index);
                var cellRowIndex = this.getHeaders.filter(item=>item.marked);
                this.cellRowIndex = cellRowIndex.map(item=>item.index)
                for(let item of getValues){
                    if (item.title=='申请人') {
                        for(let key in item){
                            if (item[key].applicant_cn) {
                                item[key] = item[key].applicant_cn
                            }
                        }
                    }else if(item.title=="初审公告日期"||item.title=="注册公告日期"|| item.title=="申请日期"){
                        for(let key in item){
                            if (key!='title' && key!='right') {
                                item[key] =  toYMDTime(item[key],'YYYY年MM月DD日')
                            }
                        }                
                    }else if(item.title=='商标图样'){
                        for(let key in item){
                            if (key!='title' && key!='right') {
                            var   url =   await this.get_img_url(this.ref_OD_info[key]);
                            if (url) {
                            var a =  await this.convertImageToBase64(url);
                            urls.push(a);
                            item[key] = ''                                
                            }
                            }                    
                        }
                    }
                }
                var sheetFilter = [];
                this.getHeaders.forEach((item)=>{
                    if(item=='title'){
                        sheetFilter.push({
                            headers:'',
                            width:25
                        })
                    }else if (item.index==0) {
                        sheetFilter.push({
                            headers:'我方商标',
                            width:25
                        })
                    }else if(item.index){
                        sheetFilter.push({
                            headers:`引证商标【${item.index}】`,
                            width:25
                        })
                    }
                })
                var sheetData=[];
                getValues.forEach((item,index)=>{
                    if (!sheetData[index]) {
                        sheetData[index] = [];
                    }
                    if (item.title) {
                        sheetData[index].push({
                            title:item.goods?`${item.title}(${item.status})`:item.title,
                    });
                    for(let key in item){
                        if (_.toNumber(key)||key==0) {                           
                            if (item.ref_goodsData&&key!=0) {
                                const result = _.filter(item.ref_goodsData, obj => _.has(obj, key));
                                if (result[0]) {
                                var data = result[0][key];
                                // var name = _.map(data, item => `${item.cloneName?item.cloneName:item.name}\n [${item.status}]`);
                                var name = _.map(data,item=>{
                                    if (_.includes(this.unStatusArr,item.status)) {
                                        return `<s>${item.cloneName?item.cloneName:item.name}</s>\n [${item.status?item.status:'无'}]`;
                                    }else{
                                        return `${item.cloneName?item.cloneName:item.name}\n [${item.status?item.status:'无'}]`
                                    }
                                })
                                sheetData[index].push({
                                    index:_.toNumber(key),
                                    title:name.join("\n")
                                });                                    
                                }
                            }else{
                            sheetData[index].push({
                                index:_.toNumber(key),
                                title:item[key]
                            });
                            }
                        }
                    }
                    }
                })

                var sheetData2 = []; 
                for(const item of sheetData){
                    if (item.length!=this.getHeaders.length) {
                        for (var i = 1; i <= this.getHeaders.length-1; i++) {
                            item.forEach(value=>{
                                if (value.index!=i-1) {
                                var a =  item.find(v=>v.index==i-1);
                                if (!a) {
                                    item.push({
                                        index:i-1,
                                        title:''
                                    })                      
                                }
                                }
                            })
                        }                        
                    }
                    var n = _.cloneDeep(item[0]);
                    item.splice(0,1)
                    var a =  _.orderBy(item, ['index'], ['asc']);
                    var arr =  _.map(a,'title');
                    arr.unshift(n.title);
                    sheetData2.push(arr)
                }
                var tmData = this.ref_OD_info[0];
                var name  = tmData.tm_alias? tmData.tm_alias:tmData.name
                var workName = ''
                var date = toYMDTime(new Date());
                workName = `${name}_${tmData.reg_num}_${tmData.class}_驳回引证商标分析_${date}.xlsx`
                this.workbookData(urls,sheetFilter,sheetData2,workName);                
            } catch (error) {
                console.log(error);
            }
        },
        async convertImageToBase64(imageUrl) {
            if (imageUrl) {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.onload = () => {
                    let canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                    };
                    img.src = imageUrl;
                });                
            }
        },
        patch_ref_goods(data,index,id){
            this.batchValue = '';
            this.ref_tm_id =id;
            var object = data.find(item=> item[index]);
            for (const key in object) {
                if (key ==index) {
                    this.selectGoodsData = _.cloneDeep(object[key])
                }
            }
            this.editStatusAllVisible = true;
        },
        cancellation_ref_goods(row){
           this.$refs[row][0].doClose();
        },
        async editStatusAllSuc() {
            var ref_data = this.ref_OD_info.find(item=>item._id==this.ref_tm_id)
            if (this.batchValue) {  //批量修改
                let batchValue = this.batchValue;
                let ids =  this.selectGoodsData.map(item=>item._id)
                let goodsCopy = _.cloneDeep(ref_data.goods)
                ids.forEach(item=>{
                goodsCopy.forEach(item2=>{
                    if (item == item2._id) {
                    item2.status = batchValue
                    }
                })
                })
                ref_data.goods = goodsCopy
                var data =  await PATCHtrademark(ref_data._id,{
                    goods:ref_data.goods
                },{$extension: {skip_status_check: true}})
                this.$emit('changerefTMData', data);
                this.getrefTMData();         
            } else {
                let goodsCopy = _.cloneDeep(ref_data.goods)
                let key = 0
                this.selectGoodsData.forEach(item=>{
                goodsCopy.forEach(item2=>{
                    if (item2._id == item._id && item2.status != item.status) {
                        item2.status = item.status
                        key++
                    }
                })
                })
                if (key) {
                    ref_data.goods = goodsCopy
                    var datas =  await PATCHtrademark(ref_data._id,{
                        goods:ref_data.goods
                    },{$extension: {skip_status_check: true}})  
                    this.$emit('changerefTMData', datas);
                    this.getrefTMData(); 
                }
            }
            this.editStatusAllVisible = false;
        },
        editStatusAllCal(){
            this.editStatusAllVisible = false;
        },
    },
    computed: {
        getValues() {
            return this.headers.map(item => {
            return this.ref_OD_info.reduce((pre, cur, index) => Object.assign(pre, {[index]: cur[item.prop]}), {'title': item.label,});
            });
        },
        ...mapGetters({
            get_config:"get_config",
        }),
    },
    watch:{
       refTMdetails_drawer(n){
        if (!n) {
            this.$emit('changerefTMdetails', n);
        }
       },
       getHeaders(data){
        // this.$refs.tableRef.doLayout();
        this.tableKey++;
       },
    },
    filters:{
        filterOD_info(v,row,a){
            switch(row.title){
                case "申请日期":
                    if (v!='申请日期') {
                     return toYMDTime(v,'YYYY年MM月DD日')
                    }else{
                        return v

                    }
                case "初审公告日期":
                    if (v!='初审公告日期') {
                     return toYMDTime(v,'YYYY年MM月DD日')
                    }else{
                        return v

                    }
                case "注册公告日期":
                    if (v!='注册公告日期') {
                     return toYMDTime(v,'YYYY年MM月DD日')
                    }else{
                        return v

                    }
                case "申请人":
                    if (v && v !='申请人') {
                        return v.applicant_cn
                    }
            }
            return v
        },  
    }
};
</script>

<style lang="less" scoped>
 .el-drawer__wrapper{
    top:72px !important;
    bottom:72px !important;
 }
::v-deep .el-drawer{
    max-width: 100% !important;
    height: 100% !important;
    // margin: 0 3%;
    padding: 10px 8px !important;
    .el-drawer__body{
        padding: 10px 20px !important;
    }
    .el-drawer__header{
       padding: 0 20px !important;
    }
}
.element-main{
    height: 100%;
}
::v-deep .el-table th.el-table__cell{
    background:#f9f9f9 !important;
}
::v-deep .el-drawer__header > :first-child{
    // margin-top: 20px;
    // margin-left: 20px;
    color: #010100;
    font-size: 16px;
    font-weight:bold;
}
.bgClass{
    background: red;
}
::v-deep .el-checkbox__label{
color: #909399;
font-size: 12px;
}
.el-icon-copy-document:hover{
    color: #303133;
}
.el-icon-copy-document{
    color: #C0C4CC;
}
.el-icon-edit-outline:hover{
    color: #303133;
}
.el-icon-edit-outline{
    color: #C0C4CC;
    cursor: pointer;
}
.goods{
    height:80px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:100%;
    align-items:flex-start;
}
.drawer_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
::v-deep .el-link--inner:hover{
    color: rgb(41, 197, 238);
}
::v-deep .el-table__fixed-body-wrapper{
  height: calc(100% - 57px) !important;
}
::v-deep .el-dialog__footer{
    padding: 20px 28px !important;

}
::v-deep  .el-table__fixed::before{
  height: 0 !important;
}
::v-deep.el-icon-close:hover{
    color: rgb(41, 197, 238);
}
</style>