<template>
  <div>
    <el-container class="MaxContainerBox">
      <el-header>
        <Header />
      </el-header>
      <el-container class="bottomContainer">
        <el-aside :class="!getisCollapse ? 'collapse-slide' : ''">
          <Aside />
        </el-aside>
        <el-main>
          <Main />
        </el-main>
      </el-container>
      
    </el-container>
  </div>
</template>

<script>
import Header from "@/components/layout/header.vue";
import Aside from "@/components/layout/aside.vue";
import Main from "@/components/layout/main.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
    Aside,
    Main,
  },
  data() {
    return {};
  },
  mounted() {
    this.filterMenu();
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  methods: {
    //过滤菜单
    async filterMenu() {
      this.goPage({
        name: this.$route.name,
        title: this.$route.meta.title,
        path: this.$route.path,
      });
      this.pathChange();
    },
    //添加页面并跳转路由
    goPage(val) {
      console.log(val);
      if (this.$route.path !== val.path) {
        this.$router.push(val.path);
      }
    },
    pathChange() {
      if (this.$route.path.indexOf("/home/tmpage") != -1) {
        this.headerFixed = true;
      } else {
        this.headerFixed = false;
      }
    },
    checkScreenWidth() {
      this.$store.commit("changeScreenWidth", document.body.clientWidth);
      this.$store.commit("changeScreenHeight", document.body.clientHeight);
      let isCollapse = document.body.clientWidth < 1200;
      this.$store.commit("saveisCollapse", isCollapse);
    },
  },
  computed: {
    ...mapGetters([
      "all_alerts",
      "unread_alerts",
      "enabled_menus2",
      "getisCollapse",
      "save_passwordCollapse",
    ]),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
.MaxContainerBox {
  height: 100vh;
  overflow: hidden;
  .el-header {
    height: @titleBarHeight !important;
    display: flex;
    background-color: @color-primary;
    padding: 0;
  }
  //   .is-vertical {
  //     width: calc(100vw - 280px);
  //   }
  //   .el-carousel__item {
  //     background-color: transparent;
  //   }
  .bottomContainer {
    overflow: auto;
  }
  .el-aside {
    width: @asideMinWidth !important;
    display: flex;
    height: 100%;
    flex-direction: column;
    &::-webkit-scrollbar {
    display: none;
  }
  }
  .collapse-slide {
    width: @asideMaxWidth !important;
  }
  .el-main {
    padding: 28px;
    background: #f0f2f5;
    .main-content {
      height: 100%;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      min-height: calc(100%);
      background: #fff;
      border-radius: 8px;
      // padding: 28px;
      .main-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>