<template>
  <el-pagination
        background
        :total="total"
        :page-size.sync="pageSizeComputed"
        :page-sizes="pageSizes"
        :current-page.sync="currentPageComputed"
        :disabled="disabled"
        :hide-on-single-page="hideOnSinglePage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :small="$store.getters.getPaginationSize"
        :pager-count="$store.getters.getPagerCount"
        :layout="$store.getters.getPaginationLayout"
      >
    </el-pagination>
</template>

<script>
export default {
    name:'pagination',
    data(){
        return{

        }
    },
    props:{
        total:{
            required:true,
            type:Number,
            default:0
        },
        pageSize:{
            type:Number,
            default:10
        },
        pageSizes:{
            type:Array,
            default(){
                return [10, 20, 30, 50, 100]
            }
        },
        currentPage:{
            type:Number,
            default:1
        },
        disabled:{
            type:Boolean,
            default:false
        },
        hideOnSinglePage:{
            type:Boolean,
            default:false
        },
    },
    computed:{
        currentPageComputed:{
            get(){
                return this.currentPage
            },
            set(val){
                this.$emit('updatePage',val)
            }
        },
        pageSizeComputed:{
            get(){
                return this.pageSize
            },
            set(val){
                this.$emit('updateSize',val)
            }
        }
    },
    watch:{
        total(newVal){
            if (this.total <= 0 || this.currentPageComputed <= 1) return;
            
            let newCurrentPage = this.currentPageComputed -1;
            let isPut = this.total <= newCurrentPage * this.pageSizeComputed;
            if (!isPut) return;

            console.log('分页器的页数我帮忙执行了-1');
            this.handleCurrentChange(newCurrentPage)
        },
    },
    methods:{
        handleSizeChange(val){
            this.$emit('changSize',val)
        },
        handleCurrentChange(val){
            this.$emit('changeCurrent',val)
        }
    }
}
</script>

<style>

</style>