<template>
  <div>
    <div class="task_upload_top">
        <div style="margin-top:14px">
            <div >
                <p style="color:#303133" v-if="uploadFileData.fileTotal!=uploadFileData.completedTotal && uploadFileData.downloadState!='canceled'">
                    <i class="el-icon-loading" style="color:#29C5EE;margin-right:7px;"></i>
                    正在上传
                </p>
                <p v-if="uploadFileData.downloadState=='canceled'&&uploadFileData.fileTotal!=uploadFileData.completedTotal" style="color:#303133">
                    <i class="el-icon-circle-check" style="color:#E6A23C;margin-right:7px;"></i>
                    部分上传
                </p>
                <p v-else-if="uploadFileData.fileTotal==uploadFileData.completedTotal" style="color:#303133">
                    <i class="el-icon-circle-check" style="color:#29C5EE;margin-right:7px;"></i>
                    完成上传
                </p>
            </div>
            <div  style="flex-direction:column">
                <p class="info_color">任务开始时间：{{uploadFileData.time}}</p>
            </div>
        </div>
        <div style="margin-top:14px;" >
            <el-button-group >
                <span >
                    文件总数量：{{uploadFileData.fileTotal?uploadFileData.fileTotal:0}}
                </span>
                <span style="margin-right:14px;">
                    ，已上传：{{uploadFileData.completedTotal?uploadFileData.completedTotal:0}}
                </span>
                <el-button type="text" @click="suspendUpload" :disabled="uploadFileData.completedTotal==uploadFileData.fileTotal || uploadFileData.downloadState=='canceled'">
                    <span v-if="uploadFileData.completedTotal!=uploadFileData.fileTotal">
                        {{uploadFileData.downloadState=='canceled'?'任务已取消':'取消任务'}}
                    </span>                    
                    <span v-else>
                        任务已完成
                    </span>
                </el-button>
            </el-button-group>
            <p class="info_color">
                <span>文件容量：{{uploadFileData.size|filterSize}}</span>
                <span style="margin:0 7px">|</span>
                <span>上传速度：{{uploadFileData.total_speed|filterSize}} <span v-if="uploadFileData.total_speed">/s</span></span>
            </p>
        </div> 
         <div style="width:100%;margin-top:14px;display:flex;align-items:center">
            <el-progress :stroke-width="8" :show-text ="false" :percentage="percentage" color="#65b1ff" style="flex:1;"></el-progress>
            <span style="margin-left:7px">已完成：{{percentage}}%</span> 
        </div>
    </div>
      <div class="task_import_main" >
        <VirtualScroll
        :data="fileList"
        :height="42"
        key-prop="id"
        @change="handleUpdateTable"> 
        <el-table
            :data="tableArr"
            stripe
            border
            height="calc(100vh - 315px)"
            row-key="id"
            :expand-row-keys="expandedRowKeys"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            :cell-class-name="tableCellClassName"
            default-expand-all
            style="width: 100%">
            <el-table-column
            prop="fileName"
            show-overflow-tooltip
            width="600"
            label="档案名称">
            <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.children?.length && scope.row.FSuffix!='zip'" style="margin-right:10px;cursor: pointer;">
                      <i class="el-icon-arrow-right" v-show="!scope.row.isExpand" @click="handleChangeRowExpand(scope.row,true)"></i>
                      <i class="el-icon-arrow-down" v-show="scope.row.isExpand" @click="handleChangeRowExpand(scope.row,false)"></i>
                  </span>
                </div>
                <span :style="scope.row.isDel?'text-decoration:none;color:#d1d5dd':''">
                    <img :src="scope.row.iconUrl" alt="" style="width:16px;height:16px;margin-top:-3px">
                    {{scope.row.fileName}}
                </span>
            </template>                
            </el-table-column>
            <el-table-column
            prop="status"
            width="300"
            :key="fileStatusKey"
            label="上传状态">
            <template slot-scope="scope">
                <div v-if="scope.row.isFile">
                    <span v-if="scope.row.file">
                        {{scope.row.file.status|filterStatus}}
                    </span>
                    <div v-else style="display:flex;width:100%;align-items: center;">
                        <span v-if="scope.row.percentCompleted && scope.row.percentCompleted==100">
                            上传成功
                        </span>
                        <span v-else-if="scope.row.percentCompleted&&!uploadFileData.suspendTaskDisabled">
                            上传中
                        </span>
                        <span v-else>
                            <span v-if="uploadFileData.suspendTaskDisabled">
                                已取消
                            </span>
                            <span v-else>
                                {{scope.row.fileStatus}}
                            </span>
                        </span>
                        <el-progress :percentage="scope.row.percentCompleted" v-if="scope.row.percentCompleted && !uploadFileData.suspendTaskDisabled" color="#65b1ff" style="width:150px;margin-left:10px">
                        </el-progress>
                        <span style="margin-left:7px;" v-if="!uploadFileData.suspendTaskDisabled">
                            {{scope.row.speed|filterSize}}
                        </span>
                    </div>
                </div>
            </template>
            </el-table-column>
            <el-table-column
            prop="reg_num"
            label="商标号">
            <template slot-scope="scope">
                <span v-if="scope.row.file">
                    {{scope.row.file.reg_num?scope.row.file.reg_num:scope.row.reg_num}}
                </span>
            </template>            
            </el-table-column>
            <el-table-column
            prop="type"
            label="官文类型">
            <template slot-scope="scope">
                <span v-if="scope.row.file">
                    {{scope.row.file.type}}
                </span>
            </template>              
            </el-table-column>
            <el-table-column
            prop="receive_date_str"
            label="收文日期">
            <template slot-scope="scope">
                <span v-if="scope.row.file">
                    {{scope.row.file.receive_date_str}}
                </span>
            </template>
            </el-table-column>
            <el-table-column
            prop="time_limit_str"
            label="官文期限">
            <template slot-scope="scope">
                <span v-if="scope.row.file">
                    {{scope.row.file.time_limit_str}}
                </span>
            </template>
            </el-table-column>
            <el-table-column
            prop="modeOfService"
            label="送达方式">
            <template slot-scope="scope">
                <span v-if="scope.row.file?.modeOfService" style="margin-right:14px;">
                    {{scope.row.file.modeOfService}}
                </span>
                <span @click="openOdDrawer(scope.row.file)" v-if="scope.row.file&&!scope.row.isDel" style="cursor: pointer;">
                    查看
                </span>
                 <span @click="delOd(scope.row.file,scope.row.id,scope.row.tm_id)" v-if="scope.row.file&&!scope.row.isDel" style="cursor: pointer;margin-left:14px;">
                    删除
                </span>               
            </template>
            </el-table-column>
        </el-table>
        </VirtualScroll> 
    </div>
    <Official-Details
      class="od"
      v-if="OfficialDetailsOpen"
      :OfficialDetailData="OfficialDetailData"
      :OfficialExplain="OfficialExplain"
      :tmatchExplain="tmatchExplain"
      :OfficialId="uploadFileData.OfficialId"
      :doc_id="doc_id"
      :details="details"
      @changeDrawerState="changeDrawerState"
      @changeOfficialForm="changeOfficialForm"
      @closeUpload="handleClose"
    ></Official-Details>
      <removerod v-if="delDialogVisible"
        @changeDelDialogVisible="changeDelDialogVisible"
        @remove_od="remove_od"
        :reg_num="reg_num">
      </removerod>
  </div>
</template>

<script>
import OfficialDetails from "@/components/officialdetails.vue";
import removerod from "../components/removeod.vue"
import { mapGetters,mapMutations } from "vuex";
import {get_size_str} from "@/utils/index";
import { trademarkEdit } from "@/utils/data";
import {GetFile,delofficial,patchcases} from '@/api/axios'
export default {
  components: {
    OfficialDetails,
    removerod,
  },
  data() {
    return {
      percentage:0,
      fileList:[],
    // 查看文件所需字段
    OfficialDetailsOpen:false,
    doc_id:'',
    OfficialDetailData:{},
    OfficialExplain:new trademarkEdit().OfficialExplain,
    tmatchExplain:new trademarkEdit().tmatchExplain,
    OfficialId:[],
    details:true,
    fileStatusKey:100,
    delDialogVisible:false,
    reg_num:'',
    delDoc_id:'',
    file_id:'',
    tableArr:[],
    expandedRowKeys:[],
    delTM_id:'',
    };
  },
  computed:{
    ...mapGetters({
        uploadFileData:'getUploadFileData'
    }),
  },
  mounted() {
    if (this.uploadFileData?.fileTotal) {
        var num = (this.uploadFileData?.completedTotal/this.uploadFileData.fileTotal)*100;
        this.percentage = Math.ceil(num);  
    }else{
        this.percentage = 0;    
    }
    if (this.uploadFileData?.expandedRowKeys.length) {
        this.expandedRowKeys = this.uploadFileData.expandedRowKeys;
    }
    this.filterFileList();
  },

  methods: {
    ...mapMutations(["saveUploadState"]),
    filterFileList() {
    // 校验 uploadFileData 和 uploadFileData.rowFileList 是否存在
    if (!this.uploadFileData || !Array.isArray(this.uploadFileData.rowFileList)) {
        console.error('uploadFileData or uploadFileData.rowFileList is not valid.');
        return;
    }

    const data = this.uploadFileData.rowFileList.filter(item => item.RowSelect);
    const rootId = null;
    const dataKey = 'id';
    const parentKey = 'parentId';
    const tree = [];
    const childrenMap = new Map(); // 使用 Map 以提高查找效率

    for (const datum of data) {
        const item = { ...datum };
        const id = item[dataKey];
        const parentId = item[parentKey];

        if (Array.isArray(item.children)) {
        childrenMap.set(id, item.children.concat(childrenMap.get(id) || []));
        } else if (!childrenMap.has(id)) {
        childrenMap.set(id, []);
        }
        item.children = childrenMap.get(id);
        if (parentId !== undefined && parentId !== rootId) {
        if (!childrenMap.has(parentId)) childrenMap.set(parentId, []);
        childrenMap.get(parentId).push(item);
        } else {
        tree.push(item);
        }
    }

    this.fileList = tree;

    // 处理 expandedRowKeys
    this.expandedRowKeys.forEach(v => {
        // 使用 Map 加速查找
        const index = this.uploadFileData.rowFileList.findIndex(n => n.id === v);
        if (index !== -1) {
        this.$set(this.uploadFileData.rowFileList[index], 'isExpand', true);
        this.handleChangeRowExpand(this.uploadFileData.rowFileList[index], true);
        } else {
        console.warn(`Expanded row key "${v}" not found.`);
        }
    });
    },
    //取消上传
    suspendUpload(){
        if (this.uploadFileData.completedTotal==this.uploadFileData.fileTotal) return
        this.saveUploadState({status:'suspend'});
        this.$emit('Setuploadstatus',this.uploadFileData.new_id,'suspend');
        this.fileStatusKey++;
    },
    async openOdDrawer(row){
        this.doc_id = row._id
        let data = await GetFile({id:row._id})
        if (data.trademark&&data.trademark.applicant == null) {
            data.trademark.applicant = {
                applicant_cn:''
            };
        }
        this.OfficialDetailData = _.cloneDeep(data)
        this.OfficialDetailsOpen = true
    },
    delOd(row,id,tm_id){
        this.file_id = id
        this.delDialogVisible = true;
        this.delDoc_id = row._id;
        this.delTM_id = tm_id;
        if (row.reg_num) {
            this.reg_num = row.reg_num;
        }
    },
    async remove_od(n){
        if (!this.delDoc_id){
            this.$message({
            type: "success",
            message: "档案异常请稍后再试!",
            });
            this.delDialogVisible = false; 
            return            
        }
        if (n) {
            await delofficial(this.delDoc_id);
            this.$message({
                type: "success",
                message: "删除成功!",
            });
            const file =  this.uploadFileData.rowFileList.find(item=>this.file_id == item.id);
            file.isDel = true;
            this.filterFileList();
            this.delDialogVisible = false;   
            this.uploadFileData.OfficialId = this.uploadFileData.OfficialId.filter(item=>item!=this.delDoc_id);
        }else{
            await patchcases(null,{
              tm_id:this.delTM_id,
              doc_id:this.delDoc_id,
            },{
              $remove_bind:1
            })
            this.$message({
            type: "success",
            message: "已删除档案与商标绑定!",
            });
            this.delDialogVisible = false;    
        }
    },
    changeDrawerState(){
        this.OfficialDetailsOpen = false;
    },
    changeOfficialForm(data){
        this.OfficialDetailData = data
    },
    handleClose(){
        //关闭抽屉
        this.$emit('closeUpload');
    },
    changePutDate(){},
    changeDelDialogVisible(n){
       this.delDialogVisible = n;
    },
    handleUpdateTable(renderData,startIndex, endIndex){
        this.tableArr = renderData;
    },
        // 点击单个节点的收缩和展开功能
    handleChangeRowExpand(expandedRows,expanded){
        // 查找到点击收缩品牌节点的row
        let index = this.fileList.findIndex(item => item.id == expandedRows.id);
        if (index === -1) return;
        // 判断点击节点是否展开
        if (expanded) {
            // 展开节点  不仅要添加自身节点  还有添加子节点展开的children
            this.fileList.splice(index + 1 ,0,...(this.getExpandedRowChildren(this.fileList[index])));
            // 展开节点  不仅要添加自身节点  还有添加子节点展开的children
            if (!_.includes(this.expandedRowKeys,expandedRows.id)) {
              this.expandedRowKeys.push(expandedRows.id);
            }
        }else{
            // 收缩节点  要删除本身节点和子节点展开children的总长度
            this.fileList.splice(index + 1 ,this.getCollapsedRowLength(expandedRows));
        }
        let obj = this.fileList[index];
        obj.isExpand = expanded;
        this.$set(this.fileList,index,obj);
    },
    // 获取需要展开节点和已经展开子节点children的排列
    getExpandedRowChildren(expandedRows){
      let childrenArray = [];
      const cb = (brand,isRootNode) =>{
          if (isRootNode) {
              childrenArray = _.concat(childrenArray,brand.children);
          }else{
              //  如果没有展开 就退出
              if (!brand.isExpand) return;
              let index = childrenArray.findIndex(i => i.id == brand.id);
              if (index > -1 && brand.id!=childrenArray[index].id){
                  childrenArray.splice(index + 1 ,0,...brand.children);
              }
          }
          if (brand.children && brand.children.length > 0) {
              for (const item of brand.children) {
                  cb(item,false);
              }
          }
      }

      cb(expandedRows,true);
        // console.log('childrenArray :>>', childrenArray);
      
      return childrenArray;
    },
    // 获取需要收缩节点和已经展开子节点children的总长度
    getCollapsedRowLength(collapsedRow){
      let num = 0;
      const cb = (brand)=>{
          // 如果没有展开 就退出
          if (!brand.isExpand) return;
          num+=brand.children.length;
          if (brand.children && brand.children.length > 0) {
              for (const item of brand.children) {
                  cb(item);
              }
          }
      }
      cb(collapsedRow);
      return num;
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }){
        if (columnIndex == 0) {
            let classes = '';
            if (!row.children  || row.children.length === 0) {
                classes += 'cellPadding  ';
            }else{
                classes += 'flexCellDiv  ';
            }

            switch (row.level) {
                case 0:
                    break;
                case 1:
                    classes += 'cellLevel2';
                    break;
                case 2:
                    classes += 'cellLevel3';
                    break;
                case 3:
                    classes += 'cellLevel4';
                    break;
                case 4:
                    classes += 'cellLevel5';
                    break;
                default:
                  if (row.level >= 5) {
                    classes += 'cellLevel5';
                  }
                    break;
            }

            return classes;
        }
    },
  },
  filters:{
    filterSize(value){
        if (!value) {
            return 
        }
        if (get_size_str(Math.ceil(value))) {
         return get_size_str(Math.ceil(value));   
        }else{
            return value
        }
    },
    filterStatus(val){
        switch (val) {
            case "succeeded":
                return '识别成功';
            case "no_receive_date":
                return "无收文日期";
            case "repeat":
                return  '文件已存在';
            case "failed_store": 
                return '文件存储失败';
            case "failed_recognize":
                return "官文识别失败"
            case "failed_unzip":
                return  '压缩包解压失败';
            case "error":
                return '官文处理错误'
            default:
             return '';
        }
    }
  },
  watch:{
    'uploadFileData.rowFileList':_.throttle(function(){
      this.filterFileList();
    },2000),
    'uploadFileData.completedTotal'(n){
        if (this.uploadFileData?.fileTotal) {
            var num = (this.uploadFileData?.completedTotal/this.uploadFileData.fileTotal)*100;
            this.percentage = Math.ceil(num);   
        }else{
            this.percentage = 0;    
        }
        if (n==this.uploadFileData?.fileTotal) {
            this.$emit('Setuploadstatus',this.uploadFileData.new_id,'completed');
            this.fileStatusKey++;
        }
    },
    'uploadFileData.total_speed':_.throttle(function(){
      this.filterFileList();
      this.fileStatusKey++;
    },1000),
    },
};
</script>
<style lang="less" scoped>
    .task_upload_top{
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .task_import_main{
        margin-top: 28px;
    }
    .info_color{
    color: #909399;
}
::v-deep .el-table td.el-table__cell > div{
  display: flex;
}
</style>