/*商标修改页面公共字段*/
export class trademarkEdit {
    trademarkForm = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string',
            edit:false,
        },
        {
            label: '申请日',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:false
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean',
            edit:false
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string",
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string',
            edit:false
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string',
            edit:false,
            show:false,
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_cn',
            edit:false
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string',
            edit:false
        },
        {
            label:'专用权期限结束日期',
            Showlabel:'专用权期限',
            value: 'private_end_date',
            type: 'date',
            edit:true,
            show:false,
        },
        {
            label: '注册公告',
            value: 'reg_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_en',
            edit:false
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '注册日期',
            value: 'private_start_date',  //商标详情页
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人地址(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_cn',
            edit:false
        },
        {
            label: '法律状态',
            value: 'legal_status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '专用权期限开始日期',
            Showlabel:'专用权期限',
            value: 'private_start_date',
            type: 'date',
            filter: 'filterConcat',
            twoLevel: 'private_end_date',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人地址(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_en',
            edit:false
        },
        {
            label: '商标状态',
            value: 'status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        // {
        //     label: '品牌',
        //     value: 'brand_name',
        //     filter: 'filterBrand',
        //     type: 'string',
        //     edit:true,
        //     show:false,
        //     show_input:false,
        // },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string',
            edit:false
        },
        // {
        //     label: '注册日期',
        //     value: 'reg_annc_date',
        //     type: 'date',
        //     filter: 'filterUtcTime'
        // },
        // {
        //     label: '备注',
        //     value: 'comments',
        //     type: 'string'
        // },
    ]
    trademarkForm2 = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string',
            edit:false,
        },
        {
            label: '注册公告',
            value: 'reg_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string",
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '注册日期',
            value: 'private_start_date',  //商标详情页
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string',
            edit:false,
            show:false,
        },
        {
            label: '专用权期限开始日期',
            Showlabel:'专用权期限',
            value: 'private_start_date',
            type: 'date',
            filter: 'filterConcat',
            twoLevel: 'private_end_date',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string',
            edit:false
        },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string',
            edit:false
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean',
            edit:false
        },
        {
            label: '法律状态',
            value: 'legal_status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string',
            edit:false
        },
        {
            label: '商标状态',
            value: 'status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_cn',
            edit:false
        },
        {
            label: '申请日',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:false
        },
        {
            label: '申请人(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_en',
            edit:false
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人地址(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_cn',
            edit:false
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请人地址(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_en',
            edit:false
        },
    ]
    trademarkForm3 = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string',
            edit:false,
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string",
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string',
            edit:false,
            show:false,
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string',
            edit:false
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '法律状态',
            value: 'legal_status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '商标状态',
            value: 'status',
            type: 'string',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '申请日',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:false
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '注册公告',
            value: 'reg_annc_issue',
            type: 'int',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label: '注册日期',
            value: 'private_start_date',  //商标详情页
            type: 'date',
            filter: 'filterUtcTime',
            edit:true,
            show:false,
            show_input:false,
        },
        {
            label:'专用权期限结束日期',
            Showlabel:'专用权期限',
            value: 'private_end_date',
            type: 'date',
            edit:true,
            show:false,
        },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string',
            edit:false
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean',
            edit:false
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string',
            edit:false
        },
        {
            label: '申请人(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_cn',
            edit:false
        },
        {
            label: '申请人(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_en',
            edit:false
        },
        {
            label: '申请人地址(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_cn',
            edit:false
        },
        {
            label: '申请人地址(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_en',
            edit:false
        },
    ]
    TMFormDrawer = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string'
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string"
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string'
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string'
        },
        {
            label: '申请人(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_cn'
        },
        {
            label: '申请人(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_en'
        },
        {
            label: '申请人地址(中文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_cn'
        },
        {
            label: '申请人地址(英文)',
            value: 'applicant_origin',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'address_en'
        },
        {
            label: '申请日',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime'
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int'
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime'
        },
        {
            label: '注册公告',
            value: 'reg_annc_issue',
            type: 'int'
        },
        {
            label: '注册日期',
            value: 'private_start_date',  // 商标抽屉
            type: 'date',
            filter: 'filterUtcTime'
        },
        {
            label: '专用权期限',
            value: 'private_start_date',
            type: 'date',
            filter: 'filterConcat',
            twoLevel: 'private_end_date'
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string'
        },
        {
            label: '商标状态',
            value: 'status',
            type: 'string'
        },
        {
            label: '法律状态',
            value: 'legal_status',
            type: 'string'
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string'
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean'
        },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string'
        },
        {
            label: '备注',
            value: 'comments',
            type: 'string'
        }
    ]
    
    ReveTM = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string'
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string"
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string'
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string'
        },
        {
            label: '申请日',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime'
        },
        {
            label: '申请人(中文)',
            value: 'applicant',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_cn'
        },
        {
            label: '申请人(英文)',
            value: 'applicant',
            filter: 'filterObj',
            type: 'string',
            twoLevel: 'applicant_en'
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int'
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime'
        },
        {
            label: '专用权期限',
            value: 'private_start_date',
            type: 'date',
            filter: 'filterConcat',
            twoLevel: 'private_end_date'
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string'
        },
        {
            label: '商标状态',
            value: 'status',
            type: 'string'
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean'
        },
        // {
        //     label: '注册日期',
        //     value: 'reg_annc_date',
        //     type: 'date',
        //     filter: 'filterUtcTime'
        // },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string'
        },
        {
            label: '备注',
            value: 'comments',
            type: 'string'
        }
    ]
    caseFields = [
        {
            prop: 'type',
            label: '案件类型',
        },
        {
            prop: 'our_part',
            label: '我方主体',
        },
        {
            prop: 'create_time',
            label: '案件申请日期',
            filter: 'filterUtcTime'
        },
        {
            prop: 'verdict_result',
            label: '案件结果',
        },
        {
            prop: 'status',
            label: '案件状态',
        },
        // {
        //     prop: 'app_num',
        //     label: '官方申请号',
        // },
        // {
        //     prop: 'attorney_casenum',
        //     label: '代理文号',
        // }
    ]
    OfficialExplain = [
        {
            key: 'type',
            title: '官文类型',
            autofocus: 'false'
        },
        // {
        //     key: 'app_num',
        //     title: '案件申请号',
        // },
        {
            key: 'reg_num',
            title: '商标号',
        },
        // {
        //     key: "brand_name",
        //     title: "品牌名称"
        // },
        {
            //trademark.class_name
            key: 'class',
            title: '类别',
        },
        {
            //trademark.class_name
            key: 'applicant_cn',
            title: '商标申请人',
        },
        {
            key: 'time_limit_str',
            title: '官方绝限',
        },
        {
            key: 'receive_date_str',
            title: '收文日期',
            type: 'date',
        },
        {
            key: 'expiration_date_str',
            title: '官方绝限',
            type: 'date',
        },
        {
            key: 'fields',
        },
    ]
    tmatchExplain = [
        {
            key: 'originalname',
            title: '官文类型',
            autofocus: 'false'
        },
        {
            key: 'name',
            title: '商标名称',
        },
        {
            key: 'reg_num',
            title: '商标号',
        },
        {
            key: 'class',
            title: '类别',
        },
        {
            //trademark.class_name
            key: 'applicant',
            title: '商标申请人',
        },
        {
            key: 'receive_date_str',
            title: '收文日期',
            type: 'date',
        },
        {
            key: 'expiration_date_str',
            title: '官方绝限',
            type: 'date',
        },
    ]
    casetypeData = [
        {
            prop: 'type',
            label: '官文类型'
        },
        {
            prop: 'base_type',
            label: '基础类型'
        },
        {
            prop: 'case_type',
            label: '业务类型'
        },

        // {
        //     prop: 'time_limit',
        //     label: '官文时限'
        // },
        // {
        //     prop:'new_tm_status',
        //     label:'商标更新状态'
        // },
        // {
        //     prop:'new_case_status',
        //     label:'案件更新状态'
        // }
    ]
    childrencolors = {
        "#2F54EB": [
            "#F0F5FF",
            "#D6E4FF",
            "#ADC6FF",
            "#85A5FF",
            "#597EF7",
            "#2F54EB",
            "#1D39C4",
            "#10239E",
            "#061178",
            "#030852",
            "#F0F5FF",
            "#D6E4FF",
        ],
        "#1890FF": [
            "#E6F7FF", "#BAE7FF", "#91D5FF", "#69C0FF", "#40A9FF", "#1890FF", "#096DD9", "#0050B3", "#003A8C", "#002766", "#E6F7FF", "#BAE7FF",
        ],
        "#13C2C2": [
            "#E6FFFB", "#B5F5EC", "#87E8DE", "#5CDBD3", "#36CFC9", "#13C2C2", "#08979C", "#006D75", "#00474F", "#002329", "#E6FFFB", "#B5F5EC",
        ],
        "#52C41A": [
            "#F6FFED", "#D9F7BE", "#B7EB8F", "#95DE64", "#73D13D", "#52C41A", "#389E0D", "#237804", "#135200", "#092B00", "#F6FFED", "#D9F7BE",
        ],
        "#A0D911": [
            "#FCFFE6", "#F4FFB8", "#EAFF8F", "#D3F261", "#BAE637", "#A0D911", "#7CB305", "#5B8C00", "#3F6600", "#254000", "#FCFFE6", "#F4FFB8",
        ],
        "#FADB14": [
            "#FEFFE6", "#FFFFB8", "#FFFB8F", "#FFF566", "#FFEC3D", "#FADB14", "#D4B106", "#AD8B00", "#876800", "#614700", "#FEFFE6", "#FFFFB8",
        ],
        "#FAAD14": [
            "#FFFBE6", "#FFF1B8", "#FFE58F", "#FFD666", "#FFC53D", "#FAAD14", "#D48806", "#AD6800", "#874D00", "#613400", "#FFFBE6", "#FFF1B8",
        ],
        "#FA8C16": [
            "#FFF7E6", "#FFE7BA", "#FFD591", "#FFC069", "#FFA940", "#FA8C16", "#D46B08", "#AD4E00", "#873800", "#612500", "#FFF7E6", "#FFE7BA",
        ],
        "#FA541C": [
            "#FFF2E8", "#FFD8BF", "#FFBB96", "#FF9C6E", "#FF7A45", "#FA541C", "#D4380D", "#AD2102", "#871400", "#610B00", "#FFF2E8", "#FFD8BF",
        ],
        "#F5222D": [
            "#FFF1F0", "#FFCCC7", "#FFA39E", "#FF7875", "#FF4D4F", "#CF1322", "#A8071A", "#AD2102", "#820014", "#5C0011", "#FFF1F0", "#FFCCC7",
        ],
        "#EB2F96": [
            "#FFF0F6", "#FFD6E7", "#FFA39E", "#FF7875", "#FF4D4F", "#CF1322", "#A8071A", "#AD2102", "#820014", "#5C0011", "#FFF0F6", "#FFD6E7",
        ],
        "#722ED1": [
            "#F9F0FF", "#EFDBFF", "#D3ADF7", "#B37FEB", "#9254DE", "#722ED1", "#531DAB", "#391085", "#22075E", "#120338", "#F9F0FF", "#EFDBFF",
        ]
    }
    TMTableColumns = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string',
            key: 'name',
            sortable: true,
            isShow: true,
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string',
            key: 'reg_num',
            sortable: true,
            isShow: true,
        },
        {
            label: '类别',
            value: 'class',
            type: 'string',
            key: 'class',
            sortable: true,
            isShow: true,
        },
        {
            label: '申请日期',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime',
            key: 'app_date',
            sortable: true,
            isShow: true,
        },

        {
            label: '商标状态',
            value: 'status',
            type: 'string',
            key: 'status',
            sortable: true,
            isShow: true,
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string',
            key: 'brand_name',
            sortable: true,
            isShow: true,
        },
        {
            label: '商标图样',
            value: 'pic_url',
            type: 'string',
            key: 'pic_url',
            sortable: true,
            isShow: true,
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string",
            key: "tm_alias",
            sortable: true,
            isShow: false,
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int',
            key: 'first_annc_issue',
            sortable: true,
            isShow: false,
        },
        {
            label: '初审公告日期',
            value: 'first_annc_date',
            type: 'date',
            filter: 'filterUtcTime',
            key: 'first_annc_date',
            sortable: true,
            isShow: false,
        },
        {
            label: '注册公告',
            value: 'reg_annc_issue',
            type: 'int',
            key: 'reg_annc_issue',
            sortable: true,
            isShow: false,
        },
        {
            label: '注册日期',
            value: 'private_start_date',  //商标table  有可能来自后端的
            type: 'date',
            filter: 'filterUtcTime',
            key: 'reg_date',
            sortable: true,
            isShow: false,
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string',
            key: 'agent',
            sortable: true,
            isShow: false,
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean',
            key: 'is_shared',
            sortable: true,
            isShow: false,
        },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string',
            key: 'trademarkType',
            sortable: true,
            isShow: false,
        },
        {
            label: '备注',
            value: 'comments',
            type: 'string',
            key: 'comments',
            sortable: true,
            isShow: false,
        },
    ]
    exportTM = [
        {
            label: '商标名称',
            value: 'name',
            type: 'string',
            key: '商标名称',
        },
        {
            label: '商标号',
            value: 'reg_num',
            type: 'string',
            key: '商标号',
        },
        {
            label: '类别',
            value: 'class_name',
            type: 'string',
            key: '类别',
        },
        {
            label: '申请日期',
            value: 'app_date',
            type: 'date',
            filter: 'filterUtcTime',
            key: '申请日期',
        },
        // {
        //     label: '注册日期',
        //     value: 'private_start_date',
        //     type: 'date',
        //     filter: 'filterConcat',
        //     twoLevel: 'private_end_date',
        //     key: '注册日期',
        // },
        {
            label: '商标状态',
            value: 'status',
            type: 'string',
            key: '商标状态',
        },
        {
            label: '品牌',
            value: 'brand_name',
            filter: 'filterBrand',
            type: 'string',
            key: '品牌',
        },
        {
            label: '图样',
            value: 'pic_url',
            type: 'string',
            key: '图样',
        },
        {
            label: "商标别名",
            value: "tm_alias",
            type: "string",
            key: '商标别名',
        },
        {
            label: '初审公告',
            value: 'first_annc_issue',
            type: 'int',
            key: '初审公告',
        },
        {
            label: '',
            value: 'reg_annc_issue',
            type: 'int',
            key: '注册公告',
        },
        {
            label: '注册日期',
            value: 'private_start_date',   // 商标导出  这个暂时不用  会用后端config里的
            type: 'date',
            filter: 'filterUtcTime',
            key: '注册日期',
        },
        {
            label: '代理机构',
            value: 'agent',
            type: 'string',
            key: '代理机构',
        },
        {
            label: '是否共有',
            value: 'is_shared',
            type: 'bool',
            filter: 'isBoolean',
            key: '是否共有',
        },
        {
            label: '商标类型',
            value: 'trademarkType',
            type: 'string',
            key: '商标类型',
        },
        {
            label: '备注',
            value: 'comments',
            type: 'string',
            key: '备注',
        },
    ]
    //法律案件
    "法律案件" = {
        case_information:[
            {
                label: '案件性质',
                value: 'base_type',
                type: 'string',
                case_value:'case_base_type',
                case_view:'case_base_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
                importCaseisFilter:true,
            },
            {
                label: '案件类型',
                value: 'type',
                type: 'down',
                case_value:'case_type',
                case_view:'case_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
            },
            {
                label: '代理所案号',
                value: 'attorney_casenum',
                type: 'string',
                case_view:'case_attorney_casenum',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '我方主体',
                value: 'our_part',
                type: 'down',
                case_value:'case_our_part',
                case_view:'case_our_part',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '上报方式',
                value: 'apply_way',
                type: 'select',
                case_view:'case_apply_way',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '案件申请日期',
                value: 'create_time',
                type: 'date',
                case_view:'case_create_time',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label: '受通到达日期',
                value: 'receipt_doc_date',
                type: 'date',
                case_view:'case_receipt_doc_date',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label: '裁定送达日期',
                value: 'decision_date',
                type: 'date',
                case_view:'case_decision_date',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label: '案件结果',
                value: 'verdict_result',
                type:'select',
                case_value:'case_results',
                case_view:'case_verdict_result',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '官方绝限',
                value: 'decision_expiration',
                type: 'date',
                case_view:'case_decision_expiration',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label: '代理机构(我方)',
                value: 'agent',
                type: 'string',
                case_view:'case_agent',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '对方主体',
                value: 'opposite',
                type: 'string',
                case_view:'case_opposite',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '备注',
                value: 'comments',
                type: 'string',
                case_view:'case_comments',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },           
        ],    
        tm_information: [
            {
                label: '商标名称',
                value: 'tm_name',
                type: 'string',
            },
            {
                label: '商标号',
                value: 'tm_reg_num',
                type: 'string',
            },
            {
                label: '类别',
                value: 'tm_class',
                type: 'number',
            },
            {
                label: '群组',
                value: 'tm_groups',
                type: 'array',
            },
            {
                label: '申请人(中)',
                value: 'tm_applicant_cn',
                type: 'string',
            },
            {
                label: '申请人(英)',
                value: 'tm_applicant_en',
                type: 'string',
            },
            {
                label: '申请日期',
                value: 'tm_app_date',
                type: 'date',
            },
            {
                label: '商标状态',
                value: 'tm_status',
                type: 'down',
            },
            {
                label: '初审公告日期',
                value: 'tm_first_annc_date',
                type: 'date',
            },
            {
                label: '初审公告',
                value: 'tm_first_annc_issue',
                type: 'string',
            },
            {
                label: '注册日期',
                value: 'tm_private_start_date',   // 法律案件抽屉的商标信息
                type: 'date',
            },
            {
                label: '注册公告',
                value: 'tm_reg_annc_issue',
                type: 'int',
            },
            {
                label: '专用权期限',
                value: 'tm_private_start_date',
                type: 'filterConcat',
                twoLevel: 'tm_private_end_date',
            },
        ],
        list:[
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                key:'case_attorney_casenum',
                type:'string'
            },
            {
                label:'我方主体',
                value:'case_our_part',
                key:'case_our_part',
                type:'string'
            },
            {
                label:'案件类型',
                value:'case_type',
                key:'case_type',
                type:'string'
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                key:'tm_reg_num',
                type:'string'
            },
            {
                label:'商标名称',
                value:'tm_name',
                key:'tm_name',
                type:'string'
            },
            {
                label:'类别',
                value:'tm_class',
                key:'tm_class',
                type:'string'
            },
            {
                label:'对方主体',
                value:'case_opposite',
                key:'case_opposite',
                type:'string'
            },
            {
                label:'案件申请日期',
                value:'case_create_time',
                key:'case_create_time',
                type:'date'
            },
            {
                label:'上报方式',
                value:'case_apply_way',
                key:'case_apply_way',
                type:'string'
            },
            {
                label:'受通到达日期',
                value:'case_receipt_doc_date',
                key:'case_receipt_doc_date',
                type:'date'
            },
            {
                label:'裁定送达日期',
                value:'case_decision_date',
                key:'case_decision_date',
                type:'date'
            },
            {
                label:'案件结果',
                value:'case_verdict_result',
                key:'case_verdict_result',
                type:'string'
            },
        ],
        search:[
            {
                label:'商标名称',
                value:'tm_name',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                type:'down',
                show:false,
            },
            {
                label:'案件结果',
                value:'case_verdict_result',
                type:'down',
                show:false,
            },
            {
                label:'案件类型',
                value:'case_type',
                type:'down',
                show:true,
                always_show:true,
            },
            {
                label:'我方主体',
                value:'case_our_part',
                type:'down',
                show:false,
            },
            {
                label:'对方主体',
                value:'case_opposite',
                type:'down',
                show:false,
            },
            {
                label:'案件申请日期',
                value:'case_create_time',
                type:'date',
                show:false,
            },
            {
                label:'裁定送达日期',
                value:'case_decision_date',
                type:'date',
                show:false,
            },

        ],
    }
    //基础案件抽屉基本信息
    "商标基础" = {
        case_information:[
            {
                label: '案件性质',
                value: 'base_type',
                type: 'string',
                case_value:'case_base_type',
                case_view:'case_base_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
                importCaseisFilter:true,
            },
            {
                label: '案件类型',
                value: 'type',
                type: 'down',
                case_value:'case_type',
                case_view:'case_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
            },
            {
                label: '我方代理机构',
                value: 'agent',
                type: 'string',
                case_view:'case_agent',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '代理所案号',
                value: 'attorney_casenum',
                type: 'string',
                case_view:'case_attorney_casenum',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '案件申请日期',
                value: 'create_time',
                type: 'date',
                case_view:'case_create_time',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label: '案件结果',
                value: 'verdict_result',
                type:'select',
                case_value:'case_results',
                case_view:'case_verdict_result',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '备注',
                value: 'comments',
                type: 'string',
                case_view:'case_comments',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },  

        ],
        tm_information:[
            {
                label: '商标名称',
                value: 'tm_name',
                type: 'string',
            },
            {
                label: '商标号',
                value: 'tm_reg_num',
                type: 'string',
            },
            {
                label: '类别',
                value: 'tm_class',
                type: 'string',
            },
            {
                label: '群组',
                value: 'tm_groups',
                type: 'array',
            },
            {
                label: '申请人(中)',
                value: 'tm_applicant_cn',
                type: 'string',
            },
            {
                label: '商标类型',
                value: 'tm_trademarkType',
                type: 'down',
            },
            {
                label: '申请日期',
                value: 'tm_app_date',
                type: 'date',
            },
            {
                label: '初审公告',
                value: 'tm_first_annc_issue',
                type: 'string'
            },
            {
                label: '初审公告日期',
                value: 'tm_first_annc_date',
                type: 'date',
            },
            {
                label: '异议截止日',
                value: 'unusual_date',
                type: 'date',
                od:'tm',
            },
            {
                label: '注册日期',
                value: 'tm_private_start_date',  // 商标基础案件抽屉的商标信息
                type: 'date',
                od:'tm',
            },
            {
                label: '注册公告',
                value: 'tm_reg_annc_issue',
                type: 'int'
            },  
            {
                label: '专用权期限',
                value: 'tm_private_start_date',
                type: 'date',
                filter: 'filterConcat',
                twoLevel: 'tm_private_end_date'
            },
            {
                label: '商标状态',
                value: 'tm_status',
                type: 'down',
            },
        ],
        list:[
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                key:'case_attorney_casenum',
                type:'string'
            },
            {
                label:'品牌名称',
                value:'tm_brand_name',
                key:'tm_brand_name',
                type:'string'
            },
            // {
            //     label:'品牌级别',
            //     value:'tm_brand_level',
            //     type:'string'
            // },
            {
                label:'商标名称',
                value:'tm_name',
                key:'tm_name',
                type:'string'
            },
            {
                label:'商标图样',
                value:'pic_url',
                key:'pic_url',
                type:'string'
            },
            {
                label:'类别',
                value:'tm_class',
                key:'tm_class',
                type:'string'
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                key:'tm_reg_num',
                type:'string'
            },
            {
                label:'案件申请日期',
                value:'case_create_time',
                key:'case_create_time',
                type:'date'
            },
            {
                label:'初审公告日',
                value:'tm_first_annc_date',
                key:'tm_first_annc_date',
                type:'date'
            },
            {
                label:'异议截止日',
                value:'tm_objection_deadline',
                key:'tm_objection_deadline',
                type:'date'
            },
            {
                label:'注册日期',
                value:'tm_private_start_date',  // 商标基础案件table列表的商标信息
                key:'tm_reg_date',
                type:'date'
            },
            {
                label:'商标状态',
                value:'tm_status',
                key:'tm_status',
                type:'string'
            },
            {
                label:'我方主体',
                value:'case_our_part',
                key:'case_our_part',
                type:'string'
            },
            {
                label:'群组',
                value:'tm_groups',
                key:'tm_groups',
                type:'array'
            },
            {
                label:'案件结果',
                value:'case_verdict_result',
                key:'case_verdict_result',
                type:'string'
            },
            {
                label:'案件类型',
                value:'case_type',
                key:'case_type',
                type:'string'
            },
        ],
        search:[
            {
                label:'商标名称',
                value:'tm_name',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'商标状态',
                value:'tm_status',
                type:'down',
                show:true,
                always_show:true,
            },
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                type:'down',
                show:false,
            },
            {
                label:'我方主体',
                value:'case_our_part',
                type:'down',
                show:false,
            },
            {
                label:'案件类型',
                value:'case_type',
                type:'down',
                show:false,
            },
            {
                label:'案件申请日期',
                value:'case_create_time',
                type:'date',
                show:false,
            },
        ]
    }
    //行政诉讼抽屉基本信息
    "行政诉讼"= {
        case_information:[
            {
                label: '案件性质',
                value: 'base_type',
                type: 'string',
                case_view:'case_base_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
                importCaseisFilter:true,
            },
            {
                label:'审级',
                value:'type',
                type:'down',
                case_value:'case_type',
                case_view:'case_type',
                in_drawer: true,
                required: true,
                importCaseType: 'string',
            },
            {
                label: '代理所案号',
                value: 'attorney_casenum',
                type: 'string',
                case_view:'case_attorney_casenum',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '我方主体',
                value: 'our_part',
                type: 'down',
                case_value:'case_our_part',
                case_view:'case_our_part',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '诉讼地位',
                value: 'client_seat',
                type: 'select',
                case_value:'case_client_seat',
                case_view:'case_client_seat',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '对方主体',
                value: 'opposite',
                type: 'string',
                case_view:'case_opposite',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '案由',
                value: 'cause',
                type: 'down',
                case_value:'case_cause',
                case_view:'case_cause',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '受理日期',
                value: 'create_time',
                type: 'date',
                case_view:'case_create_time',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label:'结案日期',
                value:'close_time',
                type:'date',
                case_view:'case_close_time',
                in_drawer: true,
                required: false,
                importCaseType: 'date',
            },
            {
                label:'法院案号',
                value:'app_num',
                type:'string',
                case_view:'case_app_num',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label:'案件结果',
                value:'verdict_result',
                type:'select',
                case_value:'case_results',
                case_view:'case_verdict_result',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '备注',
                value: 'comments',
                type: 'string',
                case_view:'case_comments',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },
            {
                label: '代理机构(我方)',
                value: 'agent',
                type: 'string',
                case_view:'case_agent',
                in_drawer: true,
                required: false,
                importCaseType: 'string',
            },  
        ],
        tm_information:[
            {
                label: '商标名称',
                value: 'tm_name',
                type: 'string',
            },
            {
                label: '商标号',
                value: 'tm_reg_num',
                type: 'string',
            },
            {
                label: '类别',
                value: 'tm_class',
                type: 'string',
            },
            {
                label: '群组',
                value: 'tm_groups',
                type: 'array',
            },
            {
                label: '申请人(中)',
                value: 'tm_applicant_cn',
                type: 'string',
            },
            {
                label: '申请人(英)',
                value: 'tm_applicant_en',
                type: 'string',
            },
            // {
            //     label: '商品或服务',
            //     value: 'tm_goods',
            //     type: 'string',
            // },
            {
                label: '申请日期',
                value: 'tm_app_date',
                type: 'date',
            },
            {
                label: '初审公告',
                value: 'tm_first_annc_issue',
                type: 'string'
            },
            {
                label: '初审公告日期',
                value: 'tm_first_annc_date',
                type: 'date',
            },
            {
                label: '注册公告',
                value: 'tm_reg_annc_issue',
                type: 'string'
            },
            {
                label: '专用权期限',
                value: 'tm_private_start_date',
                type: 'date',
                filter: 'filterConcat',
                twoLevel: 'tm_private_end_date'
            },
            {
                label: '商标状态',
                value: 'tm_status',
                type: 'down',
            },
        ],
        list:[
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                key:'case_attorney_casenum',
                type:'string'
            },
            {
                label:'商标名称',
                value:'tm_name',
                key:'tm_name',
                type:'string'
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                key:'tm_reg_num',
                type:'string'
            },
            {
                label:'类别',
                value:'tm_class',
                key:'tm_class',
                type:'string'
            },
            {
                label:'我方主体',
                value:'case_our_part',
                key:'case_our_part',
                type:'string'
            },
            {
                label:'诉讼地位',
                value:'case_client_seat',
                key:'case_client_seat',
                type:'string'
            },
            {
                label: '对方主体',
                value: 'case_opposite',
                key:'case_opposite',
                type: 'string',
            },
            // {
            //     label: '对方当事人地位',
            //     value: 'case_opposite_seat',
            //     key:'case_opposite_seat',
            //     type: 'string',
            // },
            // {
            //     label: '第三人',
            //     value: 'case_third_party',
            //     key:'case_third_party',
            //     type: 'string',
            // },
            {
                label:'案由',
                value:'case_cause',
                key:'case_cause',
                type:'string'
            },
            {
                label:'审级',
                value:'case_type',
                key:'case_type',
                type:'string'
            },
            {
                label:'结案日期',
                value:'case_close_time',
                key:'case_close_time',
                type:'date'
            },
            {
                label:'案件结果',
                value:'case_verdict_result',
                key:'case_verdict_result',
                type:'string'
            },
            {
                label:'受理日期',
                value:'case_create_time',
                key:'case_create_time',
                type:'date'
            },
            {
                label: '代理机构(我方)',
                value: 'case_agent',
                key:'case_agent',
                type: 'string',
            },  

        ],
        search:[
            {
                label:'商标名称',
                value:'tm_name',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'商标号',
                value:'tm_reg_num',
                type:'string',
                show:true,
                always_show:true,
            },
            {
                label:'代理所案号',
                value:'case_attorney_casenum',
                type:'down',
                show:false,
            },
            {
                label:'审级',
                value:'case_type',
                type:'down',
                show:false,
            },
            {
                label:'我方主体',
                value:'case_our_part',
                type:'down',
                show:false,
            },
            {
                label:'诉讼地位',
                value:'case_client_seat',
                type:'down',
                show:false,
            },
            {
                label:'案由',
                value:'case_cause',
                type:'down',
                show:false,
            },
            {
                label:'案件结果',
                value:'case_verdict_result',
                type:'down',
                show:true,
                always_show:true,
            },
            {
                label:'结案日期',
                value:'case_close_time',
                type:'date',
                show:false,
            },
            {
                label:'受理日期',
                value:'case_create_time',
                type:'date',
                show:false,
            },
        ]

    }
    //一级路由的渲染   auth_path 是 权限过滤的字段     没有默认通过
    // is_optional_feature和name是付费检查和router的联动  is_optional_feature没有默认通过   name为authorized可以通过
    allMenus = [
        {
            title: "首页",
            index: "/home/HomePage",
            icon: "el-icon-s-home",
            auth_path:"page_home",
            class:"page_home",
            name:'MyHomePage',
            pointHtml:`
            <div class="pointHtml">
            <p class="title">首页</p>
            <p class="mainText">1. 可以看到您关注的重要信息统计；</p>
            <p class="mainText">2. 商标申请量分析和品牌对商品的覆盖情况</p>
            </div>`,
            popoverOpen:false
        },
        {
          title: "品牌管理",
          index: "2",
          icon: "el-icon-s-claim",
          auth_path: "page_brandmanagement",
          icon: require("../assets/img/icon_shangbiao.png"),
          name:'authorized',
          children: [
            {
              title: "品牌",
              path: "/home/brand",
              name: "MyBrand",
              auth_path:'brand',
            },
            {
              title: "申请人",
              path: "/home/apply",
              name: "MyApply",
              auth_path:'applicant',
            },
            {
                title: "商品查询",
                path: "/home/commodityQuery",
                name: "MyCommodityQuery",
                auth_path:'commodityQuery'
            },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">品牌管理</p>
          <p class="mainText">1. 总览企业品牌，分析品牌下商标对商品类别的覆盖情况。</p>
          <p class="mainText">2. 申请人管理和商品语义查询功能。</p>
          </div>`,
            popoverOpen:false
        },
        {
          title: "商标管理",
          index: "3",
          icon: "el-icon-lock",
          auth_path: "page_tmmanagement",
          icon: require("../assets/img/icon_pinpai.png"),
          name:'authorized',
          children: [
            {
              title: "我方商标",
              path: "/home/trademark",
              name: "MyTrademark",
              icon: "",
              auth_path:'trademark',
              children: [
                {
                  title: "商标详情页",
                  path: "/home/tmpage",
                  name: "MyTMpage",
                  auth_path:'',
                },
              ],
            },
            {
              title: "涉他商标",
              path: "/home/revetm",
              name: "MyReveTM",
              icon: "",
              auth_path:'trademark',
            },
            {
              title: "时限管理",
              path: "/home/timelimit",
              name: "MyTimeLimit",
              icon: "",
              auth_path:'',
            },
           {
              title: "商标预警",
              path: "/home/flowalert",
              name: "Myflowalert",
              icon: "",
              auth_path:'flowalert',
            },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">商标管理</p>
          <p class="mainText">1. 一键导入/导出商标数据；</p>
          <p class="mainText">2. 自动生成商标关联图，一图秒懂商标生命周期及关联案件的进展；</p>
          <p class="mainText">3. 商标数据定期更新，状态异常及时预警。</p>
          </div>`,
          popoverOpen:false
        },
        {
          title: "案件管理",
          index: "4",
          auth_path: "page_casemanagement",
          icon: require("../assets/img/icon_case.png"),
          name:'authorized',
          children: [
            {
              title: "商标基础案件",
              path: "/home/tmcase",
              name: "Mytmcase",
              auth_path:'case',
            },
            {
                title: "非诉法律案件",
                path: "/home/lawcase",
                name: "Mylawcase",
                auth_path:'case',
            },
            {
              title: "行政诉讼案件",
              path: "/home/lawsuitcase",
              name: "Mylawsuitcase",
              auth_path:'case',
            },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">案件管理</p>
          <p class="mainText">商标基础案件，行政诉讼案件，非诉法律案件列表，支持查询，查看详情。</p>
          </div>`,
          popoverOpen:false
        },
        {
          title: "档案管理",
          index: "5",
          icon: "el-icon-lock",
          auth_path: "page_filemanagement",
          icon: require("../assets/img/icon_dangan.png"),
          name:'authorized',
          children: [
            {
              title: "未读档案",
              path: "/home/beprocessedOfficial",
              name: "MybeprocessedOfficial",
              icon: "",
              auth_path:'document',
            },
            {
              title: "所有档案",
              path: "/home/document",
              name: "MyOfficial",
              icon: "",
              auth_path:'document',
            },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">档案管理</p>
          <p class="mainText">上传档案，系统会自动识别档案类型和内容。查看所有档案，包括官方文件和其他文件。</p>
          </div>`,
          popoverOpen:false
        },
        {
            title: "著作权和域名",
            index: "6",
            auth_path: "",
            icon: require("../assets/img/icon_collection.png"),
            name: 'domain_and_copyright',
            is_optional_feature:true,
            children: [
                {
                title: "域名管理",
                path: "/home/domainname",
                name: "Mydomainname",
                icon: "",
                },
                {
                title: "作品著作权",
                path: "/home/workCopyright",
                name: "MyWorkCopyright",
                },
                {
                title: "软件著作权",
                path: "/home/softwareCopyright",
                name: "MySoftwareCopyright",
                },
                {
                title: "著作权档案",
                path: "/home/copyrightFile",
                name: "MyCopyrightFile",
                },
            ],
            // pointHtml:`
            // <div class="pointHtml">
            // <p class="title">在档案管理中</p>
            // <p class="mainText">上传档案，系统会自动识别档案类型和内容。查看所有档案，包括官方文件和其他文件。</p>
            // </div>`,
            popoverOpen:false
        },
        {
            title: "证据中心",
            index: "7", 
            auth_path: "",
            icon: require("../assets/img/icon_receiving.png"),
            name:'evidenceCenter',
            is_optional_feature:true,
            popoverOpen:false,
            pointHtml:`
                <div class="pointHtml">
                <p class="title">证据中心</p>
                </div>`,
            children: [
                {
                    title: "证据列表",
                    path: "/home/evidenceList",
                    name: "MyEvidenceList",
                    auth_path:'',  //evidenceList
                },
                {
                    title: "证据回收站",
                    path: "/home/evidenceRecyclingBin",
                    name: "MyEvidenceRecyclingBin",
                    auth_path:'',  //evidenceRecyclingBin
                },
                
            ],
            
        },
        {
          title: "可视化分析",
          index: "8",
          icon: "el-icon-lock",
          auth_path: "page_visualanalysis",
          icon: require("../assets/img/icon_keshihua.png"),
          name:'authorized',
          children: [
            {
              title: "商标饼图分析",
              path: "/home/pieVisualAnalysis",
              name: "pieVisualAnalysis",
              icon: "",
            },
            {
              title: "商标矩阵分析",
              path: "/home/treemapVisualAnalysis",
              name: "treemapVisualAnalysis",
              icon: "",
            },
            {
              title: "商标时间轴分析",
              path: "/home/timershaft",
              name: "Mytimershaft",
              icon: "",
            },
            {
                title: "案件可视化分析",
                path: "/home/caseshaft",
                name: "Mycaseshaft",
                icon: "",
              },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">可视化分析</p>
          <p class="mainText">站在品牌管理角度，从申请人、商标数量、保护类别、商标状态、时间等不同维度高可视化呈现商标保护数据。</p>
          </div>`,
          popoverOpen:false
        },
        {
          title: "系统管理",
          index: "9",
          icon: "el-icon-setting",
          auth_path: "page_system",
          icon: require("../assets/img/icon_shezhi.png"),
          name:'authorized',
          children: [
            {
              title: "用户管理",
              path: "/home/user",
              name: "MyUser",
              auth_path:'users',
            },
            {
              title: "角色和授权管理",
              path: "/home/role",
              name: "MyRole",
              auth_path:'roles',
            },
            {
              title: "监控邮箱配置",
              path: "/home/postbox",
              name: "MyPostbox",
              auth_path:'mails',
            },
            // {
            //     title: "操作指南",
            //     path: "/home/helpDocs",
            //     name: "MyHelpDocs",
            // },
            // {
            //     title:'数据核查',
            //     path:'/home/audit',
            //     name: 'MyAuditList',
            //     auth_path:'correction'
            // },
            // {
            //   title: "官文配置",
            //   path: "/home/documents",
            //   name: "MyDocuments",
            // },
          ],
          pointHtml:`
          <div class="pointHtml">
          <p class="title">系统管理</p>
          <p class="mainText">管理用户、权限和其他系统配置。</p>
          </div>`,
          popoverOpen:false
        },
    ]
    locate_route = [
        {
            title: "操作指南",
            path: "/home/helpDocs",
            name: "MyHelpDocs",
            icon: "el-icon-ice-cream-round",
        },
    ]
    //商标预警
    flowalert = {
        flowalert_search:[
            {
                label:'商标号',
                value:'reg_num',
                type:'string'
            },
            // {
            //     label:'商标名称',
            //     value:'name',
            //     type:'string'
            // },
            {
                label:'预警信息',
                value:'flow_type',
                type:'down'
            },
            {
                label:'操作',
                value:'has_read',
                type:'down'
            },
        ],
        flowalert_list:[
            {
                label:'品牌名称',
                value:'brand_name',
                type:'string'
            },
            {
                label:'商标号',
                value:'reg_num',
                type:'string'
            },
            {
                label:'商标名称',
                value:'name',
                type:'Object'
            },
            // {
            //     label:'商标图样',
            //     value:'pic_url',
            //     type:'url'
            // },
            {
                label:'类别',
                value:'int_class',
                type:'string'
            },
            // {
            //     label:'群组',
            //     value:'groups',
            //      type:'array'
            // },
            // {
            //     label:'商品/服务名称',
            //     value:'goods',
            //       type:'array'
            // },
            {
                label:"申请人",
                value:"applicant_cn",
                type:'applicant_origin'
            },

            {
                label:'日期',
                value:'flow_date',
                type:'date'
            },
            {
                label:'预警信息',
                value:'flow_category',
                type:'string'
            },
            {
                label:'操作',
                value:'has_read',
                type:'Boolean'
            },
        ]
    }
    //域名管理
    domain_data=[
        {
            label:'域名名称',
            value:'name',
            export_domain:true,
            domain_table:true,
        },
        {
            label:'注册商',
            value:'registrar',
            export_domain:true,
            domain_table:false,
        },
        {
            label:'申请人',
            value:'applicant_name_cn',
            export_domain:true,
            domain_table:false,
        },
        {
            label:'所属地域',
            value:'region',
            type:'domestic',
            export_domain:false,
            domain_table:true,
        },
        {
            label:'所属品牌',
            value:'brand_name',
            export_domain:false,
            domain_table:true,
        },
        {
            label:'注册时间',
            value:'registration_date',
            type:'date',
            export_domain:true,
            domain_table:true,
        },
        {
            label:'到期时间',
            value:'expiration_date',
            type:'date',
            export_domain:true,
            domain_table:true,
        },
        {
            label:'域名状态',
            value:'status',
            type:'status',
            export_domain:true,
            domain_table:true,
        }
    ]
    //证据中心
    evidenceColumns={
        //分类档案
        evidenceArchives:[
            // {
            //     label:'证据文件名称',
            //     value:'filename',
            //     type:'string',
            //     imgShow:true,
            // },
            {
                label:'标签',
                value:'tagStr',
                type:'string',
                width:150,
            },
            {
                label:'文件大小',
                value:'size',
                type:'size',
                width:120,
            },
            {
                label:'品牌名称',
                value:'brand_name',
                type:'string',
                width:120,
            },
            {
                label:'上传时间',
                value:'createdAt',
                type:'date',
                width:190,
                sortable:true
            },
            // {
            //     label:'',
            //     value:'',
            //     type:'',
            //     width:60,
            // },
        ],
        //商标档案
        TMArchives:[
            {
                label:'文档名称',
                value:'filename',
                type:'string',
                imgShow:true,
                sortable:true,
            },
            {
                label:'文件大小',
                value:'size',
                type:'size',
                width:120,
            },
            {
                label:'品牌名称',
                value:'brand_name',
                type:'trademark',
                width:120,
            },
            {
                label:'所属商标',
                value:'tm_name',
                type:'string',
                width:190,
            },
            {
                label:'',
                value:'',
                type:'',
                width:60,
            },
        ],
        //著作权档案
        copyrightArchives:[
            {
                label:'文档名称',
                value:'filename',
                type:'string',
                imgShow:true,
                sortable:true,
            },
            {
                label:'文件大小',
                value:'size',
                type:'size',
                width:120,
            },
            {
                label:'品牌名称',
                value:'brand_name',
                type:'string',
                width:120,
            },
            {
                label:'所属著作权',
                value:'copyrightName',
                type:'string',
                width:190,
            },
            {
                label:'',
                value:'',
                type:'',
                width:60,
            },
        ]    
    }
    //案件可视化列表
    caseShaft_data = [
        {
            label:'案件类型',
            value:'case_type',
        },
        {
            label:'案件结果',
            value:'case_verdict_result',
        },
        {
            label:'案件输赢',
            value:'case_general_result',
        },
        {
            label:'商标号',
            value:'tm_reg_num',
        },
        {
            label:'商标名称',
            value:'tm_name',
        },
        {
            label:'类别',
            value:'tm_class',
        },
        {
            label:'品牌名称',
            value:'tm_brand_name',
        },
        {
            label:'我方主体',
            value:'case_our_part',
        },
        {
            label:'代理机构',
            value:'case_agent',
        },
        {
            label:'案件申请日期',
            value:'case_create_time',
        },
    ]
    //档案列表
    files_list=[
        {
            label:"官文名称",
            value:"show_name",
            key:'type',
        },
        {
            label:"商标号",
            value:"trademark.reg_num",
            type:"tm",
            key:'trademark.reg_num'
        },
        {
            label:"时限",
            value:"time_limit",
            type:'number',
            key:'time_limit'
        },
        {
            label:"商标名称",
            value:"trademark.name",
            type:"tm",
            key:'trademark.name'
        },
        {
            label:"初审公告日期",
            value:"trademark.first_annc_date",
            type:'date',
            key:'trademark.first_annc_date',
        },
        {
            label:"注册日期",
            value:"trademark.private_start_date",
            type:'date',
            key:'trademark.reg_date',
        },
        {
            label:"收文日期",
            value:"receive_date",
            type:'date',
            key:'receive_date',
        },
        {
            label:"官方绝限",
            value:"expiration_date",
            type:'date',
            key:'expiration_date',
        },
        {
            label:"上传日期",
            value:"created_at_str",
            type:'date',
            key:'created_at_str',
        },
        {
            label:'状态',
            value:'has_read',
            type:'date',
            key:'has_read',
        }
    ]
    document_time_limit=[
        {
            label:'无',
            value:"0",
        },
        {
            label:'7天',
            value:"7",
        },
        {
            label:'15天',
            value:"15",
        },
        {
            label:'30天',
            value:"30",
        },
        {
            label: '30+15天(电子)',
            value: "45",
        },
        {
            label:'60天',
            value:"60",
        },
        {
            label:'2个月',
            value:'2 M',
        },
    ]
    audit_list=[
        {
            label:'所属商标',
            value:'reg_num',
        },
        {
            label:'商标名称',
            value:'',
        },
        {
            label:'所属档案',
            value:'doc',
        },
        {
            label:'所属案件',
            value:'case',
        },
        {
            label:'问题类型',
            value:'type',
        },
        {
            label:'问题描述',
            value:'description',
        },
        {
            label:'级别',
            value:'',
        },
        {
            label:'状态',
            value:'status',
        },
    ]
}

export const fileLimits = {
    textFile:{
        fileSuffix:['.xls','.xlsx','.pdf','.doc','.docx','.txt','.csv',],
        fileType:['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','text/plain','text/csv'],
        addFileField:'textFiles',
        singleFileSize:1024 * 1024 * 500,
    },
    imgFile:{
        fileSuffix:['.png','.jpg','.bmp'],  //image/webp,image/gif
        fileType:['image/png','image/jpeg','image/bmp'],
        addFileField:'imgFiles',
        singleFileSize:1024 * 1024 * 500,
    },
    zipFile:{
        fileSuffix:['.zip','.rar'],
        fileType:['application/x-zip-compressed','application/x-rar-compressed'],
        addFileField:'zipFiles',
        singleFileSize:1024 * 1024 * 1024 * 1,
    },
    mediaPlayerFile:{
        fileSuffix:['.mp4','.avi','.mov','.mkv','.wmv','.flv','.webm','.mp3','.wav','.aac','.ogg','.wma','.flac','.mid','.midi'],
        fileType:['video/mp4','video/x-msvideo','video/quicktime','video/x-matroska','video/x-ms-wmv','video/x-flv','video/webm',
        'audio/mpeg','audio/wav','audio/aac','audio/ogg','audio/x-ms-wma','audio/flac','audio/midi'],
        addFileField:'mediaPlayerFiles',
        singleFileSize:1024 * 1024 * 500,
    }
}

export const excelTypes = {
    excelMIMETypes: ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    excelSuffixs: ['.xls','.xlsx','xlsm','xlsb'],
}

export const brandViewTableHeader = [
    {
        label: "品牌名称",
        key: 1,
        prop: "name",
        width:300,
    },
    {
        label: "品牌级别",
        key: 2,
        prop: "level",
    },
    {
        label: "主商标图样",
        key: 3,
        prop: "pic_url",
    },
    {
        label: "商标数量",
        key: 4,
        prop: "tm_count",
    },
    {
        label: "类别数量",
        key: 5,
        prop: "claz.length",
    },
    {
        label: "所属申请人",
        key: 6,
        prop: "applicantCn",
        width:300,
    },
    {
        label: "所属业务线",
        key: 7,
        prop: "bus_line",
    },
    {
        label: "子品牌数量",
        key: 8,
        prop: "children.length",
    }
]

export const generateBrandColumns = [
    {
      label: "品牌级别",
      key: 0,
      sortable: true,
      isShow: true,
      prop: "level",
      scope: true,
    },
    {
      label: "品牌名称",
      key: 1,
      sortable: true,
      isShow: true,
      prop: "name",
    },
    {
      label: "主商标图样",
      key: 2,
      sortable: true,
      isShow: true,
      prop: "pic_url",
    },
  
    {
      label: "品牌信息",
      key: 3,
      sortable: true,
      isShow: true,
      prop: "",
    },
    {
      label: "子级品牌信息",
      key: 4,
      sortable: true,
      isShow: true,
      prop: "",
    },
];