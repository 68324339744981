import Vue from "vue";
import Vuex from "vuex";
import {
  Gettrademark,
  Apply,
} from "@api/axios";
import { wsModule } from "./feathers-client"
import {evidenceModule} from "./evidence-client.js"

import themeCss from "@/assets/css/theme.less";
import JsonData from "@/utils/help.json";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    token: {},
    isCollapse:false,
    routerDisabled:true,  //前端的用户指引
    toPath:null,  // 在无token时，保存的要去某个页面
    autosize: { minRows: 2, maxRows: 10 },
    screenWidth:document.body.clientWidth,
    screenHeight:document.body.clientHeight,
    // 商标上传----------
    uploadPercentage:0,  // 上传进度条
    TmDocumentUploadShow:false, // 是否隐藏
    TmDocumentUploadIf:false,  // 是否销毁
    caseTable:[],  //上传的商标关联案件
    tmInFo:{},   // 上传的商标信息
    tmUploadIsRecognition:false, // 是否正在识别中
    // 证据中心上传文件----------
    evidenceUpload:{
      percentage:0, //上传进度条
      show:false,  // 是否显示
      shouldKeepAlive:false,  // 是否存在
      Uploading:false, // 是否上传中
      info:null,  // 上传需要的一些信息
    },
    themeCss,

    G6ForceViewId:'',
    dragDrawerSize: 560,

    brandLayoutDisabled:false,
    isCloseDragDrawer:true,

    elTableColumnMinWidth:120,
    helpJson: JsonData.helpJson,
  },
  getters: {
    getisCollapse:(state) => state.isCollapse,
    getPaginationLayout:(state)=>{
      if (state.screenWidth < 800) {
        return 'prev, pager, next'
      }else{
        return 'total, sizes, prev, pager, next, jumper'
      }
    },
    getPagerCount:(state)=>{
      if (state.screenWidth < 800) {
        return 5
      }else{
        return 7
      }
    },
    getPaginationSize:(state)=>{
      if (state.screenWidth < 800) {
        return true
      }else{
        return false
      }
    },
    // 根据上传进度条的百分比获得上传数量
    getUoloadlistNum:(state)=>(uploadFileTotal)=>{
        return Math.floor(uploadFileTotal * (state.uploadPercentage / 100)) 
    },
    getScreenWidth:(state)=>{
      return state.screenWidth;
    },
    getScreenHeight:(state)=>{
      return state.screenHeight;
    },
    getEvidenceUploadShouldKeepAlive:(state) => {
      return state.evidenceUpload.shouldKeepAlive;
    },
    getThemeLess:(state)=>{
      return state.themeCss;
    },
    getG6ForceViewId:(state)=>{
      return state.G6ForceViewId;
    },
    getDragDrawerSize:(state)=>{
      return state.dragDrawerSize;
    },
    getDragDrawerMr:(state)=>{
      return state.screenWidth >= 1200 ? 640 : 56;
    },
    getBrandLayoutDisabled:(state)=>{
      return state.brandLayoutDisabled;
    },
    getIsCloseDragDrawer:(state) =>{
      return state.isCloseDragDrawer;
    },
    getElTableColumnMinWidth:(state) =>{
      return state.elTableColumnMinWidth;
    },
    getAllSubsections:(state) =>{
      let arr = [];
      state.helpJson.forEach(item =>{
        item.subsections.forEach(x =>{
          arr.push({
            title:x.title,
            id:x.id,
          })
        }) 
      })
      return arr;
    },
    getSubsectionTitle:(state, getters) => (id) => {
      return getters.getAllSubsections.find(i => i.id === id)?.title;
    },
  },
  mutations: {
    gettoken(state, token) {
      state.token = token
    },
    saveisCollapse(state,isCollapse){
      state.isCollapse=isCollapse
    },
    //用户指引
    patchRouterDisabled(state, bool){
      state.routerDisabled = bool
    },
    //登录成功后去的页面
    patchToPath(state, data){
      state.toPath = data
    },
    //监听浏览器的宽度
    changeScreenWidth(state, data) {
			state.screenWidth = data
		},
     //监听浏览器的高度
     changeScreenHeight(state, data) {
			state.screenHeight = data
		},
    // 商标档案上传--------------------
    //监听商标档案上传的进度
    setTmUploadPercentage(state, data){
      state.uploadPercentage = data;
    },
    // 打开商标档案上传
    OpenTmDocumentUpload(state,data){
      if (state.TmDocumentUploadIf) return state.TmDocumentUploadShow = true;
      if (data) {
        let {caseTable,tmInFo} = data;
        state.caseTable = caseTable;
        state.tmInFo = tmInFo;
      }
      state.TmDocumentUploadIf = true;
      state.TmDocumentUploadShow = true;
      state.uploadPercentage = 0;
      state.tmUploadIsRecognition = false;
    },
    // 隐藏商标档案上传
    setTmDocumentUploadRecognition(state){
      state.TmDocumentUploadShow = false;
    },
    // 关闭商标档案上传
    closeTmDocumentUpload(state){
      state.TmDocumentUploadIf = false;
      state.TmDocumentUploadShow = false;
      state.caseTable = [];
      state.tmInFo = {};
      state.uploadPercentage = 0;
      state.tmUploadIsRecognition = false;
    },
    // 修改系统是否正在识别商标档案上传文件
    setTmUploadIsRecognition(state,bool){
      state.tmUploadIsRecognition = bool
    },
    //  证据中心上传----------------------
    // 关闭证据中心上传
    closeEvidenceUpload(state){
      state.evidenceUpload.show = false;
      state.evidenceUpload.shouldKeepAlive = false;
      state.evidenceUpload.percentage = 0;
      state.evidenceUpload.Uploading = false;
      state.evidenceUpload.info = null;
    },
    // 打开证据中心上传
    openEvidenceUpload(state,data){
      if (state.evidenceUpload.shouldKeepAlive) return  state.evidenceUpload.show = true;
      state.evidenceUpload.shouldKeepAlive = true;
      state.evidenceUpload.show = true;
      state.evidenceUpload.percentage = 0;
      state.evidenceUpload.Uploading = false;
      state.evidenceUpload.info = data;
    },
    // 隐藏证据中心上传
    hideEvidenceUpload(state){
      state.evidenceUpload.show = false;
    },
    // 证据中心上传进度条
    setEvidenceUploadPercentage(state,data){
      state.evidenceUpload.percentage = data;
    },
    // 修改系统是否正在上传文件
    setEvidenceUploading(state,bool){
      state.evidenceUpload.Uploading = bool;
    },
    setG6ForceViewId(state,id){
      state.G6ForceViewId = id;
    },
    // 用户修改拖拽抽屉的大小
    userSetDragDrawerSize(state,size){
      size = parseInt(size) < 400 ? 400 : size;
      state.dragDrawerSize = size;
      localStorage.setItem('dragDrawerSize',size);
    },
    // 系统修改拖拽抽屉的大小（拖拽抽屉的组件监听浏览器的宽度和在created生命周期里）
    systemSetDragDrawerSize(state){
      // 拖拽抽屉的model大小，也是抽屉的大小
      let dragDrawerModelSize = state.screenWidth >= 1200 ? state.screenWidth - 640 : state.screenWidth - 56;
      // 找一下localStorage存储的大小,不用state.dragDrawerSize它可能在打开的时候没有
      let size = localStorage.getItem('dragDrawerSize');
      let newSize = 560;
      if (!size) {
        newSize =  Math.floor(dragDrawerModelSize * .8);
      }else if (size >= dragDrawerModelSize) {
        newSize =  dragDrawerModelSize - 50;
      }else {
        newSize =  size;
      }
      newSize = parseInt(newSize) < 400 ? 400 : newSize;
      state.dragDrawerSize = newSize;
      localStorage.setItem('dragDrawerSize',newSize);
    },
    setBrandLayoutDisabled(state,bool){
      state.brandLayoutDisabled = bool;
    },
    setIsCloseDragDrawer(state,bool){
      state.isCloseDragDrawer = bool;
    },
  },
  actions: {
  },
  modules: {
    ws_state: wsModule,
    evidenceModule:evidenceModule
  },
  plugins: [
    store => store.dispatch('STORE_INIT')
  ]
});
