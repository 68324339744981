<template>
  <div  v-watchWidth="info_width">
    <!-- 判断是否是未识别的官文 -->
    <div>
      <el-drawer
        :visible.sync="drawer"
        size="80%"
        :show-close="false"
        :modal="false"
      >
      <div slot="title">
        <div class="drawer_title" >
        <p style="width:100px">档案详情</p>
        <el-button-group style="padding-top:0">
          <el-button 
            icon="el-icon-arrow-left"
            @click="previousPage"
            v-if="show_button"
            class="info_button"
            size="mini" plain>上一档案</el-button>
          <el-button  
          size="mini" 
          style="margin-left:0!important"
          @click="nextPage"
          v-if="show_button"
          class="info_button"
          plain>
          下一档案
            <i class="el-icon-arrow-right el-icon--right" >
            </i>
          </el-button>
          <div v-if="!showButton">
            <el-button
              @click="editOd"
              icon="el-icon-edit"
              plain
              size="mini"
              class="info_button"
              style="border-radius: 6px;margin-left: 7px;"
              v-if="control "
            >编辑</el-button>
            <el-button
              @click="getback"
              style="border-radius: 6px;margin-left: 7px;"
              icon="el-icon-back"
              plain
              class="info_button"
              size="mini"
              v-else
            >返回</el-button>
            <el-button
              @click="saveOfficial"
              icon="el-icon-check"
              v-if="!control"
              plain
              size="mini"
              class="info_button"
              style="margin-left: 5px;border-radius: 6px;"
            >保存</el-button>
            <el-button
              @click="delOfficial"
              icon="el-icon-delete-solid"
              size="mini"
              plain
              type="danger"
              style="margin-left: 5px;border-radius: 6px;"
            >删除</el-button>
          </div>
           <i class="el-icon-close close_button" @click="drawer=false" ></i>       
        </el-button-group>        
        </div>
        <div class="drawer_original" style="width:100%">
          <div style="width:100%">
            <div style="padding:10px 30px;font-weight: 400;font-family: SourceHanSansSC;color: #101010;width:100%">
              <p style="display:flex;width:100%;">
                <span>文件名：</span>
              <span style="overflow: hidden;width: 420px;" @click="openLog">
                {{OfficialDetailData.originalname?OfficialDetailData.originalname:'无'}}</span> 
              </p>
              <p style="display:flex;width:100%;margin-top:10px;padding-left:-20px;">
                <span style="color:#b5b5b6">文件大小：</span>
              <span style="overflow: hidden;width: 420px;color:#b5b5b6;">
                {{file_size?file_size:'无'}}</span> 
              </p>
            </div>
            <p 
              class="showButtonClass">
              <el-link
              v-show="!innerDrawer && !OSSDrawerVisible" 
              @click="lookOfficials" 
              type="primary" 
              :disabled="!OfficialForm.filelink" 
              :underline="false">
                <i class="el-icon-view"></i>
                查看档案
              </el-link>
              <el-link
              v-show="innerDrawer || OSSDrawerVisible" 
              @click="closePreviewDra" 
              type="primary" 
              :disabled="!OfficialForm.filelink" 
              :underline="false">
                <i class="el-icon-view"></i>
                关闭档案
              </el-link>
              <el-link @click="oddownload" 
              v-if="$store.getters.is_authorized_path('action_download_document')"
              type="primary" 
              :disabled="!OfficialForm.filelink" 
              :underline="false">
                <i class="el-icon-download"></i>
                下载</el-link>
              <span @click="handleCommand('bockRecognition')"
                v-if="!showButton"
                style="cursor: pointer;color:#29c5ee;"
              > <i class="el-icon-refresh-right"></i> 再次识别</span>
              <el-checkbox v-model="is_evidence" @change="handleChangeEvidence" v-if="$store.getters.get_optional_features('evidenceCenter')">保存到证据中心</el-checkbox>
              <el-link 
                type="primary" 
                v-if="candidate_casetypes.length!=0"
                style="margin-left:15px;"
                @click="add_case"
                :underline="false"
                >
                立案</el-link>
            </p>
          </div>
        </div>
        <el-menu
            style="display: flex; align-items: center"
            class="el-menu-demo"
            mode="horizontal"
            default-active="1"
          >
            <el-menu-item index="1">
              <el-link
                :underline="false"
                @click="goToDetail('#od_info')"
              >基本信息</el-link>
            </el-menu-item>
            <el-menu-item index="2" v-if="trademarkData">
              <el-link
                :underline="false"
                @click="goToDetail('#doc_tm')"
              >所属商标</el-link>
            </el-menu-item>
            <el-menu-item index="3" v-if="OfficialForm.ref_trademarks && OfficialForm.ref_trademarks.length !== 0">
              <el-link
                :underline="false"
                @click="goToDetail('#authority')"
              >引证商标</el-link>
            </el-menu-item>
            <el-menu-item index="4" v-if="casesForm && casesForm.length !== 0">              
              <el-link
                :underline="false"
                @click="goToDetail('#cose')"
              >案件</el-link>
            </el-menu-item>
            <el-menu-item index="5" v-if="OfficialForm.doc_type == 'od'">              
              <el-link
                :underline="false"
                @click="goToDetail('#od_value')"
              >官文内容</el-link>
            </el-menu-item>            
        </el-menu>
        </div>
        <div class="drawer_mian">
          <div  class="drawer_mian_div" id="od_info">
            <div style="display:flex">
              <p style="width:80px">基本信息</p>
              <el-form
                label-suffix=":"
                ref="form"
                :model="OfficialForm"
                label-width="125px"
                size="mini"
                class="demo-form-inline"
                style="width:400px"
              >
                <!-- <el-form-item label="文档类型" v-if="OfficialForm.doc_type=='tmatch'">
                  <p >其他文档</p>
                </el-form-item> -->
              <el-form-item label="档案类型">
                <template>
                  <el-row class="fonthidden">
                      <div >
                        <p v-if="control">{{OfficialForm.doc_type=='od'?'官文':'其他文件'}}</p>
                        <el-select
                          v-else
                          size="small"
                          style="width:190px"
                          v-model="doc_type"
                          filterable
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in doc_types"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                  </el-row>
                </template>
              </el-form-item>
                <el-form-item label="官文类型" v-if="doc_type=='od'">
                  <p v-if="control">{{OfficialForm.type?OfficialForm.type:'无'}}</p>
                  <el-autocomplete
                    v-model="OfficialForm.type"
                    v-else
                    ref="od_type"
                    size="small"
                    style="width:190px"
                    clearable
                    :fetch-suggestions="getODoptions"
                    placeholder="请输入官文内容"
                    @select="handleSelect"
                    @clear="doc_typeClear"
                    :popper-append-to-body="false"
                  >
                    <template slot-scope="{ item }" >
                      <div class="name" >{{ item }}</div>
                    </template>
                  </el-autocomplete>
                </el-form-item>
                <el-form-item label="商标号">
                  <!-- v-if="control" -->
                  <span style="font-weight: 700;color: #000000;margin-right:5px" v-if="control" v-copyText>{{OfficialForm.reg_num?OfficialForm.reg_num:'无'}}</span>
                  <el-select
                    style="width:190px"
                    v-else
                    v-model="OfficialForm.reg_num"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    class="select"
                    placeholder="请输入商标号"
                    :remote-method="remoteMethod"
                    @clear="clear_reg_num"
                  >
                    <el-option
                    v-for="item in tm_candidates"
                    :key="item._id"
                    :label=" item.reg_num + ':' + item.name "
                    :value="item.reg_num"
                    >
                    </el-option>
                  </el-select>           
                  <span
                    style="  color: #29c5ee;cursor: pointer;"
                    v-if="details && control"
                    @click="gotoTMpage(trademarkData?._id)"
                  >详情>></span>  
                </el-form-item>
                <el-form-item label="收文日期">
                  <div v-if="control">
                    <p>{{OfficialForm.receive_date_str?OfficialForm.receive_date_str:'无'}}</p>
                    <!-- <el-tooltip
                      class="item"
                      effect="dark"
                      :content="OfficialForm.receive_date_info"
                      placement="left">
                      <i
                        class="el-icon-warning-outline"
                        style="color:#ffa228;margin-left:2px"
                        v-if="OfficialForm.receive_date_info"
                      ></i>
                    </el-tooltip>   -->
                    <p v-if="OfficialForm.receive_date_info" style="word-wrap: break-word;"> {{ OfficialForm.receive_date_info}}</p>
                  </div>
                  <!-- <el-input v-model="OfficialForm.receive_date_str" v-else></el-input> -->
                  <el-date-picker
                    v-else
                    @change="OfficialForm.receive_date_str = changeValue($event,'receive_date_str')"
                    :clearable="true"
                    v-model="OfficialForm.receive_date"
                    type="date"
                    style="width: 190px"
                  >
                  </el-date-picker>                
                </el-form-item>
                <el-form-item label="官文时限" v-if="doc_type=='od'">
                  <p v-if="control">{{OfficialForm.time_limit| filtertimeLimit(OfficialForm.time_limit)}} </p>
                  <!-- <el-input v-else v-model="OfficialForm.time_limit" style="width:190px"
                  placeholder="官文处理的时限要求，天数"
                  clearable
                  @input="changeValue($event,'receive_date_str')"> 
                  </el-input> -->
                  <el-select v-model="OfficialForm.time_limit" placeholder="官文处理的时限要求，天数" style="width:190px" v-else clearable  @change="changeValue($event,'receive_date_str')">
                    <el-option
                      v-for="item in trademarkEditItem['document_time_limit']"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="官方绝限" v-if="doc_type=='od'">
                  <p v-if="control">{{OfficialForm.expiration_date_str?OfficialForm.expiration_date_str:'无'}}</p>
                  <el-date-picker
                    v-else
                    @change="OfficialForm.expiration_date_str = changeValue($event,'expiration_date_str')"
                    v-model="OfficialForm.expiration_date_str"
                    placeholder="根据收文日期和时限计算"
                    :readonly="true"
                    type="date"
                    style="width: 190px"
                  >
                  </el-date-picker>  
                </el-form-item>
                <el-form-item label="处理状态" v-if="OfficialForm.doc_type=='od'&&doc_type=='od'">
                  <p v-if="control">{{OfficialForm.has_processed?"已处理":'未处理'}}</p>
                  <el-radio-group v-model="OfficialForm.has_processed" v-else>
                    <el-radio-button :label='true'>已处理</el-radio-button>
                    <el-radio-button :label='false'>未处理</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="备注" >
                  <p v-if="control" class="commentClass" style="white-space: pre-wrap;">{{OfficialForm.comments?OfficialForm.comments:'无'}}</p>
                  <el-input v-model="OfficialForm.comments" v-else   type="textarea"
                        :autosize="$store.state.autosize" 
                        style="width: 190px"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <el-image
              v-if="trademarkData.reg_num"
              style="
              overflow: hidden;
              width: 200px;
              height: 200px;
              margin-top:30px;
              "
              :src="
              trademarkData.reg_num
              ? '' + '/tmimage?$imgdata&reg_num=' + trademarkData.reg_num
              : ''
              "
              >
              {{ trademarkData.reg_num }}
            </el-image>
          </div>
          <div class="drawer_mian_div" v-if="trademarkData" id="doc_tm">
            <div style="display:flex">
              <p  style="width:80px">所属商标</p>
              <el-form
                label-suffix=":"
                ref="form"
                :model="OfficialForm"
                label-width="125px"
                size="mini"
                class="demo-form-inline"
              >
                <el-form-item
                  label="品牌名称"
                >             
                <template>
                  <p
                    class="fonthidden"
                    v-if="!Inputbrand_name"
                    @click="clickbrand_name"
                    style="font-weight: bold; cursor: pointer"
                  >
                    {{ brand_name ? brand_name : "无" }}
                    <i class="el-icon-edit-outline"></i>
                  </p>
                  <el-row
                    v-else
                    class="fonthidden"
                  >
                    <el-col :span="8">
                      <el-select
                        v-model="brand_name"
                        filterable
                        clearable
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in Arrbrandname"
                          :key="item.name"
                          :label="item.name"
                          :value="item.name"
                        >
                          <span style="float: left">{{ item.name }}</span>
                          <span style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            ">{{ item.level | filterLevel }}</span>
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="4">
                      <el-button
                        size="mini"
                        type="primary"
                        @click.stop="blurbrand_name"
                        style="margin-left: 10px"
                      >保存</el-button>
                    </el-col>
                    <el-col :span="3">
                      <el-button
                        size="mini"
                        @click.stop="cancelBrandSelect"
                      >取消</el-button>
                    </el-col>
                  </el-row>
                </template>
              </el-form-item>
              <el-form-item label="商标名称">
                <p v-copyText>
                  {{trademarkData.name?trademarkData.name:'无'}}
                  </p>               
              </el-form-item>
              <el-form-item label="商标申请人">
                <p v-copyText>{{trademarkData.applicant && 
                  trademarkData.applicant.applicant_cn ? trademarkData.applicant.applicant_cn: 
                  (trademarkData.applicant_cn ? trademarkData.applicant_cn : '无')}}
                </p> 
              </el-form-item>
              <el-form-item label="类别">
                <p >{{trademarkData.class?`第${trademarkData.class}类`:'无'}}</p>
                <!-- <el-input v-model="trademarkData.class" v-else style="width:190px"></el-input> -->
              </el-form-item>
              <el-form-item label="商标流程">
                <el-popover v-if=" trademarkData.flows !== undefined &&trademarkData.flows.length > 0"
                placement="right"
                width="520"
                height="500"
                title="商标流程"
                trigger="click">
                  <el-table :data="trademarkData.flows"  style="font-weight: bold" max-height="350">
                    <el-table-column
                      property="date_str"
                      label="流程日期"
                      width="150px"
                    ></el-table-column>
                    <el-table-column
                      property="category"
                      label="流程描述"
                    ></el-table-column>
                  </el-table>
                  <p  slot="reference">
                      {{
                        `${
                          trademarkData.flows[trademarkData.flows.length - 1]
                            .date_str
                        } ${
                          trademarkData.flows[trademarkData.flows.length - 1]
                            .category
                        }`
                      }}
                      <i class="el-icon-info" style="cursor: pointer"></i>
                  </p>
                </el-popover>
                <p v-else>无</p>
                </el-form-item>
            </el-form>
          </div>
          </div>
          <div class="drawer_mian_div" v-if="trademarkData">
            <p style=" padding-top: 14px; margin-right: 30px;width:80px">商品或服务</p>
          <template >
            <el-table
              :data="goodsData"
              style="margin-bottom:40px"
            >
              <el-table-column
                prop="group_name"
                label="群组"
              > </el-table-column>
              <el-table-column
                prop="name"
                label="商品/服务名称"
              > </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <span style="color: #ffa837">
                    {{ scope.row["status"] }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </template>
          </div>
          <div class="drawer_mian_div" v-if="casesForm && casesForm.length !== 0" id="cose">
            <p style="width:80px">所属案件</p>
            <div style="width:85%;margin-left:20px;margin-top:-14px">
              <el-table
                :data="casesForm"
                style="width: 100%"
              >
                <template v-for="item in trademarkEditItem['caseFields']">
                  <el-table-column
                    v-if="item.label != '代理文号' && item.label != '官方申请号'"
                    :label="item.label"
                    :key="item.label"
                    show-overflow-tooltip
                  >
                    <template
                      slot-scope="scope"
                      v-if="item.label != '代理文号'"
                    >
                      <span :style="item.prop == 'status' ? 'color:#ffa837' : ''">
                        {{
                        scope.row[item.prop]
                          | filterItem(item.filter, item.twoLevel)
                      }}
                      </span>
                    </template>
                  </el-table-column>
                </template>
              </el-table> 
            </div>
          </div>
          <div class="drawer_mian_div"  v-if="OfficialForm.ref_trademarks && ref_OD_info.length !== 0" id="authority">
            <div style="display:flex;flex:1">
              <p style="width:80px">引证商标</p>
              <div style="margin-left:20px;flex:1;" v-if="ref_OD_info.length">
                <div style="display:flex;justify-content: space-between;margin-bottom:8px">
                  <span>共有{{ref_OD_info.length}}个引证商标</span>
                  <!-- <p>查看引证商标详情</p> -->
                 <el-button type="primary" size="mini" @click="open_refTM_drawer">引证商标分析</el-button>
                </div>
                <el-table :data="ref_OD_info"  style="width: 100%" border is-horizontal>
                  <el-table-column
                    type="index"
                    width="50">
                <template slot="header" >
                    <span>序号</span>
                      </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="商标名称"
                  > </el-table-column>
                  <el-table-column
                    prop="reg_num"
                    label="商标号"
                    width="100"
                  >
                  <template slot-scope="scope">
                    <div v-if="scope.row.reg_num">
                      <span v-copyText>{{scope.row.reg_num}}</span>
                    </div>
                  </template>
                   </el-table-column>
                  <el-table-column
                    prop="class"
                    label="类别"
                  > </el-table-column>
                  <el-table-column
                    prop="applicant.applicant_cn"
                    label="商标注册人"
                    min-width="180px"
                  > </el-table-column>
                </el-table>
                <!-- <div
                  class="authority"
                  v-for="(v, i) in this.OfficialForm.ref_trademarks"
                  :key="i"
                >
                  <div class="img_preview">
                    <div class="demo-image__preview">
                      <el-image
                        style="
                          border-radius: 50%;
                          overflow: hidden;
                          width: 90px;
                          height: 90px;
                        "
                        :src="get_img_url(v)"
                      >
                      </el-image>
                    </div>
                  </div>
                  <div class="authority_describe">
                    <span
                      class="left"
                      v-if="v.is_case_main"
                      @click="gotoTMpage(v._id)"
                    >详情>></span>
                    <h3 style="font-weight: bold; font-size: 16px">
                      商标名称: {{ v.name?v.name:'无' }}
                    </h3>
                    <div>
                      <p>商标号:{{ v.reg_num }}</p>
                      <template v-for="(v1, i1) in v.od_info">
                        <p
                          :key="i1"
                          v-if="v1.name == '引证商标注册人'"
                          style=" font-size: 14px"
                        >
                          {{ v1.name | filterOD_info(v1.name) }}:{{ v1.value }}
                        </p>
                        <template v-else>
                        <p
                          style="font-size: 14px"
                          v-if="v1.name!='引证商标号'"
                          :key="i1"
                        >
                        
                          {{ v1.name| filterOD_info(v1.name) }}:{{ v1.value }}
                        </p>
                        </template>

                      </template>
                    </div>
                    <template v-if="control">
                      <div style="display: flex">
                        <p style="width: 50px">备注:</p>
                        <p v-if="v.custom_notes">{{ v.custom_notes }}</p>
                        <p v-else>无</p>
                      </div>
                    </template>
                    <el-input
                      v-else
                      type="textarea"
                      placeholder="输入备注内容"
                      v-model="v.custom_notes"
                      maxlength="300"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="drawer_mian_div" id="od_value" v-if="OfficialForm.doc_type == 'od'">
            <div style="display:flex">
              <p style="width:80px">官文内容</p>
              <el-form
                label-suffix=":"
                ref="form"
                :model="OfficialForm"
                label-width="125px"
                size="mini"
                class="demo-form-inline"
              >
                  <template v-for="v of OfficialExplain">
                    <template v-if="!v.title">
                      <div
                        :key="i"
                        v-for="(n, i) in OfficialForm[v.key]"
                      >
                        <el-form-item
                          :label="n.name"
                          v-if="is_visible_ocr_field(n.code)"
                        >
                          <template v-if="control">
                            <p class="fonthidden">{{ n.value }}</p>
                          </template>
                          <template v-else>
                            <el-date-picker
                              v-if="n.name=='申请日期'"
                              v-model="n.value"
                              type="date"
                              placeholder="选择申请日期"
                              format="yyyy年MM月dd日"
                              value-format="yyyy年MM月dd日"
                              :readonly="control">
                            </el-date-picker>                          
                            <el-input
                              v-else
                              @blur="n.value = changeValue($event)"
                              v-model="n.value"
                              :readonly="control"
                            ></el-input>
                          </template>
                        </el-form-item>
                      </div>
                    </template>
                  </template>
              </el-form>
            </div>
          </div>
        </div>
      </el-drawer>

      <register v-if="casedialogVisible" ref="case_dialog"
      :case_form='caseForm'
      :doc_id ='od_id'
      @changecasedialog="changecasedialog"
      :readOnly="true"
      ></register>
      <!-- 引证商标对比表-->
      <refTMdetails v-if="refTM_drawer"
      :ref_OD_info="ref_OD_infoCopy"
      @changerefTMdetails="changerefTMdetails"
      @changerefTMData="changerefTMData"
      >
      </refTMdetails>

      <removerod v-if="delDialogVisible"
        @changeDelDialogVisible="changeDelDialogVisible"
        @remove_od="remove_od"
        :reg_num="trademarkData?.reg_num">
      </removerod>
      
      <!-- 调用OSS的JS-SDK技术 -->
      <OSSDrawer
      v-if="OSSDrawerVisible" 
      :servicesKey="servicesKey"
      :docId="OfficialForm._id"
      :status="status"
      @closeDrawer="handleCloseOSSDrawer"
      />

      <!-- 查看官文pdf -->
      <PdfBox 
      v-if="innerDrawer"
      :filelink="filelink" 
      :fileName="fileName" 
      :fileSize="OfficialForm.size" 
      @closeDrawer="handleClosePdfDrawer"
      />
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import PdfBox from "@/components/previewFile/box.vue";
import register from "../components/register";
import refTMdetails from "../components/refTMdetails";
import removerod from "../components/removeod.vue"
import { trademarkEdit } from "../utils/data";
import OSSDrawer from '@/components/OSSJSSDK.vue';
import {
  GetFile,
  updateOfficial,
  Gettrademark,
  patchcases,
  getodprocess,
  updateOfficialstate,
  Brand,
  delofficial,
  PATCHtrademark,
  findCases,
  patchOfficial,
  Getcases,
  PushBrand,
  postBind,
  findBind,
  delBind,
} from "../api/axios";
import { toYMDTime, get_timeLimit, getNextWorkingDay,get_size_str,patchTrademarkBrand,headFileUrlUtils,downloadFile } from "../utils/index";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "Officialdetails",
  components: {
    PdfBox,
    register,
    refTMdetails,
    removerod,
    OSSDrawer
  },
  props: {
    OfficialDetailData: {
      trademark: {},    // 商标信息
      ref_trademarks: [],  //没用
    },
    OfficialExplain: [],     // 官文信息
    tmatchExplain: [],   // 其他文件信息
    //数组id
    OfficialId: [],  //文件ID总和   
    doc_id: "",  //当前文件id
    details: null,   //是否显示商标详情页的跳转
    showButton:null,
  },
  data() {
    return {
      OfficialForm: {
        trademark: {
          brand_name: "",
          tm_alias:'',
          name:''
        },
        ref_trademarks: [],
      },
      identifyForm: {
        type: {
          type: "",
        },
        TMcase: "",
        tm_name: "",
        TMcase: "",
      },
      tm_name: [],
      filelink: "",
      fileName:"",
      //控制详情页是否是只读属性
      control: true,
      innerDrawer: false,
      drawer: true,
      TMcases: [],
      base_typeArr: [],
      case_typeArr: [],
      trademarkEditItem: new trademarkEdit(),
      caseData: [],
      casetypeFrom: {},
      Data: [],
      casedrawer: false,
      // isAppNum: false,
      // isApplicant: false,
      isRegNum: false,
      isApplicant: false,
      brand_id: "",
      brand_name: "",
      Inputbrand_name: false,
      Inputhas_processed: false,
      brandform: {},
      //商标名称数组
      Arrbrandname: [],
      show_doc_content_fields: true,
      doc_types: [
        {
          value: "od",
          name: "官文",
        },
        {
          value: "tmatch",
          name: "其他文件",
        },
      ],
      doc_type: "tmatch",
      levelvalue: [],
      ODoptions: [],
      tm_candidates: [],
      casesForm: [],
      has_processed: null,
      Inputcomments: false,
      comments:null,
      tmatchControl:true,
      tm_id:'',
      goodsData:[],
      file_size:null,
      debounce_load_tm:null,
      is_evidence:false,   // 是否在证据中心
      show_button:true,
      casedialogVisible:false,
      caseForm:{},
      od_id:'',
      case_list:[],
      candidate_casetypes:[],
      ref_OD_info:[],
      refTM_drawer:false,
      ref_OD_infoCopy:[],
      OfficialFormCopy:{},
      trademarkData:{},
      delDialogVisible:false,

      //OSS的JS-SDK抽屉所需的参数
      OSSDrawerVisible:false,
      servicesKey:'document',
      status:'cachePreview',
      isOssPreview:false,
    };
  },
  async mounted() {
    this.getTrademark_complex();
    // 根据商标号来模糊查询  初始化函数
    this.debounce_load_tm = _.debounce(async (num) => {
      let { data } = await Gettrademark({
        reg_num: `${num}*`,
      });
      Vue.set(this, "tm_candidates", data);
    }, 5e2);
    // 根据父组件的值来渲染数据
    // this.handleMounted();
  },
  methods: {
    openLog(){
      console.log(this.OfficialDetailData,'docs');
    },
    //获取商标复合体数据
    async getTrademark_complex(id){
      var tm_id = ''
      if (id) {
        tm_id = id;
      }else{
         tm_id = this.OfficialDetailData.tm_ids?.length ? this.OfficialDetailData?.tm_ids[0] : '';
      }
      if (tm_id) {
        var data = this.$store.getters.get_trademark_complex(tm_id);
        if (data) {
          this.trademarkData = data;
        }else{
            this.trademarkData = await this.$store.dispatch("load_trademark_complex", {
              tm_id: tm_id,
            });
        }
      }
      return this.handleMounted();    
    },
    // 证据中心
    async handleChangeEvidence(val){
      // 商标名称
      let data = {
        is_evidence:val,
        
        logic_path:this.OfficialForm.tm_name == "TMNotFound" ? ( this.OfficialForm.trademark? this.OfficialForm.trademark?.name: "未知官文") : this.OfficialForm.tm_name
      }
      await patchOfficial(this.OfficialForm._id,data)
      this.$emit('Getofficial')
    },
    async handleMounted(){
      // 深复制  不可修改props的值
      this.OfficialFormCopy = _.cloneDeep(this.OfficialDetailData);
      this.OfficialForm = _.cloneDeep(this.OfficialDetailData);

      this.isOssPreview = this.OfficialForm.isOssPreview;
      // 如果文件不能预览，且抽屉打开，我们应该先关闭这个抽屉，再去打开另一个抽屉
      if (this.OSSDrawerVisible || this.innerDrawer) {
        this.lookOfficials();
      }
      
      if (this.trademarkData.reg_num) {
        this.OfficialForm.reg_num = this.trademarkData.reg_num;
      }
      // 2s后更改官文的状态------------
      setTimeout(async () => {
        if (!this.OfficialForm.has_read && this.OfficialForm.has_read != null) {
          try {
            await updateOfficialstate(this.OfficialForm._id, { has_read: true });
            // 删除未读档案
            this.$EventBus.$emit('delBeprocessedOfficialTableId',this.OfficialForm._id);
            console.log(`官文${this.OfficialForm._id}被标记为已读!`);
          } catch (error) {
            console.log(error);
          }
        }
      }, 2e3);
      // 商标流程  转一下时间
      if (this.OfficialForm && this.trademarkData  && this.trademarkData.flows) {
        this.trademarkData.flows?.forEach(flow=>{
          flow.date_str = toYMDTime(flow.date)
        })
      }
      // 时间的转化--------------
      // 收文时间
      this.OfficialForm.receive_date_str = toYMDTime(
        this.OfficialForm.receive_date
      );
      // 官方绝限
      this.OfficialForm.expiration_date_str = toYMDTime(
        this.OfficialForm.expiration_date
      );
      // 官方时限
      this.OfficialForm.time_limit_str = this.OfficialForm.time_limit
      // 判断引证商标是否出现
      this.ref_OD_infoCopy = [];
      if (this.OfficialForm.ref_trademarks&&this.OfficialForm.ref_trademarks.length) {
        for (const item of this.OfficialDetailData.ref_trademarks) {
            var data = {}
          data =  await Gettrademark({
            id:item._id,
            // $nodecro: true,
            // $select: [ "is_case_main"],
          })
          // item.is_case_main = data.is_case_main;
          this.ref_OD_infoCopy.push(data);          
        }
        this.ref_OD_info = _.cloneDeep(this.ref_OD_infoCopy);
        this.ref_OD_infoCopy.unshift(this.trademarkData);
      }
      // 文件大小
      this.file_size  = get_size_str(this.OfficialForm.size);
      // 证据中心
      this.is_evidence = this.OfficialForm.is_evidence
      // 官文类型  官文和其他 来显示内容
      this.doc_type = _.cloneDeep(this.OfficialForm.doc_type);
      //获取品牌名称
      if (this.trademarkData) {
        // var { data: tmdata } = await Gettrademark({
        //   reg_num: this.trademarkData.reg_num,
        // });
        if (this.trademarkData._id) {
          this.tm_id = this.trademarkData._id
          var data = await Brand({});
          this.GetBrandname(data);
          //获取品牌名称并将品牌id存储
          this.brandform = this.trademarkData;
          // 从商标复合体档案中获取case列表
          this.case_list = this.trademarkData.case_list
          let docs = this.trademarkData.tc_docs.find(item=>item._id==this.OfficialForm._id);
          if (docs) {
            this.casesForm = _.sortBy(docs.cases, o => o.create_time);
            // this.case_from = docs.cases;
          }
          this.brand_name = this.trademarkData.brand_name;
          await this.handlePutTrademarkData(this.trademarkData?.applicant?.id)
        }
        if (this.trademarkData?._id) {
          try {
          let obj = {
            $get_candidate_casetypes:1,
            tm_id:this.trademarkData._id,
            doc_id:this.OfficialForm._id
            }
            let data =  await Getcases(obj);
            if (data[0].case_type) {
             this.candidate_casetypes =data;
            }else{
              this.candidate_casetypes = [];
            }
          } catch (error) {
            console.log(error);
            this.candidate_casetypes = [];
          }    
        }
      }
      // 商标不存在   去查修改记录
      if (
        this.OfficialForm.tm_name == "TMNotFound" &&
        !this.trademarkData
      ) {
        this.getod();
      }
      // if (this.OfficialForm.ref_trademarks) {
      //   this.ref_OD_info = this.OfficialForm.ref_trademarks;
      // }
    },
    // 初始化  商品和服务  案件
    async handlePutTrademarkData(applicantId){
      // 案件table表格
      try {
        this.has_processed = _.cloneDeep(this.OfficialForm.has_processed);
        // if (this.case_list &&this.case_list.length >= 1) {
        //   const { data } = await findCases({
        //     _id: { $in: this.case_list },
        //   });
        //   //  this.casesForm = data;
        //   this.casesForm = _.sortBy(data, o => o.create_time);
        // }
      } catch (error) {
        console.log(error);
      }

      // 商品和服务   .filter((gd) => gd.code && gd.name)
      if (this.trademarkData && this.trademarkData.goods) {
        this.goodsData = []
        this.trademarkData.goods
          .forEach((good) => {
            //按状态和
            let group = this.goodsData.find(
              (gd) => gd.group == good.group && gd.status == good.status
            );
            if (!group) {
              group = {
                group: good.group,
                group_title: good.group_title,
                group_name: `${good.group ? good.group : ""} - ${
                  good.group_title ? good.group_title : ""
                }`,
                status: good.status,
                name: good.name,
                good_code: [],
              };
              group.good_code.push(good.code);
              this.goodsData.push(group);
            } else {
              group.name += `，` + good.name;
              group.good_code.push(good.code);
            }
          });
        this.goodsData.sort((a, b) =>
          a.status != b.status ? a.status - b.status : a.group_name - b.group_name
        );
        this.goodsData = _.orderBy(this.goodsData,['group'])
      }
    },
    async refresh() {
      this.OfficialForm.receive_date_str = toYMDTime(
        this.OfficialForm.receive_date
      );
      this.OfficialForm.expiration_date_str = toYMDTime(
        this.OfficialForm.expiration_date
      );

        //获取品牌名称
        if (this.trademarkData) {
          // var { data: tmdata } = await Gettrademark({
          //   reg_num: this.trademarkData.reg_num,
          // });
          // console.log("商标", tmdata[0]);
          var data = await Brand({});
          this.GetBrandname(data);
          //获取品牌名称并将品牌id存储
          this.brandform = this.trademarkData;
          this.brand_name = this.trademarkData.brand_name;
        }
    },
    async handleCommand(command) {
      if (command == "bockRecognition") {
        // console.log(this.doc_id);
        console.log("再次识别");
        if (this.doc_id == "") {
          this.$message({
            showClose: true,
            message: "请先选择要识别的官文",
            type: "warning",
          });
        } else {
          var row = this.OfficialForm._id;
          console.log(row);
          this.$notify.info({
            title: '开始重新识别',
            message: `档案: ${this.OfficialForm?.originalname} ...`
          })
          try {
            await updateOfficialstate(row, {
              has_read: false,
              trigger_recognize: 1,
              image_pdf:true,
            });
          } catch (error) {
            console.log(error);
          }
        }
      } else if (command == "del") {
        this.$confirm(
          `此操作将永久删除档案'${this.OfficialForm.type}', 是否继续?`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              // console.log(this.OfficialForm._id);
              await delofficial(this.OfficialForm._id);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.drawer = false;
              this.$emit("Getofficial");
              this.$emit("closeSelection")
            } catch (error) {
              console.log(error);
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
        console.log("删除官文");
      }
    },
    delOfficial(){
      this.delDialogVisible = true;
    },
    changeDelDialogVisible(n){
      this.delDialogVisible = n;
    },
    async remove_od(n){
      if (n) {
        await delofficial(this.OfficialForm._id);
        this.$message({
          type: "success",
          message: "删除成功!",
        });        
      }else{
        var doc_tm_id = this.OfficialDetailData.tm_ids?.length ? this.OfficialDetailData?.tm_ids[0] : '';
        await patchcases(null,{
          tm_id:doc_tm_id,
          doc_id:this.OfficialForm._id,
        },{
          $remove_bind:1
        })      
      }
      this.drawer = false;
      this.delDialogVisible = false;
      this.$emit("Getofficial");
    },
    is_visible_ocr_field(field_code) {
      return (
        field_code != "APP_NUM" &&
        field_code != "REG_NUM" &&
        field_code != "TM_NUM"
      );
    },
    //获取品牌全部名称
    GetBrandname(brandname) {
      //  console.log(brandname);
      let Brandnamearr = JSON.parse(JSON.stringify(brandname));
      let arr = [];
      Brandnamearr.forEach((n) => {
        arr = [
          ...arr,
          {
            name: n.name,
            level: n.level,
          },
        ];
        if (n.children) {
          arr = [...arr, ...this.flatten(n.children)];
        }
      });
      // console.log(arr);
      this.Arrbrandname = JSON.parse(JSON.stringify(arr));
      this.Arrbrandname.unshift({ name: "自动选择" });
    },
    flatten(obj) {
      let arr = [];
      obj.forEach((v) => {
        arr = [...arr, v];
        if (v.children) {
          arr = [...arr, ...this.flatten(v.children)];
        }
      });
      return arr;
    },
    //查看官文pdf
    async lookOfficials() {
      if (this.isOssPreview) {
        this.innerDrawer = false;
        this.OSSDrawerVisible = true;
      }else{
        let urlIsTrue = await headFileUrlUtils(this.OfficialForm.filelink);
        if (!urlIsTrue) {
          this.OSSDrawerVisible = false;
          this.innerDrawer = false;
          return;
        }
        this.filelink = this.OfficialForm.filelink;
        this.fileName = this.OfficialForm.originalname;
        this.OSSDrawerVisible = false;
        this.innerDrawer = true;
      } 
    },
    closePreviewDra(){
      this.innerDrawer = false;
      this.OSSDrawerVisible = false;
    },
    handleCloseOSSDrawer(){
      this.OSSDrawerVisible = false;
    },
    handleClosePdfDrawer(){
      this.innerDrawer = false;
    },
    //保存修改官文
    async saveOfficial() {
      if (this.OfficialForm.trademark) {
        this.OfficialForm.trademark.reg_num = this.OfficialForm.reg_num;
      }
      if (!_.isEqual(this.OfficialDetailData.receive_date_str,this.OfficialForm.receive_date)) {
        this.OfficialForm.receive_date_str = toYMDTime(this.OfficialForm.receive_date);
      }
      if (!_.isEqual(this.OfficialDetailData.expiration_date_str,this.OfficialForm.expiration_date_str)) {
        this.OfficialForm.expiration_date = this.OfficialForm.expiration_date_str
      }
      if (this.levelvalue.length >= 3) {
        this.OfficialForm.type = this.levelvalue[2];
      }
      if (this.doc_type) {
        this.OfficialForm.doc_type = this.doc_type
      }
      try {
        if (this.OfficialForm.time_limit == "空") {
          this.OfficialForm.time_limit = 0;
        }
        this.control = true;
        if (!_.isEqual(this.OfficialDetailData.goods,this.OfficialForm.goods)) {
          this.OfficialForm.sync_tm_goods = true;
        }
        if (!_.isEqual(this.OfficialDetailData.reg_num,this.OfficialForm.reg_num)) {
            // var {data:findData} = await findBind({
            //   od_id:this.OfficialForm._id
            // })
            // if (findData[0]?._id) {
            //   await delBind(findData[0]._id)
            // }
          if (this.OfficialForm.reg_num) {
              var { data:TM_data } = await Gettrademark({
                reg_num: this.OfficialForm.reg_num,
              });
              var doc_tm_id = this.OfficialDetailData.tm_ids?.length ? this.OfficialDetailData?.tm_ids[0] : '';
              if (doc_tm_id) {
                var tm_id = TM_data[0]?._id
                  await patchcases(null,{
                  tm_id:doc_tm_id,
                  doc_id:this.OfficialForm._id,
                },{
                  $remove_bind:1
                })                
              }
              var tm_id = TM_data[0]?._id
                await patchcases(null,{
                tm_id:tm_id,
                doc_id:this.OfficialForm._id,
                ref_tm_bind:false,
              },{
                $manual_bind:1
              })
              // await postBind({
              //   tm_id:TM_data[0]?._id,
              //   od_id:this.OfficialForm._id,
              //   ref_tm_bind:false,
              //   recognized_auto:true,
              // });       
          }else{
            this.OfficialForm.trademark ={};
          }
        }
       let putData =  await updateOfficial(this.OfficialForm);
        this.$message({
          showClose: true,
          message: "修改官文成功",
          type: "success",
        });
        if (this.OfficialForm.time_limit == 0) {
          this.OfficialForm.time_limit = "空";
        }
        // this.drawer = false;
        this.$emit("Getofficial");  // 重新请求父组件的table列表
        this.$emit('putDate',this.OfficialForm._id,putData)   // 修改上传官文的某个时间
        this.OfficialFormCopy = _.cloneDeep(this.OfficialForm);
        this.getTrademark_complex(tm_id);
      } catch (error) {
        this.$message({
          showClose: true,
          message: "修改官文失败",
          type: "error",
        });
        this.OfficialForm = _.cloneDeep(this.OfficialFormCopy);
      }
    },
    //上一页详情页
    async previousPage() {
      // console.log("目前详情页id", this.OfficialForm._id);
      //查到当前详情页id在数组中的位置
      var ids = this.OfficialId.indexOf(this.OfficialForm._id);
      var arrid = ids - 1;
      if (ids == 0) {
        arrid = this.OfficialId.length - 1;
      }
      var data = await GetFile({ id: this.OfficialId[arrid] });
      await this.$emit("changeOfficialForm", data);
      this.filelink = data.filelink;
      this.fileName = data.originalname
      await this.handlePutTrademarkData(data.trademark?.applicant?.id)
    },
    //下一页详情页
    async nextPage() {
      //查到当前详情页id在数组中的位置
      var ids = this.OfficialId.indexOf(this.OfficialForm._id);
      var arrid = ids + 1;
      if (ids == this.OfficialId.length - 1) {
        arrid = 0;
      }
      var data = await GetFile({ id: this.OfficialId[arrid] });
      await this.$emit("changeOfficialForm", data);
      this.filelink = data.filelink;
      this.fileName = data.originalname
      await this.handlePutTrademarkData(data.trademark?.applicant?.id)
    },
    //跳转到商标详情页
    async gotoTMpage(row) {
      if (row) {
        try {
        var data = await Gettrademark({
          id: row,
        });
        // 跳转之前 关闭上传官文页面
        await this.$emit('closeUpload')
        this.$router.push({
          name: "MyTMpage",
          params: { id: row },
          query: {
            title: `${data.name?data.name:''}(${data.reg_num}-${data.class})`,
            OurTM:data.applicant?.id?true:false
          },
        });
        } catch (error) {
          console.log(error);
        }
      }else if(this.tm_id){
        try {
        var data = await Gettrademark({
          id: this.tm_id,
        });
        // 跳转之前 关闭上传官文页面
        await this.$emit('closeUpload')
        this.$router.push({
          name: "MyTMpage",
          params: { id: this.tm_id },
          query: {
            title: `${data.name}(${data.reg_num}-${data.class})`,
            OurTM:data.applicant.id?true:false
          },
        }); 
        } catch (error) {
          console.log(error);
        } 
      }else{
        this.$message.warning('没有找到这个商标的数据.')
      }
      this.drawer = false;
    },
    get_img_url(ref_tm) {
      const url = `/tmimage?$imgdata&reg_num=${_.get(ref_tm, [
        "general_info",
        "reg_num",
      ])}`;
      return url;
    },
    //删除商品数据
    async delgoods(tag) {
      var tmgoods_cn = this.OfficialForm.goods.splice(
        this.OfficialForm.goods.indexOf(tag),
        1
      );
      this.OfficialForm.good_candidates.push(...tmgoods_cn);
    },
    async TMtrigger(row) {
      if (this.OfficialForm.type_code == "TMZC_ZCSQ" && !this.control) {
        var tmgood_cd = this.OfficialForm.good_candidates.splice(
          this.OfficialForm.good_candidates.indexOf(row),
          1
        );
        this.OfficialForm.goods.push(...tmgood_cd);
      }
    },
    changeValue(e, type) {
      console.log(type,this.OfficialForm.time_limit);
      if (type == "receive_date_str") {
        let receive_date = toYMDTime(this.OfficialForm.receive_date);
        // this.OfficialForm.time_limit=this.OfficialForm.time_limit?.replace(/[^\d]/g,'');
          if (
            this.OfficialForm.time_limit != 0 &&
            this.OfficialForm.time_limit != null &&
            this.OfficialForm.time_limit != "空" 
            &&receive_date
          ) {
            let time_limit = this.OfficialForm.time_limit;
            // console.log(time_limit);
            const endDate = getNextWorkingDay(receive_date, time_limit);
            this.OfficialForm.expiration_date_str = toYMDTime(endDate);
             this.OfficialForm.expiration_date = toYMDTime(endDate);
             console.log(`expiration_date${toYMDTime(this.OfficialForm.expiration_date)}`,this.OfficialForm.expiration_date_str);
          }else{
            this.OfficialForm.expiration_date_str = ''
            this.OfficialForm.expiration_date  = ''
          }
      }
      if (e && e.target) {
        return e.target.value;
      } else {
        return e?.displayValue;
      }
    },
    changeInput(e) {
      console.log(e);
    },
    async getTMcose(items) {
      if (items) {
        var data = await Gettrademark({
          _id: items,
        });
      }
      if (data) {
        if (data.case_list.length >= 1) {
          let params = {
            _id: { $in: data.case_list },
          };
          try {
            var { data } = await findCases(params, true);
            this.TMcases = data;
          } catch (error) {
            console.log(error);
          }
          console.log("商标案件数组", this.TMcases);
          if (this.TMcases.length == 0) {
            this.identifyForm.TMcase = "";
          }
        }
      } else {
        this.tm_name = [];
        this.identifyForm.TMcase = "";
        this.TMcases = [];
      }
    },
    async getod() {
      var { data } = await getodprocess({
        $source: "mixed",
      });
      var basearr = [];
      var casearr = [];
      data.forEach((item) => {
        basearr.push(item.base_type);
        casearr.push(item.case_type);
      });
      //使用set去重并转化成数组
      this.base_typeArr = Array.from(new Set(basearr));
      this.case_typeArr = Array.from(new Set(casearr));
      this.caseData = data;
      this.Data = data;
    },
    goToDetail(params) {
      document.querySelector(params).scrollIntoView(true);
    },
    //当选择申请人时修改选中状态
    changeApplicant(v) {
      if (v != "无" && !this.isApplicant) {
        this.isApplicant = true;
      } else if (v == "无" && this.isApplicant) {
        this.isApplicant = false;
      }
    },
    //修改申请号时改变状态
    changeRegNum(v) {
      if (v.length > 0 && !this.isRegNum) {
        this.isRegNum = true;
      } else if (v.length == 0 && this.isRegNum) {
        this.isRegNum = false;
      }
    },
    changeRadio(key, otherkey) {
      if (!this.identifyForm.TMcase && !this.identifyForm.case_applicant) {
        this[key] = !this[key];
        if (this[key] && this[otherkey]) this[otherkey] = !this[otherkey];
      }
    },
    clickbrand_name() {
      this.brand_name == "" ? (this.brand_name = "无") : this.brand_name;
      this.Inputbrand_name = true;
    },
    cancelBrandSelect() {
      this.brand_name = this.brandform.brand_name;
      this.Inputbrand_name = false;
    },
    async blurbrand_name() {
      const new_brandname = this.brand_name;
      const old_brandname = this.brandform.brand_name;
      console.log(`选择品牌名称: ${new_brandname}, 原品牌:${old_brandname}`);
      let isALl = await patchTrademarkBrand(new_brandname,old_brandname,this.brandform)
      this.Inputbrand_name = false;
      try {
        await PATCHtrademark(this.brandform._id,this.brandform);
        if (isALl)  PushBrand({ linkTradeMarks: "ALL"});
        this.$emit("Getofficial");
      } catch (error) {
        console.log(error);
      }
      console.log("品牌", this.brandform);
    },
    async blurhas_processed() {
      try {
        await updateOfficialstate(this.OfficialForm._id, {
          has_processed: this.OfficialForm.has_processed,
        });
        this.Inputhas_processed = !this.Inputhas_processed;
        this.$emit("Getofficial");
      } catch (error) {
        console.log(error);
      }
    },
    getODoptions(queryString, cb) {
      if (this.ODoptions.length == 0) {
        var odprocess = this.common_service("odprocess");
        this.ODoptions = Array.from(new Set(odprocess.map((od) => od.type)));
      }

      let results = queryString
        ? this.ODoptions.filter((od) => od.indexOf(queryString) != -1)
        : this.ODoptions;

      cb(results);
    },
    handleSelect(item){
      this.OfficialForm.type = item
    },
    async remoteMethod(e) {
      this.debounce_load_tm(e);
    },
    clear_reg_num() {
      // this.tm_candidates = [];
    },
    async oddownload() {
      let urlIsTrue = await headFileUrlUtils(this.OfficialForm.filelink);
      if (!urlIsTrue) return;
        this.$notify({
          message: '档案已经开始下载,请在右上角浏览器菜单中查看下载进度',
          type: 'success',
          duration:3000
        });
      let fileName = this.getDownLoadName()
      let url = this.OfficialForm.filelink;
      downloadFile(url,fileName);
    },
    getDownLoadName(){
      // TODO:更改文件名称  //  name.class类.注册号.type.收文时间.后缀
      let {reg_num,type,originalname,receive_date }  =  this.OfficialForm
      // console.log('this.OfficialForm.doc_type :>>', this.OfficialForm.doc_type);
      if (this.OfficialForm.doc_type !== 'od') return originalname
      let doc_type = '';
      let name = this.OfficialForm.trademark?.name
      let class1 = this.OfficialForm.trademark?.class
      let suffix = originalname.substring(originalname.lastIndexOf('.'));   // .pdf
      let receive_date_str = toYMDTime(receive_date,'YYYYMMDD')
      if (type &&suffix &&receive_date && name && class1) {
        name && (doc_type += name +'.' )
        class1 && (doc_type += class1 +'类.' )
        reg_num && (doc_type += reg_num +'.' )
        doc_type +=( type +'.' )
        doc_type += (receive_date_str) 
        doc_type += (suffix)
      } else {
        doc_type = originalname
      }
      return doc_type
    },
    editOd() {
      this.control = !this.control;
      if (this.OfficialForm.time_limit =='空') {
        this.OfficialForm.time_limit = null
      }
      if (this.trademarkData && this.trademarkData.applicant == null) {
        this.trademarkData.applicant = {
          applicant_cn:''
        };
      }
    },
    getback() {
      this.control = !this.control;
      this.OfficialForm = _.cloneDeep(this.OfficialFormCopy);
    },
    info_width(e){
    let info_mian = document.getElementById('od_info');
    if (info_mian) {
        if (e.width <700) {
        info_mian.style="flex-direction:column-reverse;"
        this.show_button = false;
      }else{
        info_mian.style="flex-direction:row;"
        this.show_button = true;
      }    
    }
    },
    add_case(){
      if (this.candidate_casetypes.length) {
        this.caseForm.type = this.candidate_casetypes[0]?.case_type;
        this.caseForm.base_type =this.candidate_casetypes[0]?.base_type;
      }
      this.caseForm.reg_num =this.OfficialForm.reg_num;
      this.caseForm.tm_id = this.trademarkData?._id;
      this.caseForm.name = this.trademarkData?.name;
      this.caseForm.class = this.trademarkData?.class;
      this.caseForm.applicant_cn = this.trademarkData?.applicant?.applicant_cn;
      this.casedialogVisible = true;
      this.od_id = this.OfficialForm._id
    },
    changecasedialog(n){
      this.casedialogVisible = n;
    },
    doc_typeClear(){
      this.$refs.od_type.activated = true;
    },
    open_refTM_drawer(){
      this.refTM_drawer = true;
    },
    changerefTMdetails(n){
      this.refTM_drawer = n;
    },
    changerefTMData(data){
     var tmdata =  this.ref_OD_infoCopy.find(item=>item._id==data._id);
      tmdata.marked =data.marked;
    },
  },
  watch: {
    drawer(n) {
      if (!n) {
        this.$emit("changeDrawerState", n);
      }
    },
    'OfficialDetailData._id':{
      handler (newV, oldV) {
        this.getTrademark_complex();
      },
      deep: true
    }
  },
  filters: {
    filterLevel(v) {
      if (v) {
        let arr = ["一", "二", "三", "四", "五"];
        let str = arr[v - 1] + "级品牌";
        return str;
      } else {
        return "无";
      }
    },
    filterItem(val, fn, twoLevel = null, obj = {}) {
      switch (fn) {
        case "filterUtcTime": //过滤时间
          if (val) {
            return toYMDTime(val);
          }
        case "filterConcat":
          if (val) {
            return toYMDTime(val) + " 至 " + toYMDTime(obj[twoLevel]);
          }
        default:
          return val;
      }
    },
    filterOD_info(v){
      return v.replace('引证', '')
    },
    filtertimeLimit(v){
      return v =  get_timeLimit(v=='空'?0:v)
    }
  },
  computed: {
    ...mapGetters({
      common_service: "common_service",
    }),
  }
};
</script>

<style lang="less" scoped>
.float {
  color: #29c5ee;
  cursor: pointer;
  z-index: 99;
  font-size: 14px;
}
// /deep/ .el-form-item--mini .el-form-item__content {
//    line-height: 30px;
//   // padding-top: 2px;
// }
/deep/ .el-col-12 {
  float: right;
  padding: 0px !important;
}
.demo-image__preview {
  // height: 150px;
  // background-color: #000;
  display: flex;
  // flex-direction:column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.img_preview {
  height: 100%;
}
//驳文展示css
.authority {
  width: 100%;
  // margin: 20px 20px;
  // padding: 20px;
  // border-bottom: 1px #ddd solid;
  display: flex;
  .authority_describe {
    position: relative;
    h3 {
      color: #666666;
    }
    > div {
      p {
        width: 50%;
        display: inline-block;
        color: #999;
        padding: 0;
        margin: 4px 0;
        font-size: 14px;
      }
      p:last-child {
        width: 100%;
        display: inline-block;
        color: #999;
        padding: 0;
        margin: 4px 0;
        font-size: 14px;
      }
    }
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.box-card {
  width: 100%;
  height: 80px;
}

.left {
  position: absolute;
  top: 37px;
  left: 130px;
  color: #29c5ee;
  cursor: pointer;
}
.el-container {
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0;
}
.fonthidden {
  font-weight: bold;
  width: 400px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // padding-top: 2px;
}
.fontthick {
  font-weight: bold;
}
.borderleft {
  border-left: 8px solid #29c5ee;
  padding-left: 10px;
  font-weight: bold;
  margin: 20px 0;
  font-size: 16px;
}
/deep/ .el-dropdown {
  display: flex;
  align-items: center;
  width: 30px;
  padding-left: 10px;
  // margin-right: 5px;
}
/deep/ .el-collapse {
  border: none;
  margin-bottom: 18px;
  margin-left: 50px;
}
/deep/ .el-collapse-item__header {
  border-bottom: none;
  display: inline;
  height: 0;
  line-height: 0;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
.drawer_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  flex-wrap: nowrap;
  border-bottom: 1px solid;
}
.drawer_original{
  // height: 100px;
  //  border: 1px solid;
   border-radius: 4px;
   margin-top: 20px;
  //  border-color: #c1c0c075;
   display:flex;
   background: #f3f6f9 ;
}
/deep/ .el-drawer__body{
  padding: 0;
}
.drawer_mian{
  margin-top: 30px;
  padding-left: 10px;
  .drawer_mian_div{
    min-height: 150px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    p{
      color: rgba(0,0,0,1);
      font-weight: 700;
    }
  }
}
.commentClass{
  overflow-wrap:break-word;
}
.close_button{
font-size:20px;cursor: pointer;
margin-left: 10px;
padding-top: 5px;
}
/deep/  .el-drawer__header{
  width: 100%;
}
/deep/.el-drawer__header>:first-child{
  width: 100%;
}
.showButtonClass{
  padding-bottom:10px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  margin-left:94px;
  color: rgb(154, 154, 154); 
  span{
    margin-right: 10px;
  } 
  .el-link{
  margin-right: 10px;
  }
}
// /deep/ .el-drawer{
//   margin-right: 720px;
// }
::v-deep .el-autocomplete-suggestion {
  width: 380px !important;
}
</style>
