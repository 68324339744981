import moment from 'moment'
import { GetBrand, UpdateBrand,findCopyRight, PushBrand, headFileUrl, getBlobFile  } from '@/api/axios'
import _ from 'lodash'
import { Message, MessageBox, Notification } from "element-ui"
import streamSaver from '@/assets/js/StreamSaver'
import randomatic from 'randomatic'
import router from '../router/index.js'
import { trademarkEdit } from "./data";
const trademarkEditItem = new trademarkEdit();
import themeCss from "@/assets/css/theme.less";

export function toBeijingTime(utc_datetime) {
    // 转为正常的时间格式 年-月-日 时:分:秒
    let time = moment(utc_datetime).utcOffset(8).format('YYYY-MM-DD HH:mm:ss');
    return time
}

//将utc格式时间转化为'YYYY-MM-DD'
export function toYMDTime(utc_datetime,format='YYYY-MM-DD') {
    if (!utc_datetime) return ''
    return moment(utc_datetime).utcOffset(8).format(format);
}

//时限页面过滤官方绝限
export function expirationTime(date) {
    if (!date) return null;
    const endDate = toYMDTime(date);
    const startDate = moment().format('YYYY-MM-DD');
    let colorObj = {
        'danger': {
            color: "#ec0f0f",
            background: "#FAEEEE"
        },
        'warning': {
            color: "#ec0f0f",
            background: "#FEF6E8"
        }
    }
    let time = moment(endDate).diff(startDate, 'day');
    switch (true) {
        case time <= 3:
            return colorObj.danger;
        case 3 < time && time <= 7:
            return colorObj.warning;
        default:
            return null;
    }
}

//时限页面过滤官方绝限
export function getTimeOut(date) {
    if (!date) return false;
    const endDate = toYMDTime(date);
    const startDate = moment().format('YYYY-MM-DD');
    let time = moment(endDate).diff(startDate, 'day');
    // console.log(time);
    if (time <= 7) {
        return true;
    } else {
        return false;
    }

}

export function getDomain_status(name,expiration_date){
    const suffix = name.substring(name.lastIndexOf("."));
        var startDate=  toYMDTime(expiration_date)
        var date = new Date()
            const endDate=  toYMDTime(date)
             let time = moment(endDate).diff(startDate, 'day');                        
            if (suffix=='.cn') {
                if (startDate) {
                    if (time<30&&time>=-60) {
                        return {
                            status:'待续费',
                            background:'#FFFDF6EC',
                            color:'#E6A23C'
                        }
                    }else if(time>=30&&time<45){
                        return {
                            status:'待赎回',
                            background:'#FEF0F0',
                            color:'#F56C6C'
                        }
                    }else if(time>=45){
                        return {
                            status:'已释放',
                            background:'#F4F4F5',
                            color:'#909399'
                        }
                    }else{
                        return {
                            status:'正常',
                            background:'',
                            color:''
                        }
                    }
                }

            }else{
                if (startDate) {
                    if (time<30&&time>=-60) {
                        return {
                            status:'待续费',
                            background:'#FFFDF6EC',
                            color:'#E6A23C'
                        }
                    }else if(time>=30&&time<60){
                        return {
                            status:'待赎回',
                            background:'#FEF0F0',
                            color:'#F56C6C'
                        }
                    }else if(time>=60){
                        return {
                            status:'已释放',
                            background:'#F4F4F5',
                            color:'#909399'
                        }
                    }else{
                        return {
                            status:'正常',
                            background:'',
                            color:''
                        }
                    }                                
                }
            }    
}

const regex_match_modes = ["包含", "等于", "包含或等于", "为开始", "为结束"]
export function get_regex_match_modes(index) {
    if (index === undefined) return regex_match_modes

    return regex_match_modes[index]
}

/**
 * 根据关键词和模式生成表达式
 * @param {*} mode 
 * @param {*} value 
 * @returns 
 */
export function get_regex_from_keyword(mode, value) {
    let v = value.trim().replaceAll(/[,]/gi, "");

    if (!regex_match_modes.includes(mode)) {
        console.error(`invalide regex mode:${mode}.`)
    }

    if (!v || !v.length) return v

    switch (mode) {
        case regex_match_modes[0]:
            v = `${v}.+|.+${v}` //包含，必须还多至少一个字符，排除了等于
            break;
        case regex_match_modes[1]://等于
            v = `^${v}$`;
            break;
        case regex_match_modes[2]://等于或包含
            v = v
            break;
        case regex_match_modes[3]://为开始
            v = `^${v}`
            break
        case regex_match_modes[4]://为结束
            v = `${v}$`
            break
    }

    return v
}

/**
 * 把一个单一的表达式合并到cvs格式的多个表达式。做了去重检查。
 * @param {} cvs_regex 
 * @param {*} new_single_regex 
 * @returns 修改后的新表达式
 */
export function merge_into_cvs_regex(cvs_regex, new_single_regex) {
    if (!new_single_regex || new_single_regex.length == 0) {
        console.log(`merge_into_cvs_regex skipped since new_single_regex is empty.`)
        return
    }

    let regex_array = (cvs_regex && cvs_regex.length) ? cvs_regex.split(',') : []

    if (!regex_array.includes(new_single_regex)) regex_array.push(new_single_regex)

    const new_cvs_regex = regex_array.join(',')

    console.log(`merge_into_cvs_regex: (${cvs_regex})+(${new_single_regex})=(${new_cvs_regex})`)

    return new_cvs_regex
}

/**
 * 把一个关键词加到cvs格式的多个表达式列表中。
 * @param {*} cvs_regex 
 * @param {*} keyword 
 * @param {*} mode 
 * @returns 修改后的新表达式
 */
export function add_keyword_to_cvs_regex(cvs_regex, keyword, mode = regex_match_modes[1]) {
    const single_regex = get_regex_from_keyword(mode, keyword)

    return merge_into_cvs_regex(cvs_regex, single_regex)
}

/**
 * 将一个关键词从多个cvs连接的正则表达式中删除. 如果关键词是唯一的表达式，就保留。
 * 留给用户决定是否手动从品牌删除。
 * 这个函数用在将一个商标从品牌删除时，把商标的名称也从品牌表达式删除。
 * @param {*} cvs_regex 
 * @param {*} keyword
 * @param {*} mode 缺省是'等于'.
 * @returns 
 */
export function remove_keyword_from_cvs_regex(cvs_regex, keyword, mode = regex_match_modes[1]) {

    let single_regex = get_regex_from_keyword(mode, keyword)

    let regex_array = (cvs_regex && cvs_regex.length) ? cvs_regex.split(',') : []

    if (regex_array.length > 1) regex_array = regex_array.filter(re => re != single_regex)

    const new_cvs_regex = regex_array.join(',')

    console.log(`remove_regex_from_cvs_regex: (${cvs_regex})-(${single_regex})=(${new_cvs_regex})`)

    return new_cvs_regex
}

/**
 * 把商标名称或者别名作为关键词添加到品牌cvs表达式或者从cvs表达式去掉，并保存品牌。
 * add_or_remove=true时候是添加，否则是去掉。
 * 如果没有传入brand就根据brand_name从服务器加载。
 */
export async function updateBrandRegex(theBrand, brand_name, tm, add_or_remove = true) {

    let keyword = tm.name && tm.name != '图形' ? tm.name : tm.tm_alias

    console.log(`call updateBrandRegex, brand id:${_.get(theBrand, '_id')}, name:${brand_name}, keyword:${keyword}, add:${add_or_remove}`)

    if (!keyword || keyword.length == 0) {
        console.warn(`updateBrandRegex skip since keyword is empty.`)
        return
    }

    if (!theBrand) {
        let { data } = await GetBrand({ name: brand_name })
        if (!data || data.length == 0) {
            console.warn(`updateBrandRegex cann't find brands by name ${brand_name}`)
            return
        }
        theBrand = data[0]
    }

    let new_regex = add_or_remove ? add_keyword_to_cvs_regex(theBrand.regx, keyword)
        : remove_keyword_from_cvs_regex(theBrand.regx, keyword)

    if (theBrand.regx == new_regex) {
        console.log(`skip update brand ${theBrand.name} since the regex doesn't change.`)
        return
    }

    console.log(`updateBrandRegex regx:${theBrand.regx},new_regex:${new_regex}`)

    theBrand.regx = new_regex
    UpdateBrand(theBrand)
}

/**
 * 1. 修订可视化数据。把数量添加到name后面。
 * 2. 排序
 * 3. 把查询条件合并到节点上，方便后续跳转。keys的个数应该和data的深度一致。
 * @param {*} data 
 * @param {*} keys 
 */
export function decorateDiagramData(data, keys) {
    var key_name = ''
    if (!keys) {
        console.error(`decorateDiagramData keys is empty.`)
        return data
    }

    const keys_len = keys.length
    const loopThrough = (items, pre_query, index) => {
        if (index >= keys_len) {
            console.error(`decorateDiagramData index(${index})>keys length(${keys_len}).`)
            return
        }
        items.forEach((item) => {
            switch (keys[index+1]){
                case 'applicant.applicant_cn':
                    key_name ='申请人'
                    break;
               case 'status':
                    key_name ='状态'
                    break;
               case 'class_name':
                    key_name = '类别'
                    break;
               case 'brand_name':
                    key_name = '品牌'
                    break;
                    default:
                        key_name = ''
               }
            let query = _.clone(pre_query)
            query[keys[index]] = item.name
            _.assign(item, {
                ori_name: item.name,
                name: `${item.name}(${item.value})`,
                key:key_name,
                query
            })

            if (item.children) loopThrough(item.children, query, index + 1)
        })

        items.sort((a, b) => a.value - b.value)
    }

    loopThrough(data, {}, 0)

    // console.log(`decorateDiagramData:${JSON.stringify(data, null, 2)}`)

    return data
}

export function splitRegNum(str) {
    const regex = /([\d|A-Z|a-z]*)/gm;

    let m;

    let results = []
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            if (groupIndex == 1 && match && match.length && !results.includes(match))
                results.push(match)
        });
    }

    return results
}

/** 根据注册日期取得注册公告发布日期。
 * 目前从外部数据取得的注册日期都是注册公告发布日期的第二天。所以我们用注册日期减一天取得注册公告发布日期。
 * 因为公告日期固定是6，13，20，27日，所以简单的减一是安全的。
 */
export function get_reg_ann_post_date_str(reg_annc_date) {
    if (reg_annc_date == null) return ''

    let rdate = new Date(reg_annc_date)

    rdate.setDate(rdate.getDate() - 1);

    return toYMDTime(rdate)
}

/**
 * 根据月份(0~11) 计算是第几个季度(0~3)
 * @param {*} month 
 * @returns 
 */
export function get_season(month) {
    return Math.floor((month) / 3)
}

export function get_span_of_season(year, season) {
    const start_m = season * 3, end_m = season * 3 + 2

    return {
        s_date: get_first_day_of_year_month(year, start_m),
        e_date: get_last_day_of_year_month(year, end_m)
    }
}

export function get_last_day_of_year_month(year, month) {
    return new Date(year, month + 1, 0, 23, 59, 59)
}

export function get_first_day_of_year_month(year, month) {
    return new Date(year, month, 1)
}

export function get_span_of_year(year) {
    return {
        s_date: get_first_day_of_year_month(year, 0),
        e_date: get_last_day_of_year_month(year, 11)
    }
}

export function get_span_of_year_month(year, month) {
    return {
        s_date: get_first_day_of_year_month(year, month),
        e_date: get_last_day_of_year_month(year, month)
    }
}
export function copyreg_num(reg_num) {
    var cInput = document.createElement("input");
    cInput.value = `${reg_num ? reg_num : ""}`;
    document.body.appendChild(cInput);
    cInput.select(); // 选取文本框内容

    // 执行浏览器复制命令
    // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
    // Input要在正常的编辑状态下原生复制方法才会生效
    document.execCommand("copy");

    Message({
        type: "success",
        message: "复制成功",
    });
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(cInput);
}

const G = 1024*1024*1024, M = 1024*1024, K = 1024

export function get_size_str(size) {

    if (!_.isInteger(size) || size < 0) return ''

    if (size >= G) {
        return `${(size/G).toFixed(2)}GB`
    } else if (size >= M) {
        return `${(size/M).toFixed(2)}MB`
    } else if (size >= K) {
        return `${(size/K).toFixed(2)}KB`
    } else {
        return `${size}B`;
    }
}
export function getNextWorkingDay(start_date, period) {
 let str_period = String(period);
  let days, months;
  if (str_period.toLowerCase().endsWith('m')) {
    months = parseInt(str_period);
    days = 0;
  } else {
    months = 0;
    days = parseInt(str_period);
  }
  let end_date = new Date(start_date).getTime();
//    end_date.add({days: days});
//    end_date.add({months: months});
      end_date= moment(end_date).add(days,'days').format('YYYY-MM-DD');
      end_date= moment(end_date).add(months,'months').format('YYYY-MM-DD');
    end_date =new Date(end_date)
//    let weekDay = end_date.getDay();
//   if ((weekDay === 0 || weekDay === 6) && config.skip_holiday) {
//     end_date = new Date(end_date.getTime() + (6 + weekDay) * 600 * 1000 * 24);
//   }
  return end_date;
  }
//  把时间转发 YYYY-MM-DD HH:mm 格式   utc_datetime的格式是Number或String类型的
export function isDateToTime(utc_datetime,time_format='YYYY-MM-DD HH:mm'){
    if (!utc_datetime) return ''
    if (!isNaN(utc_datetime))  return utc_datetime
    let dateObj  = new Date(utc_datetime)
    if (dateObj instanceof Date && !isNaN(dateObj) && dateObj.getTime() > new Date('1990-01-01').getTime()) {
        return moment(utc_datetime).format(time_format);
    }else{
        return utc_datetime
    }
}

//  把时间转发 YYYY-MM-DD HH:mm 格式 
export function ToTime(utc_datetime,time_format='YYYY-MM-DD HH:mm'){
    if (!utc_datetime) return ''
    let dateObj  = new Date(utc_datetime)
    if (dateObj instanceof Date && !isNaN(dateObj) ) {
        return moment(utc_datetime).utcOffset(8).format(time_format);
    }else{
        return utc_datetime
    }
}

/**
 *判断一个字符是否在这个数组的范围内
 * @param {*}  character  要判断的字段
 * @param {*}  filterArrAy  //数组
 * @param {*}  value     //根据数组下的某个值判断  没有 就是数组本身
 * @returns   返回这个数组的索引   没有返回-1
 */
export function findOprAudit(character,filterArrAy,value){
    if (!character ||filterArrAy.length <=0 ) {
        return -1
    }
    let index = filterArrAy.findIndex(item=>{
        if (value) {
            // return character.indexOf(item[value]) !=-1
            return character == item[value]
        } else {
            // return character.indexOf(item) !=-1
            return character == item
        }
    })

    return index
}

/**
 *  模仿mongodb的分页查询
 * @param {*}  mongodbArray  数组 
 * @param {*}  limit  查询的个数
 * @param {*}  skip   跳过的个数
 * @param {*}  sort   排序字段  "desc" 降序，或者指定为 "asc" 升序
 * @returns  返回一个数组
 */
 export function mongodbQuery(mongodbArray=[],{limit=50,skip=0,sort={}}){
    return   _(mongodbArray)
    .orderBy(Object.keys(sort), Object.values(sort))
    .slice(skip, skip + limit)
    .value();
}

  //判断要检查的字段是否相等
export function isEqual(obj1,obj2,fields) {
    const picked1 = _.pick(obj1, fields);
    const picked2 = _.pick(obj2, fields);
    return _.every(fields, (field) => {
        return   picked1[field] && picked2[field] && _.isEqual(picked1[field], picked2[field]);
    });

}
  //判断要检查的字段是否不相等
export function isFieldsDifferent (obj1,obj2,fieldsToCompare){
    const picked1 = _.pick(obj1, fieldsToCompare);
    const picked2 = _.pick(obj2, fieldsToCompare);
    return _.every(fieldsToCompare, (field) => {
        return   picked1[field] && picked2[field] && !(_.isEqual(picked1[field], picked2[field]));
    });
}

// 检查一个字段
export function InspectionType(str) {
    if (!str || str==='null' || str === 'undefined') return '无'
    return str
}


/**
 * 
和proj/src/comm/comm.bussiness.js中的同名函数保持一致
*/
const ealiest_date = new Date('1990-01-01')
const reasonable_date = (the_date) => {
    let one_year_later = new Date()
    one_year_later.setFullYear(one_year_later.getFullYear() + 1)

    let time = the_date.toDate().getTime()

    //serverLogger.debug(`reasonable_date:${the_date}, ${ealiest_date}, ${one_year_later}`)

    return time > ealiest_date.getTime() && time < one_year_later.getTime()
}

const parse年月日Date = (dateString) => {
    // Remove the non-numeric characters from the string and split it into an array
    const dateArray = dateString.replace(/[^\d]/g, '-').split('-').map(Number);
    // Ensure the month and day values are zero-padded
    const [year, month, day] = dateArray

    return moment({year, month:month-1, day}).utcOffset(8)
  }

export function extractRegNoAndDate(fileName) {
    // Helper function to check if a given date is valid
    const isValidDateString = (date) => {
        let year = parseInt(date.substr(0, 4));
        let month = parseInt(date.substr(4, 2));
        let day = parseInt(date.substr(6, 2));
        let d = new Date(year, month - 1, day);

        return year > 1990 && year < 2099 && d && d.getMonth() + 1 === month && d.getDate() === day;
    };

    // Step 5.1: Remove the file extension
    fileName = fileName.replace(/\.[^/.]+$/, "");

    let hashPattern = /^[a-zA-Z\d]+$/g;
    let regNoPattern = /(?<![A-Z\d])[A-Z]?\d{5,9}[A-Z]?(?![A-Z\d])/g;
    let datePattern1 = /(?<!\d)\d{8}(?!\d)/g; // YYYYMMDD format bounded by non-digits
    let datePattern2 = /(\d{4}年\d{1,2}月\d{1,2}日)/g; // YYYY年MM月DD日 format
    let datePattern3 = /\d{13}/g;

    if (hashPattern.test(fileName)) {//just a hash value, don't parse it.
        return {}
    }

    let regNos = [...fileName.matchAll(regNoPattern)];
    let dates1 = [...fileName.matchAll(datePattern1)];
    let dates2 = [...fileName.matchAll(datePattern2)];
    let dates3 = [...fileName.matchAll(datePattern3)];

    let reg_no = null;
    let date_str = null
    let date = undefined

    if (dates2.length > 0) {
        date = parse年月日Date(dates2[0][0]); // Use the first occurrence of YYYY年MM月DD日 format
    }

    if (dates1.length > 0) { //使用最后一个合法的日期
        for (let can of Array.from(dates1, (m) => m[0]) ) {
            if (isValidDateString(can)) {
            date_str = can
            }
        }
    }

    if (dates3.length > 0) {
        date_str = parseInt(dates3[0][0])
    }

    if (regNos.length > 0) {//If we matched a substring as regNo and date in the same time, take it as date
        let now = new Date()
        let lastday_of_this_year = now.getFullYear() * 10000 + 1231

        let candidates = Array.from(regNos, (m) => m[0])
        
        for (let can of candidates) {
        if (can != date_str) {
            reg_no = can
        }
        if (!isValidDateString(can)) {
            break
        } else {
            let suspicious_date = parseInt(reg_no)
            if (suspicious_date >= 20030000 && suspicious_date <= lastday_of_this_year) {
            reg_no = undefined
            }
        }
        }
    }

    date = date || (date_str ? moment(date_str).utcOffset(8) : undefined)
    date = date && date.isValid() && reasonable_date(date) ? date: undefined

    return { reg_num:reg_no, date }
}

  function isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime()) && date.getTime() > 10000;  //No change to have a date earlier than 1970-01-01
  }

// 处理官文期限
export function AddDayTime(date,day){
    let momentDate = moment(date)
    let newDate = momentDate.add('day',day)
    return newDate

}

export function downloadFile(url,filename) {
    if (navigator.serviceWorker) {
        console.log('开始流式下载');
        streamSaverJS(url,filename)
    }else{
        console.log('开始BLOB下载');
        saveFile(url,filename)
    }
}

export async function saveFile(url,filename) {
    let response = await getBlobFile(url).catch(err=>{
        Message.error('下载失败，请稍后重试！');
    });
    if (!response) {
        return;
    }
    const href = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    // 创建 MouseEvent 对象
    const event = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
    });
    
    // 模拟鼠标点击事件
    link.dispatchEvent(event);
    
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
}

export function streamSaverJS(url,filename) {
    const token = sessionStorage.getItem("token")
    const fileStream = streamSaver.createWriteStream(filename)
    let headers = {
        Authorization:token
    }
    fetch(url, {
        method: 'GET',
        headers,
    }).then(res=> {
        const readableStream = res.body
        console.log(res);
        if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
            .then((res2) => {
             console.log('我执行了readableStream.pipeTo方法');
            })  
            .catch(err => {
                console.error(err)
            })
        }
    
        window.writer = fileStream.getWriter()
        
        const reader = res.body.getReader()
        const pump = () => reader.read()
            .then(res => res.done
            ? writer.close()
            : writer.write(res.value).then(pump))
    
        pump()
        console.log('我执行了ileStream.getWriter方法');
        })
    .catch(err => {
        console.error(err)
    })

}

export function getScoreStr(score){
    if (!score) return  '高'
    score = Number(score.toString().match(/^\d+(?:\.\d{0,2})?/));
    let scoreArr = [
        {min:0.80,max:1.00,label:'高'},
        {min:0.40,max:0.79,label:'中'},
        {min:0.01,max:0.39,label:'低'},
    ]
    let index = scoreArr.findIndex(item=>item.min<=score&&score<=item.max)
    if (index == -1) {
        return ''
    } else {
        return scoreArr[index].label
    }
}

// 根据文件名称的后缀获取文件icon
export function getfileSuffixAndImg(filename){
    let suffix = filename.split('.').at(-1)
    let iconUrl = null
    switch(suffix){
        case "bmp":
            iconUrl = require('@/assets/img/fileType/bmp.png')
        break;
        case "excel":
            iconUrl = require('@/assets/img/fileType/excel.png')
        break;
        case "jpg":
            iconUrl = require('@/assets/img/fileType/jpg.png')
        break;
        case "pdf":
            iconUrl = require('@/assets/img/fileType/pdf.png')
        break;
        case "png":
            iconUrl = require('@/assets/img/fileType/png.png')
        break;
        case "rar":
            iconUrl = require('@/assets/img/fileType/RAR.png')
        break;
        case "word":
            iconUrl = require('@/assets/img/fileType/word.png')
        break;
        case "pictupe":
            iconUrl = require('@/assets/img/fileType/bmp.png')
        break;
        default:
            iconUrl = require('@/assets/img/fileType/Other.png')
        break;
    }
    return {
        suffix,
        iconUrl
    }
}

// 根据文件名称是否属于图片类型
export function isImgType(filename) {
    let suffix = filename.split('.').at(-1)
    const imgTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'];
    return imgTypes.includes(suffix)
}

// 判断2个时间所剩的时间
export function getTimeDifference(maxDate,minDate,is_str = true) {
    maxDate =  maxDate.getTime()
    minDate =  minDate.getTime()
    
    let remainder = maxDate - minDate
    if (remainder  >= 1000 * 60 *60 *24) {
        const days = Math.ceil(remainder / (1000 * 60 *60 *24));
        return is_str ? days + "天" : days;
    }else if (remainder  >= 1000 * 60 *60) {
        const hours = Math.ceil(remainder / ( 1000 * 60 *60)); // 计算小时数
        return is_str ? hours + "小时" : hours;
    } else if (remainder > 0) {
        const minutes = Math.ceil(remainder / (1000 * 60)); // 计算分钟数
        return is_str ? minutes + "分钟" : minutes;
    } else {
        return is_str ? "0天" : 0;
    }
}

export function getPassWord(){
    return randomatic('a0',6)
}

export function addDate(utc_datetime,day=3){
    if (!utc_datetime) return ''
    return moment(utc_datetime).add(day,'days')
}

export function getFileExtensions(fileTypes) {
    // 定义文件类型与后缀的映射关系
    if (!fileTypes || fileTypes.length == 0) {
        return {
            extensions:[],
            isFindOther:false,
        }
    }
    const extensionsMap = {
      "PDF":[".pdf"],
      "图片": [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".tiff"],
      "视频": [".mpeg", ".mpg", ".avi", ".mp4", ".mov", ".wmv"],
      "音频": [".mp3", ".wav", ".aac", ".flac", ".ogg"],
      "压缩包": [".zip", ".rar", ".7z", ".tar", ".gz"],
      "doc": [".doc", ".docx"],
      "xls": [".xls", ".xlsx"]
    };
    // 创建一个空数组来保存后缀
    let extensions = [];
    let isFindOther = false

    if (fileTypes.includes('其他')) {
        isFindOther = true
        // 返回的是判断非后缀的数组
        for (let key in extensionsMap) {
            if (!fileTypes.includes(key)) {
                extensions = _.concat(extensions,extensionsMap[key])
            }
        }
    }else{
        // 返回的是需要判断的后缀的数组
        for (let i = 0; i < fileTypes.length; i++) {
            let typeExtensions = extensionsMap[fileTypes[i]];
            if (typeExtensions) {
                extensions = _.concat(extensions,typeExtensions)
            }
        }
    }
    return {
        extensions,
        isFindOther
    };
}

// 对于重命名字段后面 + 1
export function getRenameSeq(array,name){
    const seqRex = new RegExp(`^${escapeRegExp(name)}\\((?<num>\\d+)\\)$`);
    const seqList = [];
    let isEqual = false;
    array.forEach(filename => {
      if (filename == name) {
        isEqual = true
      }
      const matchObj = seqRex.exec(filename);
      const numStr = matchObj?.groups?.num;
      numStr && seqList.push(Number(numStr))
    })
    if (seqList.length == 0) return isEqual?1:0
    seqList.sort((a, b) => a - b)

    let seq = 1
    for (const item of seqList) {
      if (item === seq) {
        seq++;
      } else {
        break;
      }
    }
    return seq;
}
// 转义正则表达式中的特殊字符
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
}
// 电脑文件系统路径中的一组特殊字符
export function hasSpecialCharacters(fileName) {
    const specialCharactersRegex = /[\\/:\*\?"<>\|]/;
    return specialCharactersRegex.test(fileName);
}

/**
 * 修改商标的品牌逻辑；
 * 更改商标的3个字段 ucfg_brand brand_id brand_name 接口需要在外部通过不同条件更改
 * @param {String}     new_brandname
 * @param {String}     old_brandname
 * @param {Object}     trademark
 * @param {*} 
 * @returns  不会返回新的结果
 */
export async function patchTrademarkBrand(new_brandname,old_brandname,trademark){
    console.log(trademark);
    let isALL = false;
    let isPatchOldBrand = false;
    // 获取商标名称和class名称
    let tm_name = trademark.name;   // 商标名称
    let tm_alias = trademark.tm_alias;  // 商标别名
    let addTm_name = tm_name!=='' && tm_name!=='图形' ? tm_name : tm_alias; //要加入的商标名称
    let tm_class_name = trademark.class_name;   // className

    if (new_brandname === "自动选择") {
        trademark.ucfg_brand = false;
        trademark.brand_id = '';
        trademark.brand_name = '';
        isALL = true;
        isPatchOldBrand = true;
    }else{
        let newBrandId = null;
        if (new_brandname && new_brandname !== old_brandname) {
            // 获取新品牌的信息并更改
            let { data: newBrand } = await GetBrand({name: new_brandname});
            if (addTm_name && newBrand && newBrand.length > 0) {
                newBrandId = newBrand[0]._id
                if (!newBrand[0].tm_names.includes(addTm_name))   newBrand[0].tm_names.push(addTm_name);
                if (!newBrand[0].claz)  newBrand[0].claz = [tm_class_name];
                if (!newBrand[0].claz.includes(tm_class_name))  newBrand[0].claz.push(tm_class_name);
                await UpdateBrand(newBrand[0])   
            }
        }
        isALL = false;
        isPatchOldBrand = new_brandname !== old_brandname;
        trademark.ucfg_brand = true;
        trademark.brand_id = newBrandId ? newBrandId : '';
        trademark.brand_name = newBrandId ? new_brandname : '';
    }
    // 询问用户是否修改旧品牌的品牌关键字
    if (addTm_name && old_brandname && isPatchOldBrand) {
        let  {data: oldBrands } = await GetBrand({name: old_brandname});
        if (oldBrands && oldBrands.length > 0) {
            let old_brand = oldBrands[0]
            if (old_brand.tm_names && old_brand.tm_names.includes(addTm_name)) {
                let isdel = await handleSleepUserSelect(old_brandname,addTm_name)
                if (isdel) {
                    _.remove(old_brand.tm_names,(tm_name=>{
                        return tm_name == addTm_name
                    }))
                    await UpdateBrand(old_brand) 
                }
            }
        }
    }
    // isALL 为true 会在函数外面调用，这里就不调用了
    if (!isALL) {
        PushBrand({
            linkTradeMarks: "ALL",
        });
        Notification({
            title:'品牌匹配商标',
            message: "开始自动匹配商标...",
            type: "info",
        })
    }
    return isALL
}
// 询问用户是否删除旧品牌的品牌关键字
const handleSleepUserSelect = (old_brandname,addTm_name)=>{
    return new Promise(resolve=>{
        MessageBox.confirm(`删除旧品牌<span style="color:#29c5ee;font-weight:800;">${old_brandname}</span>的关键字
        <span style="color:#29c5ee;font-weight:800;">${addTm_name}</span>`, '删除品牌关键字', {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString:true,
            closeOnClickModal:false,
            closeOnPressEscape:false,
            distinguishCancelAndClose:false,
        }).then(()=>{
            resolve(true)
        }).catch(()=>{
            resolve(false)
        })
    })
}

export function getCanvasText(text, fontSize,BoxWidth,isTwoText){
    let textWidth = getTextWidth(text,fontSize);
    if (textWidth < BoxWidth) {
        return text
    }
    // 初步计算一下  留下2个字的间隔
    let space =  10;
    // 省略号...的占位数字
    let ellipsTextLengtn = 2;
    // 无省略号的预留文字字数
    let spaceTextLength = Math.floor((BoxWidth - space) / fontSize);
    // 有省略号的预留文字字数
    let ellipsisTextLength = Math.floor((BoxWidth) / fontSize);

    if (isTwoText) { 
        return {
            label1:text.substr(0,spaceTextLength),
            label2:text.substr(spaceTextLength,ellipsisTextLength - ellipsTextLengtn) +  `${(spaceTextLength + ellipsisTextLength - ellipsTextLengtn) < text.length  ? '...' : "" } `,
        }
    }

    return text.substr(0,ellipsisTextLength - ellipsTextLengtn) + "...";
}

// 计算文本的宽度
export function getTextWidth(text, fontSize) {
    // 创建临时元素
    const _span = document.createElement('span')
    // 放入文本
    _span.innerText = text
    // 设置文字大小
    _span.style.fontSize = fontSize + 'px'
    // span元素转块级
    _span.style.position = 'absolute'
    // span放入body中
    document.body.appendChild(_span)
    // 获取span的宽度
    let width = _span.offsetWidth
    // 从body中删除该span
    document.body.removeChild(_span)
    // 返回span宽度
    return width
}

export async function utilGetAllCopyright(){

    let dataList = []
    let params = {
        $limit:1000,
        $skip:0,
        $select:['_id','name','registry_type'],
    }
    let { total,limit,data} = await findCopyRight(params)
    dataList = _.concat(dataList,data)
    for (let skip = limit; skip <= total;) {
        params.$skip = skip
        let {data,limit} =  await findCopyRight(params)
        if (data) {
            dataList = _.concat(dataList,data)
        }
        skip += limit
    }
    return dataList
}

let overTimeBox = false;

// 登录超时的弹框
export function loginOvertimeBox(){
    let hasAnyHash = isUnauthorizedRouter();  
    if (overTimeBox || hasAnyHash) return;
    overTimeBox = true;
    MessageBox.confirm(`您的账号已超时退出，需重新登录`,'登录超时',{
        confirmButtonText: '立即登录',
        cancelButtonText: '稍后登录',
        showClose:false,
        dangerouslyUseHTMLString:true,
        closeOnClickModal:false,
        closeOnPressEscape:false,
        type:'warning',
        cancelButtonClass:'el-button--info'
    }).then(()=>{
        overTimeBox = false;
        logoutLogin();
    }).catch(()=>{
        overTimeBox = false;
    })
}

export function logoutLogin(){
    localStorage.removeItem("feathers-jwt");
    localStorage.removeItem("Tabs");
    sessionStorage.clear();
    //刷新页面
    router.push("/platform/login").then(()=>{
        window.location.reload();
    });
}

let unauthorizedRouterPath = ['platform/login','platform/download'];

export function isUnauthorizedRouter(){
    let currentHash = window.location.hash;
    
    if (!currentHash || currentHash == '#/' || currentHash == '#') return true;
    return unauthorizedRouterPath.some((path) => {
        return currentHash.includes(path);
    });
}

let fileSeqRegx = /\s\(\d+\)$/;

export function delEvidenceSeq(inputString){

    const inputArray = inputString.split('.');

    const suffix = inputArray.pop();

    const re = inputArray.join('.')

    let result = re.replace(fileSeqRegx, '');

    result = result + '.' + suffix;

    return result
}
export function  getRandomKey(pattern="aA0", length=6) {
    return randomatic(pattern, length);
}

export function get_timeLimit(row){
  if (!row) return '无';
  let obj =   trademarkEditItem['document_time_limit'].find(item=>item.value==row);
  if (obj?.label) {
    return obj.label 
  }else{
      return row
  }
}

export function is_weixn(){
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger"){
        return true;
    }else{
        return false;
    }
} 

let fillColorArray = [];
// export function generateColorScheme() {
//     const  generateBrightColor = () => {
//         let color = '';
//         do {
//             const hue = Math.floor(Math.random() * 361);
//             const saturation = Math.floor(Math.random() * 6) + 20; // 20%-25% 饱和度
//             const lightness = Math.floor(Math.random() * 6) + 60; // 60%-65% 亮度
//             color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
//        } while(fillColorArray.includes(color));
       
//        fillColorArray.push(color);
//     //    console.log(fillColorArray);
//         return color;
//     }

//     const adjustLightness = (color, adjustment)=> {
//         const colorParts = color.match(/(\d+), (\d+)%, (\d+)%/);
//         let lightness = parseInt(colorParts[3]) + adjustment;
//         lightness = Math.min(100, Math.max(0, lightness)); // 保证亮度在0-100%之间
//         return `hsl(${colorParts[1]}, ${colorParts[2]}%, ${lightness}%)`;
//     }

//     const fillColor = generateBrightColor();
//     const strokeColor = adjustLightness(fillColor, -10); // 描边色稍暗

//     // 文本颜色：根据亮度选择黑色或白色以确保对比度
//     // const lightnessMatch = fillColor.match(/, (\d+)%\)/);
//     // const lightness = parseInt(lightnessMatch[1]);
//     // const textColor = lightness > 50 ? '#000000' : '#FFFFFF';

//     const textColor = themeCss.colorTextPrimary;
    
//     return {
//         fillColor: fillColor,
//         strokeColor: strokeColor,
//         textColor: textColor
//     };
// }

export function generateAdjustedColorByIndex(index) {
    const  generateBrightColor = () => {
        let color = '';
        const hue = (index * 137) % 360;  // 使用137作为增量因子，它是一个"黄金角度"，有助于生成视觉上均匀分布的颜色\
        //const hue = ((index * 37) % 120 + 330)%360;
        const saturation = 40; // 20%-25% 饱和度
        const lightness = 90; // 60%-65% 亮度
        color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        return color;
    }

    const adjustLightness = (color, adjustment)=> {
        const colorParts = color.match(/(\d+), (\d+)%, (\d+)%/);
        let lightness = parseInt(colorParts[3]) + adjustment;
        lightness = Math.min(100, Math.max(0, lightness)); // 保证亮度在0-100%之间
        return `hsl(${colorParts[1]}, ${colorParts[2]}%, ${lightness}%)`;
    }

    const fillColor = generateBrightColor();
    const strokeColor = adjustLightness(fillColor, -10); // 描边色稍暗

    const textColor = themeCss.colorTextPrimary;
    
    return {
        fillColor: fillColor,
        strokeColor: strokeColor,
        textColor: textColor
    };
}
    

export function closeFillColorArray(){
    fillColorArray = [];
}

export function customSort(a, b) {
    const isChinese = (text) => {
      return /[\u4e00-\u9fa5]/.test(text);
    }
    const aIsChinese = isChinese(a);
    const bIsChinese = isChinese(b);

    if (aIsChinese && !bIsChinese) {
        // 如果 a 是中文，b 是英文，a 排在后面
        return 1;
    } else if (!aIsChinese && bIsChinese) {
        // 如果 a 是英文，b 是中文，a 排在前面
        return -1;
    } else {
        // 如果 a 和 b 都是同一种语言，按字母顺序排序
        return aIsChinese ? a.localeCompare(b,'zh') : a.localeCompare(b,'en');
    }
}

export function getCaseFolderPath(node_name,parent_path){
    return parent_path ? `${parent_path}/${node_name}` : node_name;
}

export const headFileUrlUtils = async(filelink) =>{
    return true
}
export function checkedType(target) {

    if (typeof target === 'string') {
        // 判断是否是日期时间字符串
        const dateTime = new Date(target);
        const isDateTimeString = moment(target, moment.ISO_8601, true).isValid();
        if (isDateTimeString && isValidDate(dateTime)) {
            return 'date';
        }
    } else if (target instanceof Date) {
        // 判断是否是日期对象
        return 'date';
    }

	let type = Object.prototype.toString.call(target).slice(8, -1);
    return type.charAt(0).toLowerCase() + type.slice(1);
}

// 创建一个空数组
let tm_class_array = [];

// 添加1到45
for (let i = 1; i <= 45; i++) {
    tm_class_array.push(i);
}

// 添加01到09
for (let i = 1; i <= 9; i++) {
    tm_class_array.push("0" + i);
} 

export function checkedTMClass(tm_class) {
    return tm_class_array.includes(parseInt(tm_class));
}

export function checkedTMRegNum(tm_class) {
    const regex = /^[A-Za-z0-9]+$/;
    return regex.test(tm_class);
}

/**
 * 判断字符串 a 中是否存在字符串 b（不区分大小写）
 * @param {string} strA - 要搜索的字符串
 * @param {string} strB - 要匹配的子字符串
 * @returns {boolean} - 如果字符串 b 存在于字符串 a 中（不区分大小写），则返回 true，否则返回 false
 */
export function matchIgnoreCase(strA, strB) {
    // 如果输入为空或为 null，则直接返回 false
    if (!strA || !strB) {
        return false;
    }

    // 去除字符串首尾的空格
    var trimmedA = strA.trim();
    var trimmedB = strB.trim();

    // 创建一个正则表达式对象，模式为去除空格处理后的字符串 b，标志为 'i' 表示不区分大小写
    var regex = new RegExp(trimmedB, 'i');
    
    // 使用 test 方法检查去除空格处理后的字符串 a 是否匹配正则表达式 regex，返回布尔值
    return regex.test(trimmedA);
}

export function arabicToChinese(number) {
    const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chineseUnits = ['', '十', '百', '千', '万', '亿'];

    let chinese = '';
    let unitPos = 0;

    while (number > 0) {
        const num = number % 10;
        if (num !== 0) {
            chinese = chineseNumbers[num] + chineseUnits[unitPos] + chinese;
        } else {
            // 如果当前数字是 0，则需要考虑零的处理
            if (chinese[0] !== '零') {
                chinese = '零' + chinese;
            }
        }
        number = Math.floor(number / 10);
        unitPos++;
    }

    return chinese;
}