<template>
    <SelectSlot
    :selectArray="selectArray"
    :placeholder="placeholder"
    :elCardWidth="elCardWidth"
    :selectWidth="selectWidth"
    :dropdownShow="dropdownShow"
    @changeSelectModel="changeSelectModel"
    @chooseDate="handleChangeDropdownShow"
    >
    <div class="solt_box_claz">    
        <div class="slot_header" v-if="!isSimplifiedVersion">
            <el-input 
            :size="elementsize" 
            v-model.trim="input_model"
            maxlength="10"
            clearable
            placeholder="输入关键字"
            > </el-input>
            <p v-if="input_model.length > 0">
                <span v-if="input_model.length>=10" style="color:red">最多不超过10个字</span>
                <span v-else-if="input_model.length<10 && findSelectedNum">共{{findSelectedNum}}个符合</span>                                 
            </p>   
        </div>
        <div class="slot_main" :style="`max-height:${getslotMainHeight};`" v-if="!isSimplifiedVersion">
            <div class="clazClass">
                <el-checkbox 
                v-for="classes in goods_classes_groups" 
                :key="classes.key"
                @change="handleChangeClaz($event,classes.key)"
                v-model="classes.ischeck"
                :indeterminate="classes.isIndeterminate"
                >
                    <label style="cursor: pointer;" @click.stop="handleClickClaz($event,classes.key)">{{ classes.title }}</label>
                </el-checkbox>
            </div>
            <div class="groupsClass" ref="groupRefs">
                <el-checkbox-group 
                v-model="select_groups" 
                :size="elementsize"  
                :disabled="isSelectClaz" 
                v-for="classes in goods_classes_groups"
                class="singleGroup"
                :id="classes.id" 
                :key="classes.key">
                    <el-checkbox
                    v-for="groups in classes.groups" 
                    :key="groups.label"
                    :class="getGroupItemClass(groups.label)"
                    :value="groups.label"
                    class="checkboxSpan"
                    @change="handleChangeGroup($event,classes.key,groups.label)"
                    :label="groups.label"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="slot_main_2" :style="`max-height:${getslotMainHeight};`" v-else>
            <el-checkbox 
            v-for="classes in goods_classes_groups" 
            :key="classes.key"
            @change="handleChangeClaz($event,classes.key)"
            v-model="classes.ischeck"
            :indeterminate="classes.isIndeterminate"
            >
                {{classes.title}}
            </el-checkbox>
        </div>
        <div class="slot_footer">
            <el-button type="primary" :size="elementsize" @click="confirm_button_handle">确 定</el-button>
            <el-button :size="elementsize" @click="cancel_button_handle">清 空</el-button>
        </div>
    </div>
    </SelectSlot>
</template>

<script>
import lodash from 'lodash';
import Vue from 'vue';
import { mapGetters } from "vuex";
import { getGroupClazKey, getClazKey} from '@/components/selectSlot/index.js'
export default {
    name:'ClazOrGroupSlot',
    props:{
        selectDataProps:{
            type:Array,
            required:true,
        },
        isSelectClaz:{
            type:Boolean,
            required:true,
            default:true,
        },
        selectWidth:{
            type:String,
            default:'120px'
        },
        placeholder:{
            type:String,
            default:'请选择'
        },
        elCardWidth:{
            type:String,
            default:'500px'            
        },
        isSimplifiedVersion:{     // 是否是简易版 为true isSelectClaz必须也是true
            type:Boolean,
            default:false,
        }
    },
    data(){
        return{
            input_model:'',
            elementsize:"mini",
            goods_classes_groups:[],

            select_groups:[],
            select_clazs:[],

            dropdownShow:true,
            selectArrayCopy: [],
        }
    },
    async created(){
        // 通过请求 获取类别和群组的组合
        let goods_classes = await this.$store.dispatch('findGoodsClasses');
        let GoodsGroups = await this.$store.dispatch('findGoodsGroups');

        this.goods_classes_groups = goods_classes.map((claz,index) =>{
            let key = index + 1;
            let title = `第${key}类`;
            let label = claz;
            let id = `group${key}`;
            let groups = lodash.remove(GoodsGroups, groups => {
                groups.label = `${groups.name}-${groups.title}`;
                groups.oid = id;
                return groups.cls == key;
            })
            groups = lodash.sortBy(groups,(i)=>{
                return parseInt(i.name);
            })
            return {
                title,
                label,
                groups,
                key,
                id,
                isIndeterminate:false,
                ischeck:false
            }
        })
    },
    emits:['changeModel','confirmHandle','cancelHandle'],
    computed:{
        selectArray:{
            get(){
                return this.selectDataProps || [];
            },
            set(newVal){
                this.$emit('changeModel',newVal);
            }
        },
        // 获取满足群组关键字的数量
        getGroupNum(){
            if (!this.input_model  || this.input_model.length === 0 ) {
                return 0;
            }
            let data = this.all_group_flat.filter(group => group.label.includes(this.input_model));
            if (data && data.length > 0) {
                let id = '#' + data[0].oid;
                document.querySelector(id).scrollIntoView(true);
            }
            return data?.length || 0;
        },
        // 获取全部群组的扁平化
        all_group_flat(){
            let groups = this.goods_classes_groups.map(i=>{
                return i.groups;
            })
            let a = _.flatMapDeep(groups,i=>{
                return i;
            })
            return a;
        },
        // 获取满足群组关键字的class
        getGroupItemClass(){
            return (groupLabel) =>{
                if (!this.input_model  || this.input_model.length === 0 ) {
                    return '';
                }
                let is = groupLabel.includes(this.input_model);
                return is ? 'bcg_checkbox' : '';
            }
        },
        ...mapGetters({
            getScreenHeight:"getScreenHeight",
        }),
        getslotMainHeight(){
            return Math.floor(this.getScreenHeight * 0.7) + 'px';
        },
    },
    watch:{
        select_groups:{
            deep:true,
            handler(newVal){
                if (this.isSelectClaz) return;
                this.selectArray = newVal;
            },
        },
        select_clazs:{
            deep:true,
            handler(newVal){
                if (!this.isSelectClaz) return;
                this.selectArray = newVal;
            },
        },
    },
    methods:{
        // 选择单个类别  这个类别的群组进行全部勾选 或 取消勾选
        handleChangeClaz(event,key){
            // 寻找这个类别
            let index = this.goods_classes_groups.findIndex(clazObj => clazObj.key === key);
            if (index === -1) return;
            let clazObj = this.goods_classes_groups[index];

            // 修改这个类别的勾选状态 和 不确定状态；
            clazObj.ischeck = event;
            clazObj.isIndeterminate = false;
            Vue.set(this.goods_classes_groups,index,clazObj);

            // 修改select_groups数组
            clazObj.groups.forEach(group=>{
                let findIndex = this.select_groups.findIndex(i=>i === group.label);
                if (event) {
                    if (findIndex !== -1) return;
                    this.select_groups.push(group.label);
                }else{
                    if (findIndex === -1) return;
                    this.select_groups.splice(findIndex,1);
                }
            })

            // 修改select_clazs数组
            this.select_clazs = this.goods_classes_groups.filter(clazObj => clazObj.ischeck).map(clazObj =>{
                return clazObj.title;
            })
        },
        // 点击类别修改群组显示
        handleClickClaz(event,key){
            event.stopPropagation();
            if (this.$refs.groupRefs) {
                document.querySelector(`#group${key}`).scrollIntoView(true);
            }
        },
        // 选择单个群组
        handleChangeGroup(event,clazKey,groupKey){
            // console.log(event,clazKey,groupKey);
            // 寻找这个类别
            let index = this.goods_classes_groups.findIndex(clazObj => clazObj.key === clazKey);
            if (index === -1) return;
            let clazObj = this.goods_classes_groups[index];

            let num = 0;
            clazObj.groups.forEach(group=>{
                if (this.select_groups.find(i=>i===group.label)) {
                    num++;
                }
            })
            clazObj.isIndeterminate = (num == clazObj.groups.length) ? false : (num == 0 ? false : true);
            clazObj.ischeck = num ==  clazObj.groups.length;

            Vue.set(this.goods_classes_groups,index,clazObj);

            // 修改select_clazs数组
            this.select_clazs = this.goods_classes_groups.filter(clazObj => clazObj.ischeck).map(clazObj =>{
                return clazObj.title;
            })
        },
        // 子组件修改数据
        changeSelectModel(newVal){
            let oldVal = this.isSelectClaz ? this.select_clazs :this.select_groups;
            if (this.isSelectClaz) {
                // 第1类,第2类，第3类 
                // 需要删除的
                let removeClazs = oldVal.filter(oldClaz => !newVal.find(newClaz=>newClaz === oldClaz));
                removeClazs.forEach(removeClaz=>{
                    let removeClazKey = getClazKey(removeClaz);
                    this.handleChangeClaz(false,removeClazKey);
                })
                // 需要添加的
                let addClazs = newVal.filter(newClaz => !oldVal.find(oldClaz=>oldClaz === newClaz));
                addClazs.forEach(addClaz=>{
                    let addClazKey = getClazKey(addClaz);
                    this.handleChangeClaz(true,addClazKey);
                })
            }else{
                let removeGroups = oldVal.filter(oldGroup => !newVal.find(newGroup=>newGroup === oldGroup));
                // 按理说只有一个  且肯定是删除
                this.select_groups = newVal;
                removeGroups.forEach(removeGroup=>{
                    let removeGroupClazkey = getGroupClazKey(removeGroup);
                    this.handleChangeGroup(false,removeGroupClazkey,removeGroup)
                })
            }         
        },
        // 确定按钮
        confirm_button_handle(){
            this.dropdownShow = false;
            this.selectArrayCopy = lodash.cloneDeep(this.selectArray);
            this.$emit('confirmHandle');
        },
        handleChangeDropdownShow(val){
            this.dropdownShow = val;
            if (!val) {
                this.selectArray  = lodash.cloneDeep(this.selectArrayCopy);
                this.changeSelectModel(lodash.cloneDeep(this.selectArrayCopy));
            }
        },
        // 清空按钮
        cancel_button_handle(){
            this.handleClearClazORGroup();
            this.$emit('cancelHandle');
        },
        // 清除全选
        handleClearClazORGroup(){
            this.select_groups = [];
            this.select_clazs = [];
            this.goods_classes_groups.forEach((clazObj,index)=>{
                clazObj.isIndeterminate = false;
                clazObj.ischeck = false;
                Vue.set(this.goods_classes_groups,index,clazObj);
            })
        },
        emptyCopyData(val) {
            this.selectArrayCopy = val;
        }
    },
}
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
.solt_box_claz{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    > * {
        width: 100%;
    }
    .slot_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        .el-input{
           flex: 1;
           max-width: 200px;
           margin-right: 14px;
        }
        margin-bottom: 14px;
    }
    .slot_main{
        display: flex;
        flex-direction: row;
        // border: 1px dashed @border-color-base;
        margin-bottom: 14px;
        .clazClass{
            display: flex;
            flex-direction: column;
            width: 100px;
            overflow: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            .el-checkbox {
                width: 100%;
            }
        }
        .groupsClass{
            display: flex;
            flex-direction: column;
            width: calc(100% - 100px);
            overflow-x: hidden;
            overflow-y: auto;
            // border-left: 1px dashed @border-color-base;
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            .singleGroup{
                border-bottom: 1px solid @border-color-light;
                margin-bottom: 14px;
                padding-bottom: 14px;
                width: 100%;
            }
            .singleGroup:last-child{
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .el-checkbox {
                width: 100%;
                margin: 0;
            }
            ::v-deep .checkboxSpan{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                .el-checkbox__input{
                    margin-top: 4px;
                }
                .el-checkbox__label{
                    flex: 1;
                    // word-break: break-all;
                    white-space: pre-wrap;
                }
            }
        }
    }
    .slot_main_2{
        width: 100%;
        margin-bottom: 14px;
        .el-checkbox{
            width: 100px;
        }
    }
}
.bcg_checkbox{
    background-color: @findKeywordsBcg;
}
</style>