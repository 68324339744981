<template>
    <div>
        <el-dropdown trigger="click" placement="bottom-start" @visible-change="handleCommand" ref="dropdowns">
             <el-select  ref="selectRef" multiple size="mini"
                :placeholder="placeholder"
                :style="`width:${selectWidth}`"
                collapse-tags
                :id="elSelectid"
                class="selectSlotClass"
                v-model="selectModel">
            </el-select>
            <el-dropdown-menu slot="dropdown">
                <el-card  class="elCardBox"  :style="`width:${elCardWidth};`">
                    <slot></slot>
                </el-card>
            </el-dropdown-menu>
        </el-dropdown>          
    </div>
</template>

<script>
import {getRandomKey} from "@/utils/index.js";
export default {
    name: 'selectSlotIndex',
    props: {
        selectArray:{
            type:Array,
            required:true
        },
        placeholder:{
            type:String,
            default:'请选择'
        },
        selectWidth:{
            type:String,
            default:'120px'
        },
        elCardWidth:{
            type:String,
            default:'500px'
        },
        dropdownShow:{
            type:Boolean,
            default:true,
        },
    },
    data() {
        return {
            commandShow:false,
            elSelectid:null,
        };
    }, 

    created() {
        this.elSelectid = 'elSelectid' + getRandomKey('aA0',6);
    },

    methods: {
        handleCommand(val){
            this.commandShow = val;
            this.handleSetSelectStyle(val);

            this.$refs.selectRef.blur();
            this.$emit('chooseDate',val); 
        },
        handleSetSelectStyle(bool){
            const inputDemo = document.querySelector('#' + this.elSelectid);
            const bcg = bool ? '#000000' : '#ffffff';
            const bcl = bool ? '#000000' : '#DCDFE6';
            inputDemo.style.background = bcg;
            inputDemo.style.borderColor = bcl;
        },
    },
    computed:{
        selectModel:{
            get(){
                return this.selectArray || [];
            },
            set(newVal){
                this.$emit('changeSelectModel',newVal);
            }
        },
    },
    watch:{
        dropdownShow(n){
            if (!n) {
                this.$refs.dropdowns.hide();
            }
        },
        selectArray(n){
            let bool = n.length > 0 || this.commandShow;
            this.handleSetSelectStyle(bool);
        }
    }
};
</script>

<style lang="less" scoped>
// ::v-deep .el-tag.el-tag--info{
//     background-color: #f4f4f5 !important;
//     border-color: #e9e9eb !important;
//     color: #909399 !important;
// }

.el-dropdown-menu{
    padding:  0 !important;
}
.el-select-dropdown{
    display: none !important;
}
.elCardBox{
    top: 10px;
}
::v-deep .el-card__body{
    width: 100%;
    height: 100%;
}
::v-deep .el-card__body{
  padding: 14px !important;
}
::v-deep .el-checkbox{
 margin-top: 0 !important;
 margin-bottom: 0 !important;
}

::v-deep .el-tag.el-tag--info{
    color: #fff !important;
    background: #000000 !important;
    border-color: #000000 !important;
}

::v-deep .selectSlotClass{
    .el-tag__close.el-icon-close{
        display: none;
    }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
    color: #606266;
}
</style>