import Vue from 'vue';

// 导入所有组件
import SearchBox from '@/components/searchBox/index.vue';
import Pagination from '@/components/pagination/index.vue'
import TmDomcuments from "@/components/addOfficaial2.vue";
import EvidenceUpload from "@/components/evidence/upload.vue";
import SelectSlot from "@/components/selectslots.vue";
import GroupSelectSlot from "@/components/selectSlot/GroupSelectSlot.vue";
import VirtualScroll from "@/components/el-table-virtual-scroll/index.vue";
import ClazOrGroupSlot from "@/components/selectSlot/ClazOrGroupSlot.vue";
import CustomDatePicker from "@/components/customDatePicker/index.vue";

// 定义一个组件对象，其中键是组件名称，值是组件对象
const components = {
  SearchBox,
  Pagination,
  TmDomcuments,
  EvidenceUpload,
  SelectSlot,
  GroupSelectSlot,
  VirtualScroll,
  ClazOrGroupSlot,
  CustomDatePicker,
};

// 使用循环来注册所有组件
for (const componentName in components) {
  Vue.component(componentName, components[componentName]);
}