<template>
    <el-drawer
        :size="`${getDragDrawerSize}px`"
        :visible.sync="drawerOpen"
        :modal="false"
        :show-close="false"
        class="previewFileBox"
        :lose-on-press-escape="false"
        :before-close="handleCloseDrawer"
        destroy-on-close
        modal-append-to-body
        :close-on-press-escape="true"
        :wrapperClosable="true"
        :style="getDragDrawerStyle"
        ref="dragDraRef"
    >
        <PdfMain :filelink="filelink" :fileName="fileName" :file_size="fileSize"></PdfMain>
        <div class="button-group" :style="`right:${getPluginRight}px;`">
            <el-button 
            :size="elementSize" 
            @click="handleCloseDrawer(null)" 
            plain 
            title="关闭">
                <i class="el-icon-close"></i>
            </el-button>
            <el-button 
            :size="elementSize" 
            plain 
            ref="dragButtonRef" 
            title="拖动改变窗口宽度">
                <i class="icon-tuozhuai_zuoyou iconfont" @mousedown="handleOnMousedown" style="font-size:18px;"></i>
            </el-button>
        </div>
    </el-drawer>
</template>

<script>
import { mapGetters, mapMutations,mapState } from "vuex";
import '@/assets/iconfont/drag/iconfont.css';
import PdfMain from "@/components/pdf.vue";
import lodash from 'lodash';
export default {
    name:"pdf-preview-Vue",
    data(){
        return{
            drawerOpen:true,
            elementSize:'small',
            debouncedOffDrag:null,
        }
    },
    components:{
        PdfMain
    },
    props:{
        filelink:{
            type:String,
            required:true,
        },
        fileName:{
            type:String,
            required:true,
        },
        fileSize:{
            type:Number,
            required:true,
        },
    },
    created(){
        this.$store.commit('systemSetDragDrawerSize');
        this.debouncedOffDrag = lodash.debounce((dragDomWidth,event) =>{
            const dragButtonDemo = this.$refs?.dragButtonRef?.$el;
            if (!dragButtonDemo || !dragButtonDemo?.contains) return;
            // 判断鼠标是否还在拖拽button上
            if (dragButtonDemo.contains(event.target)) return;
            
            // 创建一个新的鼠标事件
            const mouseUpEvent = new MouseEvent("mouseup", {
                bubbles: true,
                cancelable: true,
                view: window
            });
            // 触发鼠标事件
            document.dispatchEvent(mouseUpEvent);
        },500)
    },
    mounted(){
        document.addEventListener('mousedown', this.handleDocumentMouseDown)
    },
    beforeDestroy(){
        if (this.drawerOpen) {
            this.handleCloseDrawer(null);
        }
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    },
    watch:{
        getScreenWidth(newVal){
            this.$store.commit('systemSetDragDrawerSize');
        }, 
    },
    computed:{
        ...mapGetters([
            "getDragDrawerSize",
            "getScreenWidth",
            "getDragDrawerMr",
            "getIsCloseDragDrawer",
        ]),
        getPluginRight(){
            let mr = this.getDragDrawerMr;
            let size = this.getDragDrawerSize;
            
            return parseInt(mr) + parseInt(size);
        },
        getDragDrawerStyle(){
            return `margin-right:${this.getDragDrawerMr}px`;
        },
    },
    methods:{
        handleCloseDrawer(done){
            if (done) {
                if (!this.getIsCloseDragDrawer) return;
                done();
            }else{
                this.drawerOpen = false;
            }
            this.$emit('closeDrawer');
        },
        handleOnMousedown(e){
            const el = this.$refs.dragDraRef.$el;
            const dragDom = el.querySelector('.el-drawer');
            const elW = dragDom.clientWidth;
            const minWidth = 400;
            const EloffsetLeft = dragDom.offsetLeft;
            const clientX = e.clientX;  
            const that = this;
            // 拖拽按钮距离浏览器头部的距离 定位的top + 第一个按钮的height + 第一个按钮的margin-bottom
            const dragIconTop = 140 + 30 + 14;
            
            document.onmousemove = function (e) {
                
                e.preventDefault();
                // 左侧鼠标拖拽位置
                if (clientX < EloffsetLeft && clientX > EloffsetLeft - 40) {
                    // 往左拖拽
                    if (clientX > e.clientX) {
                        let dragDomWidth = elW + (clientX - e.clientX);
                        that.setDragDrawerSize(dragDom,dragDomWidth,e);
                    }
                    // 往右拖拽
                    if (clientX < e.clientX) {
                        if (dragDom.clientWidth >= minWidth) {
                            let dragDomWidth = elW - (e.clientX - clientX);
                            that.setDragDrawerSize(dragDom,dragDomWidth,e);
                        }
                    }

                    if (e.clientY < dragIconTop || e.clientY  > (dragIconTop + 30)) {
                        that.$store.commit("setIsCloseDragDrawer",false);
                    }
                }
            }

            document.onmouseup = function (e) {
                document.onmousemove = null;
                document.onmouseup = null;
            }
        },
        handleDocumentMouseDown(){
            this.$store.commit("setIsCloseDragDrawer",true);
        },
        setDragDrawerSize(dragDom,dragDomWidth,event){
            dragDom.style.width = dragDomWidth + 'px';
            this.$store.commit("userSetDragDrawerSize",dragDomWidth);
            this.debouncedOffDrag(dragDomWidth,event);
        },
    },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
.button-group{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 140px;// dragIconTop
    .el-button{
        margin: 0;
        padding: 5px;
        border: 0;
        border-radius: 4px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        span{
            i{
                font-size: 20px;
            }
        }
    }
    >.el-button:nth-child(1){
        margin-bottom: 14px;// dragIconTop
        margin-left: 0 !important;
        background: @color-primary;
        color:@color-white;
        box-shadow: -7px 0 7px -3px @border-color-base, 0 -7px 7px -3px @border-color-base, 0 7px 7px -3px @border-color-base;
    }
    >.el-button:nth-child(2){
        margin-left: 0 !important;
        color:@color-primary;
        box-shadow:  -7px 0 7px -3px @border-color-base, 0 -7px 7px -3px @border-color-base, 0 7px 7px -3px @border-color-base;
        cursor: w-resize;
    }
}
</style>