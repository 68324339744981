<template>
    <SelectSlot 
    :selectArray="selectArray" 
    :elCardWidth="elCardWidth"
    :placeholder="placeholder"
    :selectWidth="selectWidth"
    :dropdownShow="dropdownShow"
    @changeSelectModel="changeSelectModel"
    @chooseDate="handleChangeDropdownShow"
    >
        <div class="solt_box">    
            <div class="slot_header">
                <el-input size="mini" v-model.trim="input_model" placeholder="输入关键字" maxlength="10" clearable></el-input>
                <p v-if="input_model.length > 0">
                    <span v-if="input_model.length>=10" style="color:red">最多不超过10个字</span>
                    <span  v-else-if="input_model.length<10 && findSelectedNum ">共{{findSelectedNum}}个符合</span>                                 
                </p>                 
            </div>
            <div class="slot_main" :style="`max-height:${getslotMainHeight};`">
                <div v-for="(item, index) in groupData" :key="index" :style="index?'margin-top:14px;':''">
                    <span :id="item.id" v-show="groupData.length > 1">{{item.label}}</span>
                    <el-checkbox-group v-model="selectArray">
                        <el-checkbox 
                        v-for="v in item.value" 
                        :key="v.key"  
                        :class="getItemClass(v.label)"  
                        :label="v.label"
                        :style="getItemStyle(v.label)">
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="slot_footer">
                <el-button size="mini"  @click="confirm_button_handle" type="primary">确定</el-button>
                <el-button size="mini" class="info_button" plain @click="cancel_button_handle">清空</el-button>
                <span class="promptTextClass" v-show="promptText && promptText.length > 0">{{ promptText }}</span>
            </div>
        </div>
    </SelectSlot>
</template>

<script>
import { mapGetters } from "vuex";
import {letterGroup} from './index.js';
import {getTextWidth, } from '@/utils/index.js';
import lodash from 'lodash'
export default {
    name:'GroupSelectSlot',
    props:{
        groupSelectData:{
            type:Array,
            required:true,
        },
        groupNameFlats:{
            type:Array,
            required:true,
        },
        groupNum:{
            type:Number,
            default: 24,
        },
        selectWidth:{
            type:String,
            default:'120px'
        },
        placeholder:{
            type:String,
            default:'请选择'
        },
        elCardWidth:{
            type:String,
            default:'500px'            
        },
        promptText:{
            type:String,
            default:'',
        }
    },
    emits:['changeModel','confirmHandle','cancelHandle'],
    data(){
        return{
            input_model:'',
            groupData:[],
            dropdownShow:true,
            selectArrayCopy: [],
        }
    },
    computed:{
        selectArray:{
            get(){
                return this.groupSelectData || [];
            },
            set(newVal){
                this.$emit('changeModel',newVal);
            }
        },
        findSelectedNum(){
            if (!this.input_model  || this.input_model.length === 0 ) {
                return 0;
            }
            
            let data = this.groupDataFlat.filter(item => item.label.includes(this.input_model));
            if (data && data.length > 0) {
                let id = '#' + data[0].demoId;
                document.querySelector(id).scrollIntoView(true);
            }
            return data?.length || 0;  
        },
        getItemClass(){
            return (name) =>{
                if (!this.input_model  || this.input_model.length === 0 ) {
                    return '';
                }
                let is = name.includes(this.input_model);
                return is ? 'bcg_checkbox ' : '';
            }
        },
        getItemStyle(){
            return  (name) =>{
                  let textWidth = getTextWidth(name,14);
                  if (this.elCardWidth=="500px") {
                    if (textWidth>196) {
                      return {
                        width:"98%",
                      }                        
                    }else{
                      return {
                        width:"46%",
                      }                         
                    }
                  }else if(this.elCardWidth == "720px"){
                  if (textWidth>130 && textWidth<=270) {
                      return {
                        width:"46%",
                      }                    
                  }else if(textWidth>270){
                      return {
                        width:"98%",
                      }
                  }else{
                    return {
                        width:"22%",
                    }
                  }                    
                  }else if(this.elCardWidth == "850px"){
                  if (textWidth>180 && textWidth<=270) {
                      return {
                        width:"47.8%",
                      }                    
                  }else if(textWidth>270){
                      return {
                        width:"98%",
                      }
                  }else{
                    return {
                        width:"23%",
                    }
                  }                     
                  }

            }
        },
        ...mapGetters({
            getScreenHeight:"getScreenHeight",
        }),
        getslotMainHeight(){
            return Math.floor(this.getScreenHeight * 0.7) + 'px';
        },
        groupDataFlat(){
            let arr = [];
            this.groupData.forEach(item=>{
                item.value.forEach(i=>{
                    i.demoId = item.id;
                    arr.push(i)
                })
            })

            return arr;
        }
    },
    created(){
        this.groupData = letterGroup(this.groupNameFlats,this.groupNum);
    },
    watch:{
        groupNameFlats:{
            deep:true,
            handler(){
                this.groupData = letterGroup(this.groupNameFlats,this.groupNum);
            },
        }
    },
    methods:{
        changeSelectModel(val){
            this.selectArray = val;
            this.selectArrayCopy = lodash.cloneDeep(val);
        },
        confirm_button_handle(){
            this.selectArrayCopy = lodash.cloneDeep(this.selectArray);
            this.dropdownShow = false;
            this.$emit('confirmHandle');
        },
        cancel_button_handle(){
            this.selectArray = [];
            this.input_model = '';
            this.$emit('cancelHandle');
        },
        handleChangeDropdownShow(val){
            this.dropdownShow = val;
            if (!val) {
                this.selectArray = lodash.cloneDeep(this.selectArrayCopy);
            }else{
                this.$emit('chooseSelectDropDown',true)
            }
        },
        emptyCopyData(val) {
            this.selectArrayCopy = val;
        }
    },
    
}
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
.solt_box{
    width: 100%;
    height: 100%;
    .slot_header{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 14px;
        .el-input{
           flex: 1;
           margin-right: 14px;
           max-width: 200px;
        }
    }
    .slot_main{
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 14px;
        span{
          color:#909399;
        }
    }
}
.bcg_checkbox{
  background: @findKeywordsBcg;
}
.el-checkbox-group{
    .el-checkbox{
        margin-right: 14px;
    }
    .el-checkbox:last-of-type {
        margin-right: 0;
    }
}
.slot_footer{
    .promptTextClass{
        margin-left: 14px;
        color: @color-text-placeholder;
    }
}
</style>