<template>
  <transition name="el-fade-in-linear">
    <ul
      class="right-menu-list"
      id="rightMenuListId"
      ref="rightMenuListRef"
      :style="`top: ${rightMenu.top};right: ${rightMenu.right};bottom: ${rightMenu.bottom};left: ${rightMenu.left};`"
    >
      <li class="right-menu-item" @click="closeTab">关闭标签页</li>
      <li class="right-menu-item" @click="closeOtherTabs">关闭其他标签页</li>
      <li class="right-menu-item" @click="closeLeftTabs">关闭左侧标签页</li>
      <li class="right-menu-item" @click="closeRightTabs">关闭右侧标签页</li>
    </ul>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      rightMenu: {
        top: 0,
        left: 0,
        bottom: "auto",
        right: "auto",
      },
    };
  },
  watch: {
    event: {
      handler: "handleOpenContextMenu",
      deep: true,
    },
    show: {
      handler: "handleWatchShow",
      deep: true,
    },
  },
  props: {
    event: {},
    route: {},
    show: false,
  },
  methods: {
    handleOpenContextMenu(newVal) {
      // 获取它的宽和高
      const demoWidth = this.$refs.rightMenuListRef.offsetWidth;
      const demoHeight = this.$refs.rightMenuListRef.offsetHeight;
      // 现在只考虑它的右边不足  和下边不足
      // 横坐标设置
      if (document.body.clientWidth - newVal.clientX > demoWidth) {
        this.rightMenu.left = newVal.clientX + "px";
        this.rightMenu.right = "auto";
      } else {
        this.rightMenu.left = "auto";
        this.rightMenu.right =
          document.body.clientWidth - newVal.clientX + "px";
      }
      // 纵坐标设置
      if (document.body.clientHeight - newVal.clientY > demoHeight) {
        this.rightMenu.top = newVal.clientY + "px";
        this.rightMenu.bottom = "auto";
      } else {
        this.rightMenu.top = "auto";
        this.rightMenu.bottom =
          document.body.clientHeight - newVal.clientY + "px";
      }
    },
    handleWatchShow(bool) {
      if (bool) {
        document.addEventListener("click", this.handleClick);
        document.addEventListener("contextmenu", this.handleClick);
      } else {
        document.removeEventListener("click", this.handleClick);
        document.removeEventListener("contextmenu", this.handleClick);
      }
    },
    handleClick() {
      this.$emit("close", null);
    },
    closeTab() {
      this.$emit("close", "closeTab", this.route);
    },
    closeOtherTabs() {
      this.$emit("close", "closeOtherTabs", this.route);
    },
    closeLeftTabs() {
      this.$emit("close", "closeLeftTabs", this.route);
    },
    closeRightTabs() {
      this.$emit("close", "closeRightTabs", this.route);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
.right-menu-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 1003;
  padding: 4px 0;
  list-style: none;
  background:@color-white;
  border-radius: @border-radius;
  color: @color-text-secondary;
  .right-menu-item {
    padding: 0 16px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    &:hover {
      background-color: @border-color-extra-light;
      // border-top: 1px solid @border-color-base;
      // border-bottom: 1px solid @border-color-base;
      // line-height: 34px;
      color: @color-text-primary;
    }
  }
}
</style>