<template>
  <div class="upload-box">
    <el-drawer
      :visible.sync="TmDocumentUploadShowPage"
      size="100%"
      v-watchWidth="handleWatch"
      custom-class="UploadfileClass"
      id="UploadfileId"
      direction="btt"
      :modal-append-to-body="false"
      :destroy-on-close="false"
      ref="uploadFile"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :show-close="!is_after_uploading"
    >
    <div slot="title" class="title">
      档案识别工作台
    </div>
    <el-form
    :label-width="tmInfoShow?'74px':'auto'"
    label-position="left"
    class="formLeftWeight"
    >
        <el-form-item label="商标" class="hight30" v-if="tmInfoShow">
           <p class="tmP">{{ tmInFo.tmName }} - {{ tmInFo.reg_num  }}</p>
        </el-form-item>
        <el-form-item label="案件" class="hight40" v-if="caseShow">
           <!-- <el-row v-show="!CaseTableShow">
            <el-col :span="2">
                <el-button  @click="handleCaseTableKey" size="mini">选择案件</el-button>
            </el-col>
            <el-col :span="22" :title="caseNames" class="text-ellipsis1" v-show="changeCaseTable.length > 0">
                <span>已选择{{ changeCaseTable.length }}个案件：{{ caseNameAbbreviation?caseNameAbbreviation:caseNames }}</span>
            </el-col>
           </el-row> -->
           <div  v-show="!CaseTableShow"  class="flex-start">
                <el-button  @click="handleCaseTableKey" size="mini">选择案件</el-button>
                <span class="span_margin">已选择{{ changeCaseTable.length }}个案件：{{ caseNameAbbreviation?caseNameAbbreviation:caseNames }}</span>
           </div>
           <div class="case"  v-show="CaseTableShow">
            <el-row>
                <el-col :span="6">
                    已选择{{ changeCaseTable.length }}个案件
                </el-col>
                <el-col :span="5" :offset="13" class="floatButton">
                    <el-button size="mini" class="button1" type="success" @click="handleCaseTableApi(true)">确定</el-button>
                    <el-button  size="mini" @click="handleCaseTableApi(false)">取消</el-button>
                </el-col>
            </el-row>
            <!-- 案件表格的高                  height="518"  -->
            <div class="CaseTableClass tableAllBox">
                <el-table
                :data="casePagingTable"
                ref="CaseTableRef"
                :height="caseTableHeight"
                :header-cell-style="{ background: '#f9f9f9', color: '#606266' }"
                row-key="_id"
                @row-click="CaseTableClick"
                @selection-change="handleSelectionChange"
                style="width: 100%;overflow-y:visible;"
                >
                    <el-table-column  type="selection" width="50"  reserve-selection> </el-table-column>
                    <el-table-column  type="index" :index="indexMethod" width="50" label="序号" ></el-table-column>
                    <template v-for="item in  caseFields">
                        <el-table-column
                        v-if="item.label != '代理文号' && item.label != '官方申请号'"
                        :label="item.label"
                        :key="item.label"
                        show-overflow-tooltip
                        tooltip-effect="dark"
                        >
                        <template slot-scope="scope">
                            <span :style="item.prop == 'status' ? 'color:#ffa837' : ''">
                            {{
                                scope.row[item.prop]
                                | filterItem(item.filter, item.twoLevel)
                            }}
                            </span>
                        </template>
                        </el-table-column>
                    </template>
                </el-table>
                <el-pagination
                background
                class="paginationClass"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page="currentPage"
                @current-change="handleCurrentChange"
                :pager-count="5"
                >
                </el-pagination>
            </div>
    
           </div>
        </el-form-item>
        <el-form-item :label="tmInfoShow?'选择档案':''"  class="hightNAN">
            <div class="upload" v-if="!is_after_uploading">
                <div class="el-upload-box">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    drag
                    :style="upLoadStyle"
                    action="none"
                    :auto-upload="false"
                    :on-change="handlenUpLoadChangeDebounced"
                    :accept="getAccept"
                    multiple>
                    <div>
                        <i class="el-icon-upload"></i>
                        <!-- <div class="el-upload__text" v-show="upLoadStyle.width === '100%'">将文件拖到此处，或<em>点击上传</em></div> -->
                        <div class="el-upload__text"><p>将文件拖到此处</p><p> 或</p><p><em>点击上传</em></p></div>
                    </div>
                    <div class="upload-footer-class">
                        <p>支持格式：pdf，jpg，png，bmp，txt，csv，zip，rar，doc，docx，xls，xlsx，mp4，avi，mov，mkv，wmv，flv，webm，mp3，wav，aac，ogg，wma，flac，mid，midi</p>
                        <p>单次上传限制：文件数量不超过1000个，文件总大小不超过1GB（压缩包不超过1GB，单个文件不超过500MB）</p>
                    </div>
                    </el-upload>
                </div>
                <div class="upload-list-box">
                    <div class="upload-list" :style="uploadListStyle" v-if="!uploadProgressShow">
                        <!-- 普通文件 -->
                        <div v-show="textFiles.length > 0">
                            <el-row class="fileTitle"><el-col class="indent24" :span="14">文档</el-col><el-col :span="10">收文日期</el-col></el-row>        
                            <div v-for="item in textFiles" :key="item.key"  @mouseout="OneFileOut(item)" @mouseover="OneFileOver(item)" class="fileConten">
                                <el-col :span="13" :title="item.fileName" class="text-ellipsis"><i class="el-icon-document-remove"></i> {{ item.fileName }}</el-col>
                                <el-col :span="6" :offset="1" v-show="!item.is_show">{{ item.fileDate || '无收文日期'}}</el-col>
                                <el-col :span="6" :offset="1" v-show="item.is_show">
                                    <el-date-picker
                                    v-model=" item.fileDate"
                                    :editable="false"
                                    size="mini"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                                <el-col :span="2"  v-show="!item.is_show && item.is_hover" :offset="2">
                                    <i class="el-icon-edit-outline fileIcon right10" @click="editFile('textFiles',item.key)"></i>
                                    <i class="el-icon-close fileIcon" @click="removeFile('textFiles',item.key)"></i>
                                </el-col>
                                <el-col :span="2" :offset="2" v-show="item.is_show">
                                <span class="right10" @click="patchFile('textFiles',item.key)">保存</span>
                                <span @click="cancelFilePatch('textFiles',item.key)">取消</span>
                                </el-col>
                            </div>
                        </div>
                        <!-- 图片  -->
                        <div v-show="imgFiles.length > 0">
                            <el-row class="fileTitle"><el-col class="indent24" :span="14">图片</el-col><el-col :span="10">收文日期</el-col></el-row>    
                            <div v-for="item in imgFiles" :key="item.key"  @mouseout="OneFileOut(item)" @mouseover="OneFileOver(item)" class="fileConten">
                                <el-col :span="13" :title="item.fileName" class="text-ellipsis"><i class="el-icon-document-remove"></i> {{ item.fileName }}</el-col>
                                <el-col :span="6" :offset="1" v-show="!item.is_show">{{ item.fileDate || '无收文日期'}}</el-col>
                                <el-col :span="6" :offset="1"  v-show="item.is_show">
                                    <el-date-picker
                                    v-model=" item.fileDate"
                                    :editable="false"
                                    size="mini"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                                <el-col :span="2"  v-show="!item.is_show && item.is_hover" :offset="2">
                                    <i class="el-icon-edit-outline fileIcon right10" @click="editFile('imgFiles',item.key)"></i>
                                    <i class="el-icon-close fileIcon" @click="removeFile('imgFiles',item.key)"></i>
                                </el-col>
                                <el-col :span="2" :offset="2" v-show="item.is_show">
                                <span  class="right10" @click="patchFile('imgFiles',item.key)">保存</span>
                                <span @click="cancelFilePatch('imgFiles',item.key)">取消</span>
                                </el-col>
                            </div>
                        </div>
                        <!-- 压缩包 -->
                        <div v-show="zipFiles.length > 0">
                            <el-row class="fileTitle"><el-col class="indent24" :span="14">压缩包</el-col><el-col :span="10">收文日期</el-col></el-row>
                            <div v-for="item in zipFiles" :key="item.key"  @mouseout="OneFileOut(item)" @mouseover="OneFileOver(item)" class="fileConten">
                                <el-col :span="14" :title="item.fileName" class="text-ellipsis"><i class="el-icon-folder-opened"></i> {{ item.fileName }}</el-col>
                                <el-col :span="6"  v-show="!item.is_show">{{ item.fileDate || '无收文日期'}}</el-col>
                                <el-col :span="6"  v-show="item.is_show">
                                    <el-date-picker
                                    v-model=" item.fileDate"
                                    :editable="false"
                                    size="mini"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                                <el-col :span="2"  v-show="!item.is_show && item.is_hover" :offset="2">
                                    <i class="el-icon-edit-outline fileIcon right10" @click="editFile('zipFiles',item.key)"></i>
                                    <i class="el-icon-close fileIcon" @click="removeFile('zipFiles',item.key)"></i>
                                </el-col>
                                <el-col :span="2" :offset="2"  v-show="item.is_show">
                                <span  class="right10" @click="patchFile('zipFiles',item.key)">保存</span>
                                <span @click="cancelFilePatch('zipFiles',item.key)">取消</span>
                                </el-col>
                            </div>
                        </div>
                         <!-- 多媒体 -->
                         <div v-show="mediaPlayerFiles.length > 0">
                            <el-row class="fileTitle"><el-col class="indent24" :span="14">多媒体</el-col><el-col :span="10">收文日期</el-col></el-row>
                            <div v-for="item in mediaPlayerFiles" :key="item.key"  @mouseout="OneFileOut(item)" @mouseover="OneFileOver(item)" class="fileConten">
                                <el-col :span="14" :title="item.fileName" class="text-ellipsis"><i class="el-icon-folder-opened"></i> {{ item.fileName }}</el-col>
                                <el-col :span="6"  v-show="!item.is_show">{{ item.fileDate || '无收文日期'}}</el-col>
                                <el-col :span="6"  v-show="item.is_show">
                                    <el-date-picker
                                    v-model=" item.fileDate"
                                    :editable="false"
                                    size="mini"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                                <el-col :span="2"  v-show="!item.is_show && item.is_hover" :offset="2">
                                    <i class="el-icon-edit-outline fileIcon right10" @click="editFile('mediaPlayerFiles',item.key)"></i>
                                    <i class="el-icon-close fileIcon" @click="removeFile('mediaPlayerFiles',item.key)"></i>
                                </el-col>
                                <el-col :span="2" :offset="2"  v-show="item.is_show">
                                <span  class="right10" @click="patchFile('mediaPlayerFiles',item.key)">保存</span>
                                <span @click="cancelFilePatch('mediaPlayerFiles',item.key)">取消</span>
                                </el-col>
                            </div>
                        </div>
                    </div>
                    <div class="upload-list-progress" :style="uploadListStyle" v-else>
                        <el-progress style="width:100%;" :text-inside="true" :stroke-width="22" :percentage="uploadPercentage"></el-progress>
                        <p style="margin-top:28px;line-height:14px;">共{{ fileList.length }}个文件，已上传{{ getUoloadlistNum }}个</p>
                    </div>
                    <div class="upload-list-footer">
                        <div>
                            <!-- <el-tooltip class="item" effect="dark" content="上传压缩包内文件，不会上传压缩包。" placement="bottom"> -->
                                <el-checkbox v-model="is_decompression_file">zip, rar文件仅保存，不解压缩</el-checkbox>
                            <!-- </el-tooltip> -->
                            <!-- <el-tooltip class="item" effect="dark" content="将图片转为PDF后上传，原图片不上传。" placement="bottom"> -->
                                <el-checkbox v-model="is_convert_to_pdf">图片转为PDF保存 (如果需要识别的官文是图片格式，例如公告，需要选中)</el-checkbox>
                            <!-- </el-tooltip> -->
                        </div>
                        <div> 
                            <!-- v-if=""  v-else -->
                            <span style="margin-right:10px;">共{{ fileList.length }}个文件</span>
                            <el-button size="small"  type="primary" v-show="!isRecognition" v-preventReClick @click="handlenSubmitFile" :loading="SubmitLoading">提&nbsp;&nbsp;交</el-button>
                            <el-button size="small" type="primary" v-show="isRecognition" v-preventReClick @click="handleSetRecognition">后台运行</el-button>
                        </div>
                    </div>
            </div>
        </div>
            <div v-else class="tableAllBox">
                <!-- OBPagingTable  OBTable    height="230" -->
                <el-table 
                :data="OBPagingTable"
                :key="OBTablekey"
                :height="OBTableHeight"
                ref="OBTableRef"
                class=""
                :header-cell-style="{ background: '#f9f9f9', color: '#606266' }"
                style="width: 100%;"
                :row-class-name="addClass"
                >
                    <el-table-column label="官文"  show-overflow-tooltip  :width="tableConfig.fileName">
                        <template slot-scope="scope">
                            <span> {{ scope.row.originalname || scope.row.filename  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商标号" prop="reg_num" show-overflow-tooltip :width="tableConfig.reg_num"></el-table-column>
                    <el-table-column label="官文类型" show-overflow-tooltip :width="tableConfig.odType">
                        <template slot-scope="scope">
                            <span> {{ scope.row.odType || scope.row.type  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="收文日期" show-overflow-tooltip>
                        <!-- value-format="yyyy-MM-dd" -->
                        <template slot-scope="scope">
                            <span v-if="!scope.row.is_edit"> {{ scope.row.receive_date_str }}</span>
                            <el-date-picker
                            v-else
                            @change="handleClearReceiveDate($event,scope.row)"
                            @blur="handleEditReceiveDate($event,scope.row)"
                            v-model="scope.row.receive_date"
                            size="mini"
                            style="width:150px;"
                            type="date"
                            format="yyyy-MM-dd"
                            :editable="false"
                            placeholder="选择日期">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column label="官方期限" prop="time_limit_str" show-overflow-tooltip :width="tableConfig.time_limit"></el-table-column>
                    <el-table-column label="送达发送"  :width="tableConfig.modeOfService">
                        <!-- modeOfServiceOption -->
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.modeOfService" v-show="scope.row.is_edit"  style="width:120px;" placeholder="请选择" @change="handleEditModeOfService($event,scope.row)">
                                <el-option
                                v-for="item in modeOfServiceOption"
                                :key="item"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                            <span v-show="!scope.row.is_edit"> {{ scope.row.modeOfService }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="status"  :width="tableConfig.status">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status !=='wait'">{{ scope.row.status | filterStatus }}</span>
                            <i v-else class="el-icon-refresh-right icon-loading-class"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"   :width="tableConfig.operate">
                        <template slot-scope="scope">
                            <el-button-group v-show="!scope.row.is_edit &&scope.row.status !=='wait'" class="butttonAll">
                                <div v-for=" item in fileStatus[scope.row.status]" :key="item.key">
                                    <el-button type="text"  size="small" v-if="item.text!=='删除'" @click="handleALLApi(item.api,'OBTable',scope.row)">{{ item.text }}</el-button>
                                    <el-popconfirm
                                        v-else
                                        :title="scope.row.status === 'repeat'?'此删除仅删除记录,不删除数据库, 是否继续?':'删除后不可恢复, 是否继续?'"
                                        confirm-button-text='确定'
                                        width="150"
                                        cancel-button-text='取消' 
                                        @confirm="handleDelFile('OBTable',scope.row)"
                                        @cancel="handleDelFileCancel"
                                        >
                                        <el-button slot="reference" type="text"  size="small">{{ item.text }}</el-button>
                                    </el-popconfirm>
                                </div>
                            </el-button-group>
                            <el-button-group v-show="scope.row.is_edit" class="butttonAll">
                               <el-button type="text"  size="small" @click="handleTableSave('OBTable',scope.row)">保存</el-button>
                               <el-button type="text"  size="small" @click="handleTableCancel('OBTable',scope.row)">取消</el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                background
                class="paginationClass"
                layout="prev, pager, next, total"
                :total="OBtotal"
                :page-size="OBpageSize"
                :current-page="OBcurrentPage"
                @current-change="handleOBCurrentChange"
                :pager-count="5"
                >
                </el-pagination>
                <!-- otherPagingTable  otherTable       height="230"                   -->
                <el-table 
                :data="otherPagingTable"
                :key="otherTablekey"
                :height="otherTableHeight"
                :header-cell-style="{ background: '#f9f9f9', color: '#606266' }"
                ref="otherTableRef"
                class="trademarkTable"
                style="width: 100%;"
                :row-class-name="addClass"
                >
                    <el-table-column label="其他文件"  show-overflow-tooltip :width="tableConfig.fileName">
                        <template slot-scope="scope">
                            <span> {{ scope.row.originalname || scope.row.filename  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商标号" prop="reg_num" show-overflow-tooltip :width="tableConfig.reg_num"></el-table-column>
                    <el-table-column label="案件类型" prop="type" show-overflow-tooltip></el-table-column>
                    <el-table-column label="分类" :width="tableConfig.modeOfService">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.type" style="width:120px;" v-show="scope.row.is_edit" placeholder="请选择" @change="handleEditType($event,'otherTable',scope.row)">
                                <el-option
                                v-for="item in TMatchTag"
                                :key="item"
                                :label="item"
                                
                                :value="item">
                                </el-option>
                            </el-select>
                            <span v-show="!scope.row.is_edit"> {{ scope.row.type }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="status"  :width="tableConfig.status">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status !=='wait'">{{ scope.row.status | filterStatus }}</span>
                            <i v-else class="el-icon-refresh-right icon-loading-class"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"  :width="tableConfig.operate">
                        <template slot-scope="scope">
                            <el-button-group v-show="!scope.row.is_edit &&scope.row.status !=='wait'" class="butttonAll">
                                <div v-for=" item in fileStatusCopy[scope.row.status]" :key="item.key">
                                    <el-button type="text"  size="small" v-if="item.text!=='删除'" @click="handleALLApi(item.api,'otherTable',scope.row)">{{ item.text }}</el-button>
                                    <el-popconfirm
                                        v-else
                                        :title="scope.row.status === 'repeat'?'此删除仅删除记录,不删除数据库, 是否继续?':'删除后不可恢复, 是否继续?'"
                                        confirm-button-text='确定'
                                        width="150"
                                        cancel-button-text='取消' 
                                        @confirm="handleDelFile('otherTable',scope.row)"
                                        @cancel="handleDelFileCancel"
                                        >
                                        <el-button slot="reference" type="text"  size="small">{{ item.text }}</el-button>
                                    </el-popconfirm>
                                </div>
                            </el-button-group>
                            <el-button-group v-show="scope.row.is_edit" class="butttonAll">
                               <el-button type="text"  size="small" @click="handleTableSave('otherTable',scope.row)">保存</el-button>
                               <el-button type="text"  size="small" @click="handleTableCancel('otherTable',scope.row)">取消</el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                background
                class="paginationClass"
                layout="prev, pager, next, total"
                :total="Othertotal"
                :page-size="OtherpageSize"
                :current-page="OthercurrentPage"
                @current-change="handleOtherCurrentChange"
                :pager-count="5"
                >
                </el-pagination>
            </div>
        </el-form-item>
    </el-form>
    <div class="footer-box"  v-show="is_after_uploading"></div>
    <div  class="drawer-footer-class" v-show="is_after_uploading" :style="`padding-left:${tmInfoShow?'114px':'40px'};`">
        <div class="flex-footer">
            <div class="left-footer">
                <el-progress :style="`width:${identifyPercentageWidth}px;`" :text-inside="true" :stroke-width="16" :percentage="identifyPercentage"></el-progress>
                <div class="text_1 animationBox" ref="animationRef" v-if="!is_complete">
                    <p v-watchWidth="handleSpanWidth">AI识别中，大约需要{{ getUploadDate }}秒...共
                    <span class="colorSpan">{{ FileNum }}</span>
                    个文件，已完成</p>&nbsp;
                    <span class="numberScroll colorSpan" :style="`left:${SpanWidth + 4 }px;`">0</span>
                    <span style="opacity: 0;" class="colorSpan">{{completeNum}}</span>
                    &nbsp;个文件
                </div>
                <p class="text_2" v-else> AI识别完成，共上传
                 <span class="colorSpan"> {{ FileNum }} </span>
                个文件，成功
                 <span class="colorSpan"> {{ succeededNum }} </span>
                个文件，失败 
                 <span class="colorSpan"> {{ FileNum - succeededNum }} </span>
                个文件</p>
            </div>
            <el-button v-if="isRecognition" size="small"  type="primary" v-preventReClick @click="handleSetRecognition">后台运行</el-button>
            <el-button v-if="is_complete" size="small" @click="handleClose" v-preventReClick type="primary"> 完&nbsp;&nbsp;成</el-button>
        </div>
    </div>
    </el-drawer>
    <Official-Details
      v-if="OfficialDetailsOpen"
      :OfficialDetailData="OfficialDetailData"
      :OfficialExplain="OfficialExplain"
      :tmatchExplain="tmatchExplain"
      :OfficialId="OfficialId"
      :doc_id="doc_id"
      :details="details"
      @changeDrawerState="changeDrawerState"
      @changeOfficialForm="changeOfficialForm"
      @closeUpload="handleClose"
      @putDate="changePutDate"
    ></Official-Details>

  </div>
</template>

<script>
import OfficialDetails from "@/components/officialdetails.vue";
import { mapGetters,mapState } from "vuex";
import { trademarkEdit,fileLimits } from "@/utils/data";
import { toYMDTime,mongodbQuery,extractRegNoAndDate,AddDayTime,get_size_str} from "@/utils/index";
import lodash from 'lodash';
import { Postofficial ,updateOfficialstate,Getofficial,delofficial,patchcases,findBind,GetFile} from '@/api/axios'
export default {
    components: {
        OfficialDetails,
    },
    data(){
        return {
            FileNum:0,
            completeNum:0,
            completeNumCopy:0,   // 计算页面更新
            succeededNum:0,
            //tmInfo
            tmInfoShow:true,
            caseShow:true,
            caseFields:new trademarkEdit().caseFields,
            //分页-----
            total:0,
            pageSize:13, //的高
            currentPage:1,
            // OB分页
            OBtotal:0,
            OBpageSize:5, 
            OBcurrentPage:1,
            // other分页
            Othertotal:0,
            OtherpageSize:5, 
            OthercurrentPage:1,
            //案件table-----
            casePagingTable:[],
            CaseTableShow:false,
            changeCaseTable:[],
            changeCaseTableCopy:[],
            caseNames:'',
            caseNameAbbreviation:null,
            //上传---------
            upLoadStyle:{
                width:'100%',
                height:''  //抽屉的高
            },
            uploadListStyle:{
                height:'' // 抽屉的高
            },
            caseTableHeight:0,   // upLoadStyle.height - 分页的样式
            OBTableHeight:0,
            otherTableHeight:0,
            // accept:'.pdf,.jpg,.png,.bmp,.txt,.csv,.zip,.rar,.doc,.docx,.xls,.xlsx,.mp4,.avi,.mov,.mkv,.wmv,.flv,.webm,.mp3,.wav,.aac,.ogg,.wma,.flac,.mid,.midi',
            fileAllSize:0,
            fileSort:fileLimits,
            fileList:[],   // 总文件
            // 只存  文件名fileName  和  收文日期fileDate-----
            textFiles:[],   // 文档文件  
            imgFiles:[],    // 图片文件
            zipFiles:[],    // 压缩包文件
            mediaPlayerFiles:[],   // 多媒体文件  （视频音频）
            // 单选按钮--
            is_decompression_file:false,
            is_convert_to_pdf:false,
            //控制是否上传后
            is_after_uploading:false,
            completeData:'',
            // 控制是否接收完毕
            is_complete:false,
            otherTable:[
            ],
            otherPagingTable:[],
            OBTable:[
            ],
            OBPagingTable:[],
            // OBTable 和 otherTable 渲染有问题  加上key  强制更改
            OBTablekey:100,
            otherTablekey:1,
            modeOfServiceOption:[
            '电子','纸质'
            ],
            fileStatus:{
                succeeded:[    //成功  3个按钮 查看 编辑 删除
                    {key:1,text:'查看',api:'handleGetOd'},
                    {key:2,text:'编辑',api:'handlePatchOd'},
                    {key:3,text:'删除',api:'handleRemoveOd'},
                ],
                failed_store:[  //文件存储失败
                    {key:4,text:'重新发送',api:'handleSubmit'},
                ],
                failed_recognize:[  //官文识别失败 
                    {key:5,text:'重新识别',api:'handleResendOd'},
                ],
                repeat:[  // 文件重复  没有按钮
                    {key:6,text:'删除',api:'handleRemoveOd'},
                ],
                failed_unzip:[   //  压缩包解压失败   重新解压
                    // {key:7,text:'重新解压',api:'handleSubmit'},
                ],
                error:[   //官文处理错误
                    {key:8,text:'重新识别',api:'handleResendOd'},
                ],
                no_receive_date:[
                    {key:9,text:'查看',api:'handleGetOd'},
                    {key:10,text:'编辑',api:'handlePatchOd'},
                    {key:11,text:'删除',api:'handleRemoveOd'},
                ],
                wait:[

                ],
            },
            // 成功列表
            succeededList:['succeeded','repeat','no_receive_date'],
            fileStatusCopy:{},
            tableConfig:{
                fileName:400,
                reg_num:200,
                odType:220,
                time_limit:200,
                modeOfService:200,
                status:150,
                operate:150,
            },
            // 查看文件所需字段
            OfficialDetailsOpen:false, 
            OfficialDetailData:{},
            OfficialExplain:new trademarkEdit().OfficialExplain,
            tmatchExplain:new trademarkEdit().tmatchExplain,
            OfficialId:[],
            doc_id:'',
            details:false,
            SpanWidth:0,
            numberScrollLoading:false,
            // 提交按钮的loading
            SubmitLoading:false,
            isRecognition:false,
            // 上传进度条
            uploadProgressShow:false,
            identifyPercentageWidth:1000,
            // 限制条件
            allFilesSize:1024 *1024 *1024 * 1,
            allFilesNum:1000,
        }
    },
    created(){
        this.is_after_uploading = false;
        // this.getCaseTableData()
        this.setTMAndCaseShow()
    },
    beforeDestroy(){
        this.$store.commit("removeAlertListener", {
            target: "upload", 
            opr: "received",  //  recognitions
            listener_name: "OneFile",
        });
    },
    watch:{
        // fileList(val){
        //     if (val.length > 0) {
        //         this.upLoadStyle.width = '20%'
        //     }else{
        //         this.upLoadStyle.width = '100%'
        //     }
        // },
        changeCaseTable(val){
            // 显示选择案件的文本
            this.caseNameAbbreviation = null
            this.caseNames = val.map(item=>item.type).join(',')
            if (val.length>3) {
                let arr = lodash.cloneDeep(val).splice(0,3)
                this.caseNameAbbreviation = arr.map(item=>item.type).join(',') + '等'
            }

            this.otherTablekey++
        },
        OBTablekey:{
            deep: true,
            handler:"watchTwoTable"
        },
        otherTablekey:{
            deep: true,
            handler:"watchTwoTable"
        },
        caseTableHeight(val){
            let bodyHeight = val - 50
            bodyHeight = bodyHeight > 100 ?bodyHeight:100
            this.pageSize = Math.floor(bodyHeight / 48)   //ceil  floor  round
            this.getCaseTableData()
        },
        OBTableHeight(val){
            let bodyHeight = val - 50
            bodyHeight = bodyHeight > 100 ?bodyHeight:100
            this.OBpageSize = Math.floor(bodyHeight / 48)   //ceil  floor  round
            this.getOBTableData()
        },
        otherTableHeight(val){
            let bodyHeight = val - 50
            bodyHeight = bodyHeight > 100 ?bodyHeight:100
            this.OtherpageSize = Math.floor(bodyHeight / 48)   //ceil  floor  round
            this.getOtherTableData()
        },
        completeNum:{
            handler:'handleWatchCompleteNum',
            deep: true,
        },
        isRecognition(newVal){
            this.$store.commit('setTmUploadIsRecognition',newVal)
        },
        // 这个字段为false 检查一下最新的数据是否和页面的数据相等 
        numberScrollLoading(newVal){
            if (newVal) return
            if (this.completeNum === this.completeNumCopy) return
            this.handleWatchCompleteNum(this.completeNum)
        },
    },
    computed:{
        getAccept(){
            let accept = ''
            for (const key in this.fileSort) {
                if (this.fileSort[key] && this.fileSort[key].fileSuffix && this.fileSort[key].fileSuffix.length > 0) {
                    accept += (this.fileSort[key].fileSuffix.join(',') +',');
                }
            }
            accept = accept.slice(0,-1);
            return accept
        },
        ...mapGetters({
            get_config:"get_config",
            TMatchTag: "get_docs_tags",
        }),
        ...mapState(['TmDocumentUploadShow','uploadPercentage','tmInFo','caseTable']),
        getUploadDate(){  // 每个文件10秒钟  返回秒数
            
            let time = 10 * (this.FileNum - this.completeNum)

            return time
        },
        getUoloadlistNum(){
            return this.$store.getters.getUoloadlistNum(this.fileList.length)
        },
        // 识别进度条
        identifyPercentage(){
            let percentage =  Math.round((this.completeNum * 100) / this.FileNum)
            if (isNaN(percentage)) return 0 
            console.log('识别进度条 :>>', percentage + '%');
            
            return percentage > 100 ? 100 : percentage
        },
        // 通过drawer的close关闭
        TmDocumentUploadShowPage:{
            get() {
                return this.TmDocumentUploadShow;
            },
            set() {
                this.$store.commit('closeTmDocumentUpload');
            }
        },
    },
    methods:{
        handleWatchCompleteNum(newVal){
            if (this.$refs.animationRef) {
                if(this.numberScrollLoading) return
                let newCurrentNumber = newVal;
                let numberElement = this.$refs.animationRef.children[1];
                const currentNumber = parseInt(numberElement.innerText);
                if (newCurrentNumber == currentNumber) return
                  
                const newNumberElement = document.createElement('span');
                newNumberElement.classList.add('numberScroll');
                newNumberElement.classList.add('colorSpan');
                newNumberElement.style.left = this.SpanWidth + 4 + 'px';
                // newNumberElement.style.top = 23 + 'px';
                newNumberElement.innerText = newCurrentNumber.toString();
                let className = '';

                if (currentNumber < newCurrentNumber) {
                    numberElement.classList.add('scroll-down');
                    newNumberElement.classList.add('scroll-down2');
                    className = 'scroll-down2'
                }else{
                    numberElement.classList.add('scroll-up');
                    newNumberElement.classList.add('scroll-up2');
                    className = 'scroll-up2'
                }
                this.completeNumCopy = newVal;
                numberElement.parentElement.insertBefore(newNumberElement, numberElement.nextSibling);
                this.numberScrollLoading = true
                // 移除原始数字元素
                numberElement.addEventListener('animationend', () => {
                    this.numberScrollLoading = false
                    numberElement.remove();
                    newNumberElement.classList.remove(className);

                });
            }
        },
        handleSpanWidth(e){
            this.SpanWidth = e.width;
        },
        // 是否显示商标和案件
        setTMAndCaseShow(){
            this.fileStatusCopy = lodash.cloneDeep(this.fileStatus)
            if (this.tmInFo && this.tmInFo.tm_id && this.tmInFo.tm_id.length > 0) {
                this.tmInfoShow = true  
                this.caseShow = true
                this.fileStatusCopy.succeeded = [   
                    {key:1,text:'查看',api:'handleGetOd'},
                    {key:2,text:'编辑',api:'handlePatchOd'},
                    {key:3,text:'删除',api:'handleRemoveOd'},
                ]
                this.details = false
            }else{
                this.tmInfoShow = false
                this.caseShow = false
                this.fileStatusCopy.succeeded = [   
                    {key:1,text:'查看',api:'handleGetOd'},
                    {key:3,text:'删除',api:'handleRemoveOd'},
                ]
                this.details = true
            }
        },
        //模仿后端查询的案件table表格
        getCaseTableData(){
            if (this.caseTable && this.caseTable.length > 0) {
                this.total = this.caseTable.length
                let limit = this.pageSize
                let skip = this.pageSize  *( this.currentPage -1)
                let mongodb = lodash.cloneDeep(this.caseTable)
                this.casePagingTable = mongodbQuery(mongodb,{skip,limit})
            }
        },
        //模仿后端查询的其他文件table表格
        getOtherTableData(){
            if (this.otherTable) {
                this.Othertotal = this.otherTable.length
                let limit = this.OtherpageSize
                let skip = this.OtherpageSize  *( this.OthercurrentPage -1)
                let mongodb = lodash.cloneDeep(this.otherTable)
                this.otherPagingTable = mongodbQuery(mongodb,{skip,limit})
            }
        },
        //模仿后端查询的OB文件table表格
        getOBTableData(){
            if (this.OBTable) {
                this.OBtotal = this.OBTable.length
                let limit = this.OBpageSize
                let skip = this.OBpageSize  *( this.OBcurrentPage -1)
                let mongodb = lodash.cloneDeep(this.OBTable)
                this.OBPagingTable = mongodbQuery(mongodb,{skip,limit})
            }
        },
        //单击table表格
        CaseTableClick(row, column, event){
            let index =this.changeCaseTable.findIndex(item=>item._id == row._id)
            if (index == -1) {
                this.$refs.CaseTableRef.toggleRowSelection(row, true);
            }else{
                this.$refs.CaseTableRef.toggleRowSelection(row, false);
            }
        },
        //序号
        indexMethod(index){
            return (this.currentPage-1) * this.pageSize +index+1
        },
        //table表格选中改变
        handleSelectionChange(row){
            this.changeCaseTable = lodash.cloneDeep( row)
        },
        //分页------------
        handleCurrentChange(val){
            this.currentPage = val
            this.getCaseTableData()
        },
        handleOBCurrentChange(val){
            console.log(val);
            this.OBcurrentPage = val
            this.getOBTableData()
        },
        handleOtherCurrentChange(val){
            this.OthercurrentPage = val
            this.getOtherTableData()
        },
        // 防抖函数
        handlenUpLoadChangeDebounced: _.debounce(function (file, fileList) {
            this.handleBatchUpload(fileList)
        }, 500),
        // 限制函数
        async handleBatchUpload(fileList){
            const batchSize = 200;  // 多少个文件停止一次

            let currentBatchSize = 0; // 现在是第几个文件

            for (const file of fileList) {

                if (this.fileAllSize > this.allFilesSize  || this.fileList.length > this.allFilesNum) continue;

                if (currentBatchSize >= batchSize) {

                    await new Promise((resolve, reject)=>{
                        setTimeout(resolve, 100)
                    });

                    currentBatchSize = 0;
                }

                this.handlenUpLoadChange(file);

                currentBatchSize++;
            }
            fileList.length = 0;

        },
        //前端上传文件
        handlenUpLoadChange(file){
            let fileType = file.raw.type
            let fileName = file.name
            let fileSize = file.size
            // 判断文件是否符合------
            let is_fileType = false
            let addFileField = null
            let fileLimitSize = 1024 * 1024 * 500
            let suffix = fileName.split('.').at(-1);
            suffix = '.' + suffix;
            // 判断文件格式是否正确 并记录需要分类数组
            for (const key in this.fileSort) {
                if (this.fileSort[key] && this.fileSort[key].fileType && this.fileSort[key].fileType.length > 0) {
                    is_fileType =  this.fileSort[key].fileType.includes(fileType)
                    if (!is_fileType) {
                        is_fileType = this.fileSort[key].fileSuffix.includes(suffix);
                    }
                    if (is_fileType){
                        addFileField = this.fileSort[key].addFileField
                        fileLimitSize = this.fileSort[key].singleFileSize
                        break;
                    } 
                }
            }
            if (!is_fileType || !addFileField) {
                this.$message({
                    message: `上传的文件${fileName}格式错误，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            // 判断文件大小 1491016   15M
            if (fileSize === 0) {
                this.$message({
                    message: `文件内容异常，请重试！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            console.log(get_size_str(fileLimitSize),'fileLimitSize');
            // 判断文件大小 1491016   15M
            if (fileSize > fileLimitSize) {
                this.$message({
                    message: `上传的文件${fileName}大小超过${get_size_str(fileLimitSize)}，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            //根据文件名和文件大小判断是否重复 fileList
            if (this.fileList.find(item=>item.name ===fileName && item.size ===fileSize)) {
                this.$message({
                    message: `您已选择过${fileName}文件，请重新选择！`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            this.fileAllSize += fileSize
            if (this.fileAllSize >  this.allFilesSize) {
                 this.$message({
                    message: `所有上传的文件大小不能超过${get_size_str(this.allFilesSize)}，请调整后分次上传.`,
                    type: 'error',
                    duration:3000
                })
                this.fileAllSize -= fileSize
                return 
            }
            if (this.fileList.length > this.allFilesNum) {
                this.$message({
                    message: `上传的文件不能超过${this.allFilesNum}个，请调整后分次上传.`,
                    type: 'error',
                    duration:3000
                })
                return 
            }
            // 获取收文时间
            let  {date} = extractRegNoAndDate(fileName)
            let recv_date = date?.format('YYYY-MM-DD')
            // 加入数据
            let key = Math.random()
            this[addFileField].push({
                fileName:fileName,
                fileDate:recv_date,
                // fileDate:'2022-01-12',
                key,
                is_show:false,
                is_hover:false,
                copyData:{
                    // fileDate:'2022-01-12',
                    fileDate:recv_date
                }
            })
            file.key=key
            this.fileList.push(file)
        },
        //前端删除文件
        removeFile(ArrayName,key){
            // vue2不能使用lodash.remove方法
            this[ArrayName].forEach((item,index)=>{
                if (item.key === key) {
                    this[ArrayName].splice(index,1)
                    return
                }
            })
            this.fileList.forEach((item,index)=>{
                if (item.key === key) {
                    this.fileList.splice(index,1)
                    return
                }
            })
        },
        // 编辑文件
        editFile(ArrayName,key){
            this[ArrayName].forEach((item)=>{
                if (item.key === key) {
                    item.is_show = true
                    return
                }
            })
        },
        //改变文件
        patchFile(ArrayName,key){
            this[ArrayName].forEach((item)=>{
                if (item.key === key) {
                    item.copyData.fileDate = item.fileDate
                    item.is_show = false
                    return
                }
            })
        },
        //取消文件修改
        cancelFilePatch(ArrayName,key){
            this[ArrayName].forEach((item)=>{
                if (item.key === key) {
                    item.fileDate = item.copyData.fileDate
                    item.is_show = false
                    return
                }
            })
        },
        // 发送请求
        async handlenSubmitFile(){
            if ( this.fileList.length <=0) {
                this.$message({
                    message: `未检查到上传文件，请选择文件`,
                    type: 'error',
                    duration:3000
                })
                return
            }
            try {
                let formData = new FormData()
                this.fileList.forEach((item,index)=>{
                    formData.append(`files`,item.raw)
                })
                let fileDates = []
                fileDates =  lodash.concat(fileDates,this.textFiles,this.imgFiles,this.zipFiles)

                let receiveDate = new Array(this.fileList.length).fill(null) //InvalidDate
                this.fileList.forEach((item,index)=>{
                    let Findindex = fileDates.findIndex(item2=>item2.key == item.key)
                    if (Findindex!==-1) {
                        receiveDate[index] = fileDates[Findindex].fileDate
                    }
                })
                // let case_id = this.changeCaseTable.map(item=>item._id)
                // this.caseShow && formData.append('case_id',case_id.join(','))
                formData.append('receiveDate',receiveDate.join(','))
                this.tmInfoShow && formData.append('tm_id',this.tmInFo.tm_id)
                formData.append('needUnzip',!this.is_decompression_file)
                formData.append('image2pdf',this.is_convert_to_pdf)
                this.$store.commit("addAlertListener", {
                    target: "upload",  //AnalysisFile
                    opr: "total",  //  recognitions
                    listener_name: "OneFile",
                    once:true,
                    callback_fun: (data) => this.getAnalysisFile(data),
                });
                this.uploadProgressShow = true
                this.SubmitLoading = true
                this.isRecognition = true
                await Postofficial(formData) 
            } catch (error) {
                this.$message({
                    message: `文件上传发生错误，请联系管理员！`,
                    type: 'error',
                    duration:3000
                })
            }
        },
        // 接收第一次alert
        getAnalysisFile(data){
            if (!data.files || !data.files.od_files || !data.files.tmatch_files) return
            this.uploadProgressShow = false 
            this.SubmitLoading = false
            this.OBTable = lodash.cloneDeep(data.files.od_files)
            this.OBTable.forEach(item=>{
                item.status = 'wait'
            })
            this.otherTable = lodash.cloneDeep(data.files.tmatch_files)
            this.otherTable.forEach(item=>{
                item.status = 'wait'
            })
            this.FileNum = this.OBTable.length + this.otherTable.length
            this.is_after_uploading = true
            this.OBTablekey++
            this.otherTablekey++
            this.$store.commit("addAlertListener", {
                target: "upload",  
                opr: "received", 
                listener_name: "OneFile",
                callback_fun: (data) => this.setAnalysisFile(data),
            });
        },
        // 接收第二次alert
        setAnalysisFile(data){
                        if (!data || !data.file || !data.file.status || !data.file.uname) return 
            if (data.file.status == "succeeded" || data.file.status == "repeat") {
                this.succeededFile(data);
            }else{
                this.ErrorFile(data);
            }
            let OBIds= this.OBTable.map(item=>item._id)
            let otherIds = this.otherTable.map(item=>item._id)
            this.OfficialId = lodash.concat(OBIds,otherIds);
            lodash.remove(this.OfficialId,item=>{
                return !item
            })
        },  
        // 处理第二次alert的成功信息
        succeededFile(data){
            if (data.file.doc_type == 'od') {
                if (data.file.need_followup) {
                    data.file.time_limit_str = (data.file.receive_date&&data.file.time_limit)?toYMDTime(AddDayTime(data.file.receive_date,data.file.time_limit)):(data.file.time_limit?data.file.time_limit:'无')
                }else{
                    data.file.time_limit_str = '无'
                }
                data.file.receive_date_str = data.file.receive_date?toYMDTime(data.file.receive_date):'未识别'
                data.file.modeOfService = data.file.is_scan_page === true?'纸质': (data.file.is_scan_page === false?'电子':'')
                data.file.is_edit = false
                if (!data.file.receive_date && data.file.status == "succeeded") {
                    data.file.status = 'no_receive_date'
                }
                // TODO:  不加等待状态      && item.status == 'wait'
                this.OBTable.forEach(item=>{
                    if (item.uname == data.file.uname ) {
                        lodash.assign(item,data.file)
                        item.copyData = {
                            receive_date_str:item.receive_date_str,
                            time_limit_str:item.time_limit_str,
                            modeOfService:item.modeOfService
                        }
                        return 
                    }
                })
                this.OBTablekey++
            }else if (data.file.doc_type === 'tmatch') {
                let OtherData = null
                data.file.is_edit = false
                data.file.type = '其他'
                if (this.tmInfoShow) {
                    data.file.reg_num = this.tmInFo.reg_num
                }
                // TODO:  不加等待状态      && item.status == 'wait'
                this.OBTable.forEach((item,index)=>{
                    if (item.uname == data.file.uname) {
                        OtherData = lodash.assign(item,data.file)
                        item.copyData = {
                            type:item.type
                        }
                        this.OBTable.splice(index,1)
                        return
                    }
                })
                this.otherTable.forEach(item=>{
                    if (item.uname == data.file.uname) {
                        lodash.assign(item,data.file)
                        item.copyData = {
                            type:item.type
                        }
                        return
                    }
                })
                if (OtherData) {
                    this.otherTable.push(OtherData)
                }
                this.otherTablekey++
            }
        },
        // 处理第二次alert的其他信息
        ErrorFile(data){
            // TODO:  不加等待状态      && item.status == 'wait'
            this.OBTable.forEach(item=>{
                if (item.uname == data.file.uname) {
                    lodash.assign(item,data.file)
                    return
                }
            })
            // TODO:  不加等待状态      && item.status == 'wait'
            this.otherTable.forEach(item=>{
                if (item.uname == data.file.uname) {
                    lodash.assign(item,data.file)
                    return
                }
            })
            this.OBTablekey++
            this.otherTablekey++
        },
        // 鼠标移入
        OneFileOut(item){
            item.is_hover = false
        },
        //鼠标移出
        OneFileOver(item){
            item.is_hover = true
        },
        //上传官文后的全部方法调用
        handleALLApi(api,arr,row){
            if (this[api]) {
                this[api](arr,row)
            }
        },
        // 查看  需要修改
        async handleGetOd(arr,row){
            this.doc_id = row._id
            let data = await GetFile({id:row._id})
            if (data.trademark&&data.trademark.applicant == null) {
                data.trademark.applicant = {
                    applicant_cn:''
                };
            }
            this.OfficialDetailData = lodash.cloneDeep(data)
            this.OfficialDetailsOpen = true
        },
        //编辑
        handlePatchOd(arr,row){
            this[arr].forEach(item=>{
                if (item.uname ==  row.uname) {
                    item.is_edit = true
                    return
                }
            })
            this.OBTablekey++
            this.otherTablekey++
        },
        // 删除取消
        handleDelFileCancel(){},
        //删除成功
        async handleDelFile(arr,row){
            let is_repeat = row.status === 'repeat'
            if (!is_repeat) {
                //删除数据库
                await delofficial(row._id);
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
            }
            this[arr].forEach((item,index)=>{
                if (item.uname === row.uname) {
                    this[arr].splice(index,1)
                    return
                }
            })
            this.OBTablekey++
            this.otherTablekey++
            
        },
        //再次识别  需要修改   他也需要监听
        async handleResendOd(arr,row){
            let doc_id = row._id
            // row.status = 'wait'
            this[arr].forEach(item=>{
                if (item.uname == row.uname) {
                    item.status = 'wait'
                }
            })
            this.OBTablekey++
            this.otherTablekey++
            try {
                await updateOfficialstate(doc_id, {
                has_read: false,
                trigger_recognize: 1,
                image_pdf:true,
                });
            } catch (error) {
                console.log(error);
            }
        },
        //重新发送
        handleSubmit(arr,row){
            this.handleSubmitOnefile(arr,row)
        },
        // 重新发送的具体逻辑
        async handleSubmitOnefile(arr,row){
            let name = row.originalname || row.filename
            let size = row.size
            if (row.rootZip) {
                name = row.rootZip.filename
                size = row.rootZip.size
            }
            let index = this.fileList.findIndex(item=>item.name == name &&item.size == size)
            if (index >= 0) {
                let file = this.fileList[index]
                // TODO：判断文件是否为压缩包    如果是压缩包  其他文件的状态为改为已存在
                let fileType = file.raw.type
                let is_fileType = this.fileSort['zipFile'].fileType.includes(fileType)
                if (is_fileType) {
                    this.$message({
                        message: '暂不支持重新上传压缩包，请重新上传！',
                        type: 'error',
                        duration:2000
                    })
                    return
                }
                let fileDates = []
                fileDates =  lodash.concat(fileDates,this.textFiles,this.imgFiles,this.zipFiles)
                let indexDate = fileDates.findIndex(item=>item.key == file.key)
                if (indexDate<0) {
                    // 在文件列表中找到，在时间列表中未找到
                    this.$message({
                        message: '文件数据丢失，请重新上传！',
                        type: 'error',
                        duration:2000
                    })
                    return
                }
                let formData = new FormData()
                console.log(fileDates[indexDate].fileDate);
                // let case_id = this.changeCaseTable.map(item=>item._id)
                formData.append(`files`,file.raw)
                formData.append('receiveDate', fileDates[indexDate].fileDate || '')
                this.tmInfoShow && formData.append('tm_id',this.tmInFo.tm_id)
                // this.caseShow && formData.append('case_id',case_id.join(','))
                formData.append('needUnzip',!this.is_decompression_file)
                formData.append('image2pdf',this.is_convert_to_pdf)
                Postofficial(formData)
                // row.status = 'wait'
                this[arr].forEach(item=>{
                    console.log(item,row);
                    if (item.uname == row.uname) {
                        item.status = 'wait'
                    }
                })
                this.OBTablekey++
                this.otherTablekey++
            }else{
                // 文件丢失，请重新上传
                this.$message({
                    message: '文件数据丢失，请重新上传！',
                    type: 'error',
                    duration:2000
                })
            }
        },
        //案件确定和取消
        handleCaseTableApi(bool){
            if (bool) {
                this.changeCaseTableCopy = lodash.cloneDeep(this.changeCaseTable)
            }else{
                this.changeCaseTable = lodash.cloneDeep(this.changeCaseTableCopy)
            }
            this.CaseTableShow = false
        },
        // 案件选择的回显
        handleCaseTableKey(){
            this.CaseTableShow = true
            if (this.$refs.CaseTableRef) {
                let length = this.$refs.CaseTableRef.selection.length
                this.$refs.CaseTableRef.selection.splice(0,length)
                this.$nextTick(()=>{
                    this.$refs.CaseTableRef.selection.push(...this.changeCaseTable)
                    this.getCaseTableData()
                })
            }
        },
        // 上传成功后根据status更改 class
        addClass({row}){
            switch(row.status){
                case 'succeeded':
                    return ''   //success-row
                case 'wait':
                    return "addBackground"
                default :
                    return 'warning-row'
            }
        },
        //编辑后的确定按钮
        async handleTableSave(arr,row){
            if (row.doc_type == 'od') {
                let obj1 = {
                    receive_date_str:row.copyData.receive_date_str,
                    modeOfService:row.copyData.modeOfService
                }
                let obj2 ={
                    receive_date_str:row.receive_date_str,
                    modeOfService:row.modeOfService
                }
                if (!lodash.isEqual(obj1,obj2)) {
                        let data ={
                            receive_date:row.receive_date,
                            is_scan_page:row.modeOfService==='纸质'?true:false
                        }
                        await updateOfficialstate(row._id,data,{},true)
                }
                this[arr].forEach((item)=>{
                    if (item.uname === row.uname) {
                        item.copyData.receive_date_str = item.receive_date_str
                        item.copyData.time_limit_str = item.time_limit_str
                        item.copyData.modeOfService = item.modeOfService
                        item.is_edit = false
                        item.status =  item.receive_date_str?'succeeded':'no_receive_date'
                        return
                    }
                })       
                this.OBTablekey++
            }else if (row.doc_type == 'tmatch') {
                this[arr].forEach(item=>{
                    if (item.uname == row.uname) {
                        item.copyData.type = item.type
                        item.is_edit = false
                        return
                    }
                })
                this.otherTablekey++
            }
        },
        // 编辑后的取消按钮
        handleTableCancel(arr,row){
            if (row.doc_type == 'od') {
                this[arr].forEach(async (item)=>{
                    if (item.uname === row.uname) {
                        item.receive_date_str = row.copyData.receive_date_str
                        item.time_limit_str = row.copyData.time_limit_str
                        item.modeOfService = row.copyData.modeOfService
                        item.is_edit = false
                        return
                    }
                })
            }else if (row.doc_type == 'tmatch') {
                this[arr].forEach(item=>{
                    if (item.uname == row.uname) {
                        item.type = row.copyData.type
                        item.is_edit = false
                        return
                    }
                })
            }
            this.OBTablekey++
            this.otherTablekey++
        },
        //修改OB文件的送达方式
        handleEditModeOfService(e,row){
            this.OBTable.forEach(item=>{
                if (item.uname === row.uname) {
                    item.modeOfService = e
                    item.is_scan_page = item.modeOfService === '纸质'?true:false
                }
            })
            this.OBTablekey++
        },
        //修改other文件的type
        handleEditType(e,arr,row){
            console.log(e,arr,row);
            this.otherTable.forEach(item=>{
                if (item.uname === row.uname) {
                    row.type = e
                    item.type = e
                }
            })
            this.otherTablekey++
        },
        // 修改OB收文时间  用了2个事件 changge事件有问题  不能修改页面的值
        handleEditReceiveDate(e,row){
            this.OBTable.forEach(item=>{
                if (item.uname === row.uname) {
                    item.receive_date = e.value
                    if (item.receive_date) {
                        item.receive_date_str = toYMDTime(item.receive_date)
                        if (item.need_followup) {
                            item.time_limit_str = item.time_limit_str==='无'?'无':toYMDTime(AddDayTime(item.receive_date,item.time_limit))
                        }else{
                            item.time_limit_str = '无'
                        }
                    }else{
                        item.receive_date_str = ''
                        item.time_limit_str = item.time_limit?item.time_limit:'无'
                    }
                    return 
                }
            })
            this.OBTablekey++
        },
        handleClearReceiveDate(e,row){
            if (!e) {
                this.OBTable.forEach(item=>{
                    if (item.uname === row.uname) {
                        item.receive_date = null
                        item.receive_date_str = ''
                        if (item.need_followup) {
                            item.time_limit_str = item.time_limit?item.time_limit:'无'
                        }else{
                            item.time_limit_str = '无'
                        }
                    }
                    return
                })
            }
        },
        // 监听oBkey和otherkey
        watchTwoTable(){   // 完成数量  和成功的数量
            if(!this.is_after_uploading)  return
            let waitNum = 0
            let succeededNum = 0
            this.OBTable.forEach(item=>{
                if (item.status ==='wait') {
                    waitNum++
                }
                if (this.succeededList.indexOf(item.status) !=-1) {
                    succeededNum ++ 
                }
            })
            this.otherTable.forEach(item=>{
                if (item.status ==='wait') {
                    waitNum++
                }
                if (this.succeededList.indexOf(item.status) !=-1) {
                    succeededNum ++ 
                }
            })
            this.getOBTableData()
            this.getOtherTableData()
            this.succeededNum = succeededNum
            this.completeNum = this.FileNum  -  waitNum    //完成的数量 = 总数量 - 等待的数量
            if (waitNum == 0) {
                this.is_complete = true     // 标记是否完成
                this.isRecognition = false  //标记是否正在上传和识别
                // 控制识别完成 用户不在识别中  就帮他自动点击完成
                if (!this.TmDocumentUploadShowPage) {
                    // this.$EventBus.$emit('closeTmDocumentUpload')
                    // this.$store.commit('closeTmDocumentUpload')
                    this.handleClose()
                }
               
            }else{
                this.isRecognition = true
                this.is_complete = false
            }
        },
        // 完成归档
        async handleClose(){
            // 对文件进行归档  案件id  商标id  官文id(其他文件)  路径
            // let otherTableCopy = this.otherTable.filter(item=>item.status == 'succeeded')
            // let OBTableCopy = this.OBTable.filter(item=>item.status == 'succeeded')
            // 成功和无收文日期  是第一次出现  直接创建bind记录    已存在 是第二次在系统存在 判断bind记录 不存在在创建
            let succeededListCopy =['succeeded','no_receive_date']
            let otherTableCopy = []
            let otherRepeatList = []
            this.otherTable.forEach(item=>{
                if (item.is_edit) {  // 处于编辑状态  把新值改为旧值
                    item.type = item.copyData.type
                }
                if (succeededListCopy.indexOf(item.status) !=-1) {   // 存在成功的
                    otherTableCopy.push(item)
                }
                if (item.status == 'repeat') {   // 文件存在
                    otherRepeatList.push(item)
                }
            })
            let OBTableCopy  = []
            let OBRepeatList = []
            this.OBTable.forEach(item=>{
                if (item.is_edit) {
                    item.receive_date_str = item.copyData.receive_date_str
                    item.time_limit_str = item.copyData.time_limit_str
                    item.modeOfService = item.copyData.modeOfService
                }
                if (succeededListCopy.indexOf(item.status) !=-1) {
                    OBTableCopy.push(item)
                }
                if (item.status == 'repeat') {   // 文件存在
                    OBRepeatList.push(item)
                }
               
            })
            let promises = []
            if (this.tmInFo&&this.tmInFo.tm_id && this.changeCaseTable.length >0 ) {
                if (otherTableCopy.length > 0) {
                    for (let file of otherTableCopy) {
                        if (!file._id) continue; 
                        if (!file.type) {
                            file.type = '其他'
                        }
                        for (let oneCase of this.changeCaseTable){
                            let data = {
                                tm_id:this.tmInFo.tm_id,
                                case_id:oneCase._id,
                                doc_id:file._id,
                                clf_case_paths:[file.type]
                            }
                            promises.push(patchcases(
                                oneCase._id,
                                data,
                                { $manual_bind: 1 }
                            ))
                        }
                    }
                }
                if (OBTableCopy.length > 0) {
                    for (let file of OBTableCopy) {
                        if (!file._id) continue; 
                        for (let oneCase of this.changeCaseTable){
                            let data = {
                                tm_id:this.tmInFo.tm_id,
                                case_id:oneCase._id,
                                doc_id:file._id,
                                clf_case_paths:['官方文件']
                            }
                            promises.push(patchcases(
                                oneCase._id,
                                data,
                                { $manual_bind: 1 }
                            ))
                        }
                    }
                } 
                if (otherRepeatList.length > 0) {
                    for (const file of otherRepeatList) {
                        if (!file._id) continue; 
                        for (let oneCase of this.changeCaseTable){
                            let data = {
                                tm_id:this.tmInFo.tm_id,
                                case_id:oneCase._id,
                                doc_id:file._id,
                            }
                            let binds = await findBind(data)   //此时 数据库中已经有商标和文件的bind记录  没有 
                            if (binds.total > 0){
                                let index = binds.data.findIndex(item=>item.tm_id == data.tm_id && item.case_id == data.case_id && item.od_id == data.doc_id)
                                if (index != -1) continue; 
                            }
                            data.clf_case_paths = ['其他']
                            promises.push(patchcases(
                                oneCase._id,
                                data,
                                { $manual_bind: 1 }
                            ))
                        }
                    }
                }
                if (OBRepeatList.length > 0) {
                    for (const file of OBRepeatList) {
                        if (!file._id) continue; 
                        for (let oneCase of this.changeCaseTable){
                            let data = {
                                tm_id:this.tmInFo.tm_id,
                                case_id:oneCase._id,
                                doc_id:file._id,
                            }
                            let binds = await findBind(data)
                            // console.log(binds);
                            // if (binds.total > 0) continue; 
                            if (binds.total > 0){
                                let index = binds.data.findIndex(item=>item.tm_id == data.tm_id && item.case_id == data.case_id && item.od_id == data.doc_id)
                                if (index != -1) continue; 
                            }
                            data.clf_case_paths = ['官方文件']
                            promises.push(patchcases(
                                oneCase._id,
                                data,
                                { $manual_bind: 1 }
                            ))
                        }
                    }
                }
            }
            Promise.all(promises).then(()=>{
                this.$message({
                    message: '归档完成，请等待数据加载...',
                    type: 'success',
                    duration:2000
                })
                this.$EventBus.$emit('closeTmDocumentUpload')
                this.$store.commit('closeTmDocumentUpload')
                this.odFileDetailDiolog = false
                this.$emit('closeUpdata')
            })

        },
        // 监听整个drawer的高
        handleWatch(e){ 
            // 获取浏览器的高
            let docHeight = document.body.offsetHeight
            let upLoadStyleHeight =  e.height 
            if(this.tmInfoShow){
                // 减去商标信息的高
                if (docHeight / 100 *3 >=30) {
                    upLoadStyleHeight = upLoadStyleHeight - docHeight / 100 * 3 -10 
                }else{
                    upLoadStyleHeight = upLoadStyleHeight - 30 -10
                }
                // 减去案件的高
                if (docHeight / 100 *5 >=40) {
                    upLoadStyleHeight = upLoadStyleHeight - docHeight / 100 * 5 -10
                }else{
                    upLoadStyleHeight = upLoadStyleHeight - 40 -10
                }
            }
            // 减去 header的高和其他元素的
            upLoadStyleHeight -= 130 
            // 有一个最小高   因素 1. 案件table   2. OBtable OtherTable
            upLoadStyleHeight = Math.round(upLoadStyleHeight)>500? Math.round(upLoadStyleHeight):500
            // 抽屉高
            this.upLoadStyle.height = upLoadStyleHeight + 'px'
            // upLoadStyleHeight += 160
            // 上传前文件信息的高
            this.uploadListStyle.height = upLoadStyleHeight - 40 +'px'    // 40 是 右侧下面的高
            // 案件table高
            this.caseTableHeight =  upLoadStyleHeight - 40 - 44    /// 40 44 是分页的高 和margin
            // OBtable高
            this.OBTableHeight = upLoadStyleHeight / 2 -8 -4 -40 -2
            // otherTable高
            this.otherTableHeight = upLoadStyleHeight / 2 -8 -4 -40 -2
            // 识别进度条的宽度
            this.identifyPercentageWidth = e.width * (7 / 10)
        },
        changeDrawerState(){
            this.OfficialDetailsOpen = false
        },
        changeOfficialForm(data){
            this.OfficialDetailData = data
        },
        changePutDate(id,data){
           for (const item of this.OBTable) {
                if (item._id == id) {
                    // 类型  商标号  收文时间  到期时间  送达方式  状态
                    item.odType = data.type
                    item.reg_num = data.reg_num
                    item.receive_date = data.receive_date
                    item.receive_date_str = data.receive_date?toYMDTime(data.receive_date):''
                    if (data.need_followup) {
                        item.time_limit_str = (data.receive_date&&data.time_limit)?toYMDTime(AddDayTime(data.receive_date,data.time_limit)):(data.time_limit?data.time_limit:'无')
                    }else{
                        item.time_limit_str = '无'
                    }
                    item.modeOfService = data.is_scan_page === true?'纸质': (data.is_scan_page === false?'电子':'')
                    item.status =  item.receive_date_str?'succeeded':'no_receive_date'
                    this.OBTablekey++
                    return
                }
           }
        },
        handleSetRecognition(){
            this.$store.commit('setTmDocumentUploadRecognition')
        },
    },
    filters: {
    //通过data.js定义的filter规则过滤展示数据
    filterItem(val, fn, twoLevel = null, obj = {}) {
      switch (fn) {
        case "isBoolean":
          return val === false ? "否" : "是";
        case "filterUtcTime": //过滤时间
          if (val) {
            return toYMDTime(val);
          }
        case "filterObj": //过滤对象
          if (val) {
            return val[twoLevel];
          }
        case "filterConcat":
          if (val) {
            return toYMDTime(val) + " 至 " + toYMDTime(obj[twoLevel]);
          }
        default:
          return val;
      }
    },
    filterStatus(val){
        switch (val) {
            case "succeeded":
                return '成功';
            case "no_receive_date":
                return "无收文日期";
            case "repeat":
                return  '文件存在';
            case "failed_store": 
                return '文件存储失败';
            case "failed_recognize":
                return "官文识别失败"
            case "failed_unzip":
                return  '压缩包解压失败';
            case "error":
                return '官文处理错误'
            case "wait":
                return "正在加载"
           
            default:
             return '';
        }
    }
  },
}
</script>

<style lang="less" scoped>
.UploadfileClass{
    .tmP{
        // color:red;
        // background: blue;
        border-radius: 10px;
    }
    .floatButton{
        .el-button{
            float: right;
        }
        .button1{
            margin-left: 10px;
        }
    }
}
@footerHeight:60px;      // 定位盒子的高度 = 占位盒子的高度 + 抽屉下padding的距离
@footerBoxHeight:40px;   //占位盒子  因为drawer的下padding占了20
::v-deep .el-drawer__body{
        // position:relative; 
        .footer-box{
            height: @footerBoxHeight;
        }
        .drawer-footer-class{
            background: #ffffff;
            position:absolute ;
            bottom: 0;
            left:0;
            width:100%;
            height:@footerHeight;
            padding: 10px 40px 10px 40px;
            z-index: 10000;
            .flex-footer{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left-footer{
                    .text_1,.text_2{
                        margin-top: 10px;
                        text-align: left;
                        line-height: 14px;
                        height:14px;
                        color:rgba(161,161,161);
                        display:flex;
                        flex-wrap: nowrap;
                        .colorSpan{
                            color:#29c5ee;
                        }
                    }
                    .text_1{
                        display:flex;
                        flex-wrap: nowrap;
                    }                   
                }
            }
        }
    }
.tableAllBox{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    .paginationClass{
        text-align: center;
        padding: 8px 0px 4px !important;
    }
   
}
.case{
    position: relative;
    .CaseTableClass{
        position:absolute ;
        width: 100%;
        z-index: 2000;
        margin-top: 10px;
    }
    // 抽屉的高
    .paginationClass{
        text-align: center;
        padding:22px 0px 22px !important;
        background: #ffffff;
    }
}
.upload{
    display: flex;
    .upload-list-box{
        flex: 1;
        margin-left: 10px;
        .upload-list-progress{
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            width: 100%;
            padding: 0 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .upload-list{
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            overflow-y:auto ;  
            .fileTitle{
                background: #f0f2f5;
                color: #606266;
                font-size: 14px;
                font-weight: 800;
                .indent24{
                    text-indent:24px;
                }
            }
            .fileConten{
                // background-color: #cccccc;
                height: 48px;
                line-height: 48px;
                // border-bottom: 1px solid #606266;
                .el-col{
                    height: 48px;
                    font-size: 14px;
                    line-height: 48px;
                }
                i{
                    font-size: 18px;
                    margin-left: 4px;
                }
                .fileIcon{
                    cursor: pointer;
                }
                span{
                    color:black;
                    margin-left: 4px;
                    cursor: pointer;
                }
                .right10{
                    margin-right: 10px;
                }
                .el-icon-edit-outline:hover{
                    color:blue;
                }
                .el-icon-close:hover{
                    color: red;
                }
            }
            .fileConten:hover{
                background-color: #F2F6FC;
            }
        }
        .upload-list-footer{
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
        }
    }
    .el-upload-box{
        width: 20%;
        min-width: 250px;
    }
   
}
.flex-start{
    // display: flex;
    // justify-content:flex-start;
    .span_margin{
        margin-left: 28px;
    }
}
.text-ellipsis{    // 超出隐藏 出现...
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.text-ellipsis1{    // 超出隐藏 出现...
  overflow:hidden;
  color:#909399;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.box{
    height:466px;
}
/deep/.formInfoClass  {
    .el-form-item {
        margin-bottom: 6px;
    }
    .el-form-item__label{
        height: 40px !important;
        line-height: 40px !important;
    }
}
/deep/.el-table .el-table__body .addBackground{
    background-color: rgb(242,242,242,.6);
}
/deep/.el-table .warning-row {
    background: oldlace;
}
/deep/.el-table .success-row {
    background: #f0f9eb;
}
.icon-loading-class{
  animation: move 1s linear infinite;
  -webkit-animation: move 1s linear infinite;
  display: inline-block;
}
@keyframes move {
  100% {
    transform: rotate(360deg);
  }
}
.trademarkImg{
    width: 170px;
    height: 170px;
    // padding: 10px;
    // background: #606266;
    border:1px solid #606266;
}
.butttonAll{
    display: flex;
    justify-content: flex-start;
    .el-button {
        margin-right: 6px;
    }
}
.el-upload__text{
    p{
        height: 18px;
        line-height: 14px;
        
    }
}
// ::v-deep .el-table .el-table__fixed-right-patch{
//     background: rgb(240, 242, 245);
//     }
</style>
<style lang="less" scoped>
@import "~@/assets/css/upload.less";
/deep/.UploadfileClass > .el-drawer__header{
    padding: 10px 20px !important;
}
</style>