<template>
  <div class="task">
    <el-drawer
    :visible.sync="taskDrawer"
    custom-class="task_drawer"
    :show-close="false"
    :modal="false">
    <p @click="taskDrawer=!taskDrawer" class="arrow_right"  style="cursor: pointer;"><i class="el-icon-arrow-right" style="color:#fff;font-size:20px;"></i></p>
    <div>
        <div style="display:flex;">
            <el-tabs v-model="activeName" @tab-click="handleClick"  style="flex:1">
                <el-tab-pane label="任务进行中" name="progress">
                    <!-- 上传任务 -->
                    <uploadTask v-if="uploadFileData.addDrawer"
                    @Setuploadstatus="Setuploadstatus"
                    @closeUpload="closeUpload">

                    </uploadTask>
                    <!-- 导出任务 -->
                    <div v-else>
                        <div class="task_import_top">
                            <div style="margin-top:14px">
                                <div >
                                    <p style="color:#303133" v-if="!odFileList.list||odFileList.list?.length==0">暂无任务</p>
                                    
                                    <p style="color:#303133" v-else-if="odFileList.completedTotal==0 && odFileList.list?.length == odFileList.total">
                                        <i class="el-icon-circle-close" style="color:#F56C6C;margin-right:7px;"></i>
                                        未导出
                                    </p>
                                    <p style="color:#303133" v-else-if="odFileList.list?.length!=odFileList.total && odFileList.downloadState!='canceled'">
                                        <i class="el-icon-loading" style="color:#29C5EE;margin-right:7px;"></i>
                                        正在导出
                                    </p>
                                    <p style="color:#303133" v-else-if="odFileList.downloadState=='canceled' || odFileList.completedTotal!=odFileList.list?.length ">
                                        <i class="el-icon-circle-check" style="color:#E6A23C;margin-right:7px;"></i>
                                        部分导出
                                    </p>
                                    <p style="color:#303133" v-else>
                                        <i class="el-icon-circle-check" style="color:#29C5EE;margin-right:7px;"></i>
                                        全部导出
                                    </p>
                                </div>
                                <div v-if="odFileList.list" style="flex-direction:column">
                                    <p class="info_color">任务名称：{{odFileList.fileName}}</p>
                                    <!-- <p class="info_color" style="margin-top:14px;margin-left:72px;">任务开始时间：{{time?time:'暂无任务'}}</p> -->
                                </div>
                            </div>
                            <div style="margin-top:14px;" v-if="odFileList.list">
                                <el-button-group >
                                    <span >
                                        文件总数量：{{odFileList.list?.length?odFileList.list?.length:0}}
                                    </span>
                                    <span style="margin-right:14px;">
                                        ，已导出：{{odFileList.completedTotal?odFileList.completedTotal:0}}
                                    </span>
                                    <el-button type="text"
                                        @click="suspendTask" 
                                        :disabled="suspendTaskDisabled||odFileList.downloadState=='canceled'||odFileList.list?.length == odFileList.total" >
                                        <span v-if="odFileList.list?.length!=odFileList.total||odFileList.downloadState=='canceled'">
                                            {{suspendTaskDisabled||odFileList.downloadState=='canceled'?'任务已取消':'取消任务'}}
                                        </span>
                                        <span v-else>
                                            任务已完成
                                        </span>
                                    </el-button>
                                    <!-- <el-button size="mini" plain style="width:98px" class="info_button" >开始</el-button>
                                    <el-button size="mini" plain style="width:98px" class="info_button">删除</el-button> -->
                                </el-button-group>
                                <p class="info_color">
                                    <span>文件容量：{{odFileList.totalBytes|filterSize}}</span>
                                    <span style="margin:0 7px">|</span>
                                    <span>下载速度：{{odFileList.total_speed|filterSize}} <span v-if="odFileList.total_speed">/s</span></span>
                                </p>
                            </div>
                            <div style="width:100%;margin-top:14px;display:flex;align-items:center" v-if="odFileList.list">
                                <el-progress :stroke-width="8" :show-text ="false" :percentage="percentage" color="#65b1ff" style="flex:1;"></el-progress>
                                <span style="margin-left:7px">已完成：{{percentage}}%</span> 
                            </div>
                        </div>
                        <div class="task_import_main" v-if="odFileList.list">
                            <el-table
                                :data="taskData"
                                stripe
                                border
                                height="calc(100vh - 345px)"
                                :row-key="getRowKeys"
                                default-expand-all
                                style="width: 100%">
                                <el-table-column
                                prop="fname"
                                show-overflow-tooltip
                                width="600"
                                label="档案名称">
                                <template slot-scope="scope">
                                    <span>
                                        <i :class="scope.row.fname?'el-icon-document':'el-icon-folder-opened'" style="margin-right:7px;"></i>
                                        {{scope.row.fname?scope.row.fname:scope.row.folder}}
                                    </span>
                                </template>                
                                </el-table-column>
                                <el-table-column
                                prop="reg_num"
                                label="商标号">
                                </el-table-column>
                                <el-table-column
                                prop="reg_name"
                                label="商标名称">
                                </el-table-column>
                                <el-table-column
                                prop="case_name"
                                label="案件名称">
                                </el-table-column>
                                <el-table-column
                                prop="size"
                                label="文件大小">
                                <template slot-scope="scope">
                                    <span>{{scope.row.size|filterSize}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="status"
                                :key="statusKey"
                                width="350"
                                label="状态">
                                <template slot-scope="scope">
                                    <div style="display:flex;width:100%;align-items: center;" v-if="scope.row.type!='folder'">
                                        <template v-if="scope.row.status">
                                            <div v-if="scope.row.status=='已完成'" style="display:flex;width:100%;align-items: center;">
                                                {{scope.row.status}}
                                                <el-progress :percentage="scope.row.percentCompleted" v-if="scope.row.percentCompleted" color="#65b1ff" style="width:150px;margin-left:10px"
                                                :status="scope.row.status|filterStatus">
                                                </el-progress>
                                                <span v-if="scope.row.downloadSpeed">{{scope.row.downloadSpeed|filterSize}}/s</span>
                                            </div>
                                            <p v-else>
                                                <span style="margin-right:7px;">{{scope.row.status}}</span>
                                                <i v-if="scope.row.status=='已取消'" class="el-icon-warning-outline" style="color:#E6A23C"></i>
                                                <i v-if="scope.row.status=='下载失败'" class="el-icon-circle-close" style="color:#F56C6C"></i>
                                            </p>
                                        </template>
                                        <template v-else>
                                            <span v-if="scope.row.downloadSpeed" >
                                                <span>下载中</span>
                                            </span>
                                            <span v-else >等待下载</span>
                                            <el-progress :percentage="scope.row.percentCompleted" v-if="scope.row.percentCompleted" color="#65b1ff" style="width:150px;margin-left:10px"
                                                :status="scope.row.status|filterStatus">
                                            </el-progress>
                                            <span v-if="scope.row.downloadSpeed">{{scope.row.downloadSpeed|filterSize}}/s</span>
                                        </template>
                                    </div>
                                </template>
                                </el-table-column>
                            </el-table>        
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="导出任务" name="export">
                    <div  style="height:28px;line-height:28px;">
                        <span style="color: #222222;">共{{fileData?.length?fileData?.length:0}}项</span>
                        <span style="color: #222222;margin-right:7px;" v-if="multipleSelection.length">，已选中{{multipleSelection.length}}项</span>
                        <el-button size="mini" plain class="info_button" @click="clearRecords" v-if="multipleSelection.length"> 清除记录</el-button>
                    </div>
                    <div class="task_import_main" style="margin-top:14px;">
                        <el-table
                            :data="fileData"
                            stripe
                            @selection-change="handleSelectionChange"
                            border
                            style="width: 100%">
                            <el-table-column
                            type="selection"
                            width="42">
                            </el-table-column>
                            <el-table-column
                            prop="taskName"
                            width="600"
                            label="任务名称">
                            </el-table-column>
                            <el-table-column
                            prop="size"
                            label="文件大小">
                            <template slot-scope="scope">
                                <span>{{scope.row.size|filterSize}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="fileTotal"
                            label="文件数量">
                            </el-table-column>
                            <el-table-column
                            prop="time"
                            label="任务开始时间">
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            :key="fileStatusKey"
                            label="任务状态">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status=='progress'">
                                    任务进行中
                                </div>
                                <div v-else-if="scope.row.status=='suspend'">
                                    任务已取消
                                </div>
                                <div v-else-if="scope.row.status=='completed'">
                                    任务已完成
                                </div>
                                <div v-else>
                                    {{scope.row.status}}
                                </div>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="result"
                            :key="fileResultKey"
                            label="导出结果">
                            <template slot-scope="scope">
                                <div>
                                    {{scope.row.result}}
                                </div>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="position"
                            label="下载位置">
                            </el-table-column>
                            <el-table-column
                            prop=""
                            label="">
                            <template slot-scope="scope" >
                                <div v-if="scope.row.status=='progress'" >
                                    <span style="cursor: pointer;" @click="suspendTask">取消</span>
                                </div>
                                <div v-else>
                                    <span style="cursor: pointer;" @click="clearRowRecords(scope.row)">清除记录</span>
                                </div>
                            </template>
                            </el-table-column>
                        </el-table>        
                    </div>
                </el-tab-pane>
                <el-tab-pane label="上传任务" name="upload">
                    <div  style="height:28px;line-height:28px;">
                        <span style="color: #222222;">共{{uploadfileData.length}}项</span>
                        <span style="color: #222222;margin-right:7px;" v-if="uploadMultipleSelection.length">，已选中{{uploadMultipleSelection.length}}项</span>
                        <el-button size="mini" plain class="info_button" @click="uploadClearRecords" v-if="uploadMultipleSelection.length"> 清除记录</el-button>
                    </div>
                    <div class="task_import_main" style="margin-top:14px;">
                        <el-table
                            :data="uploadfileData"
                            stripe
                            @selection-change="uploadHandleSelectionChange"
                            border
                            style="width: 100%">
                            <el-table-column
                            type="selection"
                            width="42">
                            </el-table-column>
                            <el-table-column
                            prop="taskName"
                            width="600"
                            label="任务名称">
                            </el-table-column>
                            <el-table-column
                            prop="size"
                            label="文件大小">
                            <template slot-scope="scope">
                                <span>{{scope.row.size|filterSize}}</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="fileTotal"
                            label="文件数量">
                            </el-table-column>
                            <el-table-column
                            prop="time"
                            label="任务开始时间">
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            :key="fileStatusKey"
                            label="任务状态">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status=='progress'">
                                    任务进行中
                                </div>
                                <div v-else-if="scope.row.status=='suspend'">
                                    任务已取消
                                </div>
                                <div v-else-if="scope.row.status=='completed'">
                                    任务已完成
                                </div>
                                <div v-else>
                                    {{scope.row.status}}
                                </div>
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="position"
                            label="上传位置">
                            </el-table-column>
                            <el-table-column
                            prop=""
                            label="">
                            <template slot-scope="scope" >
                                <div v-if="scope.row.status=='progress'" >
                                    <span style="cursor: pointer;" @click="uploadSuspendTask">取消</span>
                                </div>
                                <div v-else>
                                    <span style="cursor: pointer;" @click="uploadClearRowRecords(scope.row)">清除记录</span>
                                </div>
                            </template>
                            </el-table-column>
                        </el-table>        
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div style="padding-top:12px;position: absolute;right:28px;cursor: pointer;" @click="taskDrawer=false;">
                <i class="el-icon-close" style="font-size:18px;"></i>
            </div>
        </div>
    </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from "vuex";
import {get_size_str} from "@/utils/index";
import uploadTask from './uploadTask.vue';
export default {
  name: 'taskIndex',
    components:{
        uploadTask,
    },
  data() {
    return {
      taskData:[],
      taskDrawer:true,
      activeName:'progress',
      Data:[],
      completedTotal:0,
      percentage:0,
      statusKey:0,
      fileData:[],
      time:'',
      multipleSelection:[],
      fileStatusKey:100,
      fileResultKey:1000,
      suspendTaskDisabled:false,
      uploadfileData:[],
      uploadMultipleSelection:[],
    };
  },

  mounted() {
        this.taskData = [];
        this.suspendTaskDisabled = this.odFileList.suspendTaskDisabled;
        if (this.odFileList?.total) {
            var num = (this.odFileList?.completedTotal/this.odFileList?.list.length)*100;
            this.percentage = Math.ceil(num);   
        }else{
            this.percentage = 0;    
        }
         const fileList = localStorage.getItem('myFileData');
         this.fileData = JSON.parse(fileList);
         const uploadFileList = localStorage.getItem('myUploadFileData');
         this.uploadfileData = JSON.parse(uploadFileList);
         if (this.odFileList.sep_folder) {
            this.taskData = this.savefolderData(this.odFileList?.list);
         }else{
            this.taskData = this.odFileList?.list;
         }
         if (this.odFileList.fileName) {
            const parts = _.split(this.odFileList.fileName, '_');
            if (parts.length>1) {
                this.time = parts[1]
            }else{
                this.time = this.odFileList.fileName;
            }            
         }
  },

  methods: {
    ...mapMutations(["saveDownloadState",'saveUploadState']),
    handleClick(){},
    handleSelectionChange(val) {
    this.multipleSelection = val;
    },
    uploadHandleSelectionChange(val){
        this.uploadMultipleSelection = val;
    },
    clearRecords(){
        this.fileData = this.fileData.filter(item => {
        return !this.multipleSelection.includes(item);
        });
        this.multipleSelection = []; // 清空选中项
        localStorage.setItem('myFileData', JSON.stringify(this.fileData));
    },
    uploadClearRecords(){
        this.uploadfileData = this.uploadfileData.filter(item => {
        return !this.uploadMultipleSelection.includes(item);
        });
        this.uploadMultipleSelection = []; // 清空选中项
        localStorage.setItem('myUploadFileData', JSON.stringify(this.uploadfileData));
    },
    suspendTask(){
        if (this.suspendTaskDisabled||this.odFileList.downloadState=='canceled'||this.odFileList.list?.length==this.odFileList.total) {
            return 
        }
        this.saveDownloadState({status:'suspend'});
        var file =  this.fileData.find(item=>item.new_id ==this.odFileList.new_id);
        if (this.odFileList.completedTotal==0) {
            file.result ='未导出'
        }else{
            file.result ='部分导出'
        }
        file.status = 'suspend';
        this.fileStatusKey++;
        this.fileResultKey++;
        localStorage.setItem('myFileData', JSON.stringify(this.fileData)); 
        // this.suspendTaskDisabled = true;
        this.setFileData()
    },
    uploadSuspendTask(){
        this.saveUploadState({status:'suspend'});
    },
    savefolderData(data){
        let newData = [];
        var status = '等待下载'
        const groupedByFolder = data.reduce((groups, item) => {
            const folderGroup = groups[item.folder] || [];
            folderGroup.push(item);
            groups[item.folder] = folderGroup;
            return groups;
        }, {});
        for (const item in groupedByFolder) {
            if (_.every(groupedByFolder[item],v=>v.status==='已完成')) {
                status = '已完成';
            }
            newData.push({
                folder:groupedByFolder[item][0].folder,
                children:groupedByFolder[item],
                new_id:`${groupedByFolder[item][0].folder}${Math.floor(Math.random() * 1000000).toString()}`,
                status,
                type:'folder'
            })
        }
        return newData

    },
    setFileData(){
        this.taskData.forEach(item=>{
            if (item.children?.length) {
                item.children.forEach(v=>{
                    if (v.status=='') {
                        v.status ='已取消';
                    }
                })
            }
        })
    },
    getRowKeys(row){
        return row.new_id;
    },
    clearRowRecords(row){
        console.log(row);
        this.fileData = this.fileData.filter(item=>{
            return item.new_id != row.new_id
        })
        localStorage.setItem('myFileData', JSON.stringify(this.fileData));
    },
    uploadClearRowRecords(row){
        this.uploadfileData = this.uploadfileData.filter(item=>{
            return item.new_id != row.new_id
        })
        localStorage.setItem('myUploadFileData', JSON.stringify(this.uploadfileData));
    },
    Setuploadstatus(id,status){
        var index =  this.uploadfileData.findIndex(item=>item.new_id==id);
        this.$set(this.uploadfileData[index],'status',status);
        localStorage.setItem('myUploadFileData', JSON.stringify(this.uploadfileData)); 
    },
    closeUpload(){
        this.taskDrawer = false;
    },
  },
  computed:{
    ...mapGetters({
        odFileList:'getodFileList',
        uploadFileData:'getUploadFileData'
    }),
  },
  filters:{
    filterSize(value){
        if (!value) {
            return 
        }
        if (get_size_str(Math.ceil(value))) {
         return get_size_str(Math.ceil(value));   
        }else{
            return value
        }
    },
    filterStatus(n){
        if (n=='已完成') {
            return 'success'
        }else if(n=='下载失败'){
            return 'exception'
        }else if(n=='已取消'){
            return 'warning'  
        }
    }
  },
  watch:{
    taskDrawer(n){
        this.$emit('modifyTaskDrawer',n)
    },
    'odFileList.list'(n){
        // this.statusKey++;
        //  if (this.odFileList.sep_folder) {
        //     this.taskData = this.savefolderData(this.odFileList?.list);
        //  }else{
        //     this.taskData = this.odFileList?.list;
        //  }
    },
    'odFileList.total'(n){
         var file =  this.fileData.find(item=>item.new_id ==this.odFileList.new_id);
         if (n==this.odFileList.list?.length&&this.odFileList.downloadState!='canceled') {
            file.status = 'completed';
            this.fileStatusKey++;
         }
        if ( this.odFileList.completedTotal==0 && n==this.odFileList.list?.length) {
            file.result = '未导出'
            this.fileResultKey++;
        }else if(n==this.odFileList.list?.length&&this.odFileList.completedTotal !=this.odFileList.list?.length){
            file.result = '部分导出'
            this.fileResultKey++;
        }else if(n==this.odFileList.list?.length && this.odFileList.completedTotal == this.odFileList.list?.length){
            file.result = '全部导出'
            this.fileResultKey++;
        }
            localStorage.setItem('myFileData', JSON.stringify(this.fileData));
    },
    'odFileList.completedTotal'(n){
        this.statusKey++;
        var num = (n/this.odFileList?.list.length)*100;
        this.percentage = Math.ceil(num);
        if (this.odFileList.list?.length == n) {
            var file =  this.fileData.find(item=>item.new_id ==this.odFileList.new_id);
            file.status = 'completed'
            this.fileStatusKey++;
            localStorage.setItem('myFileData', JSON.stringify(this.fileData));
        }
         if (this.odFileList.sep_folder) {
            this.taskData = this.savefolderData(this.odFileList?.list);
         }else{
            this.taskData = this.odFileList?.list;
         }
    },
    'odFileList.suspendTaskDisabled'(n){
        this.suspendTaskDisabled = n;
    }, 
  }
};
</script>
<style lang="less" scoped>
.task{
    // width: 100%;
    // padding: 28px;
    display: flex;
    .task_main_left{
        width:100px;
        z-index: 9999;
        padding-top: 20%;
    }
    // .task_main_right{
    //     flex: 1;
    // }
    .task_import_top{
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .task_import_main{
        margin-top: 28px;
    }
}
.info_color{
    color: #909399;
}
::v-deep .task_drawer{
    max-width: unset !important;
    // margin-top: 56px;
    width: calc(100vw - 270px) !important;
}
.arrow_right{
    width: 30px;
    height: 30px;
    background: #29C5EE;
    line-height: 31px;
    text-align: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius:4px;
    position: fixed;
    right: calc(100vw - 270px);
}
::v-deep .el-table .el-table__header .el-checkbox__inner{
    margin-left: 5px !important;
}
::v-deep .el-drawer__wrapper{
    top: 56px !important;
}
::v-deep .el-drawer .el-drawer__body{
    padding: 7px 28px !important;
}
::v-deep .el-table__indent{
    padding-left:20px !important;
}
.el-icon-folder-opened{
    margin-right: 0 !important;
}
.el-icon-document{
    margin-right: 0 !important;
}
::v-deep  .el-table .el-table__header .el-checkbox__inner{
    margin-left: 0 !important;
}
</style>