<template>
  <div>
    <el-container>
      <el-aside :class="!isCollapse ? 'collapse-slide' : ''">
        <!-- 菜单渲染 -->
        <!-- :collapse="isCollapse" -->
        <el-menu
          :default-active="defaultactive"
          :background-color="variables.menuBg"
          :text-color="variables.menuText"
          unique-opened
          :collapse="isCollapse"
          :active-text-color="variables.menuActiveText"
          router
        >
          <div class="logo">
            <img src="../assets/img/logo.png" alt=""  :style="!isCollapse?'margin-left:21px':''" />
            <p>巧鲸云IP副驾驶</p>
          </div>
          <el-popover
          @hide="popoverHide"
          :placement="routerPlacement"
          :disabled="routerDisabled"
          :width="routerWidth"
          v-for="(item,index) in enabled_menus2"
          :key="item.index"
          trigger="manual"
          :value="item.popoverOpen"
          >
          <div class="popoverBox">
            <p class="text1" @click="skipPopover">跳过</p>
            <div class="htmlBox" v-html="item.pointHtml"></div>
            <p >
              <span class="text2" @click="popoNext(index)" v-if="index!=enabled_menus2.length - 1">下一步</span>
              <span class="text2" @click="skipPopover" v-else>完成</span>
              <span class="text2" @click="previousStep(index)" v-if="index!=0">上一步</span>
            </p>
          </div>
          <template slot="reference">
            <!-- 无children -->
            <el-menu-item :index="item.index" v-if="!item.children?.length" :class="item.class">
                <i :class="item.icon" style="margin-right: 20px"></i>
                <span slot="title" style="font-size: 16px">{{ item.title }}</span>
            </el-menu-item>
            <!-- 有children -->
            <el-submenu 
            :index="item.index"
            v-else
          >
            <template slot="title" >
              <img :src="item.icon" style="margin-right: 20px;width:20px" />
              <span >{{ item.title }}</span>
            </template>
            <el-menu-item
              v-for="v in item.children"
              :key="v.path"
              :index="v.path"
              @click="goPage(v)"
            >
              <!-- <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#' + v.icon"></use>
              </svg> -->
              <i class="el-icon-arrow-right" style="margin-left:15px"></i>
              {{ v.title }}
              
            </el-menu-item>
          </el-submenu>
          </template>
        </el-popover>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          "
        >
          <div class="content-header-left">
            <svg
              class="icon"
              aria-hidden="true"
              @click="isCollapse = !isCollapse"
            >
              <use :xlink:href="isCollapse ? '#icon-indent' : '#icon-outdent'"></use>
            </svg>
          </div>
          <div style="display: flex;align-items: center;margin-right:10px">
            <div class="user-center" v-if="unread_alerts.length">
              <el-popover              
                placement="left"
                width="350"
                trigger="hover"
                v-model="visible2"
                @show="
                  unread_alerts.length != 0
                    ? (visible2 = true)
                    : (visible2 = false)
                "
              >
                <div
                  v-if="unread_alerts.length != 0"
                  style="
                  height: auto;
                    overflow: auto;
                    max-height: 700px;
                    font-weight: bold;
                  "
                >
                  <div
                    style="margin-bottom: 20px; cursor: pointer"
                    @click="read(item)"
                    @mouseenter="mouseEnter(item)"
                    @mouseleave="handleMouseLeave(item)"
                    :style="{ backgroundColor: item.mouseFlag ? active : '' }"
                    v-for="item in unread_alerts"
                    :key="item.id"
                  >
                    <p>{{ item.message }}</p>

                    <p>{{ item.receivedAt | filtertime }}</p>
                    <el-divider></el-divider>
                  </div>

                  <el-button
                    type="danger"
                    plain
                    @click="emptyList"
                    style="float: right"
                    >清空列表</el-button
                  >
                </div>
                <el-badge
                  style="margin-top: 5px; margin-right: 40px"
                  :value="unread_alerts.length"
                  :max="10"
                  class="item"
                  slot="reference"
                >
                  <img src="../assets/img/icon_xiaoxi.png" alt=""  style="height:28px"/>
                </el-badge>
              </el-popover>
            </div>
            <p style="margin-top: 5px; margin-right: 40px" v-else>
              <img src="../assets/img/icon_xiaoxi.png" alt=""  />
              </p>
              <div>        
              <el-popover
              placement="bottom"
              width="80"
              trigger="hover"
              popper-class="user_popper"
              v-model="visible">
                <p class="text_center">
                  <i class="el-icon-s-custom" style="margin-right:20px;margin-left:0"></i>
                  <span v-if="$store.state.ws_state.user">{{$store.state.ws_state.user.user}}</span>
                </p>
                <p class="text_center" @click="handlecheckPass">
                  <i class="el-icon-lock" style="margin-right:20px;margin-left:0"></i>
                  <span>修改密码</span>
                </p>
                <p class="text_center" @click="logout" style="margin-bottom:0">
                  <i class="el-icon-back" style="margin-right:20px;margin-left:0"></i>
                  <span>退出登录</span>
                </p>
                <div class="loginout"  slot="reference">
                <P v-if="$store.state.ws_state.user" class="user" >
                  <img src="../assets/img/user.png" alt="">
                </P>
                </div>
            </el-popover>
            </div> 
          </div>
        </el-header>
        <el-tabs
          style="height: 56px; padding:8px;50px;background:#E9FAFF"
          :class="headerFixed ? 'absorb-top' : ''"
          size="mini" 
          tabindex=""
          v-model="editableTabsValue"
          type="card"
          :closable="closable"
          @tab-click="handleTabsEdit"
          @tab-remove="removetabs"
        >
          <el-tab-pane
            :key="item.title"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.title"
          >
          </el-tab-pane>
        </el-tabs>
        <el-main>
          <div class="main-content">
            <keep-alive :include="includeList" class="main-content">
              <router-view
                :key="$route.fullPath"
                style="min-height: 98%; height:100%;width: 100%"
              ></router-view>
            </keep-alive>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    width="24%"
    modal-append-to-body
    append-to-body
    :close-on-press-escape="false"
    >
    <el-form
    :model="ruleForm"
    label-width="80px"
    :rules="rules"
    ref="passWordForm"
    >
      <el-form-item label="新密码" prop="pass" class="formbefore">
        <el-input size="small" type="password" v-model.trim="ruleForm.pass"
        placeholder="6位及以上大写字母,小写字母,数字,特殊符号3种以上组合"
        autocomplete="new-password" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass" class="formbefore">
        <el-input size="small" type="password" v-model.trim="ruleForm.checkPass" 
        placeholder="请确认密码"
        autocomplete="new-password" show-password></el-input>
      </el-form-item>
    </el-form>
    <span
        slot="footer"
        class="dialog-footer"
      >
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button
      type="primary"
      @click="changePass"
    >确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import variables from "@assets/css/variables.less";
import { Message, Loading } from "element-ui"
import { mapGetters, mapMutations,mapState } from "vuex";
import lodash from 'lodash'
const menuList = [
  [
    {
      title: "首页",
      path: "/home/HomePage",
      name: "HomePage",
      icon: "el-icon-s-home",
    },
  ],
  [
    {
      title: "品牌",
      path: "/home/brand",
      name: "MyBrand",
      icon: "icon-pinpai",
    },
    {
      title: "申请人",
      path: "/home/apply",
      name: "MyApply",
      icon: "icon-role1",
    },
    {
      title: "商品查询",
      path: "/home/commodityQuery",
      name: "MyCommodityQuery",
      auth_path:'commodityQuery',
    },
  ],
  [
    {
      title: "我方商标",
      path: "/home/trademark",
      name: "MyTrademark",
      icon: "",
      childrens: [
        {
          title: "商标详情页",
          path: "/home/tmpage",
          name: "MyTMpage",
        },
      ],
    },
    {
      title: "涉他商标",
      path: "/home/revetm",
      name: "MyReveTM",
      icon: "",
     childrens: [
        {
          title: "商标详情页",
          path: "/home/tmpage",
          name: "MyTMpage",
        },
      ],
    },
    {
      title: "时限管理",
      path: "/home/timelimit",
      name: "MyTimeLimit",
      icon: "",
    },
    {
      title: "商标预警",
      path: "/home/flowalert",
      name: "Myflowalert",
      icon: "",
    },
  ],
  [
        {
      title: "未读档案",
      path: "/home/beprocessedOfficial",
      name: "MybeprocessedOfficial",
      icon: "",
    },
    {
      title: "所有档案",
      path: "/home/document",
      name: "MyOfficial",
      icon: "",
    },
  ],
  [
    {
      title: "域名管理",
      path: "/home/domainname",
      name: "Mydomainname",
      icon: "",
    },
    {
      title: "作品著作权",
      path: "/home/workCopyright",
      name: "MyWorkCopyright",
    },
    {
      title: "软件著作权",
      path: "/home/softwareCopyright",
      name: "MySoftwareCopyright",
    },
    {
      title: "著作权档案",
      path: "/home/copyrightFile",
      name: "MyCopyrightFile",
    },
  ],
  [
    {
      title: "商标饼图分析",
      path: "/home/pieVisualAnalysis",
      name: "pieVisualAnalysis",
      icon: "",
    },
    {
      title: "商标矩阵分析",
      path: "/home/treemapVisualAnalysis",
      name: "treemapVisualAnalysis",
      icon: "",
    },
    {
      title: "商标时间轴分析",
      path: "/home/timershaft",
      name: "Mytimershaft",
      icon: "",
    },
    {
      title: "案件可视化分析",
      path: "/home/caseshaft",
      name: "Mycaseshaft",
      icon: "",
    },
  ],
  [
    {
      title: "用户管理",
      path: "/home/user",
      name: "MyUser",
      icon: "icon-user",
    },
    {
      title: "角色和授权管理",
      path: "/home/role",
      name: "MyRole",
      icon: "icon-icon-test",
    },
    {
      title: "监控邮箱配置",
      path: "/home/postbox",
      name: "MyPostbox",
      icon: "icon-youxiang",
    },
    {
      title: "官文配置",
      path: "/home/documents",
      name: "MyDocuments",
      icon: "icon-youxiang",
    },
    {
      title: "行政诉讼案件",
      path: "/home/lawsuitcase",
      name: "Mylawsuitcase",
    },
    {
      title: "商标基础案件",
      path: "/home/tmcase",
      name: "Mytmcase",
    },
    {
      title: "非诉法律案件",
      path: "/home/lawcase",
      name: "Mylawcase",
    },
    {
      title: "证据列表",
      path: "/home/evidenceList",
      name: "MyEvidenceList",
      auth_path:'',  //evidenceList
    },
    {
      title: "证据回收站",
      path: "/home/evidenceRecyclingBin",
      name: "MyEvidenceRecyclingBin",
      auth_path:'',  //evidenceRecyclingBin
    },
  ],
];
export default {
  name: "MyHome",
  components: {
    // MyBrand
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      }else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs['passWordForm'].validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/
    return {
      isCollapse: false,
      menuList,
      visible: false,
      visible2: false,
      menuInd: 0,
      closable: true,
      editableTabsValue: "用户管理",
      editableTabs: [],
      defaultactive: this.$route.path,
      fullscreen: false,
      headerFixed: false,
      active: "",
      dialogVisible:false,
      ruleForm:{
        pass:'',
        checkPass:''
      },
      rules: {
          pass: [
            {pattern:reg,message:'大写字母,小写字母,数字,特殊符号3种以上组合', trigger: 'blur' },
            {min:6, max: 15,message:'密码要在 6 到 15 个字符',trigger: 'blur'},
            { validator: validatePass, trigger: 'blur' },
           
          ],
          checkPass: [
            {pattern:reg,message:'大写字母,小写字母,数字,特殊符号3种以上组合', trigger: 'blur' },
            {min:6, max: 15,message:'密码要在 6 到 15 个字符',trigger: 'blur'},
            { validator: validatePass2, trigger: 'blur' },
            
          ],
        },
      visible:false,
      routerPlacement:'right',
      routerWidth:'300',
      introduce_menu_index:0,
    };
  },
  mounted() {
    this.isCollapse = window.innerWidth < 1200;
    this.filterMenu();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
    this.isCollapse = window.innerWidth < 1200;
    this.$store.commit('changeScreenWidth',document.body.clientWidth)
    },
    handlecheckPass(){
      this.dialogVisible = true
      if (this.$refs["passWordForm"]) {
        this.$refs["passWordForm"].resetFields();
      }
    },
    //上一步
    previousStep(index){
      this.enabled_menus2[index].popoverOpen = false
      this.enabled_menus2[index -1 ].popoverOpen = true
    },
    //下一步
    popoNext(index){
      this.enabled_menus2[index].popoverOpen = false
      this.enabled_menus2[index + 1].popoverOpen = true
    },
    //跳过
    skipPopover(){
      this.$store.commit('patchRouterDisabled',true)
      localStorage.setItem('skip_introduce_menu',true)
    },
    //关闭时调用的函数
    popoverHide(){
    },
    //过滤菜单
    async filterMenu() {
      this.goPage({
        name: this.$route.name,
        title: this.$route.meta.title,
        path: this.$route.path,
      });
      this.pathChange();
    },
    flatMenu(v) {},
    pathChange() {
      if (this.$route.path.indexOf("/home/tmpage") != -1) {
        this.headerFixed = true;
      } else {
        this.headerFixed = false;
      }
    },
    logout() {
      this.visible = false;
      // localStorage.clear();
      localStorage.removeItem('feathers-jwt')
      localStorage.removeItem('Tabs')
      sessionStorage.clear();
      //刷新页面
      window.location.reload();
      this.$router.push("/platform/login");
    },
     changePass(){
      this.$refs['passWordForm'].validate(async(valid) => {
        if (valid) {
          var data ={
            _id:this.$store.state.ws_state.user._id,
            password:this.ruleForm.pass
          }
          await this.$store.dispatch('Changepassword',data)
          if(this.save_passwordCollapse){
            Message({
              type: 'success',
              message: `密码修改成功`,
              duration: 2000,
            })
            this.dialogVisible = false
            setTimeout(()=>{
              this.logout()
            },2000)
          }else{
            Message({
              type: 'error',
              message: `密码修改失败`,
              duration: 2000,
            })
          }
        }
      })
    },
    //切换tab页面
    handleTabsEdit(path) {
      // console.log(path);
      var patharr = this.editableTabs.find((item) => {
        return item.title == path.paneName;
      });
      this.defaultactive = patharr.path;
      if (this.$route.fullPath !== patharr.path) {
        this.$router.push(patharr.path);
      }
    },
    //删除tabs
    removetabs(name) {
      console.log("删除", name);
      var removename = this.editableTabs.findIndex((item) => {
        return item.title == name;
      });
      this.editableTabs.splice(removename, 1);
      // console.log(this.editableTabs);
      //如果页签删除到最后一个就把删除按钮关闭不让在删了
      if (this.editableTabs.length == 1) {
        this.closable = false;
      } else {
        this.closable = true;
      }
      //不管删除哪个页签都跳转到上一个
      //如果当前停留在的页面不是删除页面的话不跳转
      if (this.editableTabsValue == name) {
        this.editableTabsValue = this.editableTabs[removename - 1].title;
        this.$router.push(this.editableTabs[removename - 1].path);
        this.defaultactive = this.editableTabs[removename - 1].path;
      }
    },
    //添加页面并跳转路由
    goPage(val) {
      console.log(val);
      if (this.$route.path !== val.path) {
        this.$router.push(val.path);
      }
    },
    changeTab(val, path,n) {
      if (n) {
        if (n.query.OurTM=='false') {
        return n.query.OurTM = false
      }
        if (!n.query.OurTM&&n.query.OurTM!=null) {
          path='/home/revetm'
        }else{
          path='/home/trademark'
        }
      }
      // console.log(path);
      let url = JSON.parse(JSON.stringify(val));
      this.defaultactive = path ? path : url.path;
      var edittabarr = this.editableTabs.find((item) => {
        return item.title == url.title;
      });
      if (url && !edittabarr) {
        this.editableTabs.push(url);
      }
      //添加的页签存储到缓存中
      localStorage.setItem("Tabs", JSON.stringify(this.editableTabs));
      this.editableTabsValue = edittabarr ? edittabarr.title : url.title;
      //页签删除到最后一个后把删除按钮禁止掉，不让删最后一个。
      if (this.editableTabs.length == 1) {
        this.closable = false;
        return;
      } else {
        this.closable = true;
      }
      if (!this.$route.path.includes(url.path) && !path) {
        this.$router.push(url.path);
      }
    },
    changeMenu(ind) {
      this.menuInd = ind;
      let menu = this.menuList[ind][0];
      this.goPage(menu);
      this.defaultactive = menu.path;
    },
    read(alert) {
      console.log(alert);
      if (alert.fileLink) {
        var url = `${this.$baseURL}${alert.fileLink}`;
        const _this = this;
        var x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.responseType = "blob";
        x.onload = function (e) {
          if (e.target.readyState != 4 || e.target.status != 200) {
            _this.$message.warning("下载失败");
            return;
          }
          var blobUrl = window.URL.createObjectURL(x.response);
          var a = document.createElement("a");
          a.href = blobUrl;
          a.download = alert.opr;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(blobUrl);
        };
        x.send();
        // console.log(url);
        // console.log("点击了消息", alert.fileLink);
        // console.log(this.unread_alerts);
      }
      this.markReadAlerts([alert.id]);
      if (this.unread_alerts.length !== 0) {
        this.visible2 = true;
      } else {
        this.visible2 = false;
      }
    },
    emptyList() {
      this.emptyReadAlerts();
      this.visible2 = false;
    },
    mouseEnter(item) {
      this.$set(item, "mouseFlag", true);
      this.active = "#f5f7fa";
    },
    handleMouseLeave(item) {
      // item.mouseFlag = false;
      this.$set(item, "mouseFlag", false);
      this.active = "";
    },
    ...mapMutations(["markReadAlerts", "emptyReadAlerts","saveisCollapse"]),
  },
  computed: {
    ...mapState({
      routerDisabled: state => state.routerDisabled, 
    }),
    includeList() {
      return this.editableTabs.map((v) => v.name);
    },
    variables() {
      return variables;
    },
    ...mapGetters(["all_alerts", "unread_alerts", 'enabled_menus2', 'getisCollapse', 'save_passwordCollapse',]),
  },
  watch: {
    //监听路由变化
    $route: {
      handler: function (n) {
        this.pathChange();
        if (this.editableTabs.length === 0) {
          this.editableTabs = JSON.parse(localStorage.getItem("Tabs")) || [];
        }
        let menuInd;
        //获取地址栏路径
        let path = n.fullPath.split("#")[0].split("?")[0];
        //获取子页面标题
        let { title } = n.query;
        //通过菜单数组过滤出路由详情信息
        this.menuList.forEach((v, i) => {
          v.forEach((z) => {
            //父级菜单为当前页面路由地址
            if (z.path == path) {
              menuInd = i;
              if (title) {
                let arr = JSON.parse(JSON.stringify(z));
                arr.path = n.fullPath;
                arr.title = title;
                this.changeTab(arr, path);
              } else{
                this.changeTab(z);
              }
            } else if (z.childrens) {
              //父节点未匹配查看子节点是否匹配，匹配修改title信息
              z.childrens.forEach((o) => {
                if (path.includes(o.path)) {
                  menuInd = i;
                  let val = JSON.parse(JSON.stringify(o));
                  val.path = n.fullPath;
                  val.title = title || val.title;
                  this.changeTab(val, z.path,n);
                }
              });
            }
          });
        });
        if (this.menuInd !== menuInd) this.menuInd = menuInd;
      },
      deep: true,
      immediate: true,
    },
    isCollapse(){
      this.saveisCollapse(this.isCollapse)
    },
    routerDisabled(val){
      //监测页面指导的值  false 为出现
      let shade = document.getElementById("shade");
      if (!val) {
        shade.style.display = "block";
        //每次进入菜单介绍  把所有的状态改为false
        this.enabled_menus2.forEach(item=>{
          item.popoverOpen = false
        })
        this.enabled_menus2[0].popoverOpen = true
      }else{
        shade.style.display = "none";
      }
    }
  },
  filters: {
    filtertime(item) {
      let now = new Date().getTime();
      let time = now - item;
      let seconds = Math.floor((time / 1000) % 60);
      let minutes = Math.floor((time / (1000 * 60)) % 60);
      let day = Math.floor(time / (1000 * 60 * 60 * 24));
      let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      if (day > 0) {
        return day + "天前";
      } else if (hours > 0) {
        return hours + "小时前";
      } else if (minutes > 0) {
        return minutes + "分钟前";
      } else {
        return seconds + "秒前";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.absorb-top {
  position: sticky;
  top: -20px;
  z-index: 999;
  background-color: #fff;
  padding: 0px;
}

.el-header {
  display: flex;
  justify-content: space-between;
}

.content-header-left {
  display: flex;
}

.logo {
  height: 72px;
  display: flex;
  text-align: center;
  // align-items: center;
  line-height: 72px;
  background-color: #29c5ee;
  width: 100%;
  // box-shadow: 0px -4px 10px -8px rgb(41, 0, 0, 0.4) inset;
  img {
    width: 70px;
    // margin-left: 21px;
    margin-top: 5px;
    height: 62px;
  }
  p {
    width: 192px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    margin-left: 8px;
  }
}

.icon {
  margin-top: 7px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  font-size: 14px;
  //tab_nav去掉border
  border: 0;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  font-size: 14px;
  //激活项样式
  color: rgba(255, 255, 255, 0.9);
  background-color: #29c5ee;
  // border-radius: 4px 4px 0 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:hover {
  font-size: 14px;
  //鼠标悬浮时样式
  color: rgba(255, 255, 255, 0.9);
  background-color: #29c5ee;
  cursor: pointer;
  // border-radius: 4px 4px 0 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  margin-right: 20px;
  margin-bottom: 10px;
  // width: 120px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  // font-size: 14px;
  color: #636363;
  position: relative;
  background-color: #e9f9fd;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header {
  border: 0;
}
.loginout {
  // width: 100px;
  height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-top: 8px;
  cursor: pointer;
  p{
    // width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.user{
    margin-top: 5px;
    // width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.text_center{
  cursor: pointer;
  margin: 5px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #EFEFEF ;
  i{
    margin: 0 20px;
  }

}
.popoverBox{
  color: #29c5ee;
  .text1{
    float: right;
    cursor: pointer;
    font-size: 8px;
  }
  .htmlBox{
    clear: both;
  }
  p{
    span{
      float:right;
      cursor: pointer;
    }
    .text2{
      margin-left: 20px;
    }
  }
  
}
.page_home{
  box-shadow: 0px 4px 10px -8px rgb(41, 0, 0, 0.4) inset;
  background: #D5F6FF !important;
}
::v-deep .el-form>.formafter>.el-form-item__label:after{
  content:'*';
  color: #F56C6C;
  margin-left:4px;
}

::v-deep .el-form>.formbefore>.el-form-item__label:before{
  content:'*';
  color: #F56C6C;
  margin-right:4px;
}
</style>