import { post, get, find, put, del, patch, getBlob, head } from "./request";

//查询菜单
export const getReportList = (data) => get("/reportList", data);

//登录接口
export const Login = (data) => post("/authentication", data);

//查询用户
export const User = (data) => get("/users", data);

//添加用户
export const AddUser = (data) => post("/users", data);

//修改全字段用户
export const UpdateAUser = (data) => put("/users", data);

//修改用户
export const UpdateUser = (id, data, params) => patch("/users", id, data, params, true);

//删除用户
export const DelUser = (id, params) => del("/users", id, params);

//查询角色
export const Role = (data) => get("/roles", data, true);

//添加角色
export const AddRole = (data) => post("/roles", data);

//删除角色
export const DelRole = (id, params) => del("/roles", id, params);

//修改角色
export const UpdateRole = (id, data, params) => put('/roles', id, data, params, true);

//品牌树结构层级
export const Brand = (data) => get('/brand?get_tree=1', data);

export const GetBrand = (data) => get('/brand', data);
//品牌带业务线层级
// export const Brandclaz = (data) => get('/brand', data);

//修改品牌
export const UpdateBrand = (data) => put('/brand', data);

//查询品牌业务线
export const business = (data) => get('/constlist?const_type=business_line', data);

//添加品牌?关联商标
export const PushBrand = (data, params) => post('/brand', data, params);

//删除品牌
export const DelBrand = (id, params) => del('/brand', id, params);

//查询申请人信息
export const Apply = (data) => get("/applicant", data, true);

export const findApply = (params,use_qs) => find("/applicant", params,use_qs);

//查询state业务表
export const ApplyState = (data) => get('/constlist?const_type=state_info', data);

// 查询languageOptions配置
export const getLanguageOptionsApi = () => get('/constlist?const_type=languageOptions');

//查询删除申请人信息
export const DelApply = (id, params) => del("/applicant", id, params);

//修改申请人信息
export const UpdateApply = (data) => put("/applicant", data);

export const PatchApply = (id, data, params, use_qs) => patch('/applicant', id, data, params, use_qs);

//添加申请人
export const PushApply = (data) => post("/applicant", data);

//查询官文列表
export const Getofficial = (data) => get("/document", data, true);

//查询官文pdf
export const GetFile = (data) => get("/document", data);

//添加官文
export const Postofficial = (data,params) => post("/document", data,params);

//删除官文
export const delofficial = (id, params) => del("/document", id, params);

//编辑官文已读已处理状态
export const updateOfficialstate = (id, data, params, use_qs) => patch('/document', id, data, params, use_qs)

export const patchOfficial = (id, data, params, use_qs) => patch('/document', id, data, params, use_qs)

//编辑官文
export const updateOfficial = (data) => put('/document', data)

//查询商标列表
export const Gettrademark = (data, use_qs) => get('/trademark', data, true, use_qs)


//修改商标列表
// export const Puttrademark = (data) => put('/trademark', data)

//查询商标列表
export const Querytrademark = (data) => get('/trademark?$complex=true', data)

//修改相关商标是否处理状态
export const PATCHtrademark = (id, data, params, use_qs) => patch('/trademark', id, data, params, use_qs = true)

//删除商标
export const DELtrademark = (id, params) => del("/trademark", id, params);

//导入商标
export const Pushtrademark = (data, use_qs) => post('/trademark', data, {}, use_qs)
//查询案件
export const Getcases = (data) => get('/case', data, true)
export const GetCasesView = (data) => get('/case-view', data, true)
//查询案件列表
export const Getcases_list = (data) => find('/case-view', data, true)
//导出案件列表
export const exports_cases = (data,use_qs) => post('/case-view',data, {type:'export'}, use_qs = true)

//查询案件，但是使用find方法，可以使用id作为查询条件
export const findCases = (params) => find('/case', params, true)
//添加
export const Postcases = (data, params) => post('/case', data, params, true)
//更新案件
export const patchcases = (id, data, params) => patch('/case', id, data, params, true)

export const putcases = (data, params) => put('/case', data, params)
//删除案件
export const delcase = (id, params) => del('/case', id, params)
//查询字段表
export const Getconstlist = (data) => get('/constlist', data)

//手动拉取文件
export const Pullbox = (data,params) => post('/mailbox', data,params)

export const getConfigs = (params) => get('/config', params, true)

export const putConfigs = (data, params) => put('/config', data, params)

export const addConfigs = (data, params) => post('/config', data, params)

//查询官文字典表
export const getodprocess = (data, params) => get('/odprocess', data, params)

//删除官文字典表
export const delodprocess = (data, params) => del('/odprocess', data, params)

//添加官文字典表
export const postodprocess = (data, params) => post('/odprocess', data, params)
//获取验证码
export const getcaptcha = (data) => get('/captcha', data)
//获取商标预警列表
export const Getflowalert = (data) => find('/flowalert', data, true)
export const Patchflowalert = (id, data, params, use_qs) => patch('/flowalert', id, data, params, use_qs = true)
// 获取商标留痕的记录
export const getOprAudit = (data) =>get('/opr-audit',data)
// 请求静态资源
export const getBlobFile = (url)=> getBlob(url)
// 查看bind记录表
export const findBind = (data)=>find('/bind',data,true)

export const postBind = (data)=>post('/bind',data)

export const delBind = (id)=>del('/bind',id)

export const postdoc_exports=(data)=>post('/document_exports',data)

// 商品搜索
export const getGoodsdict=(data)=>find('/goodsdict',data)

// 商品过滤
export const updataGoodList = (data)=>post('/goodsdict',data)

// 著作权管理
export const findCopyRight = (data,use_qs=false)=>find('/copyright',data,use_qs)

export const getCopyRight = (params)=>get('/copyright',params)

export const patchCopyRight = (id,data,params)=>patch('/copyright',id,data,params)

export const postCopyRight = (data,params)=>post('/copyright',data,params)

export const removeCopyRight = (id)=>del('/copyright',id)

// 上传
export const UploadFile = (data,params) => post("/file", data,params);
export const UploadCaseExcel = (data,params) => post("/case",data,params);

export const findFiletList = (data)=>find('/file',data)

export const getFile = (params)=>get('/file',params)

export const patchFile = (id,data,params)=>patch('/file',id,data,params)

export const removeFile = (id)=>del('/file',id)
// 测试接口--------------------------------
//测试官文 邮件的样式
export const sendMailsOd = ()=>find('/dummy-test',{$sendMailsOd:true})

//测试预警 邮件的样式
export const sendFlowAlertNotifyMail = ()=>find('/dummy-test',{$sendFlowAlertNotifyMail:true})
 
//触发商标预警流程
export const check_tm_flow = (data)=>post('/flowalert',data,{$check_tm_flow:true})
 
// 定时任务 （检查商标）
export const checkTrademark = ()=>find('/dummy-test',{$checkTrademark:true})

export const sendAlertEmail = ()=>find('/dummy-test',{$sendAlertEmail:true})

export const updataTrademarkData = ()=>find('/dummy-test',{$updataTrademarkData:true})

export const getdomain = (data)=>get('/domain',data)

export const batchdomain = (data)=>post('/domain',data,{type:'batch'})

export const add_domain = (data)=>post('/domain',data,{type:'add'})

export const del_domain = (data,params)=>del('/domain',data,params)

export const patch_domain = (id,data,params)=>patch('/domain',id,data,params)

export const get_evidence = (params)=>get('/evidence',params);

export const find_evidence = (params,use_qs = false)=>find('/evidence',params,use_qs);

export const del_evidence = (data,params)=>del('/evidence',data,params);

export const patch_evidence = (id,data,params)=>patch('/evidence',id,data,params);

export const post_evidence = (data,params)=>post('/evidence',data,params);

export const find_share = (params)=>find('/file_share',params,true)

export const get_share = (params)=>get('/file_share',params)

export const created_share = (data,params)=>post('/file_share',data,params)

export const patch_share = (id,data,params)=>patch('/file_share',id,data,params)

export const del_share = (id,params)=>del('file_share',id,params)

export const download_share = (data,params)=>post('share',data,params)

//文件下载
export const download_evidence = (data,)=>post('/evidence',data,{type:'download'});

//删除文件到回收站
export const recycle_evidence = (id,data)=>patch('/evidence',id,data,{type:'recycle'});

//证据中心关联品牌
export const relate_brand = (id,data)=>patch('/evidence',id,data,{type:'relate_brand'});

//其他文件绑定商标
export const tmattchbind = (data,)=>post('/tmattchbind',data);

export const getFileTotal = (url)=> get(url);

export const goods_groups = (data,)=>post('/goodsgroups',data);

export const get_goods_groups = (data,)=>get('/goodsgroups',data);

export const del_goods_groups = (id,params)=>del('/goodsgroups',id,params);

export const patch_goods_groups = (id,data)=>patch('/goodsgroups',id,data);

export const post_audit = (data, params)=>post('/correction',data,params);

export const get_audit = (params)=>find('/correction',params,true);

export const patch_audit = (id,data,params)=>patch('/correction',id,data,params);

export const del_audit = (id,params)=>del('/correction',id,params);

export const headFileUrl = (url,params) =>head(url,params,0);