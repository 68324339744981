import { pinyin } from 'pinyin-pro';
import lodash from 'lodash';
import { getRandomKey } from "@/utils/index.js";

const filterNonChineseLetters = (inputStr) => {
    return inputStr.replace(/[^\u4e00-\u9fffA-Za-z]/g, '');
}
const nonChineseLetterKey = '无';
const JudgingStrings = (str,n) =>{
    if (!str || str.length === 0) {
        return nonChineseLetterKey;
    }

    let spliceIndex = n;
    if (str.length < n) {
        spliceIndex = str.length;
    }
    
    let strArr = str.split('').splice(0,spliceIndex);
    let newStr = '';

    for (const s of strArr) {
        if (/^[A-Za-z]/.test(s)) {// 英文取首字母并转换为小写
            newStr+= s.toLowerCase();
        } else {// 中文获取拼音首字母
            const pinyinInitial =  pinyin(s, {
                pattern: 'first',
                toneType: 'none',
            })
            newStr+= pinyinInitial.toLowerCase();
        }     
    }

    return lodash.capitalize(newStr);         
}

class MapArray {
  constructor() {
    this.map = new Map();
  }

  has(key){
    return this.map.has(key);
  }

  add(key,item){
    if (this.map.has(key)) {
        let array = this.map.get(key);
        let find = array.find(i=>i.key === item.key);
        if (find) {
            return;
        }else{
            array.push(item);
            this.map.set(key,array);
        }
    }else{
        this.map.set(key,[item]);
    }
  }

  getMap(){
    return this.map;
  }

  getItem(key){
    if (this.has(key)) {
        return this.map.get(key);
    }else{
        return [];
    }
  }
}


export const letterGroup = (nameArray,size = 9) =>{

    if (!nameArray || nameArray.length === 0) {
        return [];
    }

    let filterLabelArray = nameArray.map((name,index)=>{
        return {
            label:name,
            filterLabel: filterNonChineseLetters(name),
            key:index + 1,
        }
    })

    let map2 = new MapArray();
    let spliceIndex = 0;

    do {
        spliceIndex++;
        let map1 = new MapArray();
        let removeKeys = [];

        filterLabelArray.forEach(i=>{
            let Letter = JudgingStrings(i.filterLabel,spliceIndex);

            if (i.filterLabel.length === spliceIndex || i.filterLabel.length === 0) {
                map2.add(Letter,i);
                removeKeys.push(i.key);
            }else{
                map1.add(Letter,i);
            }

        })
        removeKeys.forEach(x=>{
            lodash.remove(filterLabelArray,(y)=>{
                return y.key == x;
            });
        })

        for (const [Letter,value] of map1.getMap()) {

            let array = map2.getItem(Letter);
            if (array.length + value.length <= size) {
                value.forEach(i=>{
                    lodash.remove(filterLabelArray,(y)=>y.key == i.key);
                    map2.add(Letter,i)
                })
            }
            
        }
    } while (filterLabelArray.length > 0);
    
    let set = new Set(map2.getMap().keys());


    let sortLettesArray = lodash.sortBy(Array.from(set));
    // console.log('sortLettesArray :>>', sortLettesArray);
    

    let findIndex = -1;
    if (map2.getItem(nonChineseLetterKey).length > size) {
        findIndex = sortLettesArray.findIndex(i => i == nonChineseLetterKey);
        sortLettesArray.splice(findIndex,1);
    }

    let cc = [];
    try {
        for (let i = 0; i <= sortLettesArray.length - 1;) {
            let dd = [];
            let key = sortLettesArray[i];

            let keyBefore = key;
            let keyAfter = key;

            let value = map2.getItem(key);
            while (dd.length + value.length <= size) {

                dd = lodash.concat(dd,value);
                dd = lodash.uniqBy(dd, (item=>{
                    return item.key;
                }))
                keyAfter = sortLettesArray[i];
                
                i++;
                if (i >= sortLettesArray.length) {
                    break;
                }
                key = sortLettesArray[i];
                value = map2.getItem(key);
                
            }

            let demoId = 'demoId' + getRandomKey('aA0',6);
            cc.push({
                label:`${keyBefore}-${keyAfter}`,
                value:dd,
                id:demoId,
            })
            
        }
    } catch (error) {
        console.log('error :>>', error);
        return [];
    }

    if (findIndex > -1) {
        let demoId = 'demoId' + getRandomKey('aA0',6); 
        cc.push({
            label:`${nonChineseLetterKey}-${nonChineseLetterKey}`,
            value:map2.getItem(nonChineseLetterKey),
            id:demoId,
        })
    }

    let ee = 0;
    cc.forEach(i=>{
        ee+=i.value.length;
    })

    console.log(ee,nameArray.length,size);
    
    return cc;
}

 

export const getClazKey = (claz) =>{
    return parseInt(claz.replace('第','').replace('类',''));
}

export const getGroupKey = (group) =>{
    return group.split('-')[0];
}

export const getGroupClazKey = (group) =>{
    let groupName = group.split('-')[0];
    let clazKeys = groupName.split('').splice(0,2);
    if (clazKeys[0] == 0) {
        return parseInt(clazKeys[1]);
    }else{
        return parseInt(clazKeys.join(''));
    }
}