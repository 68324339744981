import { Message } from 'element-ui'
const debouncdMessage = _.debounce((message)=>{
    Message.error(message);
},2000)
/**
 * @param {string} url
 * @param {number} code
 * @param {string} message
 */
export function errorLog(url, code, message) {
    const status = {
        '/authentication': {
            401: '用户名或密码错误',
            403: '账号异常请重新登录'
        },
        '/users': {
            409: '用户名已存在',
            400: '服务器发生错误，请联系管理员',
            403: '账号异常请重新登录',
        },
        '/roles': {
            409: '角色名已存在',
            400: '服务器发生错误，请联系管理员',
            403: '账号异常请重新登录',
        },
        '/brand?get_tree=1': {
            409: '品牌已存在',
            400: '服务器发生错误，请联系管理员',
            403: '账号异常请重新登录',
        },
        '/trademark': {
            409: '商标已存在',
            400: '服务器发生错误，请联系管理员',
            403: '账号异常请重新登录',
            404:"此商标已被删除!"
        },
        '/domain':{
            409:'域名已存在请重新添加',
            400:'服务器发生错误，请联系管理员',
            403:'账号异常请重新登录',
            404:"未找到此条数据,请刷新后重试",
        },
        '/case':{
            404:'未找到此案件,请刷新后重试'
        },
        '/file_share':{
            404:'该分享已删除！'
        }
    }
    if (status[url] && status[url][code]) {
        debouncdMessage(status[url][code])
    } else {
        if (message) {
            Message.error(message)   
        }
    }
}
