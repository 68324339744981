<template>
    <div>
       <div class="fileContent">
        <el-col :span="widths.fileName" :style="`margin-left:${(file.level ? file.level : 0) * 14}px;`" class="fileColumn1">
            <el-image :src="getFileTypeImg(file.filename)"  class="iconUrlClass"></el-image>
            <p>{{ file.filename }}</p>
        </el-col>
        <el-col :span="widths.fileSize">
            <span>{{ file.fileSizeStr }}</span>
        </el-col>
        <el-col :span="widths.fileStatus" style="display:flex;flex-wrap:wrap;">
            <span :style="getStatusColor(file.status)">{{ file.status | filterStatus(file) }} </span>
            <div class="loading-animation" v-if="file.status === 'wait'">
                <span v-for="(dot, index) in dots" :key="index" :style="{ animationDelay: `${index * 0.2}s` }">{{ dot }}</span>
            </div>
            <span v-if="file.status === 'failed_store'" @click="handleSubmit(file)" style="margin-left:7px;cursor: pointer;">重试</span>
            <span v-if="file.status === 'failed_unzip'" :style="`color:${getThemeLess.colorTextPlaceholder};`">请检查文件后再次上传</span>
        </el-col>
       </div>
        <template v-if="file.children && file.children.length > 0">
            <FileItem v-for="child in file.children" :key="child.key" :file="child" :widths="widths" />
        </template>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { getfileSuffixAndImg,get_size_str} from '@/utils/index.js';
export default {
    name:"FileItem",
    props: {
        file: {
            type: Object,
            required: true,
        },
        widths: {
            type: Object,
            required: true,
        },
    },
    data(){
        return{
            dots: ['.', '.', '.'],
        }
    },
    methods:{
        handleSubmit(file){
            this.$emit('oneFileSubmit',file);
        },
    },
    filters:{
        filterStatus(val,file){
            let isZip = file.children && file.children.length > 0;
            switch(val){
                case "succeeded":
                    return isZip ? '' : "上传成功";
                case "repeat":
                    return "文件存在";
                case "failed_store": 
                    return '上传失败';
                case "failed_unzip":
                    return  '压缩包解压失败';
                case "wait":
                    return "上传中"    
            }
        }
    },
    computed:{
        getStyle(){
          
        },
        getFileTypeImg(){
            return (fileName) =>{
                let { suffix, iconUrl } = getfileSuffixAndImg(fileName);
                return iconUrl
            }
        },
        getStatusColor(){
            return (fileStatus) =>{
                let color = ""
                if (fileStatus == 'failed_unzip' || fileStatus == 'failed_store' ) {
                    return {
                        color:this?.getThemeLess?.colorDanger ||'#F56C6C'
                    }
                }
            }
        },
        ...mapGetters(['getThemeLess']),
    }
};
</script>

<style lang="less" scoped>
@TableLeft:14px;
.fileContent{
    height: 48px;
    line-height: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .fileColumn1{
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .el-image{
            width: 16px;
            height: 16px;
            margin:0px @TableLeft;
        }
        p{
            flex: 1;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
        }
    }
    
}
.fileContent:hover{
    background-color: #F2F6FC;
}

.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-animation span {
    font-size: 20px;
    opacity: 0;
    animation: bounce 0.6s infinite alternate;
}

@keyframes bounce {
    to {
        opacity: 1;
        transform: translateY(-4px);
    }
}
</style>