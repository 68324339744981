import axios from "axios";
import router from '../router'
import qs from 'qs';
import { Message } from "element-ui";

import config from "@/config"

import { errorLog } from '@/utils/error-log'
import _ from "lodash"
import store from '@/store';

import { loginOvertimeBox } from '@/utils/index.js'
//引入axios实例化
const request = axios.create({
  baseURL: config.baseURL
  // baseURL: "/api",
  // timeout: 5000,
});
const loading = {
  loadingStance: null,
  open() {
    if (this.loadingStance === null) {
       this.loadingStance = Message.loading("数据加载中，请稍后......", 0);
    }
  },
  close() {
    if (this.loadingStance !== null) {
      setTimeout(this.loadingStance, 0);
    }
    this.loadingStance = null;
  }
};
//请求拦截
request.interceptors.request.use(
  (config) => {
    // loading.open();
    const token = sessionStorage.getItem("token")
    if (token) {
      config.headers.Authorization = token;
    }

    // onUploadProgress(config);

    return config;
  },
  (error) => {
    loading.close();
    return Promise.reject(error);
  }
);
//响应拦截
request.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // jwt过期
    // className:'forbidden'
    // code: 403
    // data: undefined
    // errors:{}
    // message:'Authentication error: jwt expired'
    // name:'Forbidden'
    // 用户无权限
    // className: "forbidden"
    // code: 403
    // errors: {}
    // message: "Current user is not allowed to do this action."
    // name: "Forbidden"
    if (error.name=='CanceledError') {
      return Promise.reject(error)
    }else if(error.code=='ECONNABORTED'){
      return Promise.reject(error)
    }
    let url = error.config.url
    url = url.split("/")[1]
    url = `/${url}`
    let {code,message,className,name} = _.pick(error.response.data, [ 'code', 'message','className','name','type'])
    let notMessageUrls = ['/roles','/download']
    
    if (code == 403 && message == 'Authentication error: jwt expired' && className == 'forbidden'  && name == 'Forbidden') {
      console.log('jwt过期，请重新登录！');
      loginOvertimeBox();
    }else if (notMessageUrls.includes(url)) {
      // 写这个主要是不想让他有报错提示
    }else if (code == 403) {
      Message.warning('您对当前的操作没有权限，如果需要请联系管理员。')
    }else{
      errorLog(url, code, message)
    }
    return Promise.reject(error)
  }
)

// get封装
export function get(url, params = {}, use_qs = false) {
  return new Promise((resolve, reject) => {
    if (params.id || params._id) {
      let id = params.id || params._id
      url = url + "/" + id
    } 
    
    const paramsSerializer = use_qs ? (params) => qs.stringify(params) : undefined

    request({
      url,
      params: _.omit(params, ['id', '_id']),
      paramsSerializer,
      method: "get"
    })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

/**
 * get方法从params取id的做法造成无法用id作为查询条件，所以增加这个find方法
 * @param {*} url 
 * @param {*} params 
 * @param {*} use_qs 
 * @returns 
 */
export function find(url, params = {}, use_qs = false) {
  return new Promise((resolve, reject) => {
    
    const paramsSerializer = use_qs ? (params) => qs.stringify(params) : undefined

    // console.log(`find params: ${use_qs} params:${qs.stringify(params)}`)

    request({
      url,
      params,
      paramsSerializer,
      method: "get"
    })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

// post封装
export function post(url, data = {}, params = {}, use_qs = false) {

  return new Promise((resolve, reject) => {

    const paramsSerializer = use_qs ? (params) => qs.stringify(params) : undefined

    request({
      url,
      method: "post",
      data,
      params,
      paramsSerializer
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}
// put封装
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    let obj = {};
    if (data.id || data._id) {
      let id = data.id || data._id
      obj = {
        url: url + "/" + id,
        data
      };
    } else {
      obj = {
        url,
        data
      };
    }
    request({
      ...obj,
      method: "put"
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// patch封装
export function patch(url, id, data = {}, params = {}, use_qs = false) {
  return new Promise((resolve, reject) => {
    if (id) {
      url = url + '/' + id
    } 


    const paramsSerializer = use_qs ? (params) => qs.stringify(params) : undefined
    request({
      url,
      params,
      data,
      paramsSerializer,
      method: "patch"
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// delete封装
export function del(url, id, params) {
  return new Promise((resolve, reject) => {

    if (!id && (!params || Object.keys(params).length == 0)) throw `Delete without id or params is forbidden.`

    if (id) url = `${url}/${id}`

    request({
      url,
      params,
      paramsSerializer: (params) => qs.stringify(params),
      method: "delete"
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
//   Blob封装,resopnseType:Blob一般是用于文件下载
export function getBlob(url, params = {},doc_id, speed_callback,source) {
  // 记录上一次测量的进度和时间
  var obj ={}
  if (source) {
    obj.cancelToken = source.token
  }
  let lastProgress = 0;
  let lastTime = Date.now();
  return new Promise((resolve, reject) => {
    request({
      url,
      method: "get",
      params,
      ...obj,
      paramsSerializer: (params) => qs.stringify(params),
      responseType: "blob",
      onDownloadProgress:(progressEvent)=>{
         if(!doc_id || !speed_callback) return;
        const percentCompleted = Math.round((progressEvent.loaded*100)/progressEvent.total);
        //  console.log('下载进度'+percentCompleted+'%',doc_id);
        const currentProgress = progressEvent.loaded;
        const currentTime = Date.now();
        // const size = progressEvent.total
        // 计算自上次测量以来的已下载字节数和时间差
        const downloadedSinceLast = currentProgress - lastProgress;
        const timeSinceLast = currentTime - lastTime;
    
        // 计算下载速度
        if (timeSinceLast > 0) {
          const downloadSpeed = (downloadedSinceLast / timeSinceLast) * 1000; // 乘以1000转换为KB/s，如果需要MB/s则再除以1024
          speed_callback({downloadSpeed,doc_id,percentCompleted})
        }
    
        // 更新上一次测量的进度和时间
        lastProgress = currentProgress;
        lastTime = currentTime;
      }
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function head(url, params = {}) {
  const queryString = new URLSearchParams(params).toString()
  const fullUrl = queryString ? `${url}?${queryString}` : url

  return new Promise((resolve, _) => {
    const timeoutPromise = new Promise((_, rejectTimeout) => {
      setTimeout(() => {
        rejectTimeout(new Error("Fetch request timed out"));
      }, 5000);
    })

    Promise.race([
      fetch(fullUrl, { method: 'HEAD', redirect: 'manual' }),
      timeoutPromise
    ]).then((response) => {
      console.log(`head status:${response.status}`)
      resolve(response.ok && response.status < 400)
    }).catch(error => {
      console.error(`head function`, error.message)
      resolve(false)
    })
  })
}
export function uploadFiles(url,data={},params = {},id,speed_callback,cancelTokenSource){
  let lastLoaded = 0;
  let lastTime = Date.now();;
  var obj ={}
  if (cancelTokenSource) {
    obj.cancelToken = cancelTokenSource.token; 
  }
  return new Promise((resolve, reject) => {
    request({
      url,
      method: "post",
      data,
      timeout:20000,
      params,
      ...obj,
      onUploadProgress:(progressEvent)=>{
        const { loaded, total } = progressEvent;
        let percentCompleted = Math.floor((loaded * 100) / total);
        // console.log(`上传进度: ${percentCompleted}%`);
      // 计算上传速度
      const currentTime = Date.now();
      const bytesUploaded = loaded - lastLoaded;
      const timeElapsed = currentTime - lastTime;
      if (timeElapsed>0) {
        const speed = (bytesUploaded / timeElapsed) * 1000; // 转换为每秒字节数 
        // console.log(`上传速度: ${(speed / 1024 / 1024).toFixed(2)} MB/s`);
        speed_callback(id,percentCompleted,speed);
      }
      // 更新最后的进度和时间，以便下次计算
      lastLoaded = loaded;
      lastTime = currentTime;
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  });  
}
function  onUploadProgress(config) {
    let { url,method } = config;
    // 定义多个条件的数组
    const allowedConditions = [
      { url: "/document", method: "post", uploadPercentageMutation: 'setTmUploadPercentage' },
      { url: "/evidence", method: "post", uploadPercentageMutation: 'setEvidenceUploadPercentage' },
    ];

    // 找到匹配的条件
    const matchingCondition = allowedConditions.find(condition => {
      return condition.url === url && condition.method === method;
    });
    if (matchingCondition) {
      const uploadPercentageMutation = matchingCondition.uploadPercentageMutation;
      config.onUploadProgress = function(progressEvent) {
        const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (uploadPercentage > 100) uploadPercentage = 100
        store.commit(uploadPercentageMutation,uploadPercentage) 
      };
    }
}

export default {
  get,
  post,
  del,
  getBlob,
  put,
  patch,
  head,
  uploadFiles,
};
