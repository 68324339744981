
export const models = {
    trademarks: {
    name: 'trademarks',
    writable_fields: ['tm_alias', 'reg_num', 'class', 'class_name', 'app_date', 'status', 'goods', 'legal_status',
        'groups', 'deleted_goods', 'agent', 'brand_id', 'brand_name', 'ucfg_brand', 'first_annc_issue', 'first_annc_date',
        'reg_annc_issue', 'reg_annc_date', 'private_start_date', 'private_end_date','is_ref', 'comments']
        // 'applicant', 'applicant_origin', 'shared_applicants',
    }, 
    
    cases: {
    name: 'cases',
    writable_fields: ['internal_case_seq', 'app_num', 'case_applicant', 'type', 'base_type',
        'status', 'status_split', 'create_time', 'comments', 'clf_path_tree','attorney_casenum','verdict_result', 'client_seat', 'close_time',
        'opposite', 'apply_way', 'receipt_doc_date', 'decision_date', 'cause', 'general_result']
    }, 
    
    documents: {
    name: 'documents',
    writable_fields: ['doc_type', 'has_read', 'has_processed', 'type', 'type_code', 'receive_date', 'time_limit', 'comments',
        'goods', 'good_candidates', 'clf_case_path']
    }
}