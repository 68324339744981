import Vue from 'vue'
import store from '@/store';
import { Message } from "element-ui"
import  lodash   from 'lodash';
// button按钮的节流指令  给一个毫秒时间  v-preventReClick="2000"
export const preventReClick = Vue.directive('preventReClick', {
  inserted: function(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
})


const ob = new ResizeObserver((entries)=>{{
    for(const entry of entries) {
        const handle = map.get(entry.target)
        if (handle) {
            const box = entry.borderBoxSize[0]
            handle({
                width:box.inlineSize,
                height:box.blockSize,
                el:entry.target
            })
        }
    }
}})
const map = new WeakMap()    //  WeakMap 是一个防止内存泄漏的map结构   用Map也行  但是要在unbind生命周期内  删除el
// 监听某个盒子的大小   给一个函数  返回  盒子的宽 高 节点
export const  watchWidth = Vue.directive('watchWidth', {
  inserted: function(el, binding) {
    map.set(el,binding.value)
    ob.observe(el)
  },
  unbind:function(el){
    ob.unobserve(el)
  }
})


export const drawerDrag = Vue.directive('drawerDrag', {
  bind(el, binding, vnode, oldVnode) {

    const minWidth = 400;
    const dragDom = el.querySelector('.el-drawer');

    const dragHeaderHeight = el.querySelector('.el-drawer__header').clientHeight || 54;
    
    const imgElement = document.createElement('img');
    imgElement.style.cursor = 'w-resize';
    imgElement.style.position = 'absolute';
    imgElement.style.height = '32px';
    imgElement.style.width = '32px';
    imgElement.style.left = '-6px';
    imgElement.style.top = '8px';
    imgElement.src = require('@/assets/img/drag.png');
    imgElement.title = '拖动改变窗口宽度';
    dragDom.appendChild(imgElement);


    imgElement.onmousedown = (e) => {
      const elW = dragDom.clientWidth;
      const EloffsetLeft = dragDom.offsetLeft;
      const clientX = e.clientX;

      document.onmousemove = function (e) {
        e.preventDefault();
        // 左侧鼠标拖拽位置
        if (clientX > EloffsetLeft && clientX < EloffsetLeft + 20) {
          // 往左拖拽
          if (clientX > e.clientX) {
            let dragDomWidth = elW + (clientX - e.clientX);
            dragDom.style.width = dragDomWidth + 'px';
            store.commit("userSetDragDrawerSize",dragDomWidth);
          }
          // 往右拖拽
          if (clientX < e.clientX) {
            if (dragDom.clientWidth >= minWidth) {
              let dragDomWidth = elW - (e.clientX - clientX);
              dragDom.style.width = dragDomWidth + 'px';
              store.commit("userSetDragDrawerSize",dragDomWidth);
            }
          }
        }
      }

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      }
    }
  }
});

export const copyText = Vue.directive('copyText', {
  inserted(el,binding) {
    lodash.delay(() => {
      if (binding.value === false) return;
      const textToCopy = el.textContent.toString().trim();
      // 创建i标签
      const iconElement = document.createElement('i');
      iconElement.className = 'el-icon-copy-document info_color';
      iconElement.style.cursor = 'pointer';
      iconElement.style.display = textToCopy ? 'inline' : 'none';
      const _copyHandle = (event) => {
        event.stopPropagation(); // 阻止事件冒泡
        
        const textToCopy = el.textContent.toString().trim();
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        Message({
          type: "success",
          message: "复制成功",
        });
      }
      // 加入点击事件
      iconElement.addEventListener('click',_copyHandle);

      // 将图标元素添加到目标元素内部作为其子元素
      el.appendChild(iconElement);
      // 定义个方法，移除icon图标
      el._copyHandle = _copyHandle;
      el._removeIconElement = () => {
        if (el._copyHandle) {
          iconElement.removeEventListener('click',el._copyHandle);
          delete el._copyHandle;
        }
        el.removeChild(iconElement);
      };
    },500)
  },
  unbind(el) {
    if (el._removeIconElement) {
      el._removeIconElement();
      delete el._removeIconElement;
    }
  }
})