import Vue from 'vue';
import lodash from 'lodash';
Vue.filter('trademarkFilter',(trademark)=>{
    if (lodash.isObject(trademark)) {
        let { reg_num, class:claz, name, legal_status } = lodash.pick(trademark,['reg_num','class','name','legal_status']);
        let str = '';
        str +=  reg_num ? reg_num : '';
        str += claz || claz === 0 ? `  (${claz}类)` : '';
        str += ':';
        str += name ? name : '无';
        str += legal_status ? `  (${legal_status})` : '';
        return str;
    }
    return trademark;
})