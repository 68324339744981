<template>
  <div class="main-content">
    <keep-alive :include="includeList" :exclude="excludeList" class="main-content">
      <router-view
        :key="$route.fullPath"
        style="min-height: 98%; height: 100%; width: 100%"
      ></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editableTabs: [],
      excludeList:['MyHomePage'],// 不需要缓存路由组件的名称
    };
  },
  methods: {},
  created() {
    let tabs = JSON.parse(localStorage.getItem("Tabs"));
    this.editableTabs = tabs && tabs.length > 0 ? tabs : [];
    this.$EventBus.$on("patchEditableTabs", (data) => {  
      this.editableTabs = data;
    });
  },
  computed: {
    includeList() {
      return  Array.from(new Set(this.editableTabs.map((v) => v.name)));
    },
  },
};
</script>

<style>
</style>