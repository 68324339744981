<template>
    <div class="custom-date-picker" v-watchWidth="handleWatchSize">
        <el-popover trigger="manual" placement="bottom-start" ref="elPopoverRef" popper-class="datePickerpoPoperClass" v-model="popoverVisible">
            <div slot="reference" @click="handleOpenPopover">
                <div 
                :style="getDatePickerStyle"
                class="customBox">
                    <p :style="getStartDateStyle">{{ getStartDate }}</p>
                    <span>{{ rangeSeparator }}</span>
                    <p :style="getEndDateStyle">{{ getEndDate }}</p>
                </div>
            </div>
            <slot name="header" class="header"></slot>
            <el-form
            :label-position="labelPosition" 
            label-width="80px" 
            :model="fromDateModel"
            class="elFormClass"
            :size="elementSize"
            >
                <el-form-item :label="formStartLabel">
                    <el-date-picker
                    v-model="fromDateModel.startDate"
                    type="date"
                    :size="elementSize"
                    :picker-options="pickerOptionStartDate"
                    @change="handleChangeStartDate"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="formEndLabel">
                    <el-date-picker
                    v-model="fromDateModel.endDate"
                    type="date"
                    :size="elementSize"
                    :picker-options="pickerOptionEndDate"
                    @change="handleChangeEndDate"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <slot name="footer"></slot>
            <div class="footer_box">
                <el-button :size="elementSize"  @click="confirm_button_handle" type="primary">确定</el-button>
                <el-button :size="elementSize" class="info_button" plain @click="clear_button_handle">清空</el-button>
            </div>
        </el-popover>
    </div>
</template>

<script>
import { getRandomKey, toYMDTime } from "@/utils/index.js";
import lodash from 'lodash';
import theme from "@/assets/css/theme.less";
export default {
    name:"customDatePicker",
    data(){
        return {
            fromDateModel:{
                startDate: '',
                endDate: '',
            },
            labelPosition: 'top',
            elementSize:'mini',
            pickerOptionStartDate:{
                disabledDate: (time) =>{
                    if (this.fromDateModel.endDate) {
                        let endTime = this.fromDateModel.endDate.getTime();
                        return  time.getTime() > endTime;
                    }
                    
                    return false;
                }
            },
            pickerOptionEndDate:{
                disabledDate: (time) =>{
                    if (this.fromDateModel.startDate) {
                        let endTime = this.fromDateModel.startDate.getTime();
                        return  time.getTime() < endTime;
                    }
                    
                    return false;
                }
            },
            popoverVisible: false,
            DatePickerId: null,
            DateRangeDataValueCopy: [],
            boxWidth: 200,
        }
    },
    props:{
        startPlaceholder:{
            type: String,
            default: '开始日期',
        },
        rangeSeparator:{
            type: String,
            default: '-',
        },
        endPlaceholder:{
            type: String,
            default: '结束日期',
        },
        formStartLabel: {
            type: String,
            default: '开始日期',
        },
        formEndLabel: {
            type: String,
            default: '结束日期',
        },
        DatePickerWidth:{
            type: String,
            default: 'none',
        },
        isSetStyle:{
            type: Boolean,
            default: true,
        },
        value: {
            type: Array,
            required: true,
            validator: function(value) {
                return value.every(date =>{
                    return lodash.isDate(date) || date === '' || date === null || date === undefined;
                });
            }
        },
        resetFlagKey:{
            type: Number,
            default: 99,
        },
    },
    watch:{
        value:{
            handler:function (newValue){ 
                this.fromDateModel.startDate = newValue[0] || '';
                this.fromDateModel.endDate = newValue[1] || '';
            },
            immediate: true,
            deep: true,
        },
        popoverVisible(newVal){
            if (newVal) {
                // 添加一个全局的点击事件监听器，用来在点击非弹出框区域时关闭弹出框
                document.addEventListener('click', this.handleClickOutside);
            } else {
                document.removeEventListener('click', this.handleClickOutside);
            }
        },
        // 这个值发生变化，相当于父组件通知子组件进行重置，
        resetFlagKey(newVal){
            this.DateRangeDataValueCopy = [];
        },

    },
    computed:{
        // getDateRangeData:{
        //     get(){
        //         let startDate = this.fromDateModel.startDate ? this.fromDateModel.startDate : '';
        //         let endDate = this.fromDateModel.endDate ? this.fromDateModel.endDate : '';
        //         return [startDate,endDate];
        //     },
        //     set(newVal){
        //         if (newVal) {
        //             this.fromDateModel.startDate = newVal[0];
        //             this.fromDateModel.endDate = newVal[1];
        //         }else{
        //             this.fromDateModel.startDate = '';
        //             this.fromDateModel.endDate = '';
        //         }
                
        //     },
        // },
        getDatePickerWidth(){
            if (this.DatePickerWidth === 'none') {
                let newWidth = this.boxWidth && this.boxWidth > 50 ? this.boxWidth : 50;
                return newWidth + 'px';
            }else{
                return this.DatePickerWidth;
            }
        },
        getDatePickerStyle(){
            let style = `width:${this.DatePickerWidth};`;
            if (this.isSetStyle) {
                const bool = this.popoverVisible || this.value.some(i => i);
                const bcg = bool ? theme.colorBlack : theme.colorWhite;
                style += `background:${bcg};`;

                const bcl = bool ? theme.colorBlack : theme.borderColorBase;
                style += `borderColor:${bcl};`;
            }else{
                const bcl = this.popoverVisible ? theme.colorPrimary : theme.borderColorBase;
                style += `borderColor:${bcl};`;
            }

            return style;
        },
        getStartDate(){
            if (this.fromDateModel.startDate) {
                return toYMDTime(this.fromDateModel.startDate);
            }else{
                return this.startPlaceholder;
            }
        },
        getEndDate(){
            if (this.fromDateModel.endDate) {
                return toYMDTime(this.fromDateModel.endDate);
            }else{
                return this.endPlaceholder;
            }
        },
        getStartDateStyle(){
            if (this.fromDateModel.startDate) {
                return this.isSetStyle ? `color:${theme.colorWhite}` : `color:${theme.colorTextRegular}`;
            }else{
                return `color:${theme.colorTextPlaceholder}`;
            }
        },
        getEndDateStyle(){
            if (this.fromDateModel.endDate) {
                return this.isSetStyle ? `color:${theme.colorWhite}` : `color:${theme.colorTextRegular}`;
            }else{
                return `color:${theme.colorTextPlaceholder}`;
            }
        },
    },
    created() {
        this.DatePickerId = 'DatePicker' + getRandomKey('aA0',9);
    },
    methods:{
        getValue(startDate,endDate){
            if (!startDate && !endDate) {
                return [];
            }
            return [startDate,endDate];
        },
        handleChangeStartDate(newValue){
            if (!newValue) {
                newValue = '';
            }
            let values = this.getValue(newValue,this.fromDateModel.endDate);
            this.$emit('input', values);
        },
        handleChangeEndDate(newValue){
            if (newValue) {
                newValue.setHours(23,59,59,0);
            }else{
                newValue = '';
            }
            let values = this.getValue(this.fromDateModel.startDate,newValue);
            this.$emit('input', values);
        },
        confirm_button_handle(){
            this.DateRangeDataValueCopy = lodash.cloneDeep(this.value);
            this.$emit('confirmHandle');
            this.popoverVisible = false;
        },
        clear_button_handle(){
            this.$emit('input', []);
        },
        handleOpenPopover(){
            this.popoverVisible = true;
        },
        handleClickOutside(event){
            const popover = this.$refs.elPopoverRef;
            const target = event.target;
            // 如果点击的目标不是弹出框，那么关闭弹出框
            if (!popover.$el.contains(target)) {
                if (!lodash.isEqual(this.value,this.DateRangeDataValueCopy)) {
                    let value = lodash.cloneDeep(this.DateRangeDataValueCopy);
                    this.$emit('input', value);
                }
                this.popoverVisible = false;
            }
        },
        handleWatchSize(e){
            this.boxWidth = e.width;
        },
    },
}
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
::v-deep .el-popover{
    padding: 0;
}
.elFormClass{
    padding: 14px;
    padding-bottom: 0;
}
.footer_box{
    padding: 14px;    
}
::v-deep .el-form-item{
    margin-bottom: 14px;
    .el-form-item__label{
        margin-bottom: 7px;
        padding: 0 !important;
        font-size: 14px;
        line-height: 14px;
    }
}
.customBox{
    width: 100%;
    border: 1px solid @border-color-base;
    background-color: @color-white;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: @color-text-regular;
    >p{
        flex: 1;
        min-width: 70px;
        line-height: 20px;
        height: 20px;
        font-size: 12px;
        color: @color-text-regular;
        cursor: pointer;
    }
    >span{
        padding: 7px;
    }
}

</style>