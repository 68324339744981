<template>
  <div
    @mouseenter="handleMenuEnter"
    @mouseleave="handleMenuLeave"
    class="asideBox"
  >
    <el-menu
      :default-active="defaultactive"
      :background-color="themeLess.borderColorExtraLight"
      :text-color="themeLess.colorTextPrimary"
      :unique-opened="false"
      :collapse="getisCollapse"
      :active-text-color="themeLess.colorWhite"
      :class="getisCollapse ? 'elMenuClass' : ''"
      router
      :collapse-transition="false"
    >
      <el-popover
        @hide="popoverHide"
        :placement="routerPlacement"
        :disabled="routerDisabled"
        :width="routerWidth"
        v-for="(item, index) in enabled_menus2"
        :key="item.index"
        trigger="manual"
        :value="item.popoverOpen"
      >
        <div class="popoverBox">
          <p class="text1" @click="skipPopover">跳过</p>
          <div class="htmlBox" v-html="item.pointHtml"></div>
          <p>
            <span
              class="text2"
              @click="popoNext(index)"
              v-if="index != enabled_menus2.length - 1"
              >下一步</span
            >
            <span class="text2" @click="skipPopover" v-else>完成</span>
            <span class="text2" @click="previousStep(index)" v-if="index != 0"
              >上一步</span
            >
          </p>
        </div>
        <template slot="reference">
          <el-menu-item
            :index="item.index"
            v-if="!item.children?.length"
            :class="item.class"
          >
            <i
              :class="item.icon"
              :style="
                getisCollapse
                  ? `margin:0 ${themeLess.spacingMedium};font-size:${themeLess.asideMenuSize};width:${themeLess.asideMenuSize};`
                  : `margin:0 ${themeLess.spacingMedium};font-size:${themeLess.asideMenuSize};width:${themeLess.asideMenuSize};`
              "
            ></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
          <el-submenu :index="item.index" v-else>
            <template slot="title">
              <img
                :src="item.icon"
                style="width: 20px"
                :style="
                  getisCollapse
                    ? `margin:0 28px 0 ${themeLess.spacingMedium};width:${themeLess.asideMenuSize};color:${themeLess.colorTextPlaceholder};`
                    : `margin:0 ${themeLess.spacingMedium};width:${themeLess.asideMenuSize};color:${themeLess.colorTextPlaceholder};`
                "
              />
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item
              v-for="v in item.children"
              :key="v.path"
              :index="v.path"
              @click="goPage(v)"
            >
              <i class="el-icon-arrow-right" style="margin-left: 15px"></i>
              {{ v.title }}
            </el-menu-item>
          </el-submenu>
        </template>
      </el-popover>
    </el-menu>
    <!-- 侧栏的按钮 -->
    <div
      class="sidebarButton"
      :class="getisCollapse ? 'right' : 'left'"
      v-show="sidebarButtonShow"
      @click="handleClickSidebar"
    >
      <i
        :class="getisCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
      ></i>
    </div>
    <div class="locateRouteClass" >
      <div v-for="route in locate_route" :key="route.name" @click="goPage(route)">
        <i :class="route.icon" :style="`margin:0 ${themeLess.spacingMedium};font-size:${themeLess.asideMenuSize};width:${themeLess.asideMenuSize};`"></i>
        <p v-show="!getisCollapse">{{ route.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import variables from "@assets/css/variables.less";
import theme from "@/assets/css/theme.less";
import { mapGetters, mapMutations, mapState } from "vuex";
import { trademarkEdit } from "@/utils/data.js";
export default {
  data() {
    return {
      defaultactive: null,
      routerPlacement: "right",
      routerWidth: "300",
      sidebarButtonShow: false,
    };
  },
  created(){
    this.watchRoute(this.$route);
  },
  methods: {
    popoverHide() {},
    // 上一步
    previousStep(index) {
      this.enabled_menus2[index].popoverOpen = false;
      this.enabled_menus2[index - 1].popoverOpen = true;
    },
    //下一步
    popoNext(index) {
      this.enabled_menus2[index].popoverOpen = false;
      this.enabled_menus2[index + 1].popoverOpen = true;
    },
    //跳过
    skipPopover() {
      this.$store.commit("patchRouterDisabled", true);
      localStorage.setItem("skip_introduce_menu", true);
    },
    //添加页面并跳转路由
    goPage(val) {
      console.log(val);
      if (this.$route.path !== val.path) {
        this.$router.push(val.path);
      }
    },
    handleMenuEnter() {
      this.sidebarButtonShow = true;
    },
    handleMenuLeave() {
      this.sidebarButtonShow = false;
    },
    handleClickSidebar() {
      let isCollapse = this.getisCollapse;
      this.$store.commit("saveisCollapse", !isCollapse);
    },
    watchRoute(n){
      if (!n) n = this.$route;
      
      if (!n.query || (n.query?.OurTM == null) || (n.query?.OurTM == undefined)) return this.defaultactive = n.path;  

      let OurTM = n.query.OurTM;
      if (typeof OurTM == 'string') {
        this.defaultactive = OurTM == 'true' ? '/home/trademark' : '/home/revetm';
      }else{
        this.defaultactive = OurTM ? '/home/trademark' : '/home/revetm';
      }
    },
  },
  watch: {
    $route: {
      handler: 'watchRoute',
      deep: true,
    },
    routerDisabled(val) {
      //监测页面指导的值  false 为出现
      let shade = document.getElementById("shade");
      if (!val) {
        shade.style.display = "block";
        //每次进入菜单介绍  把所有的状态改为false
        this.enabled_menus2.forEach((item) => {
          item.popoverOpen = false;
        });
        this.enabled_menus2[0].popoverOpen = true;
      } else {
        shade.style.display = "none";
      }
    },
  },
  computed: {
    ...mapGetters([
      "all_alerts",
      "unread_alerts",
      "enabled_menus2",
      "getisCollapse",
      "save_passwordCollapse",
    ]),
    ...mapState({
      routerDisabled: (state) => state.routerDisabled,
    }),
    variables() {
      return variables;
    },
    themeLess() {
      return theme;
    },
    locate_route() {
      return new trademarkEdit()?.locate_route || [];
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/theme.less";
@el-menu-border-radius: 4px;
@menuHeight: 36px;
@menuLineHeight: 36px;
@menuMargin: 3px;
.asideBox{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-right: 1px solid @border-color-base;
  border-left: 1px solid @border-color-base;
}
.el-menu {
  padding-top: 11px;
  width: 100% !important;
  overflow: auto;
  // height: 100%;
  flex: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  // 把全部的li标签原来的padding20 设置成 28
  ::v-deep li {
    padding: 0 @spacing-medium !important;
  }

  // 把无子级children的class 的padding 用 margin代替
  ::v-deep .page_home {
    padding: 0 !important;
    margin: 0 @spacing-medium !important;
    .el-tooltip {
      padding: 0 !important;
      display: flex !important;
      align-items: center;
    }
  }

  // 设置有子级children的路由样式
  ::v-deep .el-submenu__title {
    padding: 0 !important;
    height: @menuHeight !important;
    line-height: @menuLineHeight !important;
    margin-top: @menuMargin !important;
    margin-bottom: @menuMargin !important;
  }
  ::v-deep .el-submenu__title:hover {
    background-color: @border-color-lighter !important;
    border-radius: @el-menu-border-radius;
  }

  // 设置子级children的样式
  .el-menu-item {
    height: @menuHeight !important;
    line-height: @menuLineHeight !important;
    margin-top: @menuMargin !important;
    margin-bottom: @menuMargin !important;
    display: flex;
    align-items: center;
  }
  .el-menu-item:hover {
    background-color: @border-color-lighter !important;
    border-radius: @el-menu-border-radius;
  }
  // 修改选中样式的属性
  .el-menu-item.is-active {
    background-color: @color-primary !important;
    border-radius: @el-menu-border-radius;
  }
}
::v-deep .elMenuClass {
  .el-submenu__icon-arrow {
    display: none;
  }
}
// 修改菜单栏关闭的样式
.el-menu--vertical {
  .el-menu {
    // padding: @spacing-medium  0!important;   // 改不动  在源码更改.el-menu--popup的padding为14px
    .el-menu-item {
      padding: 0 !important;
      margin: 0 @spacing-medium !important;
    }
  }
}

.sidebarButton {
  position: fixed;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid @border-color-light;
  color: @border-color-light;
  background-color: @color-white;
  top: 78px;
  z-index: 99999;
  cursor: pointer;
}
.sidebarButton:hover {
  border: 1.5px solid @border-color-base;
  i {
    color: @border-color-base;
    font-weight: 600;
  }
}
.locateRouteClass{
  cursor: pointer;
  padding: 0 14px 11px 14px;
  width: 100%;
  background-color: @border-color-extra-light;
  >div{
    width: 100%;
    padding: 0 !important;
    height: @menuHeight !important;
    line-height: @menuLineHeight !important;
    margin-top: @menuMargin !important;
    margin-bottom: @menuMargin !important;
    display: flex;
    align-items: center;
    background-color: @border-color-lighter !important;
    border-radius: @el-menu-border-radius;
  }
}
.right {
  left: calc(@asideMinWidth - 12px);
}
.left {
  left: calc(@asideMaxWidth - 12px);
}


.popoverBox{
  color: #29c5ee;
  .text1{
    float: right;
    cursor: pointer;
    font-size: 8px;
  }
  .htmlBox{
    clear: both;
  }
  p{
    span{
      float:right;
      cursor: pointer;
    }
    .text2{
      margin-left: 20px;
    }
  }
  
}
</style>