import Vue from 'vue'
import App from './App.vue'
import 'default-passive-events'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './axios'
import vueToPdf from 'vue-to-pdf'
import './theme/element/index.css'
import './assets/css/comm.less'
import './assets/css/upElement.less'
import './assets/svg/iconfont'
import {preventReClick, watchWidth, drawerDrag, copyText} from '@/utils/directive'   //自定义指令
import '@/components/index.js';
import '@/filters/index.js';
import helpDocMixin  from "@/mixins/helpDocMixin.js";
Vue.mixin(helpDocMixin);

import config from "@/config"
Vue.prototype.$baseURL = config.baseURL;
Vue.prototype.$EventBus = new Vue()
Vue.use(preventReClick)
Vue.use(watchWidth)
Vue.use(drawerDrag)
Vue.use(copyText)
Vue.use(vueToPdf)
Vue.prototype.$pdf = vueToPdf
Vue.use(ElementUI)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
