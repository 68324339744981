import {
  get_evidence,
  find_evidence,
  findFiletList,
  Getofficial,
  del_evidence,
  patch_evidence,
  post_evidence,
  download_evidence,
  recycle_evidence,
  find_share,
  get_share,
  created_share,
  patch_share,
  download_share,
  relate_brand,
  del_share
} from "@api/axios";
import { toBeijingTime,
  getFileExtensions,
  mongodbQuery,
  getfileSuffixAndImg,
  toYMDTime,
  getRenameSeq,
  addDate,
  escapeRegExp,
  get_size_str,
  getTimeDifference,
  customSort
} from '@/utils/index'
import lodash from 'lodash';
const evidenceModule = {
    state: () => ({
    //证据中心目录树
    evidenceTree:[],
    }),

    getters: {

    },
    mutations: {
    },
    actions: {
      //获取证据中心目录树
      async getEvidenceTree({commit}){
        let data =  await find_evidence({
            type:'folder_tree',
          });
          // 移入操作字段
          // evidenceTreeData(data)；
          // commit('',data)
          // 证据树按照名称进行排序
          sortEvidenceTree(data);
        return data
      },
      // 根据文件夹获取一级文件和文件夹
      async getFolderOneLeavl({commit}, {fullPath,brandNames=[],suffixList=[],is_suffix=true,searchVal=null}){
        let params = {
          type:'top_file',
          logic_path:(fullPath),
        }
        let data =  await find_evidence(params);
        let {extensions:suffixs,isFindOther} = getFileExtensions(suffixList)
        // 进行过滤 根据品牌id 和文件后缀.
        if (data && data.length > 0) {
          return data.filter(item=>{
            let brandFilter = true
            let suffixFilter = !is_suffix
            let searchValFilter = true
            if (brandNames.length > 0) {
              brandFilter = item?.brand_name ? brandNames.includes(item?.brand_name) : false;
            }
            if (is_suffix) {
              suffixFilter = isFindOther ? !suffixs.includes(item.suffix) : suffixs.includes(item.suffix)
            }
            if (searchVal) {
              let reg = new RegExp(escapeRegExp(searchVal))
              let fileNameSearch =  item.filename ? item.filename.search(reg) != -1 : false
              let tagsSearch = item.tags.findIndex(tag=>tag.search(reg) != -1)  !=-1
              let brandNameSearch = item.brand_name ? item.brand_name.search(reg) !=-1 : false
              searchValFilter = fileNameSearch || tagsSearch || brandNameSearch
            }
            return item.is_dir?(brandFilter && searchValFilter):(brandFilter && suffixFilter && searchValFilter)
          })
          }else{
            return []
          }
         
      },
  
      // 获取全部的文件和文件夹数量
      async getAllFileAndFolerTotal({commit}){
        let params = {
          $limit:1,
          is_dir:false,
          show_recycle:false,
          $or:[
            {deleted_version:null},
            {deleted_version:{$exists:false}}
          ]
        }
        let {total:evidenceTotal} =  await this.dispatch('findEvidenceSocket',params)
        let {total:copyrightFileTotal} = await findFiletList({
          $limit:1,
          is_evidence:true
        })
        let {total:trademarkDocTotal} = await Getofficial({
          $limit:1,
          is_evidence:true
        })
        // console.log(evidenceTotal , copyrightFileTotal , trademarkDocTotal);
        return evidenceTotal + copyrightFileTotal + trademarkDocTotal
      },
      // 根据文件夹获取多级文件和文件夹的id
      async getFolderMultiLevel({commit},fullPath){
        let params = {
          type:'sub_file',
          logic_path:fullPath
        }
        let data =  await find_evidence(params);
        return data
      },
      // 获取单个文件或文件夹的信息
      async getOneEvidence({},{id}){
        let params = {
          id
        }
        return await get_evidence(params)
      },
      async findFolder({},{logicPath}){
        let params = {
          logic_path:logicPath,
          is_dir:true,
          show_recycle: {$ne:true}
        }
        let {total,data} = await find_evidence(params,true);
        if (total) {
          return data[0];
        }else{
          return null
        }
      },
      //删除证据中心文件
      async delEvidenceTree({state},_id){
        try {
         await del_evidence(_id);
        return 200
        } catch (error) {
          return error
        }
      },
      //修改证据中心文件位置
      async patchEvidenceTree({state},evidenceData){
          if (evidenceData.type=="copy") {
           await  post_evidence(evidenceData,{type:evidenceData.type})
          }else{
            var data  = await patch_evidence(evidenceData.id,evidenceData,evidenceData.type?{type:evidenceData.type}:{});
          }
          console.log(data);
      },
  
      async created_evidence({dispatch},{filename,UpLevelFolder}){
        console.log(filename,UpLevelFolder);
        // 检查重名直接修改名称
        let  data = await dispatch('getFolderOneLeavl',{
          fullPath:UpLevelFolder.value,
          brandNames:[],
          suffixList:[],
          is_suffix:false,
          searchVal:null
        })
        if (data && data.length > 0) {
          let tableFileName = data.map(file=>file.filename)
          if (tableFileName.length > 0 && tableFileName.includes(filename)) {
            let seq = getRenameSeq(tableFileName,filename)
            filename = seq == 0 ? filename : `${filename}(${seq})`
          }
        }

        // 获取上一级的品牌信息
        let upLevelBrand = await dispatch('findFolder',{logicPath:UpLevelFolder.value})
        let params = {
          uname:UpLevelFolder.value + '/' + filename,
          filename,
          suffix:'',
          store_path:'',
          logic_path:UpLevelFolder.value + '/' + filename,
          brand_id:upLevelBrand?.brand_id,
          brand_name:upLevelBrand?.brand_name,
          tags:[],
          size:0,
          is_dir:true,
          show_recycle:false,
        } 
        return  await post_evidence(params);
      },

      // 上传电脑文件
      async upload_evidence({},{files,logic_path,needUnzip}){
        let params = {
          type:"upload"
        }
        let formData = new FormData()
        files.forEach((item,index)=>{
          formData.append(`files`,item.raw)
        })
        formData.append('logic_path',logic_path)
        formData.append('needUnzip',needUnzip)
        return await post_evidence(formData,params)
      },
      //批量下载分享文件
      async download_evidence(){

      },
      //批量下载文件
      async download_evidence_files({state},ids){
        console.log(ids);
        await download_evidence({ids:ids})
      },
      //删除文件到回收站
      async recycle_evidence_files({state},id){
        try {
          await recycle_evidence(id);
        } catch (error) {
          console.log(error);
        }
      },

      // 获取分享列表  只要近30天的
      async find_shareList({state}){
        let DAY_MS = 1000 * 60 * 60 *24 *30 
        let params = {
          start_date:{
            $gt: new Date().getTime() - DAY_MS
          },
          $sort:{
            start_date:-1
          }
        }
        let {data} = await find_share(params)
        return data
      },
      // 自定义生成新分享的名称
      async getNewShareName({},{shareName}){
        let params = {
          share_link:{$regex:'^' + escapeRegExp(shareName)},
          $select:['share_link']
        }
        let { total,data } = await find_share(params)
        if (total === 0) {
          return shareName
        }
        let arr = data.map(share=>share.share_link)
        let seq = getRenameSeq(arr,shareName)
        return seq == 0 ? shareName : `${shareName}(${seq})`
      },
      async find_shareLink({}){
        let params = {
          $select:['share_link']
        }
        let { data } = await find_share(params)
        return data
      },
      // 创建分享记录
      async createdShare({state},{share_link,password,start_date,expiration_date,files}){
        let params = {
          name:share_link,
          share_link,
          password,
          start_date,
          expiration_date,
          files,
        }
        return await created_share(params)
      },
      // 创建副本分享记录
      async createdShareCopy({dispatch},{id}){
        let {share} = await dispatch('getShare',{id:id})
        console.log(share);
        let dateDay = getTimeDifference(new Date(share.expiration_date),new Date(share.start_date),false)
        let start_date = new Date()
        let expiration_date = (addDate(start_date,dateDay))
        let share_link =  await dispatch('getNewShareName',{shareName:share.share_link + '(副本)'})
        let data ={
          share_link,
          password:share.password,
          files:share.files.map(file=>({id:file.id,type:file.type})),
          start_date,
          expiration_date
        }
        let newShare = await dispatch('createdShare',data)
        return newShare
      },
      // 修改分享记录
      async patchShare({state},{id,data}){
        console.log(id,data);
        return await patch_share(id,data)
      },
      // 删除单个分享记录
      async delShare({},{id,params}){
        return await del_share(id,params)
      },
      // 获取单个分享列表 有密码的
      async getShare({state},{id}){
        return await get_share({id,type:'detail'})
      },

      // 获取单个分享列表 无密码的  不带有文件
      async getShareForPage({},{id}){
        let params = {
          id,
          type:"for_page"
        }
        return await find_share(params)
      },
      // 下载文件
      async downloadShare({},{_id,password}){
        let params =  {
          _id,
          password
        }
        return await download_share(params)
      },
      // 回收站全选功能
      async recycleBinGetAllList({},params){
        let dataList = []
        params.type = 'showRecycle';
        params.$limit = 1000;
        params.$skip = 0;
        let {total,limit,data} = await find_evidence(params,true)
        dataList = lodash.concat(dataList,data)
        for (let skip = limit; skip <= total;) {
            params.$skip = skip
            let {data,limit} =  await find_evidence(params)
            if (data) {
                dataList = lodash.concat(dataList,data)
            }
            skip += limit
        }

        return dataList
      },
      // 查询回收站 调用后端
      async recycleBinGetList({},params){
        params.type = 'showRecycle'
        let list =  await find_evidence(params,true);
        if (list.total > 0) {
          list.data.forEach(item=>{
            item.evidenceType = item.logic_path.split('/')[1]
            item.delTime = toBeijingTime(item.updatedAt)
            item.size = item.is_dir ? '' :  get_size_str(item.size)
          })
        }
        return list
      },
      // 回收站还原
      async recycleBinReduction({},{ids}){
        let params = {
          type:"restore"
        }
        let error = []
        for (const id of ids) {
          await patch_evidence(id,{},params).catch(err=>{
            error.push({id,err})
          })
        }
        return error
      },
      // 回收站彻底删除
      async recycleBinCompletelyDelete({},{ids}){
        let params = {
          type:'delete'
        }
        let error = []
        for (const id of ids) {
          await del_evidence(id,params).catch(err=>{
            error.push({id, err})
          })
        }
        return error
      },
      //证据中心关联品牌
      async relate_brand({},params){
        await relate_brand('',params)
      },
      // 文件重命名接口  (文件名称)
      async renameFile({},{id,filename}){
        let data = {
          filename
        }
        let params = {
          type:'rename'
        }
        return await patch_evidence(id,data,params)
      },
      // 判断文件名称是否在同级下有相同的   （文件新名称，文件自身的逻辑路径,是否为文件）
      // true为重命名 false为没有
      async checkDuplicateFileName({dispatch},{filename,logic_path,is_file,id}){
        if (!logic_path) return false

        let pathArr = logic_path.split('/')
        if (!is_file) {
          pathArr.splice(pathArr.length-1, 1)
        }
        let new_logic_path = pathArr.join('/')
        let fileList = await dispatch('getFolderOneLeavl',{
          fullPath:new_logic_path,
          brandNames:[],
          suffixList:[],
          is_suffix:false,
          searchVal:null
        })

        if (fileList && fileList.length > 0) {
          let index = fileList.findIndex(file=>file.filename == filename && id != file._id)
          return index > -1
        }else{
          return false
        }
      },
      //查询证据中心商标档案列表
      async getTMArchives({dispatch},{searchVal,suffixList=[],is_suffix=true,brandNames=[]}){
        let params = {
          is_evidence:true,
        }
        let {total,data}  =   await Getofficial(params)
        if (total ==0 ) return []
        // 更改商标档案除od类型文件的商标信息
        for (const item of data) {
          if (item.trademark) continue;
          const tm_id = _.get(item, ["tm_ids", "0"]);
          if (tm_id) {
            await dispatch("try_decorate_doc", { doc: item, tm_id });
          }
        }
        let {extensions:suffixs,isFindOther} = getFileExtensions(suffixList)
        return data.filter(item=>{
          let brandFilter = true
          let suffixFilter = !is_suffix
          let searchValFilter = true
          if (brandNames.length > 0) {
            brandFilter =  item.trademark?.brand_name ? brandNames.includes(item.trademark.brand_name) : false;
          }
          if (is_suffix) {
            let { suffix,iconUrl } = getfileSuffixAndImg(item.originalname);
            // suffixFilter = suffixs.includes('.' + suffix)
            suffixFilter = isFindOther ? !suffixs.includes('.' + suffix) :  suffixs.includes('.' + suffix)
          }
          if (searchVal) {
            let reg = new RegExp(escapeRegExp(searchVal))
            searchValFilter = (item.originalname ? item.originalname.search(reg) != -1 :false ) || ( item.trademark?.brand_name ?  item.trademark.brand_name.search(reg) != -1 : false)
          }
          return brandFilter && suffixFilter && searchValFilter
        })

      },
      //查询证据中心著作权列表
      async getcopyrightArchives({},{searchVal=null,suffixList=[],is_suffix=true,brandNames=null}){
        let params = {
          type:'get_evidence'
        }
        let data =  await findFiletList(params)
        if (data && data.length== 0) return []
        let {extensions:suffixs,isFindOther} = getFileExtensions(suffixList)
        return data.filter(item=>{
          let brandFilter = true
          let suffixFilter = !is_suffix
          let searchValFilter = true
          if (brandNames.length > 0) {
            brandFilter = item?.brand_name ? brandNames.includes(item?.brand_name) : false;
          }
          if (is_suffix) {
            let { suffix,iconUrl } = getfileSuffixAndImg(item.filename);
            // suffixFilter = suffixs.includes('.' + suffix)
            suffixFilter = isFindOther ? !suffixs.includes('.' + suffix) :  suffixs.includes('.' + suffix)
          }
          if (searchVal) {
            let reg = new RegExp(escapeRegExp(searchVal))
            searchValFilter = (item.filename ? item.filename.search(reg) != -1 :false ) || ( item.brand_name ?  item.brand_name.search(reg) != -1 : false)
          }

          return brandFilter && suffixFilter && searchValFilter
        })
      },
      // 证据中心全局搜索
      async getAllEvidenceSearch({},{search_str=null,brand_id,ext_type_list}){
        let query = {
          type:'global_search',
        };
        if (search_str)  query.search_str = search_str;
        if (brand_id)  query.brand_id = brand_id;
        if (ext_type_list  && ext_type_list.length > 0)  query.ext_type_list = ext_type_list;
        return await find_evidence(query); 
      },
      // 复制非证据中心的档案
      async copyOutsideEvidence({},{outsideIds=[],fileType,logic_path}){
        let params = {
          type:'copy_outside',
        }
        let data = {
          outsideId:'',
          fileType,
          logic_path
        }
        let err_ids = [];
        for (const outsideId of outsideIds) {
          if (!outsideId) continue;
          data.outsideId = outsideId;
          await post_evidence(data,params).catch(err=>{
            console.log('非证据中心的复制功能错误 :>>', err);
            err_ids.push(outsideId)
          })
        }
        return Array.from(new Set(err_ids))
      }
    }
}

// function  evidenceTreeData(array) {
//   array.forEach(item=>{
//     item.treeRightClickIconShow = false;
//     if (item.children && item.children.length > 0) {
//       evidenceTreeData(item.children)
//     }
//   })
// }

function sortEvidenceTree(array) {
    array.sort((a, b) => {
      return customSort(a['name'],b['name']);
    });
    
    for (const folder  of array) {
      if (folder.children && folder.children.length > 0) {
        sortEvidenceTree(folder.children);
      }
    }
}
export { evidenceModule }